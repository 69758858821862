<template>
	<div>
		<form-wrapper
			ref="formWrapper"
			:store-url="storeUrl"
			:back-url="backUrl"
			:initial-flash="initialFlash"
			v-model="attributes"
			:buttons="buttons"
		>
			<template #default="{activeTab, errors}">
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="card-title mb-4">Adatok</h4>
						<div class="row">
							<div class="col-sm-6">
								<input-field
									label="Név"
									name="name"
									rules="required"
									v-model="attributes.name"
									:errors="errors"
								/>

								<select-field
									label="Szerepek"
									name="roleIdArray"
									v-model="attributes.roleIdArray"
									:options="roles"
									:multiple="true"
									:errors="errors"
								/>

								<iconpicker-field
									v-model="attributes.icon"
									label="Ikon"
									name="icon"
								/>
							</div>
							<div class="col-sm-6">
								<input-field
									label="Prioritás"
									name="priority"
									rules="required"
									v-model="attributes.priority"
									data-type="number"
									:step="1"
									:min="1"
									:errors="errors"
								/>

								<div class="mb-3">
									<label>Szín</label>
									<input type="color" class="form-control form-control-color" v-model="attributes.color"  />
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import SwitchField from "../../components/form/SwitchField.vue"
import FormWrapper from "../../components/FormWrapper.vue";
import {formatDate} from "../../functions";
import SelectField from "../../components/form/SelectField.vue";
import IconpickerField from "../../components/form/IconpickerField.vue";
export default {
	props: ['initialAttributes', 'storeUrl', 'backUrl', 'initialFlash', 'allowEditing', 'roles'],
	components: {IconpickerField, InputField, SwitchField, FormWrapper, SelectField},
	data(){
		let buttons = []
		let attributes = this.initialAttributes
		attributes.roleIdArray = []
		_.forEach(attributes.roles, function(value){
			attributes.roleIdArray.push(value.id)
		})
		return {
			attributes: attributes,
			loading: false,
			flash: [],
			buttons: buttons
		}
	},
	mounted() {
	},
	methods: {
		formatDate: formatDate,
	}
}
</script>

<style scoped>

</style>