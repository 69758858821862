<template>
	<DataTable
		:data-url="dataUrl"
		:initial-flash="initialFlash"
		:columns="columns"
		sort-by="priority"
		sort-type="desc"
		name="productCategories"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
				:data="item"
				:edit-url="editUrl"
				:delete-url="deleteUrl"
				:is-editable="isEditable"
				:is-deletable="isDeletable"
				:emit-delete-event="true"
				@deleteButtonPressed="deleteButtonPressed"
			></TableActionCell>
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import {formatDate} from "../../functions";

export default {
	components: {
		TableActionCell,
		DataTable
	},
	props: {
		dataUrl: String,
		editUrl: String,
		deleteUrl: String,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Prioritás',
				value: 'priority',
				sortable: true,
				width: 10
			},
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'Publikus név',
				value: 'name_public',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
		}
	},
	methods: {
		formatDate: formatDate,
		isEditable: function(row){
			return row.editable
		},
		isDeletable: function(row){
			return row.deletable
		}
	}
}
</script>

<style scoped>
</style>
