<template>
	<i :class="iconClass" :style="iconStyle"></i>
</template>

<script>
export default {
	props: {
		filename: String,
		fontSize: {
			type: String,
			default: ''
		}
	},
	computed: {
		iconStyle: {
			get() {
				return this.fontSize ? 'font-size:' + this.fontSize : ''
			}
		},
		iconClass: {
			get(){
				let extension = this.filename
				if (extension.indexOf('.')){
					let tmp = extension.split('.')
					extension = tmp.pop()
				}
				const icons = {
					image: 'fa-file-image',
					pdf: 'fa-file-pdf',
					word: 'fa-file-word',
					powerpoint: 'fa-file-powerpoint',
					excel: 'fa-file-excel',
					csv: 'fa-file-csv',
					audio: 'fa-file-audio',
					video: 'fa-file-video',
					archive: 'fa-file-archive',
					code: 'fa-file-code',
					text: 'fa-file-alt',
					file: 'fa-file'
				}
				const extensions = {
					gif: icons.image,
					jpeg: icons.image,
					jpg: icons.image,
					png: icons.image,

					pdf: icons.pdf,

					doc: icons.word,
					docx: icons.word,

					ppt: icons.powerpoint,
					pptx: icons.powerpoint,

					xls: icons.excel,
					xlsx: icons.excel,

					csv: icons.csv,

					aac: icons.audio,
					mp3: icons.audio,
					ogg: icons.audio,

					avi: icons.video,
					flv: icons.video,
					mkv: icons.video,
					mp4: icons.video,

					gz: icons.archive,
					zip: icons.archive,

					css: icons.code,
					html: icons.code,
					js: icons.code,

					txt: icons.text
				}

				if (extensions[extension] !== undefined){
					return 'far ' + extensions[extension]
				}
				return 'far fa-file'
			}
		}
	}
}
</script>

<style scoped>

</style>