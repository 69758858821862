<template>
	<div class="d-flex flex-column align-items-start gap-2 flex-wrap" v-if="milestoneArray.length">
		<div v-for="milestone in milestoneArray" :class="'milestone-badge milestone-badge-' + milestone.luminosity" :style="'background:' + milestone.color + ';'">
			<i :class="'far fa-' + milestone.icon"></i> {{ milestone.short_name }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		data: Object,
		milestones: {
			type: Object,
			default: function(){
				return {}
			}
		}
	},
	created: function () {
	},
	computed: {
		milestoneArray: {
			get(){
				let array = this.data.milestones !== null ? this.data.milestones.split(',').map(Number) : []
				let payload = []
				let lastPriority = null
				let lastNormal = null

				_.forEach(this.milestones, function(milestone){
					if (array.indexOf(milestone.id) > -1){
						if (milestone.priority){
							lastPriority = milestone
						} else {
							lastNormal = milestone
						}
					}
				})
				payload = []
				if (lastNormal){
					payload.push(lastNormal)
				}
				if (lastPriority){
					payload.push(lastPriority)
				}
				return payload
			}
		}
	}
}
</script>

<style scoped>

</style>