<template>

	<DataTable
			:data-url="dataUrl"
			:initial-flash="initialFlash"
			:columns="columns"
			sort-by="created_at"
			sort-type="desc"
			name="inventoryProduct"
	>
		<template #filters="{serverOptions}">
			<div class="col-12 col-md-3">
				<v-select
						:options="warehouseArray"
						v-model="serverOptions.warehouse_id"
						:reduce="item => item.id"
						label="name"
						placeholder="Raktárak"
				/>
			</div>
			<div class="col-12 col-md-3">
				<v-select
						:options="statuses"
						v-model="serverOptions.status"
						:reduce="item => item.id"
						label="name"
						placeholder="Státusz"
				/>
			</div>
		</template>
		<template #item-operations="{item, deleteButtonPressed}">
			<div class="table-row-operations d-flex gap-2">
				<a :href="viewUrl + '/' + item.id" class="btn btn-primary btn-sm waves-effect" title="Részletek"><i class="far fa-eye"></i></a>
			</div>
		</template>
		<template #item-quantity="item">
			{{ item.quantity }}
		</template>
		<template #item-serial_number="item">
			{{ item.serial_number }}
		</template>
		<template #item-previous_quantity="item">
			{{ item.previous_quantity }}
		</template>
		<template #item-change="item">
			<div :class="{'text-success': item.quantity > item.previous_quantity, 'text-danger': item.quantity < item.previous_quantity}">{{ (item.quantity > item.previous_quantity ? '+' : '') + (item.quantity - item.previous_quantity) }}</div>
		</template>
		<template #item-created_at="item">
			{{ formatDate(item.created_at) }}
		</template>
		<template #item-shipmentId="item">
			<div v-html="getLink(item)"></div>
		</template>
		<template #item-source="item">
			<div v-html="getMovement(item)"></div>
		</template>
		<template #item-warehouse_id="item">
			{{ warehouses[item.warehouse_id].name }}
		</template>
		<template #item-eventType="item">
			<template v-if="types[item.eventType] !== undefined">
				<span :class="'badge badge-xl bg-' + types[item.eventType].color"><i :class="types[item.eventType].icon"></i> {{ types[item.eventType].name }}</span>
			</template>
			<template v-else>
				<span class="badge badge-xl bg-dark"><i class="far fa-scanner-gun"></i> Leltár</span>
			</template>
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import BooleanBadge from "../../components/BooleanBadge.vue";
import vSelect from "vue-select";
import {formatDate} from "../../functions";

export default {
	components: {
		DataTable,
		BooleanBadge,
		TableActionCell,
		vSelect
	},
	props: {
		dataUrl: String,
		viewUrl: String,
		inspectionUrl: String,
		shipmentUrl: String,
		warehouses: Object,
		types: Object,
		product: Object,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	computed: {
		warehouseArray: {
			get() {
				return _.values(this.warehouses)
			}
		}
	},
	data(){
		let columns = [
				{
					text: "Típus",
					value: "eventType",
					sortable: false
				},
				{
					text: "Időpont",
					value: "created_at",
					sortable: true
				},
				{
					text: "Raktár",
					value: "warehouse_id",
					sortable: true
				},
				{
					text: "Előző mennyiség",
					value: "previous_quantity",
					align: 'right',
					sortable: true,
				},
				{
					text: "Változás",
					value: "change",
					align: 'right',
					sortable: false,
				},
				{
					text: "Új mennyiség",
					value: "quantity",
					align: 'right',
					sortable: true,
				}
			]

		if (this.product.track_serial_number) {
			columns.push({
				text: "Sorozatszám",
				value: "serial_number",
				sortable: true,
			})
		}
		columns.push({
			text: "Mozgás",
			value: "source",
			sortable: false
		})

		columns.push({
			text: "Azon.",
			value: "shipmentId",
			sortable: true,
		})

		columns.push({
			text: "Felhasználó",
			value: "userName",
			sortable: true
		})


		return {
			columns: columns,
			productCategoriesById: _.keyBy(this.productCategories, 'id'),
			productManufacturersById: _.keyBy(this.productManufacturers, 'id'),
			statuses: [
				{
					id: 'inStock',
					name: 'Készleten'
				},
				{
					id: 'removed',
					name: 'Kiszállítva'
				}
			]
		}
	},
	methods: {
		formatDate,
		getMovement: function(item){
			let source = ''
			let destination = ''
			if (item.eventType === 'incoming') {
				source = item.supplierName
			}
			if (item.eventType === 'transfer' || item.eventType === 'delivery') {
				source = item.sourceWarehouse
			}
			if (item.eventType === 'delivery') {
				destination = item.clientName
			}
			if (item.eventType === 'return') {
				source = item.clientName || item.sourceShipmentName
			}

			if (item.eventType === 'incoming' || item.eventType === 'transfer' || item.eventType === 'return') {
				destination = item.destinationWarehouse
			}
			if (item.eventType === 'delivery' && !destination){
				destination = item.customDestinationName
			}

			if (!source && !destination){
				return ''
			}
			if (!source){
				return destination
			}
			if (!destination){
				return source
			}
			return source + ' <i class="far fa-arrow-right"></i> ' + destination
		},
		getLink: function(item){
			if (item.eventType === 'assign') {
				return '-'
			}
			if (item.eventType === 'inspection') {
				return '<a href="' + this.inspectionUrl + '/' + item.inventory_inspection_id + '"><i class="far fa-link"></i> ' + item.inventory_inspection_id + '</a>'
			} else {
				return '<a href="' + this.shipmentUrl + '/' + item.inventory_shipment_id + '"><i class="far fa-link"></i> ' + item.shipmentId + '</a>'
			}
		}
	}
}
</script>

<style scoped>
</style>
