<template>
	<InventoryShipmentForm
		v-if="attributes.id === undefined"
		v-model="attributes"
		:warehouses="warehouses"
		:suppliers="suppliers"
		:store-url="storeUrl"
		:back-url="backUrl"
		:contracts="contracts"
		:shipment-search-url="shipmentSearchUrl"
		:correction-types="correctionTypes"
		:shipments="shipments"
		:file-upload-url="fileUploadUrl"
		:delivery-without-contract="deliveryWithoutContract"
		@shipment-created="shipmentCreated"
	/>
	<template v-else>
		<div class="card">
			<div class="card-body">
				<div class="details-box mb-0">
					<div>
						<i class="far fa-tag"></i> Típus:
						<span :class="'badge badge-xl bg-' + types[attributes.type].color">
							<i :class="types[attributes.type].icon"></i> {{ types[attributes.type].name }}
						</span>
					</div>
					<div>
						<i class="far fa-hashtag"></i> Azonosító: <strong>{{ attributes.public_id }}</strong>
					</div>
					<div v-if="attributes.foreign_id">
						<i class="far fa-hashtag"></i> Külső azonosító: <strong>{{ attributes.foreign_id }}</strong>
					</div>
					<div>
						<i class="far fa-user"></i> Létrehozó: <strong>{{ attributes.created_by_user_name }}</strong>
					</div>
					<div v-if="attributes.created_at">
						<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(attributes.created_at)  }}</strong>
					</div>
					<template v-if="attributes.type === 'correction'">
						<div v-if="attributes.source_warehouse">
							<i class="far fa-warehouse-alt"></i> Raktár: <strong>{{ attributes.source_warehouse.name }}</strong>
						</div>
						<div v-if="attributes.inventory_shipment">
							<i class="far fa-truck"></i> Szállítmány: <strong> <a :href="shipmentEditUrl + '/' + attributes.inventory_shipment_id">{{ attributes.inventory_shipment.public_id }}</a></strong>
						</div>
						<div>
							<i class="far fa-tag"></i> Korrekció típus:
							<span :class="'badge badge-xl bg-' + correctionTypes[attributes.correction_type].color">
							<i :class="correctionTypes[attributes.correction_type].icon"></i> {{ correctionTypes[attributes.correction_type].name }}
						</span>
						</div>
					</template>
					<template v-else>
						<div v-if="attributes.type === 'return' && !attributes.contract_id">
							<i class="far fa-truck"></i> Eredeti szállítmány: <strong>{{ attributes.inventory_shipment.public_id + ' (' + attributes.inventory_shipment.destination_name + ')' }}</strong>
						</div>
						<div v-if="attributes.supplier">
							<i class="far fa-truck"></i> Beszállító: <strong>{{ attributes.supplier.name }}</strong>
						</div>
						<div v-if="attributes.source_warehouse">
							<i class="far fa-warehouse-alt"></i> Raktárból: <strong>{{ attributes.source_warehouse.name }}</strong>
						</div>
						<div v-if="attributes.destination_warehouse">
							<i class="far fa-warehouse-alt"></i> Raktárba: <strong>{{ attributes.destination_warehouse.name }}</strong>
						</div>
						<div v-if="attributes.contract && attributes.contract.project && attributes.contract.project.client">
							<i class="far fa-dolly"></i> Ügyfél: <strong>{{ attributes.contract.public_id + ' - ' + attributes.contract.project.client.name }}</strong>
						</div>
						<div v-if="attributes.destination_name && attributes.destination_address">
							<i class="far fa-dolly"></i> Címzett: <strong>{{ attributes.destination_name + ' (' + attributes.destination_address + ')' }}</strong>
						</div>
					</template>
					<div>
						<i class="far fa-lock"></i> Lezárva:
						<template v-if="attributes.closed_at"><strong>{{ formatDate(attributes.closed_at) }}</strong></template>
						<template v-else><span class="badge badge-xl bg-warning"><i class="far fa-clock"></i> Még nincs lezárva</span> </template>
					</div>

					<div v-if="attributes.comment_html" class="full-width text">
						<i class="far fa-comment"></i> Megjegyzés:
						<div v-html="attributes.comment_html"></div>
					</div>
				</div>
				<div v-if="attributes.files && attributes.files.length" class="mt-3">
					<FileList :files="attributes.files" title="Csatolt fileok" />
				</div>
			</div>
		</div>
		<div class="d-flex gap-2 mb-3 mt-3 mb-3 flex-wrap">
			<button type="button" class="btn btn-primary btn-label" v-if="!attributes.closed_at && attributes.editable" @click.prevent="closeShipment"><i class="far fa-lock label-icon"></i> Lezárás</button>
			<a :href="createCorrectionUrl + '?correction_type=incoming&inventory_shipment_id=' + attributes.id" class="btn btn-warning btn-label" v-if="attributes.closed_at && attributes.type === 'incoming' && canCreateCorrection"><i class="far fa-edit label-icon"></i> Korrekció létrehozása</a>
			<template v-if="attributes.file_id">
				<a :href="storeUrl + '/download'" class="btn btn-warning btn-label" v-if="attributes.type === 'transfer' && (attributes.source_warehouse.mobile || attributes.destination_warehouse.mobile)"><i class="far fa-download label-icon"></i> Átadás-átvételi jegyzőkönyv letöltése</a>
				<a :href="storeUrl + '/download'" class="btn btn-warning btn-label" v-else><i class="far fa-download label-icon"></i> Szállítólevél letöltése</a>
			</template>
			<a :href="backUrl" class="btn btn-secondary btn-label"><i class="far fa-angle-left label-icon"></i> Vissza</a>
		</div>
		<template v-if="!attributes.closed_at && attributes.editable">
			<div class="row">
				<div class="col-md-6">
					<div class="card">
						<div class="card-body">
							<div class="mb-4 d-flex align-items-center">
								<h4 class="card-title">Termék kiválasztása</h4>
								<button type="button" class="btn btn-secondary btn-sm ms-auto" @click.prevent="openAddProductForm"><i class="far fa-plus"></i> Termék hozzáadása</button>
							</div>

							<v-select
								:options="products"
								v-model="selectedProductId"
								:reduce="item => item.id"
								label="name"
								:filter-by="productFilter"
								v-on:option:selected="onProductSelected"
								:clearable="true"
								placeholder="Kattints ide"
							>
								<template v-slot:option="option">
									<div class="d-flex align-items-center">({{ option.sku }}) {{ option.name }} <i class="far fa-tag ms-1 font-size-12" v-if="option.track_serial_number"></i></div>
									<div class="font-size-11 fst-italic">{{ option.categoryName }}</div>
								</template>
								<template v-slot:selected-option="option">
									({{ option.sku }}) {{ option.name }}
								</template>
							</v-select>

							<div v-if="barcodeDownloadUrl && selectedProductId" class="mt-2 text-center">
								<button type="button" class="btn btn-secondary btn-label" @click.prevent="printBarcode"><i class="far fa-print label-icon"></i> Vonalkód nyomtatása</button>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="card">
						<div class="card-body">
							<h4 class="card-title mb-4">{{ selectedProductId && productsById[selectedProductId].track_serial_number ? 'Sorozatszám megadása' : 'Vonalkód megadása' }}</h4>

							<form v-on:submit.prevent="barcodeSubmit">
								<input-field
									name="barcode"
									:label="selectedProductId && productsById[selectedProductId].track_serial_number ? 'Sorozatszám' : 'Vonalkód'"
									v-model="barcode"
									:field-only="true"
									id="barcodeInput"
									ref="barcodeInput"
									:show-placeholder="true"
								/>
							</form>

							<template v-if="selectedProductId">
								<div class="mt-3 d-flex justify-content-center">
									<button class="btn btn-secondary btn-label " @click.prevent="selectedProductId = null">
										<i class="far fa-barcode-scan label-icon"></i> Új termék kiválasztása
									</button>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="card-body">
					<h4 class="card-title mb-4">Mennyiség</h4>
					<div class="row">
						<div class="col-md-6">
							<input-field
								name="quantityInput"
								v-model="quantityInput"
								:field-only="true"
								data-type="number"
								:step="1"
								:min="1"
								:disabled="!selectedProductId || (!!productsById[selectedProductId].track_serial_number && barcode.length < 6)"
								:spinner-buttons="true"
							/>
						</div>
						<template v-if="canSubtract">
							<div class="col-md-3">
								<button class="btn btn-success w-100 d-block" :disabled="!selectedProductId || (!!productsById[selectedProductId].track_serial_number && barcode.length < 6)" @click.prevent="sendScanRequest(true)"><i class="far fa-plus"></i> Hozzáad</button>
							</div>
							<div class="col-md-3">
								<button class="btn btn-danger w-100 d-block" :disabled="!selectedProductId || (!!productsById[selectedProductId].track_serial_number && barcode.length < 6)" @click.prevent="sendScanRequest(false)"><i class="far fa-minus"></i> Kivesz</button>
							</div>
						</template>
						<div class="col-md-6" v-else>
							<button class="btn btn-success w-100 d-block" :disabled="!selectedProductId || (!!productsById[selectedProductId].track_serial_number && barcode.length < 6)" @click.prevent="sendScanRequest(true)"><i class="far fa-plus"></i> Hozzáad</button>
						</div>
					</div>
				</div>
			</div>
		</template>

		<div class="card" v-if="getRequiredProducts.length && !attributes.closed_at">
			<div class="card-body pb-1">
				<h4 class="card-title mb-3">{{ attributes.type === 'return' ? 'Kiszállított termékek' : 'Szükséges termékek' }}</h4>
				<table class="table mb-3" v-if="attributes.type === 'delivery'">
					<thead>
					<tr>
						<th>Cikkszám</th>
						<th>Terméknév</th>
						<th class="text-end">Szükséges</th>
						<th class="text-end">Lefoglalva</th>
						<th class="text-end">Hozzáadva</th>
					</tr>
					</thead>
					<tbody>
					<template v-for="item in getRequiredProducts">
						<tr v-if="this.productsById[item.product_id] !== undefined" :key="'requiredProduct_' + item.id" :class="'table-' + getRequiredProductColorClass(item)">
							<td>{{ getProductById(item.product_id).sku }}</td>
							<td>{{ getProductById(item.product_id).name }}</td>
							<td class="text-end">{{ formatNumber(item.quantity - item.delivered) }}</td>
							<td class="text-end">{{ item.reserved }}</td>
							<td :class="'text-end fw-bold py-1 font-size-16 text-' + getRequiredProductColorClass(item)" style="vertical-align: middle">{{ item.scanned }}</td>
						</tr>
					</template>
					</tbody>
				</table>
				<table class="table mb-3" v-else>
					<thead>
					<tr>
						<th>Cikkszám</th>
						<th>Terméknév</th>
						<th class="text-end">Kiszállított</th>
						<th class="text-end">Hozzáadva</th>
					</tr>
					</thead>
					<tbody>
					<template v-for="item in getRequiredProducts">
						<tr v-if="this.productsById[item.product_id] !== undefined" :key="'requiredProduct_' + item.id" :class="'table-' + getRequiredProductColorClass(item)">
							<td>{{ getProductById(item.product_id).sku }}</td>
							<td>{{ getProductById(item.product_id).name }}</td>
							<td class="text-end">{{ item.delivered }}</td>
							<td :class="'text-end fw-bold py-1 font-size-16 text-' + getRequiredProductColorClass(item)" style="vertical-align: middle">{{ item.scanned }}</td>
						</tr>
					</template>
					</tbody>
				</table>
			</div>
		</div>

		<div class="card">
			<div class="card-body pb-1">
				<h4 class="card-title mb-3">Termékek</h4>
				<div class="alert alert-info" v-if="!items.length">Még nincsenek termékek beolvasva.</div>

				<flash-display :flash="flash" :inline="!attributes.closed_at"></flash-display>

				<div v-if="items.length">
					<table class="table table-striped mb-3">
						<thead>
						<tr>
							<th>Cikkszám</th>
							<th>Terméknév</th>
							<th class="text-end">Mennyiség</th>
							<th class="text-end">Sorszám</th>
							<th class="text-end">Sorozatszám</th>
							<th class="text-end">Időpont</th>
							<th width="1%" v-if="canEdit">&nbsp;</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="item in items" :key="item.id">
							<td>{{ getProductById(item.product_id).sku }}</td>
							<td>{{ getProductById(item.product_id).name }}</td>
							<td :class="{'button-cell': 1, 'text-end': 1, 'text-danger': canSubtract && item.quantity < 0, 'text-success': canSubtract && item.quantity > 0}">
								<div v-if="canSubtract" class="d-flex gap-2 align-items-center justify-content-end">
									<button type="button" class="btn btn-secondary btn-sm me-2" @click.prevent="toggleAddRemove(item)" v-if="!attributes.closed_at && attributes.editable">
										<i class="far fa-plus"></i> / <i class="far fa-minus"></i>
									</button>
									<div class="fw-bold">{{ item.quantity }}</div>
								</div>
								<template v-else>
									<div class="fw-bold">{{ item.quantity }}</div>
								</template>
							</td>
							<td class="text-end">{{ item.counter }}/{{ item.counter_max }}</td>
							<td class="text-end">{{ item.serial_number }}</td>
							<td class="text-end">{{ formatDate(item.created_at) }}</td>
							<td v-if="canEdit" class="button-cell"><button type="button" :class="'btn btn-danger btn-sm' + (item.deleteLoading !== undefined ? ' btn-loading' : '')" @click.prevent="removeItem(item)"><i class="far fa-times"></i></button></td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div class="d-flex gap-2 mb-3 mt-3 mb-3 flex-wrap">
			<button type="button" class="btn btn-primary btn-label" v-if="!attributes.closed_at && attributes.editable" @click.prevent="closeShipment"><i class="far fa-lock label-icon"></i> Lezárás</button>
			<a :href="backUrl" class="btn btn-secondary btn-label"><i class="far fa-angle-left label-icon"></i> Vissza</a>
		</div>
	</template>
	<teleport to="body" v-if="unknownBarcode">
		<div class="modal fade d-block show" tabindex="-1" aria-hidden="true">
			<div :class="'modal-dialog' + (mapBarcodeModalLoading ? ' loading loading--light' : '')">
				<div class="modal-content">
					<div class="modal-header">
						<h1 class="modal-title fs-5">Ismeretlen vonalkód: {{ unknownBarcode }}</h1>
					</div>
					<div class="modal-body">
						<template v-if="!mapBarcodeModalAdd">
							<div class="alert alert-info">Kérlek válaszd ki, melyik termékhez tartozik a beolvasott vonalkód <strong>{{ unknownBarcode }}</strong>!</div>

							<product-select-field
								label="Termék kiválasztása"
								name="selectProduct"
								:type-filter="['material', 'consumable']"
								v-model="mapBarcodeToProduct"
							></product-select-field>

							<div class="mb-3" v-if="mapBarcodeToProduct">
								<label>Hozzárendelés</label>
								<div class="form-check mb-3">
									<input class="form-check-input" type="radio" value="product" id="mapProductTypeProduct" :disabled="productsById[mapBarcodeToProduct].barcode !== null" v-model="mapBarcodeToProductType">
									<label class="form-check-label" for="mapProductTypeProduct">
										Termék fő vonalkód <strong v-if="productsById[mapBarcodeToProduct].barcode !== null">({{ productsById[mapBarcodeToProduct].barcode }})</strong>
									</label>
								</div>
								<div class="form-check mb-3" v-for="supplier in suppliers">
									<input class="form-check-input" type="radio" :value="supplier.id" :id="'mapProductType' + supplier.id" :disabled="getSupplierBarcodeForProduct(mapBarcodeToProduct, supplier.id)" v-model="mapBarcodeToProductType">
									<label class="form-check-label" :for="'mapProductType' + supplier.id">
										{{ supplier.name }} <strong v-if="getSupplierBarcodeForProduct(mapBarcodeToProduct, supplier.id)">({{ getSupplierBarcodeForProduct(mapBarcodeToProduct, supplier.id) }})</strong>
									</label>
								</div>
							</div>

							<p class="fw-bold text-center font-size-15">vagy</p>
							<div class="d-flex justify-content-center">
								<button type="button" class="btn btn-primary btn-label" @click.prevent="mapBarcodeModalAdd = true">
									<i class="far fa-plus label-icon"></i> Új termék hozzáadása
								</button>
							</div>
						</template>
						<template v-if="mapBarcodeModalAdd">
							<InventoryAddProductForm
								v-model="mapBarcodeModalNewModel"
								:product-types="productTypes"
								:product-manufacturers="productManufacturers"
								:product-categories="productCategories"
								ref="mapBarcodeAddForm"
								@form-submitted="assignBarcodeToProduct"
								 ></InventoryAddProductForm>
						</template>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary btn-label" @click.prevent="closeUnknownBarcodeDialog"><i class="far fa-times label-icon"></i> Bezár</button>
						<button type="button" class="btn btn-primary btn-label" @click.prevent="assignBarcodeToProduct" :disabled="(!mapBarcodeToProduct || !mapBarcodeToProductType) && !mapBarcodeModalAdd"><i class="far fa-save label-icon"></i> Mentés</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-backdrop fade show"></div>
	</teleport>
	<teleport to="body" v-if="addProduct">
		<div class="modal fade d-block show" tabindex="-1" aria-hidden="true">
			<div :class="'modal-dialog' + (addProductModalLoading ? ' loading loading--light' : '')">
				<div class="modal-content">
					<div class="modal-header">
						<h1 class="modal-title fs-5">Termék hozzáadása</h1>
					</div>
					<div class="modal-body">
						<InventoryAddProductForm
							v-model="addProductModel"
							:product-types="productTypes"
							:product-manufacturers="productManufacturers"
							:product-categories="productCategories"
							ref="addProductForm"
							@form-submitted="storeNewProduct"
							 ></InventoryAddProductForm>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary btn-label" @click.prevent="closeAddProductForm"><i class="far fa-times label-icon"></i> Bezár</button>
						<button type="button" class="btn btn-primary btn-label" @click.prevent="newProductSubmit"><i class="far fa-save label-icon"></i> Hozzáadás</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-backdrop fade show"></div>
	</teleport>
</template>

<script>
import InventoryShipmentForm from "./InventoryShipmentForm.vue";
import FileList from "../../components/FileList.vue";
import vSelect from "vue-select";
import {formatDate, formatNumber} from "../../functions";
import InputField from "../../components/form/InputField.vue";
import FlashDisplay from "../../components/FlashDisplay.vue";
import ProductSelectField from "../../components/form/ProductSelectField.vue";
import SelectField from "../../components/form/SelectField.vue";
import SwitchField from "../../components/form/SwitchField.vue";
import FormWrapper from "../../components/FormWrapper.vue";
import RadioField from "../../components/form/RadioField.vue";
import InventoryAddProductForm from "./InventoryAddProductForm.vue";
import printJS from 'print-js'

export default {
	components: {InventoryAddProductForm, RadioField, FormWrapper, SwitchField, SelectField, ProductSelectField, FlashDisplay, InputField, InventoryShipmentForm, FileList, vSelect},
	props: {
		initialAttributes: Object,
		storeUrl: String,
		backUrl: String,
		fileUploadUrl: String,
		shipmentSearchUrl: String,
		shipmentEditUrl: String,
		initialFlash: Array,
		warehouses: Array,
		productCategories: Array,
		productManufacturers: Array,
		suppliers: Array,
		productsInitial: Array,
		contracts: Array,
		shipments: Array,
		requiredProducts: Array,
		deliveredProducts: Array,
		initialItems: Array,
		reservedProducts: Object,
		types: Object,
		correctionTypes: Object,
		productTypes: Object,
		deliveryWithoutContract: Boolean,
		canCreateCorrection: Boolean,
		barcodeDownloadUrl: String,
		createCorrectionUrl: String,
	},
	data(){
		return {
			attributes: this.initialAttributes,
			products: this.productsInitial,
			selectedProductId: null,
			barcode: '',
			unknownBarcode: '',
			quantityInput: 1,
			successSound: '',
			errorSound: '',
			selectedSound: '',
			items: this.initialItems,
			flash: [],
			mapBarcodeToProduct: null,
			mapBarcodeToProductType: null,
			mapBarcodeModalLoading: false,
			mapBarcodeModalAdd: false,
			mapBarcodeModalNewModel: {},
			addProduct: false,
			addProductModalLoading: false,
			addProductModel: {},
		}
	},
	created(){
		this.successSound = new Audio('/audio/inventory-scan-success.mp3')
		this.successSound.preload = true

		this.errorSound = new Audio('/audio/inventory-scan-error.mp3')
		this.errorSound.preload = true

		this.selectedSound = new Audio('/audio/inventory-scan-selected.mp3')
		this.selectedSound.preload = true

		window.addEventListener("keypress", e => {
			if (e.target.tagName.toLowerCase() !== 'input'){
				if (e.key.match(/^[0-9a-z]+$/)){
					this.barcode = e.key
					document.getElementById('barcodeInput').focus()
				}
			}
		});

	},
	computed: {
		productsById: {
			get(){
				return _.keyBy(this.products, 'id')
			}
		},
		canEdit: {
			get(){
				return !this.attributes.closed_at
			}
		},
		canSubtract: {
			get(){
				if (this.attributes.type === 'correction' && (this.attributes.correction_type === 'incoming' || this.attributes.correction_type === 'inventory')){
					return true
				}

				return false
			}
		},
		getRequiredProducts: {
			get(){
				let payload = []
				for (let i in this.requiredProducts){
					let item = Object.assign({}, this.requiredProducts[i])
					item.scanned = 0
					item.reserved = 0

					if (this.reservedProducts[this.requiredProducts[i].product_id] !== undefined){
						item.reserved = this.reservedProducts[this.requiredProducts[i].product_id]
					}

					for (let j in this.items){
						if (this.items[j].product_id === item.product_id){
							item.scanned = this.items[j].counter_max
						}
					}

					if (this.attributes.type === 'delivery') {
						if (item.quantity > item.delivered) {
							payload.push(item)
						}
					}
					if (this.attributes.type === 'return' && item.quantity > 0) {
						item.delivered = item.quantity
						payload.push(item)
					}
				}

				return payload
			}
		}
	},
	methods: {
		formatNumber,
		formatDate,
		shipmentCreated: function(attributes){
			this.attributes = attributes
		},
		getProductById: function(id){
			if (this.productsById[id] === undefined){
				return {}
			}

			return this.productsById[id]
		},
		productFilter: function(option, label, search) {
			return option.categoryName.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
				|| option.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
				|| option.sku.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
		},
		barcodeSubmit: function(){
			if (this.barcode.length < 6){
				return
			}
			this.sendScanRequest(true)
		},
		showLoading: function(){
			document.getElementsByTagName('html')[0].classList.add('loading')
		},
		hideLoading: function(){
			document.getElementsByTagName('html')[0].classList.remove('loading')
		},
		sendScanRequest: function(add){
			this.showLoading()

			axios.post(this.storeUrl + '/scan', {
				barcode: this.barcode,
				productId: this.selectedProductId ? this.selectedProductId : null,
				quantity: add ? this.quantityInput : this.quantityInput * -1,
			}).then((response) => {
				if (response.data.action === 'error'){
					this.playSound(this.errorSound)
				}
				if (response.data.action === 'selected'){
					this.playSound(this.selectedSound)
				}
				if (response.data.action === 'added'){
					this.playSound(this.successSound)
				}

				if (response.data.productId !== undefined) {
					this.selectedProductId = response.data.productId
				}

				this.flash = response.data.flash
				this.items = response.data.items

				if (response.data.unknownBarcode !== undefined){
					this.barcode = response.data.unknownBarcode
					this.openUnknownBarcodeDialog()
				} else {
					this.quantityInput = 1
					this.barcode = ''
				}

				this.hideLoading()
			})
			.catch((error) => {
				this.barcode = ''

				this.hideLoading()
				this.flash = [{message: 'Hiba történt', level: 'danger'}]
			})
		},
		playSound: function(audio){
			if (!audio.paused){
				audio.pause()
			}
			audio.currentTime = 0
			audio.play()
		},
		onProductSelected: function(){
			this.quantityInput = 1
		},
		removeItem: function(inventoryItem){
			inventoryItem.deleteLoading = true

			axios.post(this.storeUrl + '/remove', {
				inventoryItemId: inventoryItem.id
			})
			.then((response) => {
				this.items = response.data.items
			})
			.catch((error) => {
				this.flash = [{message: 'Hiba történt', level: 'danger'}]
			})
		},
		getSupplierBarcodeForProduct: function(product, supplierId){
			for (let i in product.supplier_products){
				if (product.supplier_products[i].supplier_id === supplierId && product.supplier_products[i].barcode !== null){
					return product.supplier_products[i].barcode
				}
			}
			return false
		},
		assignBarcodeToProduct: function(){
			if (this.mapBarcodeModalAdd){
				let promise = this.$refs.mapBarcodeAddForm.validate()
				let that = this
				promise.then(function(status){
					if (status.valid){
						that.sendAssignCommand()
					}
				})
			} else {
				this.sendAssignCommand()
			}

		},
		sendAssignCommand: function(){
			this.mapBarcodeModalLoading = true
			axios.post(this.storeUrl + '/assign', {
				barcode: this.barcode,
				productId: this.mapBarcodeToProduct,
				type: this.mapBarcodeToProductType,
				add: this.mapBarcodeModalAdd,
				product: this.mapBarcodeModalAdd ? this.mapBarcodeModalNewModel : {}
			})
				.then((response) => {
					this.mapBarcodeModalLoading = false
					if (response.data.success) {
						this.unknownBarcode = ''
						this.products = response.data.products
						this.flash = response.data.flash

						this.sendScanRequest(true)
					} else {
						this.$refs.mapBarcodeAddForm.setFlash(response.data.flash)
					}
				})
				.catch((error) => {
					this.$refs.mapBarcodeAddForm.setFlash([{message: 'Hiba történt', level: 'danger'}])
					this.mapBarcodeModalLoading = false
				})
		},
		newProductSubmit: function(){
			let promise = this.$refs.addProductForm.validate()
			let that = this
			promise.then(function(status){
				if (status.valid){
					that.storeNewProduct()
				}
			})
		},
		storeNewProduct: function(){
			this.addProductModalLoading = true
			axios.post(this.storeUrl + '/addProduct', {
				product: this.addProductModel
			})
			.then((response) => {
				this.addProductModalLoading = false
				if (response.data.success) {
					this.closeAddProductForm()
					this.products = response.data.products
					this.flash = response.data.flash
					this.selectedProductId = response.data.product.id

					this.playSound(this.successSound)
				} else {
					this.$refs.addProductForm.setFlash(response.data.flash)
				}
			})
			.catch((error) => {
				this.$refs.addProductForm.setFlash([{message: 'Hiba történt', level: 'danger'}])
				this.mapBarcodeModalLoading = false
			})
		},
		closeShipment: function(){
			this.showLoading()
			axios.post(this.storeUrl + '/close', {})
			.then((response) => {
				if (response.data.success){
					this.attributes = response.data.model
					this.items = this.attributes.items
				}
				this.flash = response.data.flash

				this.hideLoading()
			})
			.catch((error) => {
				this.flash = [{message: 'Hiba történt', level: 'danger'}]
				this.hideLoading()
			})
		},
		getRequiredProductColorClass: function(item){
			if (this.attributes.type === 'return'){
				if (item.scanned > 0){
					return 'warning'
				}

				return 'light'
			}

			if (item.quantity === item.scanned){
				return 'success'
			}
			if (item.scanned > 0 && item.quantity > item.scanned){
				return 'warning'
			}
			if (item.scanned > 0){
				return 'warning'
			}

			return 'light'
		},
		toggleAddRemove: function(item){
			axios.post(this.storeUrl + '/toggleAddRemove', {
				inventoryItemId: item.id,
				set: item.quantity > 0 ? 'remove' : 'add'
			})
			.then((response) => {
				this.items = response.data.items
			})
			.catch((error) => {
				this.flash = [{message: 'Hiba történt', level: 'danger'}]
			})
		},
		openUnknownBarcodeDialog: function (){
			this.mapBarcodeToProduct = null
			this.mapBarcodeToProductType = null
			this.mapBarcodeModalAdd = false
			this.mapBarcodeModalLoading = false
			this.mapBarcodeModalNewModel = {
				type: 'material'
			}
			this.unknownBarcode = this.barcode
		},
		closeUnknownBarcodeDialog: function (){
			this.unknownBarcode = ''
			this.barcode = ''
		},
		openAddProductForm: function (){
			this.addProduct = true
			this.addProductModalLoading = false
			this.addProductModel = {
				type: 'material'
			}
		},
		closeAddProductForm: function (){
			this.addProduct = false
		},
		printBarcode: function (){
			printJS({printable: this.barcodeDownloadUrl + '/' + this.selectedProductId, type: 'pdf'})
		},
	}
}
</script>

<style scoped>

</style>