<template>
	<div>

		<form-wrapper
			v-model="client"
			:initial-flash="flash"
			:back-url="backUrl"
			:store-url="storeUrl"
			save-button-label="Küldés"
			save-button-icon="far fa-envelope"
		>
			<template #default="{activeTab, errors, editable}">
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="card-title mb-4">Adatok</h4>
						<radio-field
							label="Típus"
							v-model="client.is_company"
							:values="typeValues"
						/>
						<div class="row">
							<div class="col-sm-6">
								<input-field
									label="E-mail cím"
									name="email"
									data-type="email"
									rules="required"
									v-model="client.email"
									:errors="errors"
								/>
							</div>
							<div class="col-sm-6">
								<input-field
									label="Név"
									name="name"
									rules="required"
									v-model="client.name"
									:errors="errors"
								/>
							</div>
						</div>
					</div>
				</div>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import SwitchField from "../../components/form/SwitchField.vue"
import RadioField from "../../components/form/RadioField.vue"
import SelectField from "../../components/form/SelectField.vue"
import DateField from "../../components/form/DateField.vue";
import FormButtons from "../../components/FormButtons.vue";
import AddressInput from "../../components/AddressInput.vue";
import ProjectList from "../projects/ProjectList.vue";
import FormWrapper from "../../components/FormWrapper.vue";
import CopyToClipboard from "../../components/CopyToClipboard.vue";
import {formatDate} from "../../functions";

export default {
	components: {FormButtons, InputField, SwitchField, RadioField, SelectField, DateField, AddressInput, ProjectList, FormWrapper, CopyToClipboard},
	props: [
		'storeUrl',
		'backUrl',
		'initialFlash',
	],
	data(){
		return {
			client: {
				is_company: 0
			},
			flash: [],
			typeValues: [
				{
					label: 'Magánszemély',
					icon: 'far fa-user'
				},
				{
					label: 'Cég',
					icon: 'far fa-industry'
				},
			]
		}
	},
	mounted() {
		this.flash = this.initialFlash
	},
	methods: {
		formatDate: formatDate,
	}
}
</script>

<style scoped>

</style>