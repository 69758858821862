<template>
	<h5 v-if="title !== ''">{{ title }}</h5>
	<div class="file-list d-flex flex-wrap">
		<div v-for="file in files">
			<File :file="file"></File>
		</div>
	</div>
</template>

<script>
import File from "./File.vue";

export default {
	components: {File},
	props: {
		files: Array,
		title: {
			type: String,
			default: ''
		}
	}
}
</script>

<style scoped>
</style>