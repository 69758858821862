<template>
	<span :class="'project-type-badge project-type-badge-' + projectType.luminosity" :style="'background:' + projectType.color + ';'"><i :class="'far fa-' + projectType.icon"></i><span>{{ projectType.name }}</span></span>
</template>

<script>
export default {
	props: {
		data: Object,
		projectTypes: Array
	},
	data(){
		return {
			projectTypesById: _.keyBy(this.projectTypes, 'id')
		}
	},
	computed: {
		projectType: {
			get(){
				return this.projectTypesById[this.data.project_type_id]
			}
		}
	}
}
</script>

<style scoped>

</style>