<template>
	<div :class="{'loading': loading}">
		<div v-if="editable && (canCreate || canCreateForeignContract)" class="mb-3 d-flex gap-2 flex-wrap">
			<button type="button" class="btn btn-primary btn-label" v-if="canCreate" @click.prevent="contractFormId = null; formOpen = true"><i class="far fa-plus label-icon"></i> Szerződés létrehozása</button>
			<button type="button" class="btn btn-secondary btn-label" v-if="canCreateForeignContract" @click.prevent="foreignContractFormOpen = true"><i class="far fa-plus label-icon"></i> Külső szerződés hozzáadása</button>
		</div>
		<DataTable
			:data-url="listDataUrl"
			:initial-flash="initialFlash"
			:columns="columns"
			sort-by="created_at"
			ref="table"
			sort-type="desc"
			name="contracts"
			@data-loaded="dataLoaded"
		>
			<template #item-operations="{item, deleteButtonPressed}">
				<TableActionDropdown
					:data="item"
					:emit-edit-event="true"
					:emit-view-event="true"
					:emit-delete-event="true"
					:is-editable="item.status === 'draft' && item.editable && editable"
					:is-deletable="item.deletable && editable"
					@edit-button-pressed="openContract"
					@view-button-pressed="openContract"
					@delete-button-pressed="deleteContract"
					v-slot="{ row }"
				>
					<a href="#" class="dropdown-item" @click.prevent="displayPaymentDialog(row)" v-if="displayPaymentLink(row)">
						<i class="far fa-file-invoice fa-fw me-1 text-warning"></i> Következő díjbekérő kiállítása
					</a>
					<a href="#" class="dropdown-item" @click.prevent="openResponseForm(row.id)" v-if="editable && row.status === 'generated' && canAnswer">
						<i class="far fa-reply fa-fw me-1 text-warning"></i> Ügyfél válasz rögzítése
					</a>
					<a href="#" class="dropdown-item" @click.prevent="setDeliveryForContract(row.id)" v-if="editable && row.status === 'signed' && row.delivery === 'none' && canSetDeliveryForContract">
						<i class="far fa-truck fa-fw me-1 text-warning"></i> Kiszállítás engedélyezése
					</a>
					<a href="#" class="dropdown-item" @click.prevent="startInstallation(row.id)" v-if="editable && row.status === 'signed' && row.delivery !== 'none' && row.installation === 'none' && canStartInstallationForContract">
						<i class="far fa-tools fa-fw me-1 text-warning"></i> {{ parseInt(row.hasInstallation) ? 'Telepítés részletek' : 'Telepítés indítása' }}
					</a>
					<a class="dropdown-item" href="#" @click.prevent="generateContract(row.id)" v-if="editable && row.id && row.status === 'draft' && canUpdate">
						<i class="fas fa-file-contract fa-fw label-icon text-warning"></i> Generálás
					</a>
					<a class="dropdown-item" :href="formDataUrl + '/' + row.id + '?action=download'"  v-if="row.file_id">
						<i class="far fa-download fa-fw me-1 text-secondary"></i> Letöltés
					</a>
				</TableActionDropdown>
			</template>

			<template #item-public_id="item">
				<div :class="{'d-flex': 1, 'w-100': 1}">
					<div style="padding: 12px">
						<div class="fw-bold">{{ item.public_id }}</div>
						<div class="text-muted">{{ item.title_formatted }}</div>
						<div class="text-muted" v-if="item.foreign_id">{{ item.foreign_id }}</div>
						<a href="#" @click.prevent="emitOpenQuote(item.quote_id)">{{ item.quotePublicId }}</a>
					</div>
				</div>
			</template>

			<template #item-quote_id="item">
			</template>

			<template #item-paid_amount="item">
				<div :class="{
					'text-end': 1,
					'text-nowrap': 1,
					'text-success': item.total_price_gross <= item.paid_amount,
					'text-danger': item.total_price_gross > item.paid_amount && item.status == 'signed',
					'fw-bold': item.status == 'signed' || item.status == 'completed'
				}">
					<Price
							:value="item.paid_amount"
							:currency-id="item.currency_id"
							:currencies="currencies"
					/>
				</div>
			</template>

			<template #item-total_price_gross="item">
				<div class="d-flex gap-2 text-nowrap">
					<strong>N</strong>
					<Price
							:value="item.total_price_net"
							:currency-id="item.currency_id"
							:currencies="currencies"
					/>
				</div>
				<div class="d-flex gap-2 text-nowrap">
					<strong>B</strong>
					<Price
							:value="item.total_price_gross"
							:currency-id="item.currency_id"
							:currencies="currencies"
					/>
				</div>
			</template>

			<template #item-created_at="item">
				<div class="text-end text-nowrap">
					{{ formatDate(item.created_at, false) }}
				</div>
			</template>

			<template #item-details="item">
				<div v-if="!item.is_foreign" class="text-nowrap">
					<strong>Számla:</strong> {{ item.custom_payment ? item.invoices : item.invoices + '/' + item.paymentSteps }}
				</div>
				<span v-if="!item.is_foreign" class="text-nowrap">
					<strong>Módosítás:</strong> {{ item.amendments }}
				</span>
			</template>

			<template #item-status="item">
				<div class="d-flex gap-2 flex-wrap">
				<span :class="'badge badge-xl bg-' + contractStatusLabels[item.status].color">
					<i :class="contractStatusLabels[item.status].icon"></i> {{ contractStatusLabels[item.status].name }}
				</span>
					<span class="badge badge-xl bg-danger" v-if="item.is_foreign"><i class="far fa-file-import"></i> Külső szerződés</span>
				</div>
			</template>

			<template #item-delivery="item">
				<div class="d-flex flex-column gap-2 align-items-start">
					<span :class="'badge badge-xl bg-' + deliveryTypeLabels[item.delivery].color">
						<i :class="deliveryTypeLabels[item.delivery].icon"></i> {{ deliveryTypeLabels[item.delivery].name }}
					</span>
					<template v-if="parseInt(item.hasInstallation)">
						<span :class="'badge badge-xl bg-success'" v-if="!parseInt(item.hasPendingInstallation)">
							<i class="far fa-check fa-fw me-1"></i> Telepítve
						</span>
						<span :class="'badge badge-xl bg-warning'" v-else>
							<i class="far fa-tools fa-fw me-1"></i> {{ item.installationDate ? 'Telepítés: ' + item.installationDate : 'Telepítés folyamatban'}}
						</span>
					</template>
				</div>
			</template>
		</DataTable>

		<teleport to="body" v-if="formOpen">
			<ContractForm
				:contract-id="contractFormId"
				:project-attributes="projectAttributes"
				:product-categories="projectType.product_categories"
				:project-fields="projectType.fields_data"
				:client="client"
				:products="products"
				:taxes="taxes"
				:data-url="formDataUrl"
				:product-types="productTypes"
				:currencies="currencies"
				:editable="editable"
				:file-upload-url="fileUploadUrl"
				:contract-status-labels="contractStatusLabels"
				:can-set-delivery-for-contract="canSetDeliveryForContract"
				:can-create-contract-amendments="canCreateContractAmendments"
				:delivery-type-labels="deliveryTypeLabels"
				:can-update="canUpdate"
				:can-start-installation-for-contract="canStartInstallationForContract"
				:installation-data-url="installationDataUrl"
				:can-answer="canAnswer"
				@close="formOpen = false"
				@close-and-reload="closeContractFormAndUpdateList"
				@generate="generateContract"
				@update-stats="updateStats"
				@enable-delivery="enableDelivery"
				@start-installation="startInstallation"
				:can-start-editing="canStartEditing"
				@start-editing="$emit('startEditing')"
				:lock="lock"
				:active-tab-parameter="activeTabParameter"
				:active-tab-command="activeTabCommand"
				:amendments-list-data-url="amendmentsListDataUrl"
				:installation-list-url="installationListUrl"
				:user-levels="userLevels"
				:installation-document-types="installationDocumentTypes"
			></ContractForm>
		</teleport>
		<teleport to="body" v-if="foreignContractFormOpen">
			<ForeignContractForm
				:contract-id="contractFormId"
				:project-attributes="projectAttributes"
				:product-categories="productCategories"
				:project-fields="projectType.fields_data"
				:client="client"
				:products="products"
				:taxes="taxes"
				:data-url="formDataUrl"
				:product-types="productTypes"
				:delivery-type-labels="deliveryTypeLabels"
				:file-upload-url="fileUploadUrl"
				:currencies="currencies"
				:editable="editable"
				:contract-status-labels="contractStatusLabels"
				@close="foreignContractFormOpen = false"
				@close-and-reload="closeContractFormAndUpdateList"
				@update-stats="updateStats"
			></ForeignContractForm>
		</teleport>
		<teleport to="body" v-if="responseFormOpen">
			<ContractResponseForm
					:contract-id="contractFormId"
					:data-url="formDataUrl"
					:currencies="currencies"
					:file-upload-url="fileUploadUrl"
					:can-enable-custom-billing="canEnableCustomBilling"
					@close="responseFormOpen = false"
					@close-and-reload="closeContractResponseFormAndUpdateList"
					@update-stats="updateStats"
			></ContractResponseForm>
		</teleport>
		<teleport to="body" v-if="paymentDialogContractId">
			<ContractPaymentForm
					:contract-id="paymentDialogContractId"
					:data-url="formDataUrl"
					:currencies="currencies"
					:can-enable-custom-billing="canEnableCustomBilling"
					@close="paymentDialogContractId = false"
					@close-and-reload="closeContractPaymentFormAndUpdateList"
					@update-stats="updateStats"
			></ContractPaymentForm>
		</teleport>
		<teleport to="body" v-if="installationDialogOpen">
			<ProjectInstallationForm
				:contract-id="installationDialogId"
				:file-upload-url="fileUploadUrl"
				:installation-id="null"
				:editable="editable"
				:products="products"
				:data-url="installationDataUrl"
				:can-start-editing="canStartEditing"
				:user-levels="userLevels"
				:document-types="installationDocumentTypes"
				@close="installationDialogOpen = false"
				@close-and-reload="closeInstallationFormAndUpdateList"
			/>
		</teleport>
	</div>
</template>
<script>
import TableActionCell from "../../components/TableActionCell";
import {formatDate} from "../../functions";
import Price from "../../components/Price.vue";
import DataTable from "../../components/DataTable.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import ContractForm from "./ContractForm.vue";
import ForeignContractForm from "./ForeignContractForm.vue";
import ContractResponseForm from "./ContractResponseForm.vue";
import QuoteList from "./QuoteList.vue";
import ContractAmendmentForm from "./ContractAmendmentForm.vue";
import ContractPaymentForm from "./ContractPaymentForm.vue";
import ProjectInstallationForm from "./ProjectInstallationForm.vue";

export default {
	components: {
		ProjectInstallationForm,
		ContractPaymentForm,
		ContractAmendmentForm,
		QuoteList,
		ContractResponseForm,
		ContractForm,
		ForeignContractForm,
		TableActionDropdown,
		Price,
		TableActionCell,
		DataTable,
	},
	emits: ['formFlash', 'contractCount', 'updateStats', 'openQuote', 'startEditing'],
	props: {
		projectAttributes: [Array, Object],
		products: [Array, Object],
		taxes: [Array, Object],
		productCategories: Array,
		userLevels: Array,
		installationDocumentTypes: Array,
		lock: Object,
		client: Object,
		productTypes: Object,
		projectType: Object,
		dataUrl: String,
		installationListUrl: String,
		formDataUrl: String,
		amendmentsListDataUrl: String,
		installationDataUrl: String,
		currencies: Object,
		contractStatusLabels: Object,
		canStartEditing: Boolean,
		editable: Boolean,
		canCreate: Boolean,
		canUpdate: Boolean,
		canDelete: Boolean,
		canAnswer: Boolean,
		canCreateInvoice: Boolean,
		canCreateForeignContract: Boolean,
		canSetDeliveryForContract: Boolean,
		canCreateContractAmendments: Boolean,
		canEnableCustomBilling: Boolean,
		canStartInstallationForContract: Boolean,
		deliveryTypeLabels: Object,
		fileUploadUrl: String,
		activeTabCommand: [String, null],
		activeTabParameter: [String, null],
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Azonosító',
				value: 'public_id',
				sortable: true,
			},
			{
				text: 'Állapot',
				value: 'status',
				sortable: false,
			},
			{
				text: 'Ár',
				value: 'total_price_gross',
				align: 'right',
				sortable: true,
				width: 10
			},
			{
				text: 'Fizetve',
				value: 'paid_amount',
				align: 'right',
				sortable: true,
				width: 10
			},
			{
				text: 'Részletek',
				value: 'details',
				width: 10
			},
			{
				text: 'Létrehozva',
				value: 'created_at',
				sortable: true,
				width: 10
			},
			{
				text: 'Kiszállítás',
				value: 'delivery',
				sortable: true,
				width: 10
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			listDataUrl: this.dataUrl,
			formOpen: false,
			responseFormOpen: false,
			foreignContractFormOpen: false,
			contractFormId: null,
			loading: false,
			installationDialogOpen: false,
			installationDialogId: null,
			paymentDialogContractId: null
		}
	},
	mounted() {
		if (this.activeTabCommand === 'contract' && this.activeTabParameter){
			this.openContract(this.activeTabParameter)
		}
		if (this.activeTabCommand === 'amendment' && this.activeTabParameter){
			let tmp = this.activeTabParameter.split('|')
			this.openContract(tmp[0])
		}
	},
	methods: {
		formatDate: formatDate,
		dataLoaded: function(data){
			this.$emit('contractCount', data.meta.total)
		},
		isEditable: function(row){
			return row.editable
		},
		openContract: function(id){
			this.contractFormId = id
			this.formOpen = true
		},
		deleteContract: function(id){
			this.loading = true

			let that = this
			axios.post(this.formDataUrl + '/' + id + '?action=delete', {}).then((response)=>{
				this.loading = false
				if (response.data.flash !== undefined){
					this.$emit('formFlash', response.data.flash);
				}
				this.$refs.table.getData()
			}).catch(function (error) {
				that.loading = false
				that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
			})
		},
		closeContractFormAndUpdateList: function(){
			this.formOpen = false
			this.foreignContractFormOpen = false
			this.contractFormId = null
			this.$refs.table.getData()
		},
		closeContractResponseFormAndUpdateList: function(){
			this.responseFormOpen = false
			this.contractFormId = null
			this.$refs.table.getData()
		},
		formFlash: function(flash){
			this.$emit('formFlash', flash);
		},
		generateContract: function (contractId){
			this.loading = true
			let that = this
			axios.post(this.formDataUrl + '/' + contractId + '?action=generate').then((response)=>{
				if (response.data.flash !== undefined){
					this.$emit('formFlash', response.data.flash);
				}
				that.loading = false
				this.formOpen = false
				this.$refs.table.getData()
			}).catch(function (error) {
				that.loading = false
				that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
			})
		},
		setDeliveryForContract: function (contractId){
			this.loading = true
			let that = this
			axios.post(this.formDataUrl + '/' + contractId + '?action=enableDelivery').then((response)=>{
				if (response.data.flash !== undefined){
					this.$emit('formFlash', response.data.flash);
				}
				that.loading = false
				this.formOpen = false
				this.$refs.table.getData()
			}).catch(function (error) {
				that.loading = false
				that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
			})
		},
		openResponseForm: function (id){
			this.contractFormId = id
			this.responseFormOpen = true
		},
		enableDelivery: function(){
			this.setDeliveryForContract(this.contractFormId)
		},
		updateStats: function(stats){
			this.$emit('updateStats', stats);
		},
		emitOpenQuote: function(id){
			this.$emit('openQuote', id);
		},
		displayPaymentLink: function(row){
			if (!this.editable || row.status !== 'signed' || !this.canCreateInvoice || row.is_foreign){
				return false
			}
			if (!row.custom_payment) {
				return row.invoices < row.paymentSteps
			}

			return row.paid_amount < row.total_price_gross
		},
		displayPaymentDialog: function(row){
			this.paymentDialogContractId = row.id
		},
		closeContractPaymentFormAndUpdateList: function(){
			this.paymentDialogContractId = null
			this.$refs.table.getData()
		},
		closeInstallationFormAndUpdateList: function(){
			this.installationDialogOpen = false
			this.$refs.table.getData()
		},
		startInstallation: function(id){
			this.installationDialogId = id
			this.installationDialogOpen = true
		}
	}
}
</script>

<style scoped>
</style>