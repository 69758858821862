<template>
	<div class="card">
		<div class="card-body">
			<div class="details-box details-box--narrow">
				<h4>Ügyfél</h4>
				<div><i class="far fa-user"></i> Név: <strong>{{ client.name }}</strong></div>
				<div><i class="far fa-envelope"></i> E-mail cím: <EmailAddress :value="client.email"></EmailAddress></div>
				<div><i class="far fa-phone"></i> Telefonszám: <PhoneNumber :value="client.phone"></PhoneNumber></div>
			</div>
		</div>
	</div>
	<div class="d-none d-md-block">
		<div v-for="(field, fieldKey) in projectFields" :key="field.id">
			<div class="card" v-if="(field.type === 'title' || field.type === 'multirow' || (projectAttributes[field.id] !== undefined && projectAttributes[field.id].id !== undefined))
												&& (!field.private_only || (field.private_only && !client.is_company))
												&& (!field.company_only || (field.company_only && client.is_company))">
				<div class="card-body">
					<h4 class="card-title mb-3">{{ field.title }}</h4>
					<div v-if="projectAttributes[field.id] !== undefined" v-html="projectAttributes[field.id].formatted_value"></div>

					<div v-if="field.type !== 'multirow' && field.children !== undefined && field.children.length">
						<template v-for="(childField, childFieldKey) in field.children" :key="childField.id">
							<div class="mb-3 mb-last-0" :data-field-id="childField.id" v-if="(projectAttributes[childField.id] !== undefined && projectAttributes[childField.id].formatted_value)
															&& (!childField.private_only || (childField.private_only && !client.is_company))
															&& (!childField.company_only || (childField.company_only && client.is_company))">

								<label class="mb-0">{{ childField.title }}</label>
								<div v-if="projectAttributes[childField.id] !== undefined" v-html="projectAttributes[childField.id].formatted_value"></div>
							</div>
						</template>
					</div>
					<div v-if="field.type === 'multirow' && field.children !== undefined && field.children.length">
						<template v-for="(multirowAttributes, rowKey) in projectAttributes[field.id]">
							<div class="card border shadow-none">
								<div class="card-body">
									<template v-for="(childField, childFieldKey) in field.children" :key="rowKey + '_' + childField.id">
										<div class="mb-3 mb-last-0" v-if="(multirowAttributes[childField.id] !== undefined && multirowAttributes[childField.id].formatted_value)
																	&& (!childField.private_only || (childField.private_only && !client.is_company))
																	&& (!childField.company_only || (childField.company_only && client.is_company))">

											<label class="mb-0">{{ childField.title }}</label>
											<div v-if="multirowAttributes[childField.id] !== undefined" v-html="multirowAttributes[childField.id].formatted_value"></div>
										</div>
									</template>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import EmailAddress from "../../components/EmailAddress.vue";
import PhoneNumber from "../../components/PhoneNumber.vue";

export default {
	components: {PhoneNumber, EmailAddress},
	props: {
		projectFields: Array,
		projectAttributes: Object,
		client: Object,
	}
}
</script>

<style scoped>

</style>