<template>
	<DataTable
			:data-url="dataUrl"
			:initial-flash="initialFlash"
			:columns="columns"
			sort-by="created_at"
			sort-type="desc"
			name="userCommissions"
	>
		<template #item-created_at="item">
			{{ formatDate(item.created_at) }}
		</template>
		<template #item-type="item">
			<template v-if="item.model_type === 'UserBonus'">
				<template v-if="userBonusesById[item.model_id] !== undefined">
					<color-badge :value="userBonusesById[item.model_id]"></color-badge>
				</template>
			</template>
			{{ types[item.type] }}
		</template>
		<template #item-amount="item">
			<Price
				:value="item.amount"
				:currencies="currencies"
				/>
		</template>
		<template #item-paid="item">
			<template v-if="item.paid">
				<span class="badge bg-success">Igen</span>
			</template>
			<template v-else>
				<span class="badge bg-danger">Nem</span>
			</template>
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import {formatDate} from "../../functions";
import BooleanBadge from "../../components/BooleanBadge.vue";
import UserLevelBadge from "../../components/UserLevelBadge.vue";
import Price from "../../components/Price.vue";
import ColorBadge from "../../components/ColorBadge.vue";

export default {
	components: {
		ColorBadge,
		Price,
		UserLevelBadge,
		DataTable,
		BooleanBadge,
		TableActionCell
	},
	props: {
		dataUrl: String,
		editUrl: String,
		deleteUrl: String,
		isEditable: Boolean,
		showUserLevels: Boolean,
		showCommissions: Boolean,
		userBonuses: Array,
		userLevels: Array,
		currencies: Array,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	computed: {
		userBonusesById: {
			get() {
				return _.keyBy(this.userBonuses, 'id')
			}
		}
	},
	data(){
		let columns = [
			{
				text: 'Időpont',
				value: 'created_at',
				sortable: true,
			},
			{
				text: 'Típus',
				value: 'type',
				sortable: true,
			},
			{
				text: 'Összeg',
				value: 'amount',
				align: 'right',
				sortable: true,
			},
			{
				text: 'Kifizetve',
				value: 'paid',
				align: 'right',
				sortable: true,
			},
			{
				text: 'Team jutalék',
				value: 'sourceUser',
				sortable: true,
			},
			{
				text: 'Szerződés',
				value: 'contractId',
				sortable: true,
			},
			{
				text: 'Projekt',
				value: 'projectId',
				sortable: true,
			},
		]

		return {
			columns: columns,
			types: {
				direct: 'Direkt jutalék',
				indirect: 'Team jutalék',
				reward: 'Jutalom',
				levelReward: 'Szintlépés',
			}
		}
	},
	methods: {
		formatDate: formatDate,
	}
}
</script>

<style scoped>
</style>
