<template>
	<flash-display :flash="flash" ref="notifications"></flash-display>
	<div :class="{'overlay-form-wrapper': 1}">
		<div :class="{'overlay-form-wrapper--content': 1, 'loading': loading}">
			<div class="row">
				<div class="col-md-3">
					<div class="page-title-box">
						<h4 class="mb-sm-0 font-size-18">Projekt adatok</h4>
					</div>
					<ProjectInfoSidebar
						:project-fields="projectFields"
						:project-attributes="projectAttributes"
						:client="client"
					></ProjectInfoSidebar>
				</div>
				<div class="col-md-9">
					<div class="page-title-box">
						<h4 class="mb-sm-0 font-size-18">Szerződés</h4>
					</div>
					<div class="card">
						<div class="card-body">
							<div class="details-box">
								<div v-if="contract.id !== undefined">
									<i class="far fa-hashtag"></i> Azonosító:
									<strong>{{ contract.public_id }}</strong>
								</div>
								<div v-if="contractStatusLabels[contract.status] !== undefined">
									<i class="far fa-question"></i> Státusz:
									<span :class="'badge badge-xl bg-' + contractStatusLabels[contract.status].color"><i :class="contractStatusLabels[contract.status].icon"></i> {{ contractStatusLabels[contract.status].name }}</span>
								</div>
								<div v-if="contract.foreign_id">
									<i class="far fa-hashtag"></i> Külső azonosító: <strong>{{ contract.foreign_id }}</strong>
								</div>
								<div v-if="contract.is_foreign">
									<i class="far fa-question"></i> Típus: <span class="badge badge-xl bg-danger"><i class="far fa-file-import"></i> Külső szerződés</span>
								</div>
								<div v-if="contract.deadline !== undefined && contract.deadline">
									<i class="far fa-calendar-check"></i> Érvényesség:
									<strong>{{ formatDate(contract.deadline, false) }}</strong>
								</div>
								<div v-if="deliveryTypeLabels[contract.delivery] !== undefined">
									<i class="far fa-truck"></i> Szállítás:
									<span :class="'badge badge-xl bg-' + deliveryTypeLabels[contract.delivery].color"><i :class="deliveryTypeLabels[contract.delivery].icon"></i> {{ deliveryTypeLabels[contract.delivery].name }}</span>
								</div>
								<template v-for="currency in currencies" v-if="contract.currency_values !== undefined">
									<div v-if="contract.currency_values['display_' + currency.id] !== undefined">
										<i class="far fa-dollar-sign"></i> {{ currency.name }} árfolyam:
										<strong>
											<Price
												:value="contract.currency_values['display_' + currency.id]"
												:currencies="currencies"
												:decimals="2"
											/>
										</strong>
									</div>
								</template>
								<template v-if="contract.id !== undefined">
									<div class="break"></div>
									<div v-if="contract.created_at">
										<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(contract.created_at) }}</strong>
									</div>
									<div v-if="contract.created_by_user_name">
										<i class="far fa-user"></i> Létrehozó: <strong>{{ contract.created_by_user_name }}</strong>
									</div>
									<div v-if="contract.updated_at">
										<i class="far fa-calendar-alt"></i> Módosítva: <strong>{{ formatDate(contract.updated_at) }}</strong>
									</div>
									<div class="break"></div>
									<div v-if="contract.answered_at">
										<i class="far fa-calendar-alt"></i> Megválaszolva: <strong>{{ formatDate(contract.answered_at) }}</strong>
									</div>
									<div v-if="contract.answered_by_user_name">
										<i class="far fa-user"></i> Választ rögzítette: <strong>{{ contract.answered_by_user_name }}</strong>
									</div>
									<div v-if="contract.signed_at">
										<i class="far fa-calendar-alt"></i> Aláírva: <strong>{{ formatDate(contract.signed_at, false) }}</strong>
									</div>
									<div v-if="contract.deadline">
										<i class="far fa-calendar-alt"></i> Határidő: <strong>{{ formatDate(contract.deadline, false) }}</strong>
									</div>
								</template>
							</div>
							<LockMessage :lock="lock" :margin-top="true"></LockMessage>
							<div v-if="contract.files && contract.files.length" class="mt-3">
								<FileList :files="contract.files" title="Aláírt szerződés" />
							</div>
							<div class="row mt-3">
								<div class="col-md-4" v-if="!contract.is_foreign">
									<InputField
										label="Kedvezmény"
										name="discount"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="contract.discount"
										:disabled="!isEditable"
										:errors="{}"
										@input="productChanged"
									/>
								</div>
								<div class="col-md-4" v-if="!contract.is_foreign">
									<InputField
										label="Kedvezmény anyag"
										name="discount_material"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="contract.discount_material"
										:disabled="!isEditable"
										:errors="{}"
										@input="productChanged"
									/>
								</div>
								<div class="col-md-4" v-if="!contract.is_foreign">
									<InputField
										label="Kedvezmény munka"
										name="discount_work"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="contract.discount_work"
										:disabled="!isEditable"
										:errors="{}"
										@input="productChanged"
									/>
								</div>
								<div class="col-md-4">
									<label>Fizetési konstrukció</label>

									<v-select
										:options="paymentPlans"
										v-model="contract.project_type_payment_plan_id"
										label="name_public"
										:reduce="item => item.id"
										:disabled="!isEditable"
										:clearable="false"
									>
									</v-select>
									<p class="text-muted mt-2" v-if="isEditable && contract.project_type_payment_plan_id && paymentPlansById[contract.project_type_payment_plan_id].description" v-html="paymentPlansById[contract.project_type_payment_plan_id].description_html"></p>
								</div>
							</div>
						</div>
					</div>
					<div class="mb-3">
						<div class="d-flex flex-wrap gap-2">
							<button type="submit" class="btn btn-primary btn-label waves-effect" v-if="isEditable" :disabled="!allCategoriesValid" @click.prevent="saveForm">
								<i class="fas fa-save label-icon"></i> Mentés
							</button>
							<button type="submit" class="btn btn-warning btn-label waves-effect" v-if="!editable && canStartEditing" @click.prevent="$emit('startEditing')">
								<i class="fas fa-pencil label-icon"></i> Szerkesztés
							</button>
							<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="generateContract" v-if="editable && contract.id && contract.status === 'draft'">
								<i class="fas fa-check label-icon"></i> Generálás
							</button>
							<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="createNewAmendment" v-if="editable && contract.id && (contract.status === 'signed' || contract.status === 'generated') && !contract.is_foreign && canCreateContractAmendments">
								<i class="fas fa-file-plus label-icon"></i> Szerződésmódosítás létrehozása
							</button>
							<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="enableDelivery" v-if="editable && contract.id && contract.status === 'signed' && contract.delivery === 'none' && canSetDeliveryForContract">
								<i class="fas fa-truck label-icon"></i> Kiszállítás engedélyezése
							</button>
							<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="startInstallation" v-if="editable && contract.id && contract.status === 'signed' && contract.delivery !== 'none' && contract.installation === 'none' && canStartInstallationForContract">
								<i class="fas fa-tools label-icon"></i> Telepítés indítása
							</button>
							<a class="btn btn-secondary btn-label waves-effect" v-if="contract.id && contract.status !== 'draft' && contract.file_id" :href="dataUrl + '/' + contract.id + '?action=download'">
								<i class="far fa-download label-icon"></i> Letöltés
							</a>
							<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
								<i class="fas fa-times label-icon"></i> Bezár
							</button>
						</div>
					</div>

					<div :class="{'card': 1, 'd-none': !showInstallationList}">
						<div class="card-body">
							<h4 class="card-title mb-4">Telepítés</h4>
							<ProjectInstallationList
									:list-url="installationListUrl + '/' + contract.id"
									:file-upload-url="fileUploadUrl"
									:products="products"
									:user-levels="userLevels"
									:installation-data-url="installationDataUrl"
									:installation-document-types="installationDocumentTypes"
									v-if="contract.id !== undefined"
									@results-found="showInstallationList = true"
									:hide-contract="true"
									:editable="editable"
									:can-start-editing="canStartEditing"
									:lock="lock"
									@start-editing="$emit('startEditing')"
							></ProjectInstallationList>
						</div>
					</div>

					<ContractAmendmentList
						v-if="contract.id !== undefined"
						:data-url="amendmentsListDataUrl + '/' + contract.id"
						:form-data-url="dataUrl"
						:contract="contract"
						:contract-status-labels="contractStatusLabels"
						:currencies="currencies"
						:editable="editable"
						:product-categories="productCategories"
						:product-types="productTypes"
						:products="products"
						:taxes="taxes"
						:client="client"
						:delivery-type-labels="deliveryTypeLabels"
						:can-create-contract-amendments="canCreateContractAmendments"
						:file-upload-url="fileUploadUrl"
						:can-update="canUpdate"
						:active-tab-command="activeTabCommand"
						:active-tab-parameter="activeTabParameter"
						:lock="lock"
						:can-start-editing="canStartEditing"
						:can-answer="canAnswer"
						ref="amendmentList"
						@form-flash="setFlash"
						@start-editing="$emit('startEditing')"
						@changed="reloadContractList"
					/>

					<ProjectProductList
						:data-url="dataUrl"
						:product-categories="productCategories"
						:products="products"
						:is-editable="isEditable"
						v-model="contractProducts"
						:parent-model="contract"
						:product-types="productTypes"
						:currencies="currencies"
						:product-versions="contract.product_versions"
						mode="contract"
						ref="productList"
						@flash="setFlash"
						v-if="!contract.quote_id && !contract.is_foreign && !contract.amendment_to_contract_id"
					>
					</ProjectProductList>
					<template v-else>
						<div class="card border">
							<div class="card-body">
								<h4 class="card-title mb-4">Termékek</h4>

								<table class="table">
									<thead>
									<tr>
										<th>Termék</th>
										<th width="160" class="text-end">Menny.</th>
										<th width="80" class="text-end" v-if="contract.delivery !== 'none'">Foglalva</th>
										<th width="80" class="text-end" v-if="contract.delivery !== 'none'">Kiszállítva</th>
										<th width="15%">Típus</th>
										<th width="10%" class="text-end text-nowrap">Nettó egys.</th>
										<th width="10%" class="text-end">Bruttó ár</th>
									</tr>
									</thead>
									<tbody>
										<template v-for="product in contractProducts">
											<tr>
												<td>{{ productsById[product.product_id].name }}</td>
												<td class="text-end">{{ product.quantity }} {{ productsById[product.product_id] !== undefined ? productsById[product.product_id].unit : 'db' }}</td>
												<td class="text-end" v-if="contract.delivery !== 'none'">
													<template v-if="productsById[product.product_id].type === 'material' && productsById[product.product_id].track_inventory">
														{{ formatNumber(product.reserved) }}
													</template>
												</td>
												<td class="text-end" v-if="contract.delivery !== 'none'">
													<span :class="{
														'fw-bold': 1,
														'text-success': product.delivered >= product.quantity,
														'text-danger': product.delivered < product.quantity && productsById[product.product_id].requires_delivery}"
														  v-if="productsById[product.product_id].type === 'material' && productsById[product.product_id].track_inventory">{{ formatNumber(product.delivered)
														}}</span>
												</td>
												<td>
													<div v-html="getProductType(product)"></div>
												</td>
												<td class="text-end vertical-align-middle">
													<Price
														v-if="product.product_id && productPrices[(product.order + 1)] !== undefined"
														:value="productPrices[(product.order + 1)].net - productPrices[(product.order + 1)].discountNet"
														:currencies="currencies"
														:loading="pricesLoading"
													/>
												</td>
												<td class="text-end vertical-align-middle">
													<Price
														v-if="product.product_id && productPrices[(product.order + 1)] !== undefined"
														:value="(productPrices[(product.order + 1)].gross - productPrices[(product.order + 1)].discountGross) * product.quantity"
														:currencies="currencies"
														:loading="pricesLoading"
													/>
												</td>
											</tr>
										</template>
									</tbody>
								</table>
							</div>
						</div>


						<div class="card">
							<div class="card-body">
								<table class="table">
									<thead>
									<tr>
										<th>&nbsp;</th>
										<th width="15%" class="text-end">Nettó</th>
										<th width="15%" class="text-end">ÁFA</th>
										<th width="15%" class="text-end">Bruttó</th>
									</tr>
									</thead>
									<tbody>
									<tr v-if="getTotalPrice(false, true, 'work')">
										<td class="text-end">
											<strong>Kedvezmény a munka jellegű tételekből</strong>
										</td>
										<td class="text-end">
											<Price
													:value="getTotalPrice(false, true, 'work')"
													:currencies="currencies"
													:loading="pricesLoading"
											/>
										</td>
										<td class="text-end">
											<Price
													:value="getTotalPrice(true, true, 'work') - getTotalPrice(false, true, 'work')"
													:currencies="currencies"
													:loading="pricesLoading"
											/>
										</td>
										<td class="text-end">
											<Price
													:value="getTotalPrice(true, true, 'work')"
													:currencies="currencies"
													:loading="pricesLoading"
											/>
										</td>
									</tr>
									<tr v-if="getTotalPrice(false, true, 'material')">
										<td class="text-end">
											<strong>Kedvezmény az anyag jellegű tételekből</strong>
										</td>
										<td class="text-end">
											<Price
													:value="getTotalPrice(false, true, 'material')"
													:currencies="currencies"
													:loading="pricesLoading"
											/>
										</td>
										<td class="text-end">
											<Price
													:value="getTotalPrice(true, true, 'material') - getTotalPrice(false, true, 'material')"
													:currencies="currencies"
													:loading="pricesLoading"
											/>
										</td>
										<td class="text-end">
											<Price
													:value="getTotalPrice(true, true, 'material')"
													:currencies="currencies"
													:loading="pricesLoading"
											/>
										</td>
									</tr>
									<template v-if="contract.price_history.length">
										<tr v-for="historyItem in contract.price_history">
											<td class="text-end">
												<strong>{{ historyItem.name }}</strong>
											</td>
											<td class="text-end">
												<Price
														:value="historyItem.net"
														:currencies="currencies"
														:loading="pricesLoading"
												/>
											</td>
											<td class="text-end">
												<Price
														:value="historyItem.gross - historyItem.net"
														:currencies="currencies"
														:loading="pricesLoading"
												/>
											</td>
											<td class="text-end">
												<Price
														:value="historyItem.gross"
														:currencies="currencies"
														:loading="pricesLoading"
												/>
											</td>
										</tr>
									</template>
									<tr>
										<td class="text-end">
											<strong>Végösszeg</strong>
										</td>
										<td class="text-end">
											<Price
													:value="getTotalPrice(false, false) - getTotalPrice(false, true)"
													:currencies="currencies"
													:loading="pricesLoading"
											/>
										</td>
										<td class="text-end">
											<Price
													:value="(getTotalPrice(true, false) - getTotalPrice(true, true)) - (getTotalPrice(false, false) - getTotalPrice(false, true))"
													:currencies="currencies"
													:loading="pricesLoading"
											/>
										</td>
										<td class="text-end">
											<Price
													:value="getTotalPrice(true, false) - getTotalPrice(true, true)"
													:currencies="currencies"
													:loading="pricesLoading"
											/>
										</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</template>

					<div class="mt-3">
						<div class="d-flex flex-wrap gap-2">
							<button type="submit" class="btn btn-primary btn-label waves-effect" v-if="isEditable" :disabled="!allCategoriesValid" @click.prevent="saveForm">
								<i class="fas fa-save label-icon"></i> Mentés
							</button>
							<button type="submit" class="btn btn-warning btn-label waves-effect" v-if="!editable && canStartEditing" @click.prevent="$emit('startEditing')">
								<i class="fas fa-pencil label-icon"></i> Szerkesztés
							</button>
							<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="generateContract" v-if="editable && contract.id && contract.status === 'draft'">
								<i class="fas fa-check label-icon"></i> Generálás
							</button>
							<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="createNewAmendment" v-if="editable && contract.id && contract.status === 'signed' && !contract.is_foreign && canCreateContractAmendments">
								<i class="fas fa-file-plus label-icon"></i> Szerződésmódosítás létrehozása
							</button>
							<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="enableDelivery" v-if="editable && contract.id && contract.status === 'signed' && contract.delivery === 'none' && canSetDeliveryForContract">
								<i class="fas fa-truck label-icon"></i> Kiszállítás engedélyezése
							</button>
							<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="startInstallation" v-if="editable && contract.id && contract.status === 'signed' && contract.delivery !== 'none' && contract.installation === 'none' && canStartInstallationForContract">
								<i class="fas fa-tools label-icon"></i> Telepítés indítása
							</button>
							<a class="btn btn-secondary btn-label waves-effect" v-if="contract.id && contract.status !== 'draft' && contract.file_id" :href="dataUrl + '/' + contract.id + '?action=download'">
								<i class="far fa-download label-icon"></i> Letöltés
							</a>
							<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
								<i class="fas fa-times label-icon"></i> Bezár
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {formatDate, formatNumber, moveValueInArray} from "../../functions";
import ProjectInfoSidebar from "./ProjectInfoSidebar.vue";
import FlashDisplay from "../../components/FlashDisplay.vue";
import InputField from "../../components/form/InputField.vue";
import ProjectProductList from "./ProjectProductList.vue";
import Price from "../../components/Price.vue";
import vSelect from "vue-select";
import FileList from "../../components/FileList.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import DataTable from "../../components/DataTable.vue";
import ContractAmendmentList from "./ContractAmendmentList.vue";
import ProjectInstallationList from "./ProjectInstallationList.vue";
import LockMessage from "../../components/LockMessage.vue";

export default {
	components: {LockMessage, ProjectInstallationList, ContractAmendmentList, TableActionDropdown, FileList, Price, ProjectProductList, InputField, FlashDisplay, ProjectInfoSidebar, vSelect, DataTable},
	emits: ['close', 'closeAndReload', 'reloadList', 'generate', 'updateStats', 'enableDelivery', 'startInstallation', 'startEditing'],
	props: {
		productCategories: [Array, Object],
		productTypes: Object,
		currencies: Object,
		taxes: Object,
		editable: Boolean,
		products: Array,
		installationDocumentTypes: Array,
		client: Object,
		dataUrl: String,
		installationListUrl: String,
		fileUploadUrl: String,
		amendmentsListDataUrl: String,
		installationDataUrl: String,
		projectAttributes: Object,
		lock: Object,
		projectFields: Array,
		userLevels: Array,
		contractStatusLabels: Object,
		deliveryTypeLabels: Object,
		canSetDeliveryForContract: Boolean,
		activeTabCommand: [String, null],
		activeTabParameter: [String, null],
		canStartEditing: Boolean,
		canCreate: Boolean,
		canUpdate: Boolean,
		canDelete: Boolean,
		canAnswer: Boolean,
		canCreateContractAmendments: Boolean,
		canStartInstallationForContract: Boolean,
		contractId: {
			type: [Number, String],
			default: 0
		}
	},
	mounted() {
		this.loadData()
	},
	computed: {
		isEditable: {
			get(){
				if (!this.editable){
					return false
				}
				return this.contract.editable
			}
		},
		allCategoriesValid: {
			get(){
				return this.$refs.productList.allCategoriesValid()
			}
		},
		paymentPlansById: {
			get(){
				return _.keyBy(this.paymentPlans, 'id')
			}
		},
		productsById: {
			get() {
				return _.keyBy(this.products, 'id')
			}
		},
	},
	data() {
		return {
			contract: {
				product_versions: {}
			},
			contractProducts: [],
			flash: [],
			loading: false,
			productPrices: {},
			pricesLoading: false,
			pricesLoadingCancelToken: null,
			priceCalculationRequired: false,
			showInstallationList: false,
			paymentPlans: [],
		};
	},
	methods: {
		formatNumber,
		formatDate: formatDate,
		moveValueInArray: moveValueInArray,
		loadData: function () {
			this.loading = true

			let url = this.dataUrl
			if (this.contractId) {
				url += '/' + this.contractId
			}
			let that = this
			axios.get(url + '?action=load').then((response) => {
				this.handleResponse(response)
			}).catch(function (error) {
				if (error.response !== undefined) {
					that.flash = [{level: 'danger', message: error.response.data.message}]
				}
			})
		},
		reloadContractList: function(){
			this.loadData()
			this.$emit('reloadList', this.contractId)
		},
		productChanged: function(){
			this.$refs.productList.productChanged()
		},
		generateContract: function(){
			this.loading = true
			this.$emit('generate', this.contractId)
		},
		enableDelivery: function(){
			this.loading = true
			this.$emit('enableDelivery', this.contractId)
		},
		emitClose: function(){
			this.$emit('close')
		},
		saveForm: function(e){
			if (this.allCategoriesValid){
				this.loading = true

				let payload = {
					contract: this.contract,
					products: this.$refs.productList.getFilteredProducts()
				}

				let url = this.dataUrl
				if (this.contractId){
					url += '/' + this.contractId
				}
				let that = this
				axios.post(url + '?action=save', payload).then((response)=>{
					this.handleResponse(response)
				}).catch(function (error) {
					that.flash = [{level: 'danger', message: error.response.data.message}]
				})
			} else {
				//this.flash = [{level: 'danger', message: 'Kérlek töltsd ki a pirossal jelölt mezőket!'}]
			}
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.status === undefined || response.data.status === 'success') {
				this.contract = response.data.contract
				this.productPrices = response.data.prices
				this.contractProducts = this.contract.products_pivot
				this.paymentPlans = response.data.paymentPlans

				this.$refs.productList.setProductPrices(response.data.prices)

				if (response.data.status !== undefined && response.data.status === 'success') {
					this.$emit('closeAndReload')
				}
			}
			if (response.data.stats !== undefined){
				this.$emit('updateStats', response.data.stats)
			}
			if (response.data.flash !== undefined){
				this.flash = response.data.flash
			}
		},
		setFlash: function(flash){
			this.flash = flash
		},
		getProductType: function(productRow){
			if (!productRow.product_id){
				return ''
			}
			return '<span class="badge badge-xl bg-' + this.productTypes[this.productsById[productRow.product_id].type].color + '">' +
				'<i class="' + this.productTypes[this.productsById[productRow.product_id].type].icon + '"></i> ' + this.productTypes[this.productsById[productRow.product_id].type].label +
				'</span>'
		},
		getTotalPrice: function(gross, discount, type) {
			type = type || 'all'
			let total = 0

			_.forEach(this.productPrices, function(productPrice, productPriceKey){
				if (productPrice.bundle || (type !== 'all' && productPrice.type !== type)){
					return
				}
				if (discount){
					total += (gross ? productPrice.discountGross : productPrice.discountNet) * productPrice.quantity
				} else {
					total += (gross ? productPrice.gross : productPrice.net) * productPrice.quantity
				}
			})

			return total
		},
		createNewAmendment: function(){
			this.$refs.amendmentList.openContract(null)
		},
		startInstallation: function (){
			this.$emit('startInstallation', this.contract.id)
		}
	}
}
</script>

<style scoped>

</style>