<template>
	<div>
		<div v-if="editable && canCreate" class="mb-3">
			<button type="button" class="btn btn-primary btn-label" @click.prevent="quoteFormId = null; formOpen = true"><i class="far fa-plus label-icon"></i> Árajánlat létrehozása</button>
		</div>
		<DataTable
			:data-url="dataUrl"
			:initial-flash="initialFlash"
			:columns="columns"
			sort-by="created_at"
			ref="table"
			sort-type="desc"
			name="quotes"
		>
			<template #item-operations="{item, deleteButtonPressed}">
				<TableActionDropdown
					:data="item"
					:emit-edit-event="true"
					:emit-view-event="true"
					:emit-delete-event="true"
					:is-editable="item.status === 'draft' && item.editable && editable"
					:is-deletable="canDeleteQuote || (item.status === 'draft' && item.deletable && editable)"
					@edit-button-pressed="openQuote"
					@view-button-pressed="openQuote"
					@delete-button-pressed="deleteQuote"
					v-slot="{ row }"
				>
					<a href="#" class="dropdown-item" @click.prevent="openResponseForm(row.id)" v-if="editable && (row.status === 'approved' || row.status === 'sent' || row.status === 'read') && canAnswer">
						<i class="far fa-reply fa-fw me-1 text-warning"></i> Ügyfél válasz rögzítése
					</a>
					<a href="#" class="dropdown-item" @click.prevent="cloneQuote(row.id)" v-if="editable && canCreate">
						<i class="far fa-clone fa-fw me-1 text-secondary"></i> Duplikálás
					</a>
					<a href="#" class="dropdown-item" @click.prevent="reopenQuote(row.id)" v-if="editable && canReopenQuote && (row.status === 'accepted' || row.status === 'rejected')">
						<i class="far fa-lock-open fa-fw me-1 text-secondary"></i> Újranyitás
					</a>
					<a href="#" class="dropdown-item" @click.prevent="approveQuote(row.id)" v-if="row.status === 'draft' && canApproveQuote">
						<i class="far fa-check fa-fw me-1 text-success"></i> Jóváhagyás
					</a>
					<a href="#" class="dropdown-item" @click.prevent="requestQuoteApproval(row.id)" v-if="canSendApprovalRequest(row)">
						<i class="far fa-check fa-fw me-1 text-warning"></i> Jóváhagyás igénylése
					</a>
					<a href="#" class="dropdown-item" @click.prevent="contractFromQuote(row.id)" v-if="editable && row.status === 'accepted' && !row.contract_id && canCreateContract">
						<i class="far fa-file-contract fa-fw me-1 text-success"></i> Szerződés generálás
					</a>
					<a class="dropdown-item" :href="formDataUrl + '/' + row.id + '?action=download'"  v-if="row.file_id">
						<i class="far fa-download fa-fw me-1 text-secondary"></i> Letöltés
					</a>
					<a href="#" class="dropdown-item" @click.prevent="sendQuote(row.id)" v-if="editable && row.status === 'approved'">
						<i class="far fa-envelope fa-fw me-1 text-warning"></i> Küldés az ügyfélnek
					</a>
				</TableActionDropdown>
			</template>
			<template #item-public_id="item">
				<div v-if="highlight === item.id" class="highlighted">
					{{item.public_id}} <i class="far fa-exclamation-triangle ms-2"></i>
				</div>
				<template v-else>
					{{item.public_id}}
				</template>
			</template>
			<template #item-title="item">
				<template v-if="item.title">
					{{ item.title }}
				</template>
				<template v-else>
					{{ client.is_company ? projectType.name_in_quote_company : projectType.name_in_quote_consumer }}
				</template>
			</template>
			<template #item-status="item">
				<div class="d-flex gap-2 flex-wrap">
					<span :class="'badge badge-xl bg-' + quoteStatusLabels[item.status].color">
						<i :class="quoteStatusLabels[item.status].icon"></i> {{ quoteStatusLabels[item.status].name }}
					</span>
					<span :class="'badge badge-xl bg-light'" v-if="item.approveQuoteRequestId">
						<i class="far fa-check"></i> Jóváhagyás alatt
					</span>
				</div>
			</template>
			<template #item-contract_public_id="item">
				<a href="#" @click.prevent="emitOpenContract(item.contract_id)">{{ item.contract_public_id }}</a>
			</template>
			<template #item-total_price_net="item">
				<Price
					:value="item.total_price_net"
					:currency-id="item.currency_id"
					:currencies="currencies"
					/>
			</template>
			<template #item-total_price_gross="item">
				<Price
					:value="item.total_price_gross"
					:currency-id="item.currency_id"
					:currencies="currencies"
					/>
			</template>
			<template #item-created_at="item">
				{{ formatDate(item.created_at) }}
			</template>
		</DataTable>

		<teleport to="body" v-if="formOpen">
			<QuoteForm
				:quote-id="quoteFormId"
				:project-attributes="projectAttributes"
				:product-categories="projectType.product_categories"
				:project-fields="projectType.fields_data"
				:client="client"
				:products="products"
				:taxes="taxes"
				:data-url="formDataUrl"
				:product-types="productTypes"
				:currencies="currencies"
				:editable="editable"
				:quote-status-labels="quoteStatusLabels"
				@close="formOpen = false"
				@close-and-reload="closeQuoteFormAndUpdateQuotes"
				@approve="approveQuote"
				@send="sendQuote"
				:lock="lock"
				:can-approve-quote="canApproveQuote"
				:can-start-editing="canStartEditing"
				@start-editing="$emit('startEditing')"
				@update-stats="updateStats"
				:can-add-quote-discount-material="canAddQuoteDiscountMaterial"
				:can-add-quote-discount-work="canAddQuoteDiscountWork"
			></QuoteForm>
		</teleport>
		<teleport to="body" v-if="responseFormOpen">
			<QuoteResponseForm
				:quote-id="quoteFormId"
				:data-url="formDataUrl"
				:currencies="currencies"
				:file-upload-url="fileUploadUrl"
				@close="responseFormOpen = false"
				@close-and-reload="closeQuoteResponseFormAndUpdateQuotes"
				></QuoteResponseForm>
		</teleport>
	</div>
</template>
<script>
import TableActionCell from "../../components/TableActionCell";
import QuoteForm from './QuoteForm.vue'
import {formatDate} from "../../functions";
import Price from "../../components/Price.vue";
import DataTable from "../../components/DataTable.vue";
import QuoteResponseForm from "./QuoteResponseForm.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";

export default {
	components: {
		TableActionDropdown,
		QuoteResponseForm,
		Price,
		TableActionCell,
		QuoteForm,
		DataTable,
	},
	emits: ['formFlash', 'quoteCount', 'updateStats', 'openContract', 'startEditing'],
	mounted() {
		if (this.activeTabCommand === 'quote' && this.activeTabParameter){
			this.openQuote(this.activeTabParameter)
		}
	},
	props: {
		projectAttributes: [Array, Object],
		products: [Array, Object],
		taxes: [Array, Object],
		client: Object,
		productTypes: Object,
		projectType: Object,
		dataUrl: String,
		formDataUrl: String,
		currencies: Object,
		quoteStatusLabels: Object,
		editable: Boolean,
		canApproveQuote: Boolean,
		canDeleteQuote: Boolean,
		canReopenQuote: Boolean,
		canAddQuoteDiscountWork: Boolean,
		lock: Object,
		canAddQuoteDiscountMaterial: Boolean,
		canStartEditing: Boolean,
		canCreateContract: Boolean,
		activeTabCommand: [String, null],
		activeTabParameter: [String, null],
		canCreate: Boolean,
		canUpdate: Boolean,
		canDelete: Boolean,
		canAnswer: Boolean,
		fileUploadUrl: String,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Azonosító',
				value: 'public_id',
				sortable: true,
				width: 10
			},
			{
				text: 'Név',
				value: 'title',
				sortable: false,
			},
			{
				text: 'Állapot',
				value: 'status',
				sortable: false,
			},
			{
				text: 'Nettó',
				value: 'total_price_net',
				align: 'right',
				sortable: true,
				width: 10
			},
			{
				text: 'Bruttó',
				value: 'total_price_gross',
				align: 'right',
				sortable: true,
				width: 10
			},
			{
				text: 'Szerződés',
				value: 'contract_public_id',
				sortable: true,
				width: 10
			},
			{
				text: 'Létrehozva',
				value: 'created_at',
				wrap: false,
				align: 'right',
				sortable: true,
				width: 10
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]
		let highlight = null;
		if (location.hash && location.hash.length > 1){
			let tmp = location.hash.substring(1).split('-')
			if (tmp[0] !== undefined && tmp[0] === 'quotes' && tmp[1] !== undefined && tmp[1].indexOf('highlight') > -1) {
				highlight = parseInt(tmp[1].replace('highlight:', ''))
			}
		}

		return {
			highlight: highlight,
			columns: columns,
			formOpen: false,
			quoteFormId: null,
			responseFormOpen: false,
		}
	},
	methods: {
		formatDate: formatDate,
		isEditable: function(row){
			return row.editable
		},
		openQuote: function(quoteId){
			this.quoteFormId = quoteId
			this.formOpen = true
		},
		deleteQuote: function(quoteId){
			this.loading = true
			let that = this
			axios.post(this.formDataUrl + '/' + quoteId + '?action=delete', {}).then((response)=>{
				if (response.data.flash !== undefined){
					this.$emit('formFlash', response.data.flash);
				}
				this.$refs.table.getData()
			}).catch(function (error) {
				that.loading = false
				that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
			})
		},
		closeQuoteFormAndUpdateQuotes: function(){
			this.formOpen = false
			this.$refs.table.getData()
		},
		closeQuoteResponseFormAndUpdateQuotes: function(){
			this.responseFormOpen = false
			this.$refs.table.getData()
		},
		approveQuote: function (quoteId){
			let that = this
			axios.post(this.formDataUrl + '/' + quoteId + '?action=approve').then((response)=>{
				if (response.data.flash !== undefined){
					this.$emit('formFlash', response.data.flash);
				}
				this.formOpen = false
				this.$refs.table.getData()
			}).catch(function (error) {
				that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
			})
		},
		canSendApprovalRequest: function(row){
			if (row.status !== 'draft' || this.canApproveQuote){
				return false
			}

			return row.approveQuoteRequestId < 1
		},
		requestQuoteApproval: function (quoteId){
			let that = this
			axios.post(this.formDataUrl + '/' + quoteId + '?action=requestApproval').then((response)=>{
				if (response.data.flash !== undefined){
					this.$emit('formFlash', response.data.flash);
				}
				this.formOpen = false
				this.$refs.table.getData()
			}).catch(function (error) {
				that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
			})
		},
		sendQuote: function (quoteId){
			let that = this
			axios.post(this.formDataUrl + '/' + quoteId + '?action=send').then((response)=>{
				if (response.data.flash !== undefined){
					this.$emit('formFlash', response.data.flash);
				}
				this.formOpen = false
				this.$refs.table.getData()
			}).catch(function (error) {
				that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
			})
		},
		cloneQuote: function (quoteId){
			let that = this
			axios.post(this.formDataUrl + '/' + quoteId + '?action=clone').then((response)=>{
				if (response.data.flash !== undefined){
					this.$emit('formFlash', response.data.flash);
				}
				this.formOpen = false
				this.$refs.table.getData()
			}).catch(function (error) {
				that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
			})
		},
		reopenQuote: function (quoteId){
			let that = this
			axios.post(this.formDataUrl + '/' + quoteId + '?action=reopen').then((response)=>{
				if (response.data.flash !== undefined){
					this.$emit('formFlash', response.data.flash);
				}
				this.formOpen = false
				this.$refs.table.getData()
			}).catch(function (error) {
				that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
			})
		},
		contractFromQuote: function (quoteId){
			let that = this
			axios.post(this.formDataUrl + '/' + quoteId + '?action=createContract').then((response)=>{
				if (response.data.flash !== undefined){
					this.$emit('formFlash', response.data.flash);
				}
				this.formOpen = false
				this.$refs.table.getData()
				if (response.data.stats !== undefined) {
					this.$emit('updateStats', response.data.stats)
				}
			}).catch(function (error) {
				that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
			})
		},
		openResponseForm: function (quoteId){
			this.quoteFormId = quoteId
			this.responseFormOpen = true
		},
		updateStats: function(stats){
			this.$emit('updateStats', stats);
		},
		emitOpenContract: function(id){
			this.$emit('openContract', id);
		}
	}
}
</script>

<style scoped>
</style>