<template>
	<div>
		<form-wrapper
			ref="formWrapper"
			:store-url="storeUrl"
			:back-url="backUrl"
			:initial-flash="initialFlash"
			v-model="attributes"
		>
			<template v-slot:buttons>
				<a :href="createClientUrl" v-if="!client && attributes.id !== undefined" class="btn btn-warning btn-label"><i class="far fa-user-plus label-icon"></i> Ügyfél létrehozása</a>
			</template>
			<template v-slot:top>
				<div class="card mb-3" v-if="client || attributes.id !== undefined">
					<div class="card-body">
						<div v-if="attributes.id !== undefined" class="details-box mb-0">
							<div v-if="attributes.public_id">
								<i class="far fa-hashtag"></i> Azonosító: <strong>{{ attributes.public_id }}</strong>
							</div>
							<div v-if="attributes.handler">
								<i class="far fa-user-friends"></i> Tanácsadó: <strong>{{ attributes.handler.name }}</strong>
							</div>
							<div v-if="attributes.created_at">
								<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(attributes.created_at)  }}</strong>
							</div>
							<div v-if="attributes.source">
								<i class="far fa-globe"></i> Forrás: <strong>{{ attributes.source }}</strong>
							</div>
						</div>
						<div v-if="client" class="mb-4 details-box">
							<h4>Ügyfél</h4>
							<div>
								<i class="far fa-hashtag"></i> Azonosító:
								<a :href="clientUrl" v-if="clientUrl">{{ client.public_id }}</a>
								<strong v-else>{{ client.public_id }}</strong>
							</div>
							<div v-if="client.created_at">
								<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(client.created_at) }}</strong>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template #default="{activeTab, errors}">
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="card-title mb-4">Adatok</h4>
						<div class="row">
							<div class="col-sm-6">
								<div class="mb-2" v-if="allowHandlerSelect">
									<label>Tanácsadó</label>

									<v-select
										:options="users"
										v-model="attributes.handler_user_id"
										label="name"
										:reduce="item => item.id"
										:clearable="false"
									>
									</v-select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-sm-6">
								<radio-field
									label="Típus"
									v-model="attributes.is_company"
									:disabled="!allowEditing"
									:values="typeValues"
									:hide-non-active="attributes.id !== undefined"
								/>

								<input-field
									label="Vezetéknév"
									name="last_name"
									rules="required"
									v-model="attributes.last_name"
									:disabled="!allowEditing"
									:errors="errors"
								/>
								<input-field
									label="Keresztnév"
									name="first_name"
									rules="required"
									v-model="attributes.first_name"
									:disabled="!allowEditing"
									:errors="errors"
								/>
								<input-field
									label="Cégnév"
									name="company_name"
									rules="required"
									v-model="attributes.company_name"
									:disabled="!allowEditing"
									v-if="attributes.is_company"
									:errors="errors"
								/>
								<select-field
									label="Projekt típus"
									name="project_type_id"
									rules="required"
									v-model="attributes.project_type_id"
									:disabled="!allowEditing || attributes.id !== undefined"
									:options="projectTypes"
									:errors="errors"
								/>
							</div>
							<div class="col-sm-6">
								<RadioField
									label="Állapot"
									name="status"
									v-model="attributes.status"
									:values="filteredStatusLabels"
								></RadioField>
								<DateField
									label="Emlékeztető időpontja"
									name="postponed_until"
									v-model="attributes.postponed_until"
									v-if="attributes.status === 'postponed'"
									></DateField>
								<input-field
									label="E-mail cím"
									name="email"
									rules="required|email"
									v-model="attributes.email"
									:disabled="!allowEditing"
									:errors="errors"
								/>
								<input-field
									label="Telefonszám"
									name="phone"
									rules="required"
									data-type="phone"
									v-model="attributes.phone"
									:disabled="!allowEditing"
									:errors="errors"
								/>
							</div>
						</div>
						<textarea-field
							label="Üzenet"
							name="message"
							v-model="attributes.message"
							:disabled="true"
							/>

						<textarea-field
							label="Megjegyzés"
							name="comment"
							v-model="attributes.comment"
							:disabled="!allowEditing"
							:rows="3"
							/>
					</div>
				</div>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import SwitchField from "../../components/form/SwitchField.vue"
import RadioField from "../../components/form/RadioField.vue"
import SelectField from "../../components/form/SelectField.vue"
import FormWrapper from "../../components/FormWrapper.vue";
import {formatDate} from "../../functions";
import DateField from "../../components/form/DateField.vue";
import vSelect from "vue-select";
import TextareaField from "../../components/form/TextareaField.vue";

export default {
	components: {
		TextareaField,
		DateField, InputField, SwitchField, RadioField, SelectField, FormWrapper, vSelect},
	props: {
		initialAttributes: Object,
		storeUrl: String,
		backUrl: String,
		initialFlash: Array,
		projectTypes: Array,
		createClientUrl: String,
		allowEditing: Boolean,
		client: Object,
		clientUrl: String,
		statusLabels: Object,
		users: Array,
		allowHandlerSelect: Boolean
	},
	data(){
		return {
			attributes: this.initialAttributes,
			loading: false,
			flash: [],
			typeValues: [
				{
					label: 'Magánszemély',
					icon: 'far fa-user'
				},
				{
					label: 'Cég',
					icon: 'far fa-industry'
				},
			]
		}
	},
	computed: {
		filteredStatusLabels: {
			get(){
				let payload = this.statusLabels
				if (this.attributes.status !== 'pending'){
					delete payload.pending
				}
				if (this.client){
					delete payload.deleted
					delete payload.postponed
				} else {
					delete payload.client
				}

				return payload
			}
		}
	},
	mounted() {
	},
	methods: {
		formatDate
	}
}
</script>

<style scoped>

</style>