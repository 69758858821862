<template>
	<div>
		<form-wrapper
			ref="formWrapper"
			:store-url="storeUrl"
			:back-url="backUrl"
			:initial-flash="[]"
			v-model="componentValue"
			save-button-label="Tovább"
			save-button-icon="fas fa-angle-right"
			:disable-redirect="true"
			@after-submit="shipmentCreated"
		>
			<template #default="{activeTab, errors}">
				<div class="card">
					<div class="card-body">
						<h4 class="card-title mb-3">Szállítmány adatai</h4>
						<div class="row">
							<div class="col-md-6">
								<template v-if="componentValue.type === 'incoming'">
									<select-field
										label="Beszállító"
										name="supplier_id"
										rules="required"
										v-model="componentValue.supplier_id"
										:options="suppliers"
										:errors="errors"
									/>
									<select-field
										label="Raktár"
										name="destination_warehouse_id"
										rules="required"
										v-model="componentValue.destination_warehouse_id"
										:options="warehouses"
										:errors="errors"
									/>
									<input-field
										label="Szállítólevél azonosító"
										name="foreign_id"
										rules="required"
										v-model="componentValue.foreign_id"
										:errors="errors"
									/>
								</template>
								<template v-if="componentValue.type === 'transfer'">
									<select-field
										label="Forrás raktár"
										name="source_warehouse_id"
										rules="required"
										v-model="componentValue.source_warehouse_id"
										:options="warehouses"
										:errors="errors"
									/>
									<select-field
										label="Cél raktár"
										name="destination_warehouse_id"
										rules="required"
										v-model="componentValue.destination_warehouse_id"
										:options="destinationWarehouses"
										:errors="errors"
									/>
								</template>
								<template v-if="componentValue.type === 'delivery'">
									<select-field
										label="Raktár"
										name="source_warehouse_id"
										rules="required"
										v-model="componentValue.source_warehouse_id"
										:options="warehouses"
										:errors="errors"
									/>

									<template v-if="deliveryWithoutContract">
										<contract-select
												label="Szerződés"
												name="contract_id"
												v-model="componentValue.contract_id"
												:errors="errors"
										/>
										<template v-if="!componentValue.contract_id">
											<input-field
													label="Címzett neve"
													name="destination_name"
													rules="required"
													v-model="componentValue.destination_name"
													:errors="errors"
											/>
											<input-field
													label="Címzett címe"
													name="destination_address"
													rules="required"
													v-model="componentValue.destination_address"
													:errors="errors"
											/>
										</template>
									</template>
									<template v-else>
										<contract-select
												label="Szerződés"
												name="contract_id"
												v-model="componentValue.contract_id"
												:errors="errors"
												rules="required"
										/>
									</template>
								</template>
								<template v-if="componentValue.type === 'return'">
									<select-field
										label="Raktár"
										name="destination_warehouse_id"
										rules="required"
										v-model="componentValue.destination_warehouse_id"
										:options="warehouses"
										:errors="errors"
									/>
									<template v-if="deliveryWithoutContract">
										<contract-select
											label="Szerződés"
											name="contract_id"
											v-model="componentValue.contract_id"
											:errors="errors"
											v-if="!componentValue.inventory_shipment_id"
										/>
										<inventory-shipment-select
											label="Szállítmány"
											type-filter="delivery"
											name="inventory_shipment_id"
											v-model="componentValue.inventory_shipment_id"
											:errors="errors"
											v-if="!componentValue.contract_id"
										/>
									</template>
									<template v-else>
										<contract-select
												label="Szerződés"
												name="contract_id"
												v-model="componentValue.contract_id"
												:errors="errors"
												v-if="!componentValue.inventory_shipment_id"
												rules="required"
										/>
									</template>
								</template>
								<template v-if="componentValue.type === 'correction'">
									<select-field
										label="Raktár"
										name="source_warehouse_id"
										rules="required"
										v-model="componentValue.source_warehouse_id"
										:options="warehouses"
										:errors="errors"
									/>

									<select-field
										label="Típus"
										name="correction_type"
										rules="required"
										data-type="string"
										v-model="componentValue.correction_type"
										:options="correctionTypesArray"
										:errors="errors"
									/>

									<inventory-shipment-select
										label="Szállítmány"
										name="inventory_shipment_id"
										rules="required"
										type-filter="incoming"
										v-model="componentValue.inventory_shipment_id"
										:errors="errors"
										v-if="componentValue.correction_type && correctionTypes[componentValue.correction_type].hasInventoryShipment"
									/>
								</template>
							</div>
							<div class="col-md-6">
								<TextareaField
									name="comment"
									:rows="4"
									v-model="componentValue.comment"
									label="Megjegyzés"
									></TextareaField>
							</div>
						</div>
					</div>
				</div>
				<div class="card" v-if="componentValue.type === 'incoming'">
					<div class="card-body">
						<h4 class="card-title mb-3">Dokumentumok</h4>
						<FileUploader
							field-name="file"
							v-model="componentValue.files"
							:file-upload-url="fileUploadUrl"
							file-type="file"
						></FileUploader>
					</div>
				</div>
			</template>
		</form-wrapper>
	</div>

</template>

<script>
import FormWrapper from "../../components/FormWrapper.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import SelectField from "../../components/form/SelectField.vue";
import InputField from "../../components/form/InputField.vue";
import FileUploader from "../../components/FileUploader.vue";
import {objectToArray} from "../../functions";
import InventoryShipmentSelect from "../../components/form/InventoryShipmentSelect.vue";
import ContractSelect from "../../components/form/ContractSelect.vue";
export default {
	emits: ['update:modelValue', 'shipmentCreated'],
	components: {ContractSelect, InventoryShipmentSelect, InputField, TextareaField, FormWrapper, SelectField, FileUploader},
	props: {
		modelValue: Object,
		storeUrl: String,
		backUrl: String,
		fileUploadUrl: String,
		shipmentSearchUrl: String,
		warehouses: Array,
		suppliers: Array,
		contracts: Array,
		shipments: Array,
		correctionTypes: Object,
		deliveryWithoutContract: Boolean
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		correctionTypesArray: {
			get(){
				return objectToArray(this.correctionTypes)
			}
		},
		shipmentSearchUrlFormatted: {
			get(){
				if (this.componentValue.correction_type && this.correctionTypes[this.componentValue.correction_type].hasInventoryShipment){
					return this.shipmentSearchUrl + '?type=' + this.correctionTypes[this.componentValue.correction_type].hasInventoryShipment + '&q='
				}

				return this.shipmentSearchUrl + '?q='
			}
		},
		destinationWarehouses: {
			get(){
				let that = this
				let payload = _.filter(this.warehouses, function(item){
					return item.id !== that.componentValue.source_warehouse_id
				})

				if (that.componentValue.source_warehouse_id === that.componentValue.destination_warehouse_id){
					let first = _.first(payload)
					if (first) {
						that.componentValue.destination_warehouse_id = first.id
					} else {
						that.componentValue.destination_warehouse_id = ''
					}
				}

				return payload
			}
		}
	},
	data(){
		return {
			attributes: this.initialAttributes,
		}
	},
	methods: {
		shipmentCreated: function(attributes){
			this.$emit('shipmentCreated', attributes)
		},
	}
}
</script>

<style scoped>

</style>