<template>
	<div>
		<form-wrapper
			ref="formWrapper"
			:store-url="storeUrl"
			:back-url="backUrl"
			:initial-flash="initialFlash"
			v-model="attributes"
			v-slot="{ errors }"
		>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!allowEditing"
								:errors="errors"
							/>

							<switch-field
								label="Szuperadmin"
								name="is_superadmin"
								v-model="attributes.is_superadmin"
								:disabled="!allowEditing"
								/>
						</div>
					</div>
				</div>
			</div>
			<template v-if="!attributes.is_superadmin">
				<div v-for="module in modules">
					<div class="card mb-3" v-if="module.permissions.length">
						<div class="card-body">
							<h4 class="card-title mb-4"><i :class="module.icon"></i> {{ module.name }}</h4>
							<div class="row">
								<div class="col-md-3" v-for="permission in module.permissions">
									<div class="form-check form-switch form-check-inline mb-2">
										<input class="form-check-input" v-model="attributes.permissions" type="checkbox" :id="'permission_' + permission.id" :value="permission.id">
										<label class="form-check-label" :for="'permission_' + permission.id">{{permission.actionName}}</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import SwitchField from "../../components/form/SwitchField.vue"
import FormWrapper from "../../components/FormWrapper.vue";
export default {
	components: {InputField, SwitchField, FormWrapper},
	props: ['initialAttributes', 'storeUrl', 'backUrl', 'modules', 'initialFlash', 'allowEditing'],
	data(){
		return {
			attributes: this.initialAttributes,
			loading: false
		}
	},
	created: function () {
		this.moment = window.moment;
		let tmp = []
		for (let i in this.attributes.permissions){
			tmp.push(this.attributes.permissions[i].id)
		}
		this.attributes.permissions = tmp

	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style scoped>

</style>