<template>
	<div>
		<form-wrapper
				ref="formWrapper"
				:store-url="storeUrl"
				:back-url="backUrl"
				:show-back-button="false"
				:initial-flash="initialFlash"
				v-model="config"
				v-slot="{ errors }"
		>
			<div class="row">
				<template v-for="box in attributes">
					<div class="col-md-6">
						<div class="card">
							<div class="card-body">
								<h4 class="card-title mb-4">{{ box.title }}</h4>
								<template v-for="(attrDetails, attrKey) in box.attributes">
									<input-field
										:label="attrDetails.name"
										:name="attrKey"
										:info="attrDetails.info !== undefined ? attrDetails.info : ''"
										:prefix="attrDetails.prefix !== undefined ? attrDetails.prefix : ''"
										:suffix="attrDetails.suffix !== undefined ? attrDetails.suffix : ''"
										v-model="config[attrKey].value"
										v-if="attrDetails.type === 'text'"
										></input-field>
									<switch-field
											:label="attrDetails.name"
											:name="attrKey"
											:info="attrDetails.info !== undefined ? attrDetails.info : ''"
											v-model="config[attrKey].value"
											v-if="attrDetails.type === 'boolean'"
											></switch-field>
								</template>
							</div>
						</div>
					</div>
				</template>
			</div>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import SwitchField from "../../components/form/SwitchField.vue"
import FormWrapper from "../../components/FormWrapper.vue";
export default {
	components: {InputField, SwitchField, FormWrapper},
	props: {
		attributes: Object,
		initialConfig: Object,
		storeUrl: String,
		backUrl: String,
		initialFlash: Array,
	},
	data(){
		return {
			config: this.initialConfig,
			loading: false
		}
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style scoped>

</style>