<template>
	<a :href="'tel:+' + value">{{ formattedValue }}</a>
</template>

<script>
export default {
	props: {
		value: String
	},
	computed: {
		formattedValue: {
			get(){
				if (this.value.length === 11){
					return '+' + this.value.substring(0, 2) + ' ' + this.value.substring(2, 4) + ' ' + this.value.substring(4, 7) + ' ' + this.value.substring(7, 11)
				}
				if (this.value.substring(2, 3) === "1"){
					return '+' + this.value.substring(0, 2) + ' ' + this.value.substring(2, 3) + ' ' + this.value.substring(3, 6) + ' ' + this.value.substring(6, 10)
				}
				return '+' + this.value.substring(0, 2) + ' ' + this.value.substring(2, 4) + ' ' + this.value.substring(4, 7) + ' ' + this.value.substring(7, 10)
			}
		}
	}
}
</script>

<style scoped>

</style>