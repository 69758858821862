export let mixed = {
	default: '${path} érvénytelen',
	required: '${path} mező kitöltése kötelező',
	notNull: '${path} nem lehet null',
	notType: ({ path, type, value, originalValue }) => {
		return `${path} mező kitöltése kötelező`;
	},
};

export let string = {
	min: '${path} minimum ${min} karakter hosszú legyen',
	max: '${path} maximum ${max} karakter hosszú legyen',
	email: '${path} érvényes e-mail címnek kell lennie',
	url: '${path} érvényes URL-nek kell lennie',
};

export let number = {
	min: '${path} nagyobb vagy egyenlőnek kell lennie mint ${min}',
	max: '${path} kisebb vagy egyenlőnek kell lennie mint ${max}',
	lessThan: '${path} kisebbnek kell lennie mint ${less}',
	moreThan: '${path} nagyobbnak kell lennie mint ${more}',
	positive: '${path} pozítív szám legyen',
	negative: '${path} negatív szám legyen',
	integer: '${path} egész szám legyen',
};

export let date = {
	min: '${path} későbbi dátum legyen mint ${min}',
	max: '${path} korábbi dátum legyen mint ${max}',
};

export let array = {
	min: '${path} legalább ${min} elemnek kell lennie',
	max: '${path} maximum ${max} elemnek kell lennie',
	length: '${path} ${length} elemnek kell lennie',
};

export default {
	mixed,
	string,
	number,
	date,
	array,
};
