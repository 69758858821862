<template>
	<DataTable
		:data-url="listDataUrl"
		:initial-flash="initialFlash"
		:columns="columns"
		sort-by="public_id"
		sort-type="desc"
		name="projects"
	>
		<template #filters="{serverOptions}">
			<div class="col-12 col-md-3">
				<v-select
					v-model="serverOptions.project_type_id"
					:options="projectTypes"
					:reduce="option => option.id"
					placeholder="Típus"
					label="name"
					:multiple="false"
					:clearable="true"
				>
					<template v-slot:option="option">
						<span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
					</template>
					<template v-slot:selected-option="option">
						<span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
					</template>
				</v-select>
			</div>
			<div class="col-12 col-md-3">
				<v-select
					v-model="serverOptions.project_type_milestone_id"
					:options="getMilestoneSelectOptions(serverOptions.project_type_id)"
					:reduce="option => option.id"
					placeholder="Mérföldkő"
					label="name"
					:multiple="false"
					:clearable="true"
				>
					<template v-slot:option="option">
						<template v-if="serverOptions.project_type_id">
							<span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
						</template>
						<template v-else>
							<div class="d-flex gap-3">
								{{ getProjectTypeById(option.project_type_id).name }}
								<span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
							</div>
						</template>
					</template>
					<template v-slot:selected-option="option">
						<template v-if="serverOptions.project_type_id">
							<span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
						</template>
						<template v-else>
							<div class="d-flex gap-3">
								{{ getProjectTypeById(option.project_type_id).name }}
								<span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
							</div>
						</template>
					</template>
				</v-select>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
				:data="item"
				:edit-url="editUrl"
				:delete-url="deleteUrl"
				:is-editable="isEditable(item)"
				:is-deletable="isDeletable(item)"
				:emit-delete-event="true"
				@deleteButtonPressed="deleteButtonPressed"
			></TableActionCell>
		</template>
		<template #item-public_id="item">
			<div class="fw-bold">{{ item.public_id }}</div>
			<div v-if="item.handlerUserName"><i class="far fa-user"></i> {{ item.handlerUserName }}</div>
			<div><i class="far fa-clock"></i> {{ formatDate(item.created_at) }}</div>
		</template>
		<template #item-clientName="item">
			<ClientCell
				:data="item"></ClientCell>
		</template>
		<template #item-type="item">
			<ProjectTypeCell
				:data="item"
				:project-types="projectTypes"
			></ProjectTypeCell>
		</template>
		<template #item-milestones="item">
			<ProjectMilestonesCell
				:data="item"
				:milestones="milestones"
			></ProjectMilestonesCell>
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import ProjectTypeCell from './ProjectTypeCell'
import ProjectMilestonesCell from './ProjectMilestonesCell.vue'
import {formatDate} from "../../functions";
import ClientCell from "./ClientCell.vue";
import vSelect from 'vue-select'

export default {
	components: {
		DataTable,
		ClientCell,
		ProjectMilestonesCell,
		ProjectTypeCell,
		TableActionCell,
		vSelect,
	},
	props: {
		dataUrl: String,
		editUrl: String,
		deleteUrl: String,
		projectTypes: Array,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
		clientId: {
			type: Number,
			default: 0
		},
		milestones: Array,
	},
	data(){
		let columns = [{
			text: 'Adatok',
			value: 'public_id',
			sortable: true,
		}]
		if (this.clientId < 1){
			columns.push({
				text: 'Ügyfél',
				value: 'clientName',
				sortable: true,
			})
		}
		columns.push({
			text: 'Típus',
			value: 'type',
			sortable: true,
		})
		columns.push({
			text: 'Haladás',
			value: 'milestones',
			sortable: false,
		})
		columns.push({
			label: '',
			value: 'operations',
			width: 5,
		})

		let listDataUrl = this.dataUrl
		if (this.clientId){
			listDataUrl += '/' + this.clientId
		}

		return {
			serverOptions: {
				page: 1,
				rowsPerPage: 20,
				sortBy: 'public_id',
				sortType: 'desc',
				search: '',
				project_type_id: ''
			},
			columns: columns,
			listDataUrl: listDataUrl,
		}
	},
	methods: {
		formatDate: formatDate,
		isEditable: function(row){
			return row.editable
		},
		isDeletable: function(row){
			return row.deletable
		},
		getProjectTypeById: function (id){
			for (let i = 0; i < this.projectTypes.length; i++){
				if (this.projectTypes[i].id === id){
					return this.projectTypes[i]
				}
			}
		},
		getMilestoneSelectOptions: function(typeId){
			let payload = []
			if (typeId !== null && typeId){
				for (let i = 0; i < this.milestones.length; i++){
					if (this.milestones[i].project_type_id === typeId){
						payload.push(this.milestones[i])
					}
				}
			} else {
				payload = this.milestones
			}

			return payload
		}
	}
}
</script>

<style scoped>
</style>