<template>
	<div class="card shadow-none border file p-2 m-0">
		<div class="d-flex">
			<div style="width: 100px;" class="me-3 d-flex align-items-center justify-content-center">
				<a href="#" v-if="file.thumbnail" class="d-block" @click.prevent="displayLightbox = true">
					<img class="img-fluid" :src="file.thumbnail" />
				</a>
				<div class="file-icon" v-else>
					<FileIcon :filename="file.extension"></FileIcon>
				</div>
			</div>
			<div>
				<div class="filename fw-bold">
					<a :href="file.download_url" target="_blank" :title="file.name + '.' + file.extension"><i class="far fa-download"></i> {{file.name}}</a>
				</div>
				<div class="d-flex gap-2">
					<div class="file-size"><i class="far fa-file"></i> {{ formatFileSize(file.size) }}</div>
					<div v-if="file.width" class="file-resolution"><i class="far fa-image"></i> {{file.width}} x {{file.height}} px</div>
				</div>
				<div><i class="far fa-calendar-alt"></i> {{formatDate(file.created_at)}}</div>
				<div><i class="far fa-user"></i> {{file.created_by_user_name}}</div>
			</div>
		</div>
	</div>
	<teleport to="body" v-if="displayLightbox">
		<div class="lightbox" @click.prevent="displayLightbox = false">
			<div class="lightbox-image">
				<img :src="file.download_url" class="img-responsive" />
			</div>
			<button class="btn btn-secondary btn-label" @click.prevent="displayLightbox = false">
				<i class="far fa-times label-icon"></i> Bezár
			</button>
		</div>
	</teleport>
</template>

<script>
import FileIcon from "./FileIcon.vue";
import {formatFileSize, formatDate} from "../functions";

export default {
	components: {FileIcon},
	props: {
		file: Object
	},
	data(){
		return {
			displayLightbox: false
		}
	},
	methods: {
		formatFileSize,
		formatDate
	}
}
</script>

<style scoped>

</style>