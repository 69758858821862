<template>
	<DataTable
			:data-url="dataUrl"
			:initial-flash="initialFlash"
			:columns="columns"
			sort-by="name"
			sort-type="asc"
			name="partners"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
				:data="item"
				:edit-url="editUrl"
				:delete-url="deleteUrl"
				:emit-delete-event="true"
				@delete-button-pressed="deleteButtonPressed"
			></TableActionCell>
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import BooleanBadge from "../../components/BooleanBadge.vue";

export default {
	components: {
		DataTable,
		BooleanBadge,
		TableActionCell
	},
	props: {
		dataUrl: String,
		editUrl: String,
		deleteUrl: String,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'E-mail',
				value: 'email',
				sortable: true
			},
			{
				text: 'Telefonszám',
				value: 'phone',
				sortable: true
			},
			{
				text: 'Weboldal',
				value: 'url',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
		}
	},
}
</script>

<style scoped>
</style>
