<template>
	<div class="card-body">
		<h4 class="card-title mb-3">Mérföldkövek</h4>
		<div class="card border shadow-none card-body" v-for="(milestone, milestoneKey) in componentValue" v-bind:key="'milestone_' + milestone.order">
			<div class="d-flex">
				<div class="sortable-form-card--left">
					<button type="button" @click.prevent="moveMilestone(milestoneKey, 'up')" class="btn btn-secondary mx-1 mt-2"><i class="far fa-arrow-up"></i></button>
					<button type="button" @click.prevent="moveMilestone(milestoneKey, 'down')" class="btn btn-secondary mx-1"><i class="far fa-arrow-down"></i></button>
					<button type="button" @click.prevent="removeMilestone(milestoneKey)" class="btn btn-danger mx-1"><i class="far fa-times"></i></button>
				</div>
				<div class="sortable-form-card--right">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group mb-2">
								<label>Név</label>
								<input type="text" required v-model="milestone.name" class="form-control">
							</div>
							<div class="form-group mb-2">
								<label>Rövid név</label>
								<input type="text" required v-model="milestone.short_name" class="form-control">
							</div>
							<iconpicker-field
								v-model="milestone.icon"
								label="Ikon"
								:name="'icon' + milestone.order + 'Checkbox'"
								/>
							<div class="form-group mb-2">
								<label>Szín</label>
								<input type="color" v-model="milestone.color" class="form-control form-control-color">
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group mb-2">
								<SwitchField
									label="Publikus"
									:name="'is_public' + milestone.order + 'Checkbox'"
									v-model="milestone.is_public"
								/>
								<SwitchField
									label="Prioritás"
									:name="'priority' + milestone.order + 'Checkbox'"
									v-model="milestone.priority"
								/>
								<SwitchField
									label="Telepítéshez szükséges"
									:name="'required_for_installation' + milestone.order + 'Checkbox'"
									v-model="milestone.required_for_installation"
								/>
								<SwitchField
									label="Ügyfél értesítése"
									:name="'notify_client' + milestone.order + 'Checkbox'"
									v-model="milestone.notify_client"
								/>
								<SwitchField
									label="Tanácsadó értesítése"
									:name="'notify_user' + milestone.order + 'Checkbox'"
									v-model="milestone.notify_user"
								/>
							</div>
							<div class="mb-3 mb-md-0 position-relative" v-if="milestone.notify_user">
								<label>Szerepek értesítése</label>
								<v-select
									v-model="milestone.notification_settings"
									:options="roles"
									:reduce="option => option.id"
									label="name"
									:multiple="true"
									:clearable="false"
								></v-select>
							</div>
						</div>
					</div>
					<h4 class="card-title mb-2 mt-3">Feltételek</h4>
					<div class="card bg-light p-3 mb-3" v-for="(condition, conditionKey) in milestone.conditions">
						<div class="row">
							<div class="col-md-4">
								<div class="mb-3 mb-md-0 position-relative">
									<label>Típus</label>
									<v-select
										v-model="condition.type"
										:options="milestoneTypes"
										:reduce="option => option.key"
										label="name"
										:multiple="false"
										:clearable="false"
									></v-select>
								</div>
							</div>
							<template v-if="condition.type === 'files'">
								<div class="col-md-4">
									<div class="mb-3 mb-md-0 position-relative">
										<label>Filetípus</label>
										<v-select
											v-model="condition.documentType"
											:options="documentTypes"
											:reduce="option => option.id"
											label="name"
											:multiple="false"
											:clearable="false"
										></v-select>
									</div>
								</div>
								<div class="col-md-4">
									<div class="mb-3 mb-md-0 position-relative">
										<label>Min. mennyiség</label>
										<div class="input-group">
											<input type="text" v-model="condition.documentCount" class="form-control" />
											<span class="input-group-text">db</span>
										</div>
									</div>
								</div>
							</template>

							<template v-if="condition.type === 'quotes'">
								<div class="col-md-4">
									<div class="mb-3 mb-md-0 position-relative">
										<label>Státusz</label>
										<v-select
											v-model="condition.quoteStatus"
											:options="milestoneQuoteStatuses"
											:reduce="option => option.key"
											label="name"
											:multiple="false"
											:clearable="false"
										></v-select>
									</div>
								</div>
							</template>

							<template v-if="condition.type === 'contracts'">
								<div class="col-md-4">
									<div class="mb-3 mb-md-0 position-relative">
										<label>Státusz</label>
										<v-select
											v-model="condition.contractStatus"
											:options="milestoneContractStatuses"
											:reduce="option => option.key"
											label="name"
											:multiple="false"
											:clearable="false"
										></v-select>
									</div>
								</div>
							</template>

							<template v-if="condition.type === 'payments'">
								<div class="col-md-4">
									<div class="mb-3 mb-md-0 position-relative">
										<label>Státusz</label>
										<v-select
											v-model="condition.paymentStatus"
											:options="milestonePaymentStatuses"
											:reduce="option => option.key"
											label="name"
											:multiple="false"
											:clearable="false"
										></v-select>
									</div>
								</div>
							</template>

							<template v-if="condition.type === 'delivery'">
								<div class="col-md-4">
									<div class="mb-3 mb-md-0 position-relative">
										<label>Státusz</label>
										<v-select
											v-model="condition.deliveryStatus"
											:options="objectToArray(deliveryTypeLabels)"
											:reduce="option => option.key"
											label="name"
											:multiple="false"
											:clearable="false"
										></v-select>
									</div>
								</div>
							</template>

							<template v-if="condition.type === 'installation'">
								<div class="col-md-4">
									<div class="mb-3 mb-md-0 position-relative">
										<label>Státusz</label>
										<v-select
											v-model="condition.installationStatus"
											:options="objectToArray(installationStatuses)"
											:reduce="option => option.key"
											label="name"
											:multiple="false"
											:clearable="false"
										></v-select>
									</div>
								</div>
							</template>
						</div>
						<div class="mt-2 text-center" v-if="milestone.conditions.length > 1">
							<a href="#" class="text-danger" @click.prevent="removeMilestoneCondition(milestoneKey, conditionKey)"><i class="far fa-times"></i> feltétel törlése</a>
						</div>
					</div>
					<div class="mt-3">
						<button type="button" class="btn btn-secondary btn-label" @click.prevent="addMilestoneCondition(milestoneKey)">
							<i class="far fa-plus label-icon"></i> Feltételt hozzáad
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="mt-3">
			<button type="button" class="btn btn-secondary btn-label" @click.prevent="addMilestone">
				<i class="far fa-plus label-icon"></i> Hozzáad
			</button>
		</div>
	</div>
</template>

<script>
import {moveValueInArray, objectToArray} from "../../functions";
import SwitchField from "../../components/form/SwitchField.vue";
import vSelect from "vue-select";
import IconpickerField from "../../components/form/IconpickerField.vue";
export default {
	emits: ['update:modelValue'],
	components: {
		IconpickerField,
		SwitchField,
		vSelect,
	},
	props: {
		modelValue: Array,
		documentTypes: Array,
		roles: Array,
		deliveryTypeLabels: Object,
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit('update:modelValue', val)
			}
		},
	},
	data(){
		return {
			milestoneTypes: [
				{
					key: 'attributes',
					name: 'Projektadatok kitöltése'
				},
				{
					key: 'files',
					name: 'Dokumentumok'
				},
				{
					key: 'quotes',
					name: 'Árajánlat'
				},
				{
					key: 'contracts',
					name: 'Szerződés'
				},
				{
					key: 'payments',
					name: 'Fizetés'
				},
				{
					key: 'delivery',
					name: 'Kiszállítás'
				},
				{
					key: 'installation',
					name: 'Telepítés'
				},
			],
			milestoneQuoteStatuses: [
				{
					key: 'approved',
					name: 'Jóváhagyva'
				},
				{
					key: 'sent',
					name: 'Elküldve'
				},
				{
					key: 'read',
					name: 'Megnézve'
				},
				{
					key: 'accepted',
					name: 'Elfogadva'
				},
			],
			milestoneContractStatuses: [
				{
					key: 'generated',
					name: 'Generálva'
				},
				{
					key: 'signed',
					name: 'Aláírva'
				},
				{
					key: 'completed',
					name: 'Teljesítve'
				},
			],
			milestonePaymentStatuses: [
				{
					key: 'minimumPaid',
					name: 'Minimum befizetve'
				},
				{
					key: 'paid',
					name: 'Teljes összeg befizetve'
				},
			],
			milestoneDeliveryStatuses: [
				{
					key: 'sourcing',
					name: 'Beszerzés'
				},
				{
					key: 'partiallyReady',
					name: 'Részben lefoglalva'
				},
				{
					key: 'ready',
					name: 'Lefoglalva'
				},
				{
					key: 'partial',
					name: 'Részben kiszállítva'
				},
				{
					key: 'delivered',
					name: 'Kiszállítva'
				},
			],
			installationStatuses: [
				{
					key: 'created',
					name: 'Létrehozva'
				},
				{
					key: 'planned',
					name: 'Betervezve'
				},
				{
					key: 'completed',
					name: 'Telepítve'
				},
				{
					key: 'amendmentSigned',
					name: 'Szerződésmódosítás aláírva'
				},
			],
		}
	},
	methods: {
		objectToArray,
		moveValueInArray,
		addMilestone: function(){
			let maxOrder = 0

			this.componentValue.forEach(function(item, index){
				if (item.order > maxOrder){
					maxOrder = item.order
				}
			})
			this.componentValue.push({
				order: maxOrder + 1,
				conditions: [{order: 0}]
			})
		},
		addMilestoneCondition: function(milestoneKey){
			let maxOrder = 0

			this.componentValue[milestoneKey].conditions.forEach(function(item, index){
				if (item.order > maxOrder){
					maxOrder = item.order
				}
			})
			this.componentValue[milestoneKey].conditions.push({
				order: maxOrder + 1,
			})
		},
		moveMilestone: function(index, direction){
			this.componentValue = this.moveValueInArray(this.componentValue, index, direction)
			let order = 0
			this.componentValue.forEach(function(item, index){
				item.order = order
				order++
			})
		},
		removeMilestone: function(index){
			this.componentValue.splice(index, 1);
		},
		removeMilestoneCondition: function(index, conditionIndex){
			this.componentValue[index].conditions.splice(conditionIndex, 1);
		},
	}
}
</script>

<style scoped>

</style>