<template>
	<DataTable
		:data-url="dataUrl"
		:initial-flash="initialFlash"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="documentTemplates"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
				:data="item"
				:edit-url="editUrl"
				:delete-url="''"
				:is-editable="isEditable"
				:is-deletable="false"
			></TableActionCell>
		</template>
		<template #item-type="item">
			{{ types[item.type] }}
		</template>
	</DataTable>
</template>
<script>
import TableActionCell from "../../components/TableActionCell";
import {formatDate} from "../../functions";
import DataTable from "../../components/DataTable.vue";

export default {
	components: {
		DataTable,
		TableActionCell
	},
	props: {
		dataUrl: String,
		editUrl: String,
		deleteUrl: String,
		isEditable: Boolean,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'Típus',
				value: 'type',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			types: {
				'email': 'E-mail',
				'document': 'Dokumentum',
				'web': 'Weboldal'
			}
		}
	},
	methods: {
		formatDate: formatDate,
	}
}
</script>

<style scoped>
</style>

