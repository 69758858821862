<template>
	<div :class="{'mb-3': !fieldOnly && !noMargin}">
		<label :class="{required: isRequired}" v-if="!fieldOnly">{{ label }}</label>

		<div class="input-group">
			<span class="input-group-text" v-if="componentValue" @click="overlayVisible = true"><i :class="'far fa-' + componentValue"></i></span>

			<Field
				v-model="componentValue"
				:key="inputKey"
				:name="name"
				type="text"
				:readonly="disabled"
				:disabled="disabled"
				@focus="overlayVisible = true"
				@input="onInput"
				:rules="rulesValue"
				:id="id"
				:placeholder="showPlaceholder ? label : ''"
				:class="{'form-control': 1, 'is-invalid': errors[name]}"
				/>
		</div>
		<div class="invalid-feedback" v-if="errors[name]">{{ errors[name] }}</div>
		<p class="text-muted mt-2" v-if="info" v-html="info"></p>
	</div>
	<teleport to="body" v-if="overlayVisible">
		<div class="modal-backdrop fade show" @click.prevent="overlayVisible = false"></div>
		<div class="modal fade show d-block modal-xl" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h1 class="modal-title fs-5">Ikonválasztó</h1>
						<button type="button" class="btn-close" aria-label="Bezár" @click.prevent="overlayVisible = false"></button>
					</div>
					<div class="modal-body">
						<div class="icon-picker-overlay--search mb-3 d-flex justify-content-center">
							<input type="text" class="form-control" placeholder="Keresés" v-model="searchPhrase" style="max-width:300px;" />
						</div>
						<div class="icon-picker-overlay--results d-flex gap-2 justify-content-center flex-wrap">
							<template v-for="icon in currentIcons" :key="icon.class">
								<div :class="['icon-picker-overlay--icon', icon.class === modelValue ? 'selected' : undefined]" @click.prevent.stop="componentValue = icon.class; overlayVisible = false">
									<i :class="'far fa-' + icon.class"></i>
									<div class="icon-picker-overlay--icon-label">{{ icon.label}}</div>
								</div>
							</template>
						</div>
						<div class="mt-3 font-size-16 text-center">{{ icons.length }} db ikon</div>
						<div class="mt-3 d-flex justify-content-center">
							<button type="button" @click.prevent.stop="previous" class="btn btn-secondary font-size-16"><i class="far fa-angle-left"></i></button>
							<div class="font-size-16 p-3">{{ page }}/{{ totalPages }} oldal</div>
							<button type="button" @click.prevent.stop="next" class="btn btn-secondary font-size-16"><i class="far fa-angle-right"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</teleport>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import iconData from "../icons.json";
export default {
	components: {Field, ErrorMessage},
	props: {
		rules: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		label: String,
		name: {
			type: String,
			default: ''
		},
		modelValue: [String, Number],
		errors: {
			type: Object,
			default: function(){
				return {}
			}
		},
		info: {
			type: String,
			default: ''
		},
		id: {
			type: String,
			default: ''
		},
		fieldOnly: Boolean,
		noMargin: Boolean,
		showPlaceholder: Boolean,
		overlayVisible: Boolean,
	},
	data() {
		return {
			inputKey: 0,
			searchPhrase: '',
			page: 1,
			itemsPerPage: 50
		}

	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		isRequired: {
			get(){
				return this.rules.indexOf('required') > -1
			}
		},
		rulesValue: {
			get() {
				let tmp = this.rules.split('|')
				let rules = yup.string()
				if (tmp.indexOf('required') > -1){
					rules = rules.required()
				} else {
					rules = rules.nullable()
				}
				rules = rules.label(this.label)

				return rules
			}
		},
		currentIcons: {
			get() {
				return this.icons.slice(
					(this.page - 1) * this.itemsPerPage,
					this.page * this.itemsPerPage
				)
			}
		},
		icons: {
			get() {
				let icons = iconData
				if (this.searchPhrase) {
					icons = icons.filter(icon => {
						return this.searchPhrase
							? icon.label.toLowerCase().includes(this.searchPhrase.toLowerCase())
							: true
					});
					this.page = 1
				}
				return icons
			}
		},
		totalItems: {
			get() {
				return this.icons.length
			}
		},
		totalPages: {
			get() {
				return Math.ceil(this.icons.length / this.itemsPerPage)
			}
		},
	},
	methods: {
		onInput: function(){
			this.$emit('input')
		},
		next: function() {
			if (this.page + 1 <= this.totalPages) {
				this.page++
			}
		},
		previous:function() {
			if (this.page > 1) {
				this.page--
			}
		},
	}
}
</script>

<style scoped>
.icon-picker-overlay--icon {
	display: flex;
	flex-direction: column;
	width: 100px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding: 10px;
	border-radius: 3px;
}
.icon-picker-overlay--icon.selected {
	background: var(--bs-light);
}
.icon-picker-overlay--icon:hover {
	background: var(--bs-primary);
	color: #fff;
}
.icon-picker-overlay--icon i {
	width: 50px;
	height: 50px;
	font-size: 40px;
	text-align: center;
}
.icon-picker-overlay--icon-label {
	line-height: 18px;
	height: 36px;
	overflow: hidden;
	text-align: center;
}
</style>