<template>
	<div :class="{'data-table-compact': compact}">
		<flash-display :flash="flash" ref="notifications"></flash-display>

		<div class="data-table-filter-wrapper d-flex gap-2 flex-wrap mb-3" v-if="hasSearch">
			<div class="flex-shrink-1 flex-grow-0 col d-flex gap-2 align-items-start">
				<div class="dropdown">
					<button type="button" class="btn btn-secondary text-nowrap" data-bs-toggle="dropdown" :id="'colToggleDropdown_' + name" aria-expanded="false"><i class="far fa-eye"></i> {{ visibleColumns.length - 1 }}/{{ columnCount }} <i class="far fa-angle-down"></i></button>
					<ul class="dropdown-menu" :aria-labelledby="'colToggleDropdown_' + name">
						<template v-for="column in columns">
							<li v-if="column.value !== 'operations'">
								<a :class="'dropdown-item' + (columnVisible(column) ? '' : ' text-muted')" href="#" @click.prevent="toggleColumn(column)">
									<i :class="'far fa-eye' + (columnVisible(column) ? '' : '-slash')"></i> {{ column.text }}
								</a>
							</li>
						</template>
						<li>
							<hr class="dropdown-divider" />
						</li>
						<li>
							<a class="dropdown-item" href="#" @click.prevent="showAllColumn">
								<i class="far fa-check"></i> Mindet mutat
							</a>
						</li>
					</ul>
				</div>

				<button type="button" class="btn btn-secondary text-nowrap" @click="getData"><i class="far fa-sync"></i></button>
			</div>
			<div class="col-12 col-md-3" v-if="!disableSearch">
				<div class="input-group">
					<input
						name="name"
						class="form-control"
						v-model="componentOptions.search"
						placeholder="Keresés"
					/>
					<span class="input-group-text clear-search" v-if="componentOptions.search" @click.prevent="componentOptions.search = ''"><i class="far fa-times"></i></span>
				</div>
			</div>
			<slot name="filters" :server-options="componentOptions"></slot>
		</div>

		<Vue3EasyDataTable
			v-model:server-options="dataTableOptions"
			:server-items-length="serverItemsLength"
			buttons-pagination
			:headers="visibleColumns"
			:items="items"
			:loading="loading"
			:must-sort="true"
			class="data-table-bootstrap"
			:alternating="false"
			:buttons-pagination="true"
			empty-message="Nincs találat"
			:body-row-class-name="getRowClass"
			:fixed-index="false"
			rows-per-page-message="sor oldalanként"
			rows-of-page-separator-message=", összesen"
			theme-color="#4cc1efff"
			:hide-footer="!hasPagination"
			:header-item-class-name="getHeaderItemClass"
			:body-item-class-name="getCellClass"
		>
			<template v-for="(_, name) in $slots" #[name]="slotProps">
				<template v-if="name === 'item-operations'">
					<slot :name="name" :item="slotProps || {}" :deleteButtonPressed="deleteButtonPressed"></slot>
				</template>
				<template v-else>
					<slot :name="name" v-bind="slotProps || {}"></slot>
				</template>
			</template>
		</Vue3EasyDataTable>
	</div>
</template>

<script>
import Vue3EasyDataTable from "vue3-easy-data-table";
import FlashDisplay from "./FlashDisplay.vue";
import {formatDate} from "../functions";
export default {
	emits: ['dataLoaded', 'noResultsFound', 'resultsFound'],
	components: {Vue3EasyDataTable, FlashDisplay},
	props: {
		dataUrl: String,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
		columns: Array,
		sortBy: String,
		sortType: {
			type: String,
			default: 'asc'
		},
		name: String,
		disableSearch: Boolean,
		search: {
			type: Boolean,
			default: true
		},
		pagination: {
			type: Boolean,
			default: true
		},
		compact: {
			type: Boolean,
			default: false
		},
		rowClassCallback: {
			type: Function
		},
		defaultFilters: {
			type: Object,
			default: function(){
				return {}
			}
		}
	},
	data() {
		if (!this.sortBy){
			this.sortBy = this.columns[0].name
		}
		let dataTableOptions = {
			page: 1,
			rowsPerPage: 25,
			sortBy: this.sortBy,
			sortType: this.sortType,

		}
		let componentOptions = {
			search: '',
			visibleColumns: []
		}

		_.merge(dataTableOptions, this.defaultFilters)

		let stored = JSON.parse(localStorage.getItem(this.name + 'List')) || {}
		if (stored.dataTableOptions !== undefined){
			dataTableOptions = stored.dataTableOptions
			componentOptions = stored.componentOptions
		}

		let columnDetails = {}
		for (let i = 0; i < this.columns.length; i++){
			columnDetails[this.columns[i].value] = this.columns[i]
		}

		return {
			componentOptions: componentOptions,
			dataTableOptions: dataTableOptions,
			listDataUrl: this.dataUrl,
			flash: this.initialFlash,
			items: [],
			serverItemsLength: 0,
			loading: false,
			columnDetails: columnDetails,
			debounceTimer: null,
			cancelToken: null
		}
	},
	computed: {
		visibleColumns: {
			get() {
				let payload = []
				if (this.componentOptions.visibleColumns === undefined){
					this.componentOptions.visibleColumns = []
				}
				let showAll = this.componentOptions.visibleColumns.length < 1
				let that = this

				_.forEach(this.columns, function(column){
					if (showAll || that.componentOptions.visibleColumns.indexOf(column.value) > -1 || column.value === 'operations'){
						payload.push(column)
					}
				})

				return payload
			}
		},
		columnCount: {
			get() {
				return this.columns.length - 1
			}
		},
		hasPagination: {
			get(){
				return !this.compact && this.pagination
			}
		},
		hasSearch: {
			get(){
				return !this.compact && this.search
			}
		}
	},
	mounted() {
		this.flash = this.initialFlash

		this.$refs.notifications.update();

		this.getData();
	},
	watch: {
		dataTableOptions: {
			handler(newValue, oldValue) {
				this.dataTableOptionsWatch = _.clone(newValue)
				this.getDataDebounced();
				localStorage.setItem(this.name + 'List', JSON.stringify({
					dataTableOptions: newValue,
					componentOptions: this.componentOptions
				}));
			},
			deep: true
		},
		componentOptions: {
			handler(newValue, oldValue) {
				this.componentOptionsWatch = _.clone(newValue)
				this.getDataDebounced();
				localStorage.setItem(this.name + 'List', JSON.stringify({
					dataTableOptions: this.dataTableOptions,
					componentOptions: newValue
				}));
			},
			deep: true
		}
	},
	methods: {
		formatDate: formatDate,
		getSlotName: function(colName){
			return 'item-' + colName.value
		},
		getDataDebounced: function(){
			if (this.debounceTimer !== null){
				clearTimeout(this.debounceTimer)
				this.debounceTimer = null
			}
			this.debounceTimer = setTimeout(this.getData, 200)
		},
		getData: function() {
			this.loading = true

			if (this.cancelToken !== undefined && this.cancelToken !== null && this.cancelToken.cancel !== undefined) {
				this.cancelToken.cancel("Operation canceled due to new request.")
			}

			this.cancelToken = axios.CancelToken.source()

			let params = _.merge(_.clone(this.dataTableOptions), _.clone(this.componentOptions))
			params.pagination = this.hasPagination
			let that = this

			axios.get(this.listDataUrl, {
				params: params,
				cancelToken: this.cancelToken.token
			}).then((response)=>{
				if (response.data.meta.last_page < response.data.meta.current_page){
					this.dataTableOptions.page = response.data.meta.last_page
					this.getData()

					return
				}
				this.loading = false
				this.items = response.data.data
				this.serverItemsLength = response.data.meta.total

				this.$emit('dataLoaded', response.data)
				if (this.serverItemsLength < 1){
					this.$emit('noResultsFound')
				} else {
					this.$emit('resultsFound')
				}
			}).catch(function (error) {
				if (error.request !== undefined && error.request.status === 401){
					window.location.href = '/login?returnTo=' + window.location.href
				}
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		deleteButtonPressed: function(id, url){
			this.loading = true
			let that = this
			axios.get(url, {}).then((response)=>{
				this.loading = false
				if (response.data.flash !== undefined) {
					this.flash = response.data.flash
				}

				this.getData()
			}).catch(function (error) {
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		columnVisible: function (column){
			if (this.componentOptions.visibleColumns === undefined || this.componentOptions.visibleColumns.length < 1){
				return true
			}
			for (let i = 0; i < this.componentOptions.visibleColumns.length; i++){
				if (this.componentOptions.visibleColumns[i] === column.value){
					return true
				}
			}

			return false
		},
		toggleColumn: function (column){
			if (this.componentOptions.visibleColumns.length < 1){
				this.showAllColumn()
			}
			if (this.columnVisible(column)){
				_.pull(this.componentOptions.visibleColumns, column.value)
			} else {
				this.componentOptions.visibleColumns.push(column.value)
			}
		},
		showAllColumn: function(){
			let tmp = []
			for (let i = 0; i < this.columns.length - 1; i++){
				tmp.push(this.columns[i].value)
			}
			this.componentOptions.visibleColumns = tmp
		},
		getHeaderItemClass: function(colDetails){
			let classes = []
			if (colDetails.align !== undefined && colDetails.align === 'right'){
				classes.push('text-end')
			}
			if (colDetails.align !== undefined && colDetails.align === 'center'){
				classes.push('text-center')
			}

			return classes
		},
		getCellClass: function(colName){
			let classes = []

			if (this.columnDetails[colName] !== undefined) {
				if (this.columnDetails[colName].align !== undefined && this.columnDetails[colName].align === 'right') {
					classes.push('text-end')
				}
				if (this.columnDetails[colName].align !== undefined && this.columnDetails[colName].align === 'center') {
					classes.push('text-center')
				}
				if (this.columnDetails[colName].wrap !== undefined && !this.columnDetails[colName].wrap) {
					classes.push('text-nowrap')
				}
			}

			return classes
		},
		getRowClass: function (item, rowNumber){
			let classes = []
			if (this.rowClassCallback !== undefined){
				classes = this.rowClassCallback(item, rowNumber)
			}
			return classes
		}
	}
}
</script>

<style scoped>

</style>