<template>
	<div class="mb-4">
		<div class="inactivity-overlay-bg" v-if="isInactive">
			<div class="inactivity-overlay" v-if="isInactive">
				<p>Túl sokáig voltál inaktív, ezért újra kell töltened az oldalt!</p>
				<a href="#" class="btn btn-primary" @click.prevent="reloadPage">Oldal újratöltése</a>
			</div>
		</div>
		<Form @submit="onSubmit" v-slot="{ errors }" ref="form" @input="valueChanged" enctype="multipart/form-data">
			<div class="mb-4" v-if="showButtons">
				<form-buttons
					:editable="componentValue.editable"
					:invalid="Object.keys(errors).length > 0"
					:back-url="backUrl"
					@back-pressed="backPressed"
					@edit-pressed="editPressed"
					:buttons="buttons"
					:lockable="lockable"
					:lock-acquired="lockAcquired"
					:save-button-label="saveButtonLabel"
					:save-button-icon="saveButtonIcon"
					:grouped="groupedButtons"
					:show-back-button="showBackButton"
				>
					<slot name="buttons"></slot>
				</form-buttons>
			</div>
			<flash-display :flash="flash" ref="notifications"></flash-display>

			<slot name="top"></slot>

			<template v-if="tabs.length && displayTabs">
				<div class="card mb-3">
					<div class="card-body">
						<ul class="nav nav-pills nav-justified mb-0" role="tablist">
							<li class="nav-item" role="presentation" v-for="tab in tabs">
								<a :class="{'nav-link': 1, 'active': activeTab === tab.name}" @click.prevent="setActiveTab(tab.name)" href="#" role="tab" :aria-selected="activeTab === tab.name ? 'true' : 'false'">
									<div class="icon">
										<i :class="tab.icon"></i>
										<span class="badge bg-danger ms-1" v-if="tabBadges[tab.name] !== undefined">{{ tabBadges[tab.name] }}</span>
									</div>
									<div class="label">{{ tab.label }}</div>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</template>

			<slot :active-tab="activeTab" :errors="errors" :editable="componentValue.editable && (!lockable || (lockable && lockAcquired))"></slot>

			<div class="mt-4" v-if="showButtons">
				<form-buttons
					:editable="componentValue.editable"
					:invalid="Object.keys(errors).length > 0"
					:back-url="backUrl"
					@back-pressed="backPressed"
					@edit-pressed="editPressed"
					:buttons="buttons"
					:lockable="lockable"
					:lock-acquired="lockAcquired"
					:save-button-label="saveButtonLabel"
					:save-button-icon="saveButtonIcon"
					:grouped="groupedButtons"
					:show-back-button="showBackButton"
				>
					<slot name="buttons"></slot>
				</form-buttons>
			</div>
		</Form>
	</div>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import yupLocaleHun from '../validationMessages'
import FormButtons from "./FormButtons.vue";
import FlashDisplay from "./FlashDisplay.vue";

yup.setLocale(yupLocaleHun);

export default {
	name: "FormWrapper",
	components: {Form, Field, ErrorMessage, FormButtons, FlashDisplay},
	props: {
		storeUrl: String,
		backUrl: String,
		lockUrl: {
			type: String,
			default: ''
		},
		updateLockUrl: {
			type: String,
			default: ''
		},
		initialFlash: Array,
		modelValue: Object,
		disableRedirect: {
			type: Boolean,
			default: false
		},
		buttons: {
			type: Array,
			default: function () {
				return []
			}
		},
		hiddenAttributes: {
			type: Array,
			default: function () {
				return []
			}
		},
		showButtons: {
			type: Boolean,
			default: true
		},
		submitFunction: {
			type: Function,
			default: null
		},
		tabs: {
			type: Array,
			default: function(){
				return []
			}
		},
		tabBadges: {
			type: Object,
			default: function(){
				return {}
			}
		},
		showBackButton: {
			type: Boolean,
			default: true
		},
		displayTabs: Boolean,
		editButton: Boolean,
		saveInPlace: Boolean,
		saveButtonLabel: {
			type: String,
			default: ''
		},
		saveButtonIcon: {
			type: String,
			default: ''
		},
		groupedButtons: Boolean
	},
	created: function () {
		setInterval(this.updateLock, 30000);

		window.onmousemove = this.resetTimer;
		window.onmousedown = this.resetTimer;
		window.ontouchstart = this.resetTimer;
		window.ontouchmove = this.resetTimer;
		window.onclick = this.resetTimer;
		window.onkeydown = this.resetTimer;
		window.addEventListener('scroll', this.resetTimer, true);


		window.addEventListener("hashchange", this.onHashChange, false)

		this.onHashChange()
	},
	emits: ['update:modelValue', 'beforeSubmit', 'afterSubmit', 'backPressed', 'editPressed', 'attributesUpdated'],
	data(){
		let activeTab = ''
		if (this.tabs.length){
			activeTab = this.tabs[0].name
		}
		return {
			loading: false,
			flash: [],
			activeTab: activeTab,
			activityTimer: null,
			isInactive: false,
			unsavedChanged: false,
			lockAcquired: false
		}
	},
	mounted() {
		this.flash = this.initialFlash

		this.$refs.notifications.update()
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		lockable: {
			get(){
				return this.lockUrl !== '' && this.updateLockUrl !== '' && this.modelValue.id !== undefined && !!this.modelValue.id
			}
		}
	},
	methods: {
		onHashChange: function(){
			if (location.hash && location.hash.length > 1){
				let tmp = location.hash.substring(1).split('-')
				if (tmp[0] !== undefined) {
					this.setActiveTab(tmp[0], tmp[1] || '')
				}
			}
		},
		setActiveTab: function(tab, params){
			params = params || ''
			this.activeTab = tab
			location.hash = tab + (params ? '-' + params : '')
		},
		resetTimer: function(){
			clearTimeout(this.activityTimer);
			let that = this

			this.activityTimer = setTimeout(function(){
				if (that.editable) {
					that.isInactive = true
				}
			}, 3600000);
		},
		updateLock: function(){
			if (this.componentValue.id !== undefined
				&& this.componentValue.id
				&& this.lockable !== ''
				&& this.componentValue.editable
				&& this.lockAcquired
				&& !this.isInactive) {
				let that = this
				axios.post(this.updateLockUrl).then((response) => {
				}).catch(function (error) {
					that.flash = [{level: 'danger', message: error.response.data.message}]
				})
			}
		},
		showLoading: function(){
			document.getElementsByTagName('html')[0].classList.add('loading')
		},
		hideLoading: function(){
			document.getElementsByTagName('html')[0].classList.remove('loading')
		},
		onSubmit: function(){
			if (this.submitFunction !== null){
				this.submitFunction()
			} else {
				this.showLoading()

				this.$emit('beforeSubmit')

				let attributes = this.componentValue

				if (this.hiddenAttributes.length) {
					attributes = _.omit(attributes, this.hiddenAttributes)
				}

				axios.post(this.storeUrl, attributes)
					.then((response) => {
						this.handleResponse(response)
					})
					.catch((error) => {
						this.flash = [{
							level: 'danger',
							message: error
						}]

						this.hideLoading()
					})
			}
		},
		handleResponse: function(response){
			this.flash = response.data.flash || []

			if (response.data.success) {
				this.formClean()
				if (response.data.redirect !== undefined){
					location.href = response.data.redirect
				} else if (this.disableRedirect === undefined || !this.disableRedirect) {
					location.href = this.backUrl
				} else {
					this.hideLoading()
					this.$emit('afterSubmit', response.data.model)
				}
				this.lockAcquired = false
			} else {
				this.hideLoading()

				if (response.data.errors !== undefined){
					let errors = []
					_.forEach(response.data.errors, function(fieldErrors){
						errors.push(fieldErrors[0])
					})
					this.flash = [{
						level: 'danger',
						message: errors.join(', ')
					}]
				}

				if (response.data.attributes !== undefined){
					this.componentValue = response.data.attributes
					this.$emit('attributesUpdated')
				}
				if (response.data.lockAcquired !== undefined && response.data.lockAcquired){
					this.lockAcquired = true
				}
			}
		},
		backPressed: function(){
			this.$emit('backPressed')
		},
		editPressed: function(){
			this.showLoading()

			let that = this
			axios.post(this.lockUrl).then((response)=>{
				this.handleResponse(response)
			}).catch(function (error) {
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		formClean: function(){
			window.onbeforeunload = null
			this.unsavedChanged = false
		},
		reloadPage: function (){
			this.formClean()
			location.reload()
		},
		valueChanged: function(e){
			window.onbeforeunload = function() {
				return 'Az oldalon el nem mentett változások vannak. Biztos, hogy el akarod hagyni az oldalt?';
			}

			this.unsavedChanged = true
		},
		validate: function(){
			let valid = this.$refs.form.validate()
			this.valueChanged()

			return valid
		},
		submit: function(){
			this.$refs.form.requestSubmit()
		},
		addFlash: function(flash){
			this.flash = flash
		}
	}
}
</script>

<style scoped>

</style>