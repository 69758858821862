<template>
	<div class="project-form">
		<div class="inactivity-overlay-bg" v-if="isInactive">
			<div class="inactivity-overlay" v-if="isInactive">
				<p>Túl sokáig voltál inaktív, ezért a projekt csak újratöltés után lesz szerkeszthető!</p>
				<a href="#" class="btn btn-primary" @click.prevent="reloadPage">Oldal újratöltése</a>
			</div>
		</div>
		<flash-display :flash="flash" ref="notifications"></flash-display>

		<div v-if="project.id === undefined">
			<div class="card mb-3">
				<div class="card-body">
					<div class="wizard clearfix">
						<div class="steps clearfix">
							<ul role="tablist">
								<li role="tab" :class="{first: 1, current: !client, done: client}">
									<a href="#">
										<span class="number">1.</span> Ügyfél kiválasztása
									</a>
								</li>
								<li role="tab" :class="{current: client && !projectType, done: client && projectType}">
									<a href="#">
										<span class="number">2.</span> Projekttípus kiválasztása
									</a>
								</li>
								<li role="tab" :class="{current: client && projectType}">
									<a href="#">
										<span class="number">3.</span> Projekt részletek megadása
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!client">
			<div class="card mb-3">
				<div class="card-body">
					<div class="mb-3 position-relative">
						<label>Válassz ügyfelet</label>
						<v-select
							:options="clientOptions"
							v-model="client"
							label="name"
							placeholder="Kezd el gépelni a keresést"
							@search="onSearch"
							:filterable="false"
						>
							<template slot="no-options">
								Nincs találat
							</template>
							<template slot="option" slot-scope="option">
								{{ option.public_id}} - {{ option.name }} ({{option.address_text}})
							</template>
							<template slot="selected-option" slot-scope="option">
								{{ option.public_id}} - {{ option.name }} ({{option.address_text}})
							</template>
						</v-select>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div v-if="!projectType">
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="mb-0">{{ client.name }}</h4>
					</div>
				</div>
				<div class="card mb-3">
					<div class="card-body">
						<label>Válassz projekttípust</label>
						<div class="mt-2 d-flex flex-wrap gap-2">
							<template v-for="type in projectTypes">
								<button v-if="type.active" :class="'btn btn-secondary btn-label waves-effect border-0 btn-bg-' + type.luminosity" :style="'background-color:' + type.color + ';'" @click.prevent="selectProjectType(type)">
									<i :class="'far fa-' + type.icon + ' label-icon'"></i>
									{{ type.name }}
								</button>
							</template>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<form @submit.prevent="saveForm">
					<div class="mb-3">
						<div class="d-flex flex-wrap gap-2">
							<button type="submit" class="btn btn-primary btn-label" v-if="editable">
								<i class="fas fa-save label-icon"></i> Mentés
							</button>
							<button type="submit" class="btn btn-warning btn-label" v-if="canStartEditing" @click.prevent="startEditing">
								<i class="fas fa-pencil label-icon"></i> Szerkesztés
							</button>
							<a :href="backUrl" v-if="backUrl" class="btn btn-secondary btn-label"><i class="fas fa-angle-left label-icon"></i> Vissza</a>
						</div>
					</div>
					<div class="card mb-3">
						<div class="card-body">
							<h4>
								<strong v-if="project.public_id" class="me-1">{{ project.public_id }}</strong>
								{{ client.name }}
								<small v-if="clientAddress" class="ms-1">{{ clientAddress }}</small>
							</h4>
							<div class="d-flex gap-2">
								<span :class="'project-type-badge project-type-badge-' + projectType.luminosity" :style="'background:' + projectType.color + ';'"><i :class="'far fa-' + projectType.icon"></i><span>{{ projectType.name }}</span></span>
								<button type="button" class="btn" @click.prevent="detailsVisible = !detailsVisible">Részletek <i :class="'far fa-' + (detailsVisible ? 'chevron-up' : 'chevron-down')"></i></button>
							</div>
							<LockMessage :lock="lock" :margin-top="true" :margin-bottom="false"></LockMessage>
							<div class="mt-3 details-box" v-if="project.id !== undefined && detailsVisible">
								<div v-if="project.created_by_user_name">
									<i class="far fa-user"></i> Létrehozó: <strong>{{ project.created_by_user_name }}</strong>
								</div>
								<div v-if="project.created_at">
									<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(project.created_at) }}</strong>
								</div>
								<div v-if="project.updated_by_user_name">
									<i class="far fa-user"></i> Módosító: <strong>{{ project.updated_by_user_name }}</strong>
								</div>
								<div v-if="project.updated_at">
									<i class="far fa-calendar-alt"></i> Módosítva: <strong>{{ formatDate(project.updated_at) }}</strong>
								</div>
								<div v-if="project.handler_user_name">
									<i class="far fa-user-tie"></i> Tanácsadó: <strong>{{ project.handler_user_name }}</strong>
								</div>
								<div v-if="project.client_input_required || project.client_input_at">
									<i class="far fa-clipboard-check"></i> Ügyfél adatkitöltés: <strong>{{ project.client_input_at ? formatDate(project.client_input_at) : '-' }}</strong>
								</div>
							</div>
							<div class="mt-3 details-box" v-if="detailsVisible">
								<h4>Ügyfél</h4>
								<div>
									<i class="far fa-hashtag"></i> Azonosító:
									<a :href="clientUrl" v-if="clientUrl">{{ client.public_id }}</a>
									<strong v-else>{{ client.public_id }}</strong>
								</div>
								<div>
									<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(client.created_at) }}</strong>
								</div>
								<div v-if="client.created_by_user_name">
									<i class="far fa-user"></i> Létrehozó: <strong>{{ client.created_by_user_name }}</strong>
								</div>
								<div><i class="far fa-envelope"></i> E-mail cím: <EmailAddress :value="client.email"></EmailAddress></div>
								<div><i class="far fa-phone"></i> Telefonszám: <PhoneNumber :value="client.phone"></PhoneNumber></div>
							</div>
							<div class="mt-3 details-box" v-if="project.inquiry && detailsVisible">
								<h4>Érdeklődés</h4>
								<div v-if="project.inquiry">
									<i class="far fa-hashtag"></i> Azonosító:
									<a :href="inquiryUrl" v-if="inquiryUrl">{{ project.inquiry.public_id }}</a>
									<strong v-else>{{ project.inquiry.public_id }}</strong>
								</div>
								<div v-if="project.inquiry">
									<i class="far fa-calendar-alt"></i> Időpont: <strong>{{ formatDate(project.inquiry.created_at) }}</strong>
								</div>
								<div v-if="project.inquiry && project.inquiry.handler">
									<i class="far fa-user-friends"></i> Tanácsadó: <strong>{{ project.inquiry.handler.name }}</strong>
								</div>
							</div>
						</div>
					</div>

					<div>
						<div class="card" v-if="project.id">
							<div class="card-body">
								<ul class="nav nav-pills nav-justified mb-0" role="tablist">
									<template v-for="(tab, tabKey) in availableTabs">
										<li class="nav-item" role="presentation">
											<a :class="{'nav-link': 1, 'active': activeTab === tabKey}" @click.prevent="setActiveTab(tabKey)" href="#" role="tab" :aria-selected="activeTab === tabKey ? 'true' : 'false'">
												<div class="icon">
													<i :class="'far fa-' + tab.icon"></i>
													<span class="badge rounded-pill bg-danger ms-1" v-if="tabBadges[tabKey] !== undefined">{{ tabBadges[tabKey] }}</span>
												</div>
												<div class="label">
													{{ tab.name }}
												</div>
											</a>
										</li>
									</template>
								</ul>
							</div>
						</div>
						<div :class="{'d-none': activeTab !== 'attributes'}">
							<div class="card card-body" v-if="editable && hasRight('attributes', 'update')">
								<div class="row">
									<div class="col-md-6">
										<label>Kitöltöm az ügyfél helyett a számlázáshoz és a külsős ügyvitelhez szükséges adatokat</label>

										<SwitchField
											v-model="project.client_input_required"
											:field-only="true"
											:invert="true"
										></SwitchField>
									</div>
									<div class="col-md-6" v-if="admin">
										<user-select-field
											name="handlerUser"
											v-model="handlerUser"
											label="Tanácsadó"
											:errors="{}"
											:user-levels="userLevels"
											/>
									</div>
								</div>
							</div>

							<ProjectFormAttributes
								v-model="project"
								:client="client"
								:project-type="projectType"
								:cities="cities"
								:editable="editable && hasRight('attributes', 'update')"
								user-type="user"
								ref="projectAttributes"
								@valueChanged="valueChanged"
								></ProjectFormAttributes>
						</div>
						<div v-if="activeTab === 'documents'">
							<div class="card">
								<div class="card-body">
									<div class="row mb-3">
										<div class="col-md-3">
											<ul class="list-group document-types-list-group mb-3">
												<a href="#"
												   :class="{
													'list-group-item': 1,
													'list-group-item-action': 1,
													'list-group-item-action-active': typeof activeDocumentType === 'object' && activeDocumentType.id === documentType.id,
													'd-flex': 1,
													'justify-content-between': 1,
													'align-items-start': 1}"
												   v-for="documentType in project.project_type.document_types"
												   @click.prevent="activeDocumentType = documentType">
													<div class="ms-2 me-auto">
														<span class="list-group-item-border" :style="'background:' + (documentType.color || '#eff2f7') + ';'"></span>
														<i :class="'me-2 far fa-' + documentType.icon" v-if="documentType.icon"></i>{{ documentType.name }}
													</div>
													<span class="badge bg-danger rounded-pill" v-if="getDocumentTypeCount(documentType)">{{ getDocumentTypeCount(documentType) }}</span>
												</a>
												<a href="#"
												   :class="{
													'list-group-item': 1,
													'list-group-item-action': 1,
													'list-group-item-action-active': typeof activeDocumentType !== 'object' && activeDocumentType === 'quotes',
													'd-flex': 1,
													'justify-content-between': 1,
													'align-items-start': 1}"
												   v-if="hasAccess('quotes')"
												   @click.prevent="activeDocumentType = 'quotes'">
													<div class="ms-2 me-auto">
														<span class="list-group-item-border" style="background:#f1b44c"></span>
														<i class="me-2 far fa-file-invoice-dollar"></i>Árajánlatok
													</div>
													<span class="badge bg-danger rounded-pill" v-if="getDocumentTypeCount('quotes')">{{ getDocumentTypeCount('quotes') }}</span>
												</a>
												<a href="#"
												   :class="{
													'list-group-item': 1,
													'list-group-item-action': 1,
													'list-group-item-action-active': typeof activeDocumentType !== 'object' && activeDocumentType === 'contracts',
													'd-flex': 1,
													'justify-content-between': 1,
													'align-items-start': 1}"
												   v-if="hasAccess('contracts')"
												   @click.prevent="activeDocumentType = 'contracts'">
													<div class="ms-2 me-auto">
														<span class="list-group-item-border" style="background:#f1b44c"></span>
														<i class="me-2 far fa-file-contract"></i>Szerződések
													</div>
													<span class="badge bg-danger rounded-pill" v-if="getDocumentTypeCount('contracts')">{{ getDocumentTypeCount('contracts') }}</span>
												</a>
												<a href="#"
												   :class="{
													'list-group-item': 1,
													'list-group-item-action': 1,
													'list-group-item-action-active': typeof activeDocumentType !== 'object' && activeDocumentType === 'invoices',
													'd-flex': 1,
													'justify-content-between': 1,
													'align-items-start': 1}"
												   v-if="hasAccess('invoices')"
												   @click.prevent="activeDocumentType = 'invoices'">
													<div class="ms-2 me-auto">
														<span class="list-group-item-border" style="background:#f1b44c"></span>
														<i class="me-2 far fa-file-invoice"></i>Számlák
													</div>
													<span class="badge bg-danger rounded-pill" v-if="getDocumentTypeCount('invoices')">{{ getDocumentTypeCount('invoices') }}</span>
												</a>
												<a href="#"
												   :class="{
													'list-group-item': 1,
													'list-group-item-action': 1,
													'list-group-item-action-active': typeof activeDocumentType !== 'object' && activeDocumentType === 'shipments',
													'd-flex': 1,
													'justify-content-between': 1,
													'align-items-start': 1}"
												   v-if="hasAccess('shipments')"
												   @click.prevent="activeDocumentType = 'shipments'">
													<div class="ms-2 me-auto">
														<span class="list-group-item-border" style="background:#f1b44c"></span>
														<i class="me-2 far fa-truck"></i>Szállítmányok
													</div>
													<span class="badge bg-danger rounded-pill" v-if="getDocumentTypeCount('shipments')">{{ getDocumentTypeCount('shipments') }}</span>
												</a>
											</ul>
										</div>
										<div class="col-md-9">
											<ProjectFormDocumentCategory
												:can-upload="editable && hasRight('documents', 'create') && typeof activeDocumentType === 'object'"
												:can-delete="editable && hasRight('documents', 'delete') && typeof activeDocumentType === 'object'"
												:can-update="editable && hasRight('documents', 'update') && typeof activeDocumentType === 'object'"
												:system-documents="project.system_documents"
												:file-upload-url="fileUploadUrl"
												:document-type="activeDocumentType"
												v-bind:existing-files="project.documents[activeDocumentType.id]"
												v-bind:uploaded-files="uploadedFiles[activeDocumentType.id]"
												@valueChanged="valueChanged"
												></ProjectFormDocumentCategory>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-if="activeTab === 'progress'">
							<div class="card">
								<div class="card-body">
									<table class="table mb-0 table-collapse-mobile">
										<thead class="table-light">
										<tr>
											<th>Mérföldkő</th>
											<th>Időpont</th>
											<th>Felhasználó</th>
										</tr>
										</thead>
										<tbody>
										<tr v-for="milestone in projectType.milestones">
											<td>
												<div :class="'milestone-badge milestone-badge-' + milestone.luminosity + (project.milestone_log[milestone.id] === undefined ? ' milestone-badge-inactive' : '')" :style="'background:' + milestone.color + ';'">
													<i :class="'far fa-' + milestone.icon"></i> {{ milestone.name }}
												</div>
											</td>
											<td>
												<div v-if="project.milestone_log[milestone.id] !== undefined">{{ formatDate(project.milestone_log[milestone.id].created_at) }}</div>
											</td>
											<td>
												<div v-if="project.milestone_log[milestone.id] !== undefined">{{ project.milestone_log[milestone.id].created_by }}</div>
											</td>
										</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div v-if="activeTab === 'comments'">
							<Comments
								:data-url="commentsDataUrl"
								:can-create="hasRight('comments', 'create')"
								@comment-count="commentCountUpdated"
								:active-tab-command="activeTabCommand"
								:active-tab-parameter="activeTabParameter"
							></Comments>
						</div>
						<div v-if="activeTab === 'quotes'">
							<div class="card">
								<div class="card-body">
									<QuoteList
										:data-url="quotesUrl"
										:currencies="currencies"
										:quote-status-labels="quoteStatusLabels"
										:editable="editable"
										ref="quoteList"
										:project-attributes="project.attribute_values"
										:project-type="projectType"
										:client="client"
										:products="products"
										:file-upload-url="fileUploadUrl"
										:taxes="taxes"
										:form-data-url="quoteDataUrl"
										:product-types="productTypes"
										:active-tab-command="activeTabCommand"
										:active-tab-parameter="activeTabParameter"
										:can-approve-quote="canApproveQuote"
										:can-delete-quote="canDeleteQuote"
										:can-reopen-quote="canReopenQuote"
										:can-add-quote-discount-material="canAddQuoteDiscountMaterial"
										:can-add-quote-discount-work="canAddQuoteDiscountWork"
										:can-create-contract="hasRight('contracts', 'read') && hasRight('contracts', 'create')"
										:can-create="hasRight('quotes', 'create')"
										:can-update="hasRight('quotes', 'update')"
										:can-delete="hasRight('quotes', 'delete')"
										:can-answer="hasRight('quotes', 'answer')"
										:can-start-editing="canStartEditing"
										:lock="lock"
										@start-editing="startEditing"
										@form-flash="formFlash"
										@update-stats="updateStats"
										@open-contract="openContract"
										/>
								</div>
							</div>
						</div>
						<div v-if="activeTab === 'contracts'">
							<div class="card">
								<div class="card-body">
									<ContractList
										:data-url="contractsUrl"
										:currencies="currencies"
										:editable="editable"
										:project-attributes="project.attribute_values"
										:product-categories="productCategories"
										ref="contractList"
										:project-type="projectType"
										:client="client"
										:products="products"
										:file-upload-url="fileUploadUrl"
										:taxes="taxes"
										:form-data-url="contractDataUrl"
										:delivery-type-labels="deliveryTypeLabels"
										:contract-status-labels="contractStatusLabels"
										:amendments-list-data-url="amendmentsListDataUrl"
										:can-create-foreign-contract="canCreateForeignContract"
										:can-set-delivery-for-contract="canSetDeliveryForContract"
										:can-create-contract-amendments="canCreateContractAmendments"
										:can-enable-custom-billing="canEnableCustomBilling"
										:can-create="hasRight('contracts', 'create')"
										:can-update="hasRight('contracts', 'update')"
										:can-delete="hasRight('contracts', 'delete')"
										:can-answer="hasRight('contracts', 'answer')"
										:can-create-invoice="hasRight('contracts', 'invoice')"
										:product-types="productTypes"
										:can-start-installation-for-contract="canStartInstallationForContract"
										:installation-data-url="installationDataUrl"
										:user-levels="userLevels"
										:active-tab-command="activeTabCommand"
										:active-tab-parameter="activeTabParameter"
										:installation-document-types="installationDocumentTypes"
										:can-start-editing="canStartEditing"
										:lock="lock"
										@start-editing="startEditing"
										@form-flash="formFlash"
										@update-stats="updateStats"
										@open-quote="openQuote"
										:installation-list-url="installationListUrl"
										/>
								</div>
							</div>
						</div>
						<div v-if="activeTab === 'invoices'">
							<div class="card">
								<div class="card-body">
									<InvoiceList
										:list-url="invoicesUrl"
										:data-url="invoiceDataUrl"
										:currencies="currencies"
										:editable="editable"
										:project-attributes="project.attribute_values"
										ref="invoiceList"
										:project-type="projectType"
										:invoice-type-labels="invoiceTypeLabels"
										:taxes="taxes"
										:can-regenerate-proforma-invoice="canRegenerateProformaInvoice"
										@form-flash="formFlash"
										@open-contract="openContract"
										:active-tab-command="activeTabCommand"
										:active-tab-parameter="activeTabParameter"
										/>
								</div>
							</div>
						</div>
						<div v-if="activeTab === 'roles'">
							<div class="card">
								<div class="card-body">
									<ProjectRoles
										v-model="project.roles"
										:project-type-roles="projectTypeRoles"
										:roles="roles"
										:users="users"
										:editable="editable"
										:can-update="hasRight('roles', 'update')"
										:can-delete="hasRight('roles', 'delete')"
										/>
								</div>
							</div>
						</div>
						<div class="mt-3 mb-4">
							<div class="d-flex flex-wrap gap-2">
								<button type="submit" class="btn btn-primary btn-label" v-if="editable">
									<i class="fas fa-save label-icon"></i> Mentés
								</button>
								<button type="submit" class="btn btn-warning btn-label" v-if="canStartEditing" @click.prevent="startEditing">
									<i class="fas fa-pencil label-icon"></i> Szerkesztés
								</button>
								<a :href="backUrl" v-if="backUrl" class="btn btn-secondary btn-label"><i class="fas fa-angle-left label-icon"></i> Vissza</a>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import ClientForm from '../clients/ClientForm.vue'
import {formatDate, formatFileSize} from "../../functions";
import VueUploadComponent from 'vue-upload-component'
import QuoteList from './QuoteList.vue'
import PhoneNumber from '../../components/PhoneNumber.vue';
import EmailAddress from '../../components/EmailAddress.vue';
import FileIcon from "../../components/FileIcon.vue";
import Comments from "../../components/Comments.vue";
import ConfirmButton from "../../components/ConfirmButton.vue";
import ConfirmModal from "../../components/ConfirmModal.vue";
import FlashDisplay from "../../components/FlashDisplay.vue";
import vSelect from 'vue-select'
import AddressInput from "../../components/AddressInput.vue";
import TextareaAutosize from "../../components/TextareaAutosize.vue";
import SwitchField from "../../components/form/SwitchField.vue";
import ProjectFormAttributes from "./ProjectFormAttributes.vue";
import ContractList from "./ContractList.vue";
import InvoiceList from "./InvoiceList.vue";
import ProjectRoles from "./ProjectRoles.vue";
import UserSelectField from "../../components/form/UserSelectField.vue";
import ProjectFormDocumentCategory from "./ProjectFormDocumentCategory.vue";
import LockMessage from "../../components/LockMessage.vue";

export default {
	components: {
		LockMessage,
		ProjectFormDocumentCategory,
		UserSelectField,
		ProjectRoles,
		ProjectFormAttributes,
		SwitchField,
		ClientForm,
		VueUploadComponent,
		QuoteList,
		PhoneNumber,
		EmailAddress,
		FileIcon,
		Comments,
		ConfirmButton,
		ConfirmModal,
		FlashDisplay,
		vSelect,
		AddressInput,
		TextareaAutosize,
		ContractList,
		InvoiceList,
	},
	props: {
		initialProject: Object,
		dataUrl: String,
		backUrl: String,
		initialFlash: Array,
		cities: Array,
		clientStoreUrl: String,
		projectTypes: Array,
		productCategories: Array,
		userLevels: Array,
		admin: Boolean,
		users: Array,
		fileUploadUrl: String,
		clientUrl: String,
		inquiryUrl: String,
		products: Array,
		quotesUrl: String,
		quoteDataUrl: String,
		contractsUrl: String,
		contractDataUrl: String,
		invoicesUrl: String,
		invoiceDataUrl: String,
		amendmentsListDataUrl: String,
		installationDataUrl: String,
		installationListUrl: String,
		currencies: Object,
		taxes: Object,
		quoteStatusLabels: Object,
		productTypes: Object,
		canApproveQuote: Boolean,
		canDeleteQuote: Boolean,
		canReopenQuote: Boolean,
		commentsDataUrl: String,
		initialStats: Object,
		contractStatusLabels: Object,
		invoiceTypeLabels: Object,
		canAddQuoteDiscountWork: Boolean,
		canAddQuoteDiscountMaterial: Boolean,
		projectTypeRoles: Array,
		installationDocumentTypes: Array,
		roles: Array,
		canCreateForeignContract: Boolean,
		canSetDeliveryForContract: Boolean,
		canRegenerateProformaInvoice: Boolean,
		canCreateContractAmendments: Boolean,
		canEnableCustomBilling: Boolean,
		canStartInstallationForContract: Boolean,
		deliveryTypeLabels: Object,
	},
	data(){
		return {
			project: this.initialProject,
			loading: false,
			flash: [],
			clientOptions: [],
			client: null,
			projectType: null,
			visibleFields: [],
			uploadedFiles: {},
			fields: [],
			alertTimeout: null,
			unsavedChanged: false,
			activityTimer: null,
			isInactive: false,
			activeTab: 'attributes',
			userSelectOptions: this.users,
			handlerUser: false,
			activeDocumentType: null,
			activeTabCommand: null,
			activeTabParameter: null,
			lock: {
				updated: false,
				locked: false,
				lockedByCurrentUser: false,
				lockedByUserName: '',
				lockedByClientName: '',
				lockedAt: false,
			},
			lightboxImage: '',
			unloadHandler: null,
			quoteFormOpen: false,
			detailsVisible: false,
			tabBadges: this.initialStats,
			highlightComment: 0,
			tabs: {
				attributes: {
					name: 'Adatlap',
					icon: 'file-spreadsheet'
				},
				progress: {
					name: 'Haladás',
					icon: 'tasks'
				},
				comments: {
					name: 'Üzenőfal',
					icon: 'comments'
				},
				documents: {
					name: 'Dokumentumok',
					icon: 'folder-open'
				},
				quotes: {
					name: 'Árajánlatok',
					icon: 'file-invoice-dollar'
				},
				contracts: {
					name: 'Szerződések',
					icon: 'file-contract'
				},
				invoices: {
					name: 'Számlák',
					icon: 'file-invoice'
				},
				roles: {
					name: 'Jogosultságok',
					icon: 'users'
				},
			}
		}
	},
	created: function () {
		this.moment = moment

		setInterval(this.updateLock, 10000);

		window.onmousemove = this.resetTimer;
		window.onmousedown = this.resetTimer;
		window.ontouchstart = this.resetTimer;
		window.ontouchmove = this.resetTimer;
		window.onclick = this.resetTimer;
		window.onkeydown = this.resetTimer;
		window.addEventListener('scroll', this.resetTimer, true);

		this.fillAttributesFromProject()

		window.addEventListener("hashchange", this.onHashChange, false)

		this.onHashChange()

		this.checkActiveTab()

		this.updateLock()
	},
	updated: function (){
	},
	mounted() {
		this.fillAttributesFromProject()

		this.flash = this.initialFlash

		this.$refs.notifications.update()
	},
	computed: {
		clientAddress: {
			get() {
				for (let i = 0; i < this.projectType.fields_data.length; i++){
					if (this.projectType.fields_data[i].type === 'address'){
						if (this.project.attribute_values[this.projectType.fields_data[i].id] !== undefined){
							return this.project.attribute_values[this.projectType.fields_data[i].id].formatted_value
						}
					}
				}
				return ''
			}
		},
		availableTabs: {
			get(){
				let payload = {}
				let that = this
				_.each(this.project.rights, function(rights, category){
					if (that.hasAccess(category) && that.tabs[category] !== undefined){
						payload[category] = that.tabs[category]
					}
				})
				return payload
			}
		},
		editable: {
			get() {
				if (this.project.id === undefined){
					return true
				}
				return this.lock.locked && this.lock.lockedByCurrentUser
			}
		},
		canStartEditing: {
			get() {
				return this.lock.updated && !this.lock.locked && this.project.editable
			}
		}
	},
	methods: {
		formatDate: formatDate,
		formatFileSize: formatFileSize,
		hasAccess: function(category){
			return (this.project.rights[category] !== undefined && this.project.rights[category].indexOf('read') > -1)
		},
		showLoading: function(){
			document.getElementsByTagName('html')[0].classList.add('loading')
		},
		hideLoading: function(){
			document.getElementsByTagName('html')[0].classList.remove('loading')
		},
		checkActiveTab: function(){
			if (this.project.id !== undefined && (this.project.rights[this.activeTab] === undefined || this.project.rights[this.activeTab].indexOf('read') < 0)){
				let activeTab = ''
				_.each(this.project.rights, function(rights, category){
					if (rights.indexOf('read') > -1 && activeTab === ''){
						activeTab = category
					}
				})

				this.setActiveTab(activeTab)
			}
		},
		hasRight: function(category, right){
			if (this.project.rights[category] !== undefined && this.project.rights[category].indexOf(right) > -1){
				return true
			}

			return false
		},
		onHashChange: function(){
			if (location.hash && location.hash.length > 1){
				let tmp = location.hash.substring(1).split('-')
				if (tmp[0] !== undefined) {
					this.activeTabCommand = null
					this.activeTabParameter = null
					if (tmp[1] !== undefined){
						let t = tmp[1].split(':')
						this.activeTabCommand = t[0]
						this.activeTabParameter = t[1] !== undefined ? t[1] : null
					}
					this.setActiveTab(tmp[0], tmp[1] || '')

					if (this.activeTab === 'comments'){
						this.highlightComment = 0
						if (tmp[1] !== undefined) {
							this.highlightComment = parseInt(tmp[1].replace('highlight:', ''))
						}
					}
				}
			}
		},
		resetTimer: function(){
			clearTimeout(this.activityTimer);
			let that = this

			this.activityTimer = setTimeout(function(){
				if (that.editable) {
					that.isInactive = true
				}
			}, 3600000);
		},
		onSearch(search, loading) {
			if(search.length) {
				loading(true);
				this.search(loading, search, this);
			}
		},
		search: function(loading, search, vm) {
			fetch(
				this.dataUrl + '?action=clientSearch&q=' + search
			).then(res => {
				res.json().then(json => (vm.clientOptions = json.results));
				loading(false);
			});
		},
		saveForm: function(e){
			if (this.$refs.projectAttributes.validateForm()){
				this.showLoading()

				let payload = {
					attributes: JSON.parse(JSON.stringify(this.project['attribute_values'])),
					clientId: this.client.id,
					typeId: this.projectType.id,
					uploadedFiles: this.uploadedFiles,
					documents: this.project.documents,
					roles: this.project.roles,
					client_input_required: this.project.client_input_required,
				}
				if (this.admin){
					payload.handlerUserId = this.handlerUser
				}

				let url = this.dataUrl
				if (this.project.id){
					url += '/' + this.project.id
				} else {
					if (this.project.inquiry_id){
						payload.inquiryId = this.project.inquiry_id
					}
				}
				let that = this
				axios.post(url + '?action=saveProject', payload).then((response)=>{
					this.handleResponse(response)
					this.lockResponse(response)
				}).catch(function (error) {
					that.flash = [{level: 'danger', message: error.response.data.message}]
				})
			} else {
				this.flash = [{level: 'danger', message: 'Kérlek töltsd ki a pirossal jelölt mezőket!'}]
			}
		},
		fillAttributesFromProject: function(){
			this.projectType = this.project.project_type !== undefined ? this.project.project_type : null
			this.client = this.project.client !== undefined ? this.project.client : null
			this.handlerUser = this.project.handler_user_id

			if (this.projectType) {
				for (let i = 0; i < this.projectType.document_types.length; i++) {
					if (this.activeDocumentType === null) {
						this.activeDocumentType = this.projectType.document_types[i]
					}
					this.uploadedFiles[this.projectType.document_types[i].id] = []
				}
				if (this.activeDocumentType === null && this.hasAccess('quotes')){
					this.activeDocumentType = 'quotes'
				}
				if (this.activeDocumentType === null && this.hasAccess('contracts')){
					this.activeDocumentType = 'contracts'
				}
				if (this.activeDocumentType === null && this.hasAccess('invoices')){
					this.activeDocumentType = 'invoices'
				}
				if (this.activeDocumentType === null && this.hasAccess('shipments')){
					this.activeDocumentType = 'shipments'
				}
			}
		},
		handleResponse: function (response){
			if (response.data.redirect !== undefined){
				this.formClean()
				location.href = response.data.redirect
			} else {
				this.hideLoading()
				if (response.data.success !== undefined) {
					this.editable = false
				}
			}

			if (response.data.project !== undefined) {
				this.project = response.data.project
				this.fillAttributesFromProject()
			}
			if (response.data.stats !== undefined) {
				this.tabBadges = response.data.stats
			}

			if (response.data.flash !== undefined){
				this.flash = response.data.flash
			}

			this.$refs.projectAttributes.updateVisibleFields()
		},
		valueChanged: function(e){
			window.onbeforeunload = function() {
				return 'Az oldalon el nem mentett változások vannak. Biztos, hogy el akarod hagyni az oldalt?';
			}

			this.unsavedChanged = true
		},
		formClean: function(){
			window.onbeforeunload = null
			this.unsavedChanged = false
		},
		lockResponse: function(response){
			this.lock.updated = true
			this.lock.lockedAt = response.data.lockedAt
			this.lock.locked = response.data.locked
			this.lock.lockedByCurrentUser = response.data.lockedByCurrentUser
			this.lock.lockedByUserName = response.data.lockedByUserName
			this.lock.lockedByClientName = response.data.lockedByClientName
		},
		updateLock: function(){
			if (this.isInactive){
				return
			}
			if (this.project !== undefined && this.project.id) {
				let that = this
				axios.post(this.dataUrl + '/' + this.project.id + '?action=updateLock').then((response) => {
					this.lockResponse(response)
				}).catch(function (error) {
					that.flash = [{level: 'danger', message: error.response.data.message}]
				})
			}
		},
		reloadPage: function (){
			this.formClean()
			location.reload()
		},
		selectProjectType: function(type){
			this.showLoading()
			let that = this
			axios.get(this.dataUrl + '?action=getProject&typeId=' + type.id + '&clientId=' + this.client.id).then((response)=>{
				this.hideLoading()
				this.handleResponse(response)
				this.editable = true
			}).catch(function (error) {
				that.flash = [{level: 'danger', message: error.response.data.message}]
				this.hideLoading()
			})
		},
		startEditing: function(){
			this.showLoading()
			let that = this
			axios.post(this.dataUrl + '/' + this.project.id + '?action=lock').then((response)=>{
				this.lockResponse(response)
				this.hideLoading()
			}).catch(function (error) {
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		formFlash: function(flash){
			this.flash = flash
		},
		commentCountUpdated: function(count){
			this.tabBadges.comments = count
		},
		updateStats: function (stats){
			this.tabBadges = stats
		},
		getDocumentTypeCount: function(type){
			if (type === null){
				return 0
			}
			if (typeof type === 'object') {
				return this.project.documents[type.id].length + this.uploadedFiles[type.id].length
			}

			return this.getSystemDocuments(type).length
		},
		openContract: function(id){
			this.setActiveTab('contracts')
			let that = this
			setTimeout(function(){
				if (that.activeTab === 'contracts') {
					that.$refs.contractList.openContract(id)
				} else {
					that.flash = [{level: 'danger', message: 'Nem megfelelő jogosultság'}]
				}
			}, 100)
		},
		openQuote: function(id){
			this.setActiveTab('quotes')
			let that = this
			setTimeout(function(){
				if (that.activeTab === 'quotes') {
					that.$refs.quoteList.openQuote(id)
				} else {
					that.flash = [{level: 'danger', message: 'Nem megfelelő jogosultság'}]
				}
			}, 100)
		},
		setActiveTab: function(tab, params){
			params = params || ''
			this.activeTab = tab
			location.hash = tab + (params ? '-' + params : '')

			this.checkActiveTab()
		},
		getSystemDocuments: function(type){
			if (this.project.system_documents[type] !== undefined){
				return this.project.system_documents[type]
			}

			return []
		}
	}
}
</script>

<style scoped>
.alert-wrapper {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0);
	background: white;
	padding: 15px;
	z-index: 9900;
	box-shadow: 0 0 15px rgba(0,0,0,.1);
}
.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>