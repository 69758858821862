<template>
	<div>
		<form-wrapper
				ref="formWrapper"
				:store-url="storeUrl"
				:back-url="backUrl"
				:initial-flash="initialFlash"
				v-model="attributes"
				v-slot="{ errors }"
		>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<div v-if="attributes.created_at">
							<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(attributes.created_at) }}</strong>
						</div>
						<div v-if="attributes.modified_at">
							<i class="far fa-calendar-alt"></i> Módosítva: <strong>{{ formatDate(attributes.modified_at) }}</strong>
						</div>
						<div v-if="attributes.last_run">
							<i class="far fa-calendar-alt"></i> Előző futás: <strong>{{ formatDate(attributes.last_run) }}</strong>
						</div>
						<div v-if="attributes.next_runtime">
							<i class="far fa-calendar-alt"></i> Következő futás: <strong>{{ formatDate(attributes.next_runtime) }}</strong>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-4">Adatok</h4>
							<switch-field
								label="Aktív"
								name="active"
								v-model="attributes.active"
								/>

							<input-field
									label="Név"
									name="name"
									rules="required"
									v-model="attributes.name"
									:disabled="!allowEditing"
									:errors="errors"
							/>
							<iconpicker-field
									v-model="attributes.icon"
									label="Ikon"
									name="icon"
							/>
							<div class="mb-3">
								<label>Szín</label>
								<input type="color" class="form-control form-control-color" v-model="attributes.color"  />
							</div>
						</div>
					</div>
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-4">Feltétel</h4>
							<div class="details-box details-box--narrow details-box--md mb-2">
								<h4>Változók</h4>
								<template v-for="(label, key) in variables">
									<div>
										<strong>{{ key }}</strong> - {{ label }}
										<CopyToClipboard :value="key" />
									</div>
								</template>
							</div>
							<div class="alert alert-secondary alert-sm">
								<p>A Symfony Expression nyelv szabályairól <a href="https://symfony.com/doc/current/reference/formats/expression_language.html" target="_blank">itt olvashatsz</a>.</p>
							</div>
							<textarea-field
									v-model="attributes.condition_expression"
									name="condition_expression"
									rules="required"
									:field-only="true"
									:no-margin="true"
							></textarea-field>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-4">Futási idők</h4>
							<div class="alert alert-secondary alert-sm">
								<p>Az alábbi mezők határozzák meg, hogy milyen időközönként fut a bónusz logikája. Futáskor a rendszer összehasonlítja a megfelelő mezőket (pl az itt megadott hónapot az aktuális hónappal), ha mindegyik egyezik, akkor lefut. A mezők lehetséges értékei:</p>
								<ul>
									<li><strong>*</strong> - minden esetben fusson</li>
									<li><strong>*/3</strong> - minden 3. esetben fusson</li>
									<li><strong>2-5</strong> - csak akkor fusson, ha az aktuális érték a tartományon belül van</li>
									<li><strong>4</strong> - csak akkor fusson, ha az aktuális érték 4</li>
								</ul>
							</div>
							<input-field
									label="Futási idő (hónap)"
									name="interval_month"
									v-model="attributes.interval_month"
									rules="required"
									:disabled="!allowEditing"
									:errors="errors"
							/>
							<input-field
									label="Futási idő (hét napja)"
									name="interval_week"
									v-model="attributes.interval_week"
									rules="required"
									info="Hétfő: 1, ... , Vasárnap: 7"
									:disabled="!allowEditing"
									:errors="errors"
							/>
							<input-field
									label="Futási idő (nap)"
									name="interval_day"
									v-model="attributes.interval_day"
									rules="required"
									:disabled="!allowEditing"
									:errors="errors"
							/>
						</div>
					</div>

					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-4">Jutalom</h4>
							<div class="details-box details-box--narrow details-box--md mb-2">
								<h4>Változók</h4>
								<template v-for="(label, key) in variables">
									<div>
										<strong>{{ key }}</strong> - {{ label }}
										<CopyToClipboard :value="key" />
									</div>
								</template>
							</div>
							<div class="alert alert-secondary alert-sm">
								<p>A Symfony Expression nyelv szabályairól <a href="https://symfony.com/doc/current/reference/formats/expression_language.html" target="_blank">itt olvashatsz</a>.</p>
							</div>
							<textarea-field
									v-model="attributes.reward_expression"
									name="reward_expression"
									rules="required"
									:field-only="true"
									:no-margin="true"
							></textarea-field>
						</div>
					</div>
				</div>
			</div>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import FormWrapper from "../../components/FormWrapper.vue";
import {formatDate} from "../../functions";
import SwitchField from "../../components/form/SwitchField.vue";
import IconpickerField from "../../components/form/IconpickerField.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import CopyToClipboard from "../../components/CopyToClipboard.vue";
export default {
	props: ['initialAttributes', 'storeUrl', 'backUrl', 'initialFlash', 'allowEditing'],
	components: {CopyToClipboard, TextareaField, IconpickerField, SwitchField, InputField, FormWrapper},
	data(){
		return {
			attributes: this.initialAttributes,
			loading: false,
			flash: [],
			variables: {
				ujMunkatars: 'Beléptetett munkatárs',
				fizetettSzerzodes: 'Fizetett szerződések száma',
				fizetettSzerzodesOsszeg: 'Fizetett szerződések összértéke',
				szint: 'Felhasználó szintje',
			}
		}
	},
	mounted() {
	},
	methods: {
		formatDate: formatDate,
	}
}
</script>

<style scoped>

</style>