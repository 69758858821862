<template>
	<div :class="{'mb-3': !fieldOnly && !noMargin}">
		<label :class="{required: isRequired}" v-if="!fieldOnly">{{ label }}</label>
		<div v-if="spinnerButtons && !disabled">
			<div class="input-group">
				<button class="btn btn-secondary" type="button" @click.prevent="decrease"><i class="far fa-minus"></i></button>
				<Field
					v-model="componentValue"
					:key="inputKey"
					:name="name"
					:type="componentType"
					:readonly="disabled"
					:disabled="disabled"
					@keypress="onKeypress($event)"
					@input="onInput"
					:step="stepValue"
					:min="min"
					:max="max"
					:rules="rulesValue"
					:style="inputStyle"
					:id="id"
					:placeholder="showPlaceholder ? label : ''"
					:class="inputClass"
				/>
				<button class="btn btn-secondary" type="button" @click.prevent="increase"><i class="far fa-plus"></i></button>
				<div class="invalid-feedback" v-if="errors[name]">{{ errors[name] }}</div>
			</div>
		</div>
		<div class="input-group" v-else-if="prefixValue || suffix || prefixIcon || suffixIcon">
			<span class="input-group-text" v-if="prefixValue">{{ prefixValue }}</span>
			<span class="input-group-text" v-if="prefixIcon"><i :class="prefixIcon"></i></span>
			<Field
				v-model="componentValue"
				:key="inputKey"
				:name="name"
				:type="componentType"
				:readonly="disabled"
				:disabled="disabled"
				@keypress="onKeypress($event)"
				@input="onInput"
				:step="stepValue"
				:min="min"
				:max="max"
				:rules="rulesValue"
				:style="inputStyle"
				:id="id"
				:placeholder="showPlaceholder ? label : ''"
				:class="inputClass"
			/>
			<span class="input-group-text" v-if="suffixIcon"><i :class="suffixIcon"></i></span>
			<span class="input-group-text" v-if="suffix">{{ suffix }}</span>
			<div class="invalid-feedback" v-if="errors[name]">{{ errors[name] }}</div>
		</div>
		<template v-else>
			<Field
				v-model="componentValue"
				:key="inputKey"
				:name="name"
				:type="componentType"
				:readonly="disabled"
				:disabled="disabled"
				@keypress="onKeypress($event)"
				@input="onInput"
				:step="stepValue"
				:min="min"
				:max="max"
				:rules="rulesValue"
				:style="inputStyle"
				:id="id"
				:placeholder="showPlaceholder ? label : ''"
				:class="inputClass"
			/>
		</template>
		<div class="invalid-feedback" v-if="errors[name] && !prefixValue && !suffix && !spinnerButtons">{{ errors[name] }}</div>
		<p class="text-muted mt-2" v-if="info" v-html="info"></p>
	</div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
export default {
	components: {Field, ErrorMessage},
	props: {
		rules: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		label: String,
		name: {
			type: String,
			default: ''
		},
		modelValue: [String, Number],
		errors: {
			type: Object,
			default: function(){
				return {}
			}
		},
		prefix: {
			type: String,
			default: ''
		},
		suffix: {
			type: String,
			default: ''
		},
		prefixIcon: {
			type: String,
			default: ''
		},
		suffixIcon: {
			type: String,
			default: ''
		},
		dataType: {
			type: String,
			default: 'string'
		},
		inputType: {
			type: String,
			default: 'auto'
		},
		step: {
			type: [String, Number],
			default: null
		},
		min: {
			type: [String, Number],
			default: null
		},
		max: {
			type: [String, Number],
			default: null
		},
		info: {
			type: String,
			default: ''
		},
		id: {
			type: String,
			default: ''
		},
		fixedWidth: {
			type: Number,
			default: null
		},
		align: {
			type: String,
			default: 'left'
		},
		zeroAllowed: {
			type: Boolean,
			default: true
		},
		fieldOnly: Boolean,
		spinnerButtons: Boolean,
		noMargin: Boolean,
		equalTo: {
			type: String,
			default: ''
		},
		showPlaceholder: Boolean
	},
	data() {
		return {
			inputKey: 0,
			rulesValue: null
		}
	},
	mounted() {
		this.updateRules(this.componentValue)
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				let updateInputKey = false
				if (this.dataType === 'number'){
					if (value !== '' && Number.isNaN(parseFloat(value))){
						updateInputKey = true
						value = this.modelValue
					}
					if (this.min !== null && this.min && parseFloat(value) < parseFloat(this.min)){
						updateInputKey = true
						value = this.min
					}
					if (this.max !== null && this.max && parseFloat(value) > parseFloat(this.max)){
						updateInputKey = true
						value = this.max
					}
				}
				if ((value === '' && this.modelValue !== '') || (value !== '' && this.modelValue === '')){
					this.updateRules(value)
				}
				this.$emit('update:modelValue', value)

				if (updateInputKey) {
					this.inputKey++
				}
			}
		},
		componentType: {
			get() {
				if (this.dataType === 'color'){
					return 'color'
				}
				if (this.dataType === 'password'){
					return 'password'
				}
				return this.dataType === 'number' && this.inputType !== 'text' ? 'number' : 'text'
			}
		},
		stepValue: {
			get() {
				if (this.dataType !== 'number'){
					return null
				}
				return this.step ? this.step : 1
			}
		},
		isRequired: {
			get(){
				return this.rules.indexOf('required') > -1
			}
		},
		prefixValue: {
			get() {
				if (this.dataType === 'phone'){
					return '+'
				}
				return this.prefix
			}
		},
		inputStyle: {
			get() {
				let payload = {}

				if (this.fixedWidth){
					payload.width = this.fixedWidth + 'px'
					payload.paddingLeft = '4px'
					payload.paddingRight = '4px'
				}

				return payload
			}
		},
		inputClass: {
			get() {
				let payload = ['form-control']

				if (this.errors[this.name] !== undefined){
					payload.push('is-invalid')
				}
				if (this.dataType === 'color'){
					payload.push('form-control-color')
				}
				if (this.align === 'center'){
					payload.push('text-center')
				}
				if (this.align === 'right'){
					payload.push('text-end')
				}

				return payload
			}
		},
	},
	methods: {
		onKeypress: function(e){
			if (this.dataType === 'number' || this.dataType === 'phone'){
				let keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
				if (this.step !== null && this.step < 1){
					keysAllowed.push('.')
				}
				if (this.min === null || this.min < 0){
					keysAllowed.push('-')
				}
				const keyPressed = e.key;

				if (!keysAllowed.includes(keyPressed)) {
					e.preventDefault()
				}
			}
		},
		onInput: function(){
			this.$emit('input')
		},
		decrease: function(){
			let value = parseFloat(this.componentValue)
			value -= this.stepValue
			if (value === 0 && !this.zeroAllowed){
				value -= this.stepValue
			}
			if (this.min){
				value = Math.max(this.min, value)
			}

			this.componentValue = value
		},
		increase: function(){
			let value = parseFloat(this.componentValue)
			value += this.stepValue
			if (value === 0 && !this.zeroAllowed){
				value += this.stepValue
			}
			if (this.max){
				value = Math.min(this.max, value)
			}

			this.componentValue = value
		},
		updateRules: function(value){
			let tmp = this.rules.split('|')
			let rules
			if ((this.dataType === 'number' || this.dataType === 'phone') && value !== ''){
				rules = yup.number()
			} else {
				rules = yup.string()
			}
			if (this.dataType === 'email'){
				rules = rules.email()
			}
			if (tmp.indexOf('required') > -1){
				rules = rules.required()
			} else {
				rules = rules.notRequired()
			}
			if (this.equalTo !== ''){
				rules = rules.oneOf([this.equalTo], 'A jelszavaknak meg kell egyeznie')
			}
			_.forEach(tmp, function(rule){
				if (rule.indexOf('min:') > -1){
					rule = rule.replace('min:', '')
					rules = rules.min(parseInt(rule))
				}
			})
			this.rulesValue = rules.label(this.label)
		}
	}
}
</script>

<style scoped>

</style>