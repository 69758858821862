<template>
	<DataTable
		:data-url="dataUrl"
		:initial-flash="initialFlash"
		:columns="columns"
		sort-by="created_at"
		sort-type="desc"
		name="inventoryShipments"
	>
		<template #filters="{serverOptions}">
			<div class="col-12 col-md-3">
				<v-select
					:options="warehouseSelectData"
					v-model="serverOptions.warehouseId"
					:reduce="item => item.id"
					label="name"
					placeholder="Raktár"
				/>
			</div>
			<div class="col-12 col-md-3">
				<v-select
					:options="objectToArray(types)"
					v-model="serverOptions.type"
					:reduce="item => item.id"
					label="name"
					placeholder="Típus"
				>
					<template v-slot:option="option">
						<span :class="'badge badge-xl bg-' + option.color">
							<i :class="option.icon"></i> {{ option.name }}
						</span>
					</template>
					<template v-slot:selected-option="option">
						<span :class="'badge badge-xl bg-' + option.color">
							<i :class="option.icon"></i> {{ option.name }}
						</span>
					</template>
				</v-select>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
				:data="item"
				:edit-url="editUrl"
				:delete-url="deleteUrl"
				:is-editable="isEditable"
				:is-deletable="isDeletable"
				:emit-delete-event="true"
				@deleteButtonPressed="deleteButtonPressed"
			>
				<a :href="editUrl + '/' + item.id + '/download'" v-if="item.file_id" class="btn btn-warning btn-sm" title="Szállítólevél letöltése"><i class="far fa-download"></i></a>
			</TableActionCell>
		</template>
		<template #item-public_id="item">
			{{ item.foreign_id ? item.foreign_id : item.public_id }}
		</template>
		<template #item-type="item">
			<span :class="'badge badge-xl bg-' + types[item.type].color">
				<i :class="types[item.type].icon"></i> {{ types[item.type].name }}
			</span>
		</template>
		<template #item-created_at="item">
			{{ formatDate(item.created_at) }}
		</template>
		<template #item-closed_at="item">
			<div v-if="item.closed_at !== null" class="text-success text-center">
				{{ formatDate(item.closed_at) }}
			</div>
			<div v-else class="text-danger text-center">
				<i class="far fa-times"></i>
			</div>

		</template>
		<template #item-source="item">
			<template v-if="suppliers[item.supplier_id] !== undefined">
				{{ suppliers[item.supplier_id].name }}
			</template>
			<template v-else-if="warehouses[item.source_warehouse_id] !== undefined">
				{{ warehouses[item.source_warehouse_id].name }}
			</template>
			<template v-else-if="item.contract !== null">
				{{ item.contract }}
			</template>
			<template v-else-if="item.sourceName !== null">
				{{ item.sourceName }}
			</template>
		</template>
		<template #item-destination="item">
			<template v-if="warehouses[item.destination_warehouse_id] !== undefined">
				{{ warehouses[item.destination_warehouse_id].name }}
			</template>
			<template v-else-if="item.contract !== null">
				{{ item.contract }}
			</template>
			<template v-else-if="item.destination_name">
				{{ item.destination_name }}
			</template>
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import BooleanBadge from "../../components/BooleanBadge.vue";
import vSelect from "vue-select";
import {formatDate, objectToArray} from "../../functions";

export default {
	components: {
		DataTable,
		BooleanBadge,
		TableActionCell,
		vSelect
	},
	props: {
		dataUrl: String,
		editUrl: String,
		deleteUrl: String,
		warehouses: Object,
		suppliers: Object,
		types: Object,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	computed: {
		warehouseSelectData: {
			get(){
				return _.values(this.warehouses)
			}
		}
	},
	data(){
		let columns = [
			{
				text: "Azon.",
				value: "public_id",
				sortable: true
			},
			{
				text: "Típus",
				value: "type",
				sortable: true
			},
			{
				text: "Létrehozva",
				value: "created_at",
				wrap: false,
				sortable: true
			},
			{
				text: "Lezárva",
				value: "closed_at",
				sortable: true
			},
			{
				text: "Forrás",
				value: "source",
				sortable: false
			},
			{
				text: "Cél",
				value: "destination",
				sortable: false
			},
			{
				text: "Felhasználó",
				value: "createdByUserName",
				sortable: true
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
		}
	},
	methods: {
		objectToArray,
		formatDate,
		isEditable: function(item){
			return item.editable
		},
		isDeletable: function(item){
			return item.deletable
		},
	}
}
</script>

<style scoped>
</style>
