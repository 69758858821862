<template>
	<flash-display :flash="flash" ref="notifications"></flash-display>
	<div :class="{'overlay-form-wrapper': 1}">
		<div :class="{'overlay-form-wrapper--content': 1, 'loading': loading}">
			<div v-if="loaded">
				<div class="page-title-box">
					<h4 class="mb-sm-0 font-size-18">{{ installation.id !== undefined ? 'Telepítés' : 'Telepítés indítása'}}</h4>
				</div>

				<div class="mb-3">
					<div class="d-flex flex-wrap gap-2">
						<button type="submit" class="btn btn-primary btn-label waves-effect" v-if="isEditable" @click.prevent="saveForm">
							<i class="fas fa-save label-icon"></i> Mentés
						</button>
						<button type="submit" class="btn btn-warning btn-label waves-effect" v-if="!editable && canStartEditing" @click.prevent="$emit('startEditing')">
							<i class="fas fa-pencil label-icon"></i> Szerkesztés
						</button>
						<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="closeInstallation" :disabled="!canCloseInstallation" v-if="isEditable && installation.id && !installation.closed_at">
							<i class="fas fa-check label-icon"></i> Lezárás
						</button>
						<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
							<i class="fas fa-times label-icon"></i> Bezár
						</button>
					</div>
				</div>

				<div class="row">
					<div class="col-lg-6">
						<div class="card" v-if="installation.id">
							<div class="card-body">
								<div class="details-box mb-0">
									<div v-if="installation.contract_id !== undefined">
										<i class="far fa-hashtag"></i> Szerződés:
										<strong>{{ installation.contract.public_id }}</strong>
									</div>
									<div>
										<i class="far fa-calendar"></i> Létrehozva:
										<strong>{{ formatDate(installation.created_at) }}</strong>
									</div>
									<div>
										<i class="far fa-question"></i> Státusz:
										<color-badge
												:color="installation.closed_at ? 'success' : 'warning'"
												:icon="installation.closed_at ? 'check' : 'hourglass'"
												:content="installation.closed_at ? 'Lezárva' : 'Folyamatban'"
												></color-badge>
									</div>
									<div v-if="installation.closed_at">
										<i class="far fa-calendar-check"></i> Lezárva:
										<strong>{{ formatDate(installation.closed_at) }}</strong>
									</div>
									<div v-if="installation.closed_by_user !== null && installation.closed_by_user !== undefined">
										<i class="far fa-user"></i> Lezárta:
										<strong>{{ installation.closed_by_user.name }}</strong>
									</div>
								</div>
								<LockMessage :lock="lock" :margin-bottom="false" :margin-top="true"></LockMessage>
							</div>
						</div>

						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-md-6">
										<DateField
											label="Telepítés dátuma"
											name="installation_date"
											v-model="installation.installation_date"
											:disabled="!isEditable"
											rules="required"
											:errors="{}"
										/>
									</div>
									<div class="col-md-6">
										<UserSelectField
												label="Telepítő"
												name="installer_user_id"
												rules="required"
												type-filter="installer"
												v-model="installation.installer_user_id"
												:disabled="!isEditable || isInstaller"
												:errors="{}"
												:user-levels="userLevels"
												@change="installerChanged"
										/>
									</div>
									<div class="col-12">
										<TextareaField
												name="comment"
												:rows="4"
												v-model="installation.comment"
												:disabled="!isEditable"
												label="Megjegyzés"
										></TextareaField>
									</div>
								</div>
							</div>
						</div>
						<template v-for="documentType in documentTypes">
							<div class="card">
								<div class="card-body">
									<h4 :class="{
										'card-title': 1,
										'mb-0': 1,
										'd-flex': 1,
										'align-items-center': 1,
										'text-danger': !validateDocumentType(documentType) && documentType.required_for_installation !== null && documentType.required_for_installation,
										'text-success': validateDocumentType(documentType) && documentType.required_for_installation !== null && documentType.required_for_installation,
										}">
										<i v-if="documentType.icon" :class="'far fa-' + documentType.icon + ' fa-fw me-2'"></i>

										{{ documentType.name }}

										<span class="badge bg-danger badge-xl ms-3 me-3" v-if="!validateDocumentType(documentType) && documentType.required_for_installation !== null && documentType.required_for_installation">Hiányzó fileok</span>
										<span class="d-md-none ms-auto">
											<button class="btn btn-secondary" type="button" @click.prevent="openDocumentType(documentType)">
												<i class="far fa-angle-up font-size-18" v-if="openDocumentTypeCategory === documentType.id"></i>
												<i class="far fa-angle-down font-size-18" v-else></i>
											</button>
										</span>
									</h4>

									<div :class="{'hide-on-mobile': 1, 'mt-4': 1, 'd-block': openDocumentTypeCategory === documentType.id}">
										<ProjectFormDocumentCategory
												:can-upload="isEditable"
												:can-delete="isEditable"
												:can-update="isEditable"
												:file-upload-url="fileUploadUrl"
												:document-type="documentType"
												:ref="'documentCategory_' + documentType.id"
												v-bind:existing-files="installation.documents[documentType.id]"
												v-bind:uploaded-files="uploadedFiles[documentType.id]"
										></ProjectFormDocumentCategory>
									</div>
								</div>
							</div>
						</template>
					</div>
					<div class="col-lg-6">
						<h4 class="mb-3 font-size-18">Fogyóeszköz felhasználás</h4>
						<div class="mb-3">
							<template v-for="(product, productKey) in productList">
								<template v-if="getProduct(product).type === 'consumable'">
									<ProjectInstallationProduct
										v-model="productList[productKey]"
										:product="getProduct(product)"
										:contract-product="getContractProduct(product)"
										:product-list="productList"
										:stock="stock"
										:editable="isProductEditable"
										:min="getMinQuantity(productKey)"
										:product-key="productKey"
										:scanner-product-index="scannerProductIndex"
										:scanner-mode="scannerMode"
										:delivered-products="deliveredProducts"
										:installation-products="installationProducts"
										:installation="installation"
										v-if="installationProducts !== undefined"
										@quantity-changed="quantityChanged"
										@show-scanner="showScanner"
										@save-delivery-requirement="saveDeliveryRequirement"
										@remove-serial-number="removeSerialNumber"
										@barcode-submit="barcodeSubmit"
										/>
								</template>
							</template>
						</div>
						<h4 class="mb-3 font-size-18">Szerződés termékei</h4>
						<div class="mb-3">
							<template v-for="(product, productKey) in productList">
								<template v-if="getProduct(product).type === 'material'">
									<ProjectInstallationProduct
										v-model="productList[productKey]"
										:product="getProduct(product)"
										:contract-product="getContractProduct(product)"
										:product-list="productList"
										:stock="stock"
										:editable="isProductEditable"
										:min="getMinQuantity(productKey)"
										:product-key="productKey"
										:scanner-product-index="scannerProductIndex"
										:scanner-mode="scannerMode"
										:delivered-products="deliveredProducts"
										:installation-products="installationProducts"
										:installation="installation"
										v-if="installationProducts !== undefined"
										@quantity-changed="quantityChanged"
										@show-scanner="showScanner"
										@save-delivery-requirement="saveDeliveryRequirement"
										@remove-serial-number="removeSerialNumber"
										@barcode-submit="barcodeSubmit"
										/>
								</template>
							</template>
						</div>

						<div class="card" v-if="editable">
							<div class="card-body">
								<product-select-field
									label="Termék hozzáadása"
									name="addProduct"
									:field-only="true"
									:type-filter="['material']"
									:excluded="addProductSelectExcluded"
									v-model="addProduct"
									></product-select-field>
							</div>
						</div>
					</div>
				</div>


				<div class="mt-3">
					<div class="d-flex flex-wrap gap-2">
						<button type="submit" class="btn btn-primary btn-label waves-effect" v-if="isEditable" @click.prevent="saveForm">
							<i class="fas fa-save label-icon"></i> Mentés
						</button>
						<button type="submit" class="btn btn-warning btn-label waves-effect" v-if="!editable && canStartEditing" @click.prevent="$emit('startEditing')">
							<i class="fas fa-pencil label-icon"></i> Szerkesztés
						</button>
						<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="closeInstallation" :disabled="!canCloseInstallation" v-if="editable && installation.id && !installation.closed_at">
							<i class="fas fa-check label-icon"></i> Lezárás
						</button>
						<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
							<i class="fas fa-times label-icon"></i> Bezár
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {formatDate, moveValueInArray} from "../../functions";
import ProjectInfoSidebar from "./ProjectInfoSidebar.vue";
import FlashDisplay from "../../components/FlashDisplay.vue";
import InputField from "../../components/form/InputField.vue";
import ProjectProductList from "./ProjectProductList.vue";
import Price from "../../components/Price.vue";
import vSelect from "vue-select";
import FileList from "../../components/FileList.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import DateField from "../../components/form/DateField.vue";
import UserSelectField from "../../components/form/UserSelectField.vue";
import ProductSelectField from "../../components/form/ProductSelectField.vue";
import FileIcon from "../../components/FileIcon.vue";
import VueUploadComponent from "vue-upload-component";
import ProjectFormDocumentCategory from "./ProjectFormDocumentCategory.vue";
import SwitchField from "../../components/form/SwitchField.vue";
import ColorBadge from "../../components/ColorBadge.vue";
import LockMessage from "../../components/LockMessage.vue";
import ProjectInstallationProduct from "./ProjectInstallationProduct.vue";

export default {
	components: {
		ProjectInstallationProduct,
		LockMessage,
		ColorBadge,
		SwitchField,
		ProjectFormDocumentCategory,
		VueUploadComponent, FileIcon, ProductSelectField, UserSelectField, DateField, TextareaField, FileList, Price, ProjectProductList, InputField, FlashDisplay, ProjectInfoSidebar, vSelect},
	emits: ['close', 'closeAndReload', 'generate', 'updateStats', 'startEditing'],
	props: {
		productCategories: [Array, Object],
		productTypes: Object,
		editable: Boolean,
		canStartEditing: Boolean,
		products: Array,
		documentTypes: Array,
		client: Object,
		lock: Object,
		dataUrl: String,
		fileUploadUrl: String,
		projectAttributes: Object,
		projectFields: Array,
		userLevels: Array,
		contractId: Number,
		installationId: {
			type: [Number, String],
			default: 0
		}
	},
	mounted() {
		this.loadData()
	},
	created() {
		this.successSound = new Audio('/audio/inventory-scan-success.mp3')
		this.successSound.preload = true

		this.errorSound = new Audio('/audio/inventory-scan-error.mp3')
		this.errorSound.preload = true

		window.addEventListener("keypress", e => {
			if (e.target.tagName.toLowerCase() !== 'input'){
				if (e.key.match(/^[0-9a-z]+$/)){
					this.barcode = e.key
					if (document.getElementById('barcodeInput')) {
						document.getElementById('barcodeInput').focus()
					}
				}
			}
		});
	},
	computed: {
		isEditable: {
			get(){
				if (!this.editable){
					return false
				}
				return this.installation.editable
			}
		},
		isProductEditable: {
			get(){
				if (!this.editable || !this.installation.id){
					return false
				}
				return this.installation.editable
			}
		},
		canCloseInstallation: {
			get() {
				if (!this.installation.installation_date || !this.installation.installer_user_id){
					return false
				}
				for (let i = 0; i < this.documentTypes.length; i++){
					if (!this.validateDocumentType(this.documentTypes[i])){
						return false
					}
				}

				return true
			}
		},
		productsById: {
			get() {
				return _.keyBy(this.products, 'id')
			}
		},
		contractProductsById: {
			get() {
				return _.keyBy(this.contractProducts, 'id')
			}
		},
		addProductSelectExcluded: {
			get(){
				let excluded = []
				_.forEach(this.productList, function(item, key){
					excluded.push(item.productId)
				})
				return excluded
			}
		}
	},
	watch: {
		addProduct: {
			handler(newValue, oldValue) {
				if (newValue){
					let order = 1
					_.forEach(this.productList, function (item, key){
						key = parseInt(key)
						if (key >= order){
							order = key + 1
						}
					})

					this.productList[order] = {
						contractProductId: null,
						quantity: 0,
						contractQuantity: 0,
						deliveredQuantity: 0,
						order: order,
						productId: newValue,
						installationProducts: [],
						requiresDelivery: this.productsById[newValue].requires_delivery
					}
				}

				this.addProduct = null
			}
		},
	},
	data() {
		let uploadedFiles = {}
		_.forEach(this.documentTypes, function(type){
			uploadedFiles[type.id] = []
		})
		return {
			installation: {},
			productList: {},
			installationProducts: [],
			consumableProducts: [],
			flash: [],
			loading: false,
			stock: {},
			contractProducts: [],
			deliveredProducts: {},
			loaded: false,
			isInstaller: false,
			barcode: '',
			successSound: '',
			errorSound: '',
			scannerProductIndex: null,
			openDocumentTypeCategory: null,
			scannerMode: 'remove',
			quantityChangeTimeout: null,
			addProduct: null,
			uploadedFiles: uploadedFiles,
		};
	},
	methods: {
		formatDate: formatDate,
		moveValueInArray: moveValueInArray,
		getDataUrl: function(){
			let url = this.dataUrl + '/' + this.contractId
			if (this.installationId) {
				url += '/' + this.installationId
			}

			return url
		},
		loadData: function () {
			this.loading = true

			let that = this
			axios.get(this.getDataUrl() + '?action=load').then((response) => {
				this.handleResponse(response)
			}).catch(function (error) {
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		emitClose: function(){
			this.$emit('close')
		},
		closeInstallation: function (e){
			this.saveForm(e, true)
		},
		saveForm: function(e, close){
			let that = this
			this.saveProductQuantities(function(){
				that.saveFormRequest(close)
			})
		},
		saveFormRequest: function (close){
			close = close || false
			this.loading = true

			if (this.installation.installation_date !== undefined && this.installation.installation_date instanceof Date) {
				this.installation.installation_date.setHours(12)
			}
			let payload = {
				installation: this.installation,
				uploadedFiles: this.uploadedFiles,
				close: close
			}

			let that = this
			axios.post(this.getDataUrl() + '?action=save', payload).then((response)=>{
				this.handleResponse(response)
			}).catch(function (error) {
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.success === undefined || response.data.success) {
				this.installation = response.data.installation
				if (response.data.contractProducts !== undefined) {
					this.contractProducts = response.data.contractProducts
				}
				if (response.data.deliveredProducts !== undefined) {
					this.deliveredProducts = response.data.deliveredProducts
				}
				if (response.data.installationProducts !== undefined) {
					this.installationProducts = response.data.installationProducts
				}
				if (response.data.isInstaller !== undefined) {
					this.isInstaller = response.data.isInstaller
				}
				if (response.data.consumableProducts !== undefined) {
					this.consumableProducts = response.data.consumableProducts
				}
				if (response.data.stock !== undefined) {
					this.stock = response.data.stock
				}

				this.setDefaultProducts()

				this.parseInstallationProducts()

				for (let i = 0; i < this.documentTypes.length; i++){
					this.uploadedFiles[this.documentTypes[i].id] = []
				}

				this.loaded = true

				if (response.data.success !== undefined) {
					this.$emit('closeAndReload')
				}
			}
			if (response.data.flash !== undefined){
				this.flash = response.data.flash
			}
		},
		getProduct: function(installationProduct){
			if (installationProduct.productId && this.productsById[installationProduct.productId] !== undefined){
				return this.productsById[installationProduct.productId]
			}

			return {}
		},
		getContractProduct: function(installationProduct){
			if (installationProduct.contractProductId && this.contractProductsById[installationProduct.contractProductId] !== undefined){
				return this.contractProductsById[installationProduct.contractProductId]
			}

			return {}
		},
		setFlash: function(flash){
			this.flash = flash
		},
		checkQuantityErrors: function(){
			let that = this
			_.forEach(this.productList, function(product){
				let available = that.stock[product.productId] !== undefined ? that.stock[product.productId] : 0
				if (product.quantity > available){
					product.quantityError = product.quantity
					product.quantity = available
				} else {
					product.quantityError = null
				}
			})
		},
		setDefaultProducts: function(){
			let payload = {}
			let order = 0
			let that = this
			_.forEach(this.contractProducts, function(product){
				payload[order] = {
					contractProductId: product.id,
					quantity: 0,
					contractQuantity: product.quantity,
					deliveredQuantity: product.delivered,
					order: order++,
					productId: product.product_id,
					installationProducts: [],
					requiresDelivery: that.productsById[product.product_id].requires_delivery
				}
			})
			_.forEach(this.deliveredProducts, function(deliveredProductItem, productId){
				let current = _.find(payload, function(item){
					return item.productId === parseInt(productId)
				})
				let product = that.productsById[productId]

				if (product.track_serial_number){
					if (current === undefined) {
						payload[order] = {
							contractProductId: null,
							quantity: 0,
							contractQuantity: 0,
							deliveredQuantity: Object.keys(deliveredProductItem).length,
							order: order++,
							productId: product.id,
							installationProducts: [],
							requiresDelivery: product.requires_delivery
						}
					} else {
						current.deliveredQuantity = Object.keys(deliveredProductItem).length
					}
				} else {
					if (current === undefined) {
						payload[order] = {
							contractProductId: null,
							quantity: 0,
							contractQuantity: 0,
							deliveredQuantity: deliveredProductItem.quantity,
							order: order++,
							productId: product.id,
							installationProducts: [],
							requiresDelivery: product.requires_delivery
						}
					} else {
						current.deliveredQuantity = deliveredProductItem.quantity
					}
				}
			})
			_.forEach(this.installationProducts, function(product){
				let current = _.find(payload, function(item){
					return item.productId === product.product_id
				})

				if (!current) {
					payload[order] = {
						contractProductId: null,
						quantity: product.quantity,
						contractQuantity: 0,
						deliveredQuantity: 0,
						order: order++,
						productId: product.product_id,
						installationProducts: [],
						requiresDelivery: product.requires_delivery
					}
				} else {
					current.requiresDelivery = product.requires_delivery
				}
			})

			_.forEach(this.consumableProducts, function(product){
				let current = _.find(payload, function(item){
					return item.productId === product.id
				})

				if (!current) {
					payload[order] = {
						contractProductId: null,
						quantity: 0,
						contractQuantity: 0,
						deliveredQuantity: 0,
						order: order++,
						productId: product.id,
						installationProducts: [],
						requiresDelivery: product.requires_delivery
					}
				}
			})

			this.productList = payload

			this.checkQuantityErrors()
		},
		showScanner: function(productKey, action){
			this.scannerProductIndex = productKey
			this.scannerMode = action
			this.barcode = ''
		},
		getMinQuantity: function (installationProductKey){
			if (this.productList[installationProductKey].deliveredQuantity){
				return -1 * this.productList[installationProductKey].deliveredQuantity
			}

			return 0
		},
		playSound: function(audio){
			if (!audio.paused){
				audio.pause()
			}
			audio.currentTime = 0
			audio.play()
		},
		parseInstallationProducts: function (){
			let that = this
			_.forEach(this.productList, function (product){
				product.installationProducts = []
				product.quantity = 0
				_.forEach(that.installationProducts, function(installationProduct){
					if (installationProduct.product_id === product.productId){
						product.installationProducts.push(installationProduct)
						product.quantity += installationProduct.quantity
					}
				})
			})
		},
		barcodeSubmit: function(barcode, installationProduct){
			if (barcode.length < 6){
				return
			}

			this.loading = true

			let that = this
			axios.post(this.getDataUrl() + '?action=scan', {
					barcode: barcode,
					productId: installationProduct.productId,
					remove: this.scannerMode === 'remove'
			}).then((response) => {
				this.loading = false
				if (!response.data.success){
					this.playSound(this.errorSound)
				} else {
					this.playSound(this.successSound)
				}
				this.installationProducts = response.data.installationProducts
				this.parseInstallationProducts()

				this.flash = response.data.flash
			}).catch(function (error) {
				that.loading = false
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		removeSerialNumber: function (product, serialNumber){
			let that = this
			axios.post(this.getDataUrl() + '?action=removeProduct', {
				barcode: serialNumber,
				productId: product.productId
			}).then((response) => {
				this.loading = false

				if (response.data.success){
					this.playSound(this.successSound)
				}

				this.installationProducts = response.data.installationProducts
				this.parseInstallationProducts()

				this.flash = response.data.flash
			}).catch(function (error) {
				that.loading = false
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		quantityChanged: function(){
			if (this.quantityChangeTimeout !== null){
				clearTimeout(this.quantityChangeTimeout)
			}
			let that = this
			this.quantityChangeTimeout = setTimeout(function(){
				that.saveProductQuantities()
			}, 1000)
		},
		saveProductQuantities: function (callback){
			let payload = {}
			_.forEach(this.productList, function (product){
				if (product.quantityChanged !== undefined && product.quantityChanged){
					payload[product.productId] = product.quantity
					product.quantityChanged = false
				}
			})
			if (Object.keys(payload).length){
				let that = this
				axios.post(this.getDataUrl() + '?action=setQuantities', {
					quantities: payload
				}).then((response) => {
					if (response.data.flash !== undefined){
						this.flash = response.data.flash
					}
					if (callback !== undefined){
						callback()
					}
				}).catch(function (error) {
					that.flash = [{level: 'danger', message: error.response.data.message}]
				})
			} else {
				if (callback !== undefined){
					callback()
				}
			}
		},
		saveDeliveryRequirement: function (product){
			let that = this
			axios.post(this.getDataUrl() + '?action=saveDeliveryRequirement', {
				productId: product.productId,
				requiresDelivery: product.requiresDelivery
			}).then((response) => {
			}).catch(function (error) {
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		getDocumentTypeCount: function (documentType){
			let count = 0
			if (this.installation.documents[documentType.id] !== undefined){
				count += this.installation.documents[documentType.id].length
			}
			if (this.uploadedFiles[documentType.id] !== undefined){
				count += this.uploadedFiles[documentType.id].length
			}

			return count
		},
		validateDocumentType: function (documentType){
			if (documentType.required_for_installation === null || documentType.required_for_installation < 1){
				return true
			}
			if (this.getDocumentTypeCount(documentType) >= documentType.required_for_installation){
				return true
			}

			return false
		},
		installerChanged: function (installer){
			if (!this.installation.id){
				return
			}
			let that = this
			axios.post(this.getDataUrl() + '?action=setInstaller', {
				installerId: installer
			}).then((response) => {
			}).catch(function (error) {
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		openDocumentType: function (type){
			if (this.openDocumentTypeCategory === type.id){
				this.openDocumentTypeCategory = null
			} else {
				this.openDocumentTypeCategory = type.id
			}
		}
	}
}
</script>

<style scoped>
@media (max-width: 600px) {
	.hide-on-mobile {
		display: none;
	}
}
</style>