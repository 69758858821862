<template>
	<div>
		<form-wrapper
			ref="formWrapper"
			:store-url="storeUrl"
			:back-url="backUrl"
			:initial-flash="initialFlash"
			v-model="attributes"
		>
			<template #default="{activeTab, errors}">
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="card-title mb-4">Adatok</h4>
						<div class="row">
							<div class="col-sm-6">
								<input-field
									label="Megnevezés"
									name="name"
									rules="required"
									v-model="attributes.name"
									:errors="errors"
								/>

								<input-field
									label="Prioritás"
									name="priority"
									rules="required"
									data-type="number"
									:step="1"
									:min="1"
									v-model="attributes.priority"
									:errors="errors"
								/>
							</div>
							<div class="col-sm-6">
								<select-field
									label="Kategória"
									name="document_category_id"
									rules="required"
									v-model="attributes.document_category_id"
									:options="categories"
									:errors="errors"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="card-title mb-4">File</h4>
						<FileUploader
							field-name="file"
							v-model="attributes.file"
							:file-upload-url="fileUploadUrl"
							:multiple="false"
						></FileUploader>
					</div>
				</div>
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="card-title mb-4">Leírás</h4>
						<textarea rows="6" v-model="attributes.description" class="form-control"></textarea>
					</div>
				</div>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import SwitchField from "../../components/form/SwitchField.vue"
import FormWrapper from "../../components/FormWrapper.vue";
import SelectField from "../../components/form/SelectField.vue";
import FileUploader from "../../components/FileUploader.vue";
import ConfirmButton from "../../components/ConfirmButton.vue";
import {formatDate} from "../../functions";
export default {
	props: ['initialAttributes', 'storeUrl', 'backUrl', 'initialFlash', 'allowEditing', 'categories', 'fileUploadUrl'],
	components: {InputField, SwitchField, FormWrapper, SelectField, FileUploader, ConfirmButton},
	data(){
		let attributes = this.initialAttributes
		attributes.file = attributes.file !== undefined && attributes.file !== null ? [attributes.file] : []
		return {
			attributes: attributes,
			loading: false,
			flash: [],
		}
	},
	mounted() {
	},
	methods: {
		formatDate: formatDate,
	}
}
</script>

<style scoped>

</style>