<template>
	<div>
		<DataTable
				:data-url="listUrl"
				:initial-flash="initialFlash"
				:columns="columns"
				sort-by="created_at"
				ref="table"
				sort-type="desc"
				name="invoices"
				:row-class-callback="getRowClass"
		>
			<template #item-operations="{item, deleteButtonPressed}">
				<div class="table-row-operations d-flex gap-2" v-if="item.file_id">
					<a class="btn btn-secondary btn-sm waves-effect" :href="dataUrl + '/' + item.id + '/download'" title="Letöltés">
						<i class="far fa-download label-icon"></i>
					</a>
					<button type="button" class="btn btn-warning btn-sm waves-effect" v-if="canRegenerateProformaInvoice && item.deletable && editable" title="Újragenerálás" @click.prevent="regenerateProformaInvoice(item)">
						<i class="far fa-repeat label-icon"></i>
					</button>
				</div>
				<span class="badge bg-warning" v-else>Generálásra vár</span>
			</template>
			<template #item-invoice_number="item">
				<div v-if="highlight === item.id" class="highlighted">
					{{item.invoice_number}} <i class="far fa-exclamation-triangle ms-2"></i>
				</div>
				<template v-else>
					{{item.invoice_number}}
				</template>
			</template>
			<template #item-type="item">
				<span :class="'badge badge-xl bg-' + invoiceTypeLabels[item.type].color">
					<i :class="invoiceTypeLabels[item.type].icon"></i> {{ invoiceTypeLabels[item.type].name }}
				</span>
			</template>
			<template #item-payment_method="item">
				<span :class="'badge badge-xl bg-' + paymentMethods[item.payment_method].color">
					<i :class="paymentMethods[item.payment_method].icon"></i> {{ paymentMethods[item.payment_method].label }}
				</span>
			</template>
			<template #item-amount="item">
				<Price
					:value="item.amount"
					:currency-id="item.currency_id"
					:currencies="currencies"
				/>
			</template>
			<template #item-paid_at="item">
				<div :class="'text-end text-nowrap ' + (item.paid_at !== null ? 'text-success' : 'text-danger')">
					{{ item.paid_at ? formatDate(item.paid_at, false) : '-' }}
				</div>
			</template>
			<template #item-contract_public_id="item">
				<a href="#" @click.prevent="emitOpenContract(item.contract_id)">{{ item.contract_public_id }}</a>
			</template>
			<template #item-created_at="item">
				<div class="text-end text-nowrap">
					{{ formatDate(item.created_at, false) }}
				</div>
			</template>
			<template #item-deadline="item">
				<div :class="'text-end text-nowrap ' + (item.expired ? 'text-danger' : '')">
					{{ formatDate(item.deadline, false) }}
				</div>
			</template>
		</DataTable>
	</div>
</template>
<script>
import TableActionCell from "../../components/TableActionCell";
import QuoteForm from './QuoteForm.vue'
import {formatDate} from "../../functions";
import Price from "../../components/Price.vue";
import DataTable from "../../components/DataTable.vue";
import QuoteResponseForm from "./QuoteResponseForm.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";

export default {
	components: {
		TableActionDropdown,
		QuoteResponseForm,
		Price,
		TableActionCell,
		QuoteForm,
		DataTable,
	},
	emits: ['formFlash', 'openContract'],
	props: {
		taxes: [Array, Object],
		listUrl: String,
		dataUrl: String,
		activeTabCommand: String,
		activeTabParameter: String,
		invoiceTypeLabels: Object,
		currencies: Object,
		editable: Boolean,
		canRegenerateProformaInvoice: Boolean,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Azonosító',
				value: 'invoice_number',
				sortable: true,
			},
			{
				text: 'Szerződés',
				value: 'contract_public_id',
				sortable: true,
			},
			{
				text: 'Összeg',
				value: 'amount',
				align: 'right',
				sortable: true,
			},
			{
				text: 'Típus',
				value: 'type',
				sortable: true,
			},
			{
				text: 'Fizetés',
				value: 'payment_method',
				sortable: true,
			},
			{
				text: 'Dátum',
				value: 'created_at',
				sortable: true,
			},
			{
				text: 'Határidő',
				value: 'deadline',
				sortable: true,
			},
			{
				text: 'Fizetve',
				value: 'paid_at',
				sortable: true,
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]
		let highlight = null;
		if (location.hash && location.hash.length > 1){
			let tmp = location.hash.substring(1).split('-')
			if (tmp[0] !== undefined && tmp[0] === 'invoices' && tmp[1] !== undefined && tmp[1].indexOf('highlight') > -1) {
				highlight = parseInt(tmp[1].replace('highlight:', ''))
			}
		}

		return {
			highlight: highlight,
			columns: columns,
			formOpen: false,
			quoteFormId: null,
			responseFormOpen: false,
			paymentMethods: {
				wire_transfer: {
					label: 'Átutalás',
					icon: 'far fa-credit-card',
					color: 'light'
				},
				cash: {
					label: 'Készpénz',
					icon: 'far fa-money-bill',
					color: 'dark'
				}
			},
		}
	},
	methods: {
		formatDate: formatDate,
		emitOpenContract: function(id){
			this.$emit('openContract', id);
		},
		regenerateProformaInvoice: function(row){
			let that = this
			axios.post(this.dataUrl + '/' + row.id + '/regenerateProforma').then((response)=>{
				if (response.data.flash !== undefined){
					this.$emit('formFlash', response.data.flash);
				}
				this.$refs.table.getData()
			}).catch(function (error) {
				that.loading = false
				that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
			})
		},
		getRowClass: function (item, rowNumber){
			let classes = []
			if (this.activeTabCommand === 'invoice' && parseInt(this.activeTabParameter) === item.id){
				classes.push('table-info')
			}
			return classes.join(' ')
		}
	}
}
</script>

<style scoped>
</style>