<template>
	<textarea
		v-model="componentValue"
		:class="componentClasses"
		:rows="rows"
		@input="resize"
		:disabled="!editable"
		:readonly="!editable"
		:placeholder="placeholder"
	></textarea>
</template>
<script>
export default {
	emits: ['update:modelValue', 'changed'],
	props: {
		modelValue: String,
		classes: String | Array,
		editable: {
			type: Boolean,
			default: true
		},
		placeholder: {
			type: String,
			default: ''
		},
		rows: {
			type: Number,
			default: 1
		}
	},
	methods: {
		resize(event) {
			event.target.style.height = "auto";
			event.target.style.height = Math.max(41, event.target.scrollHeight) + "px";
		},
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
				this.$emit('changed')
			}
		},
		componentClasses: {
			get() {
				let classes = this.classes instanceof Array ? this.classes : [this.classes]
				classes.push('form-control')

				return classes
			},
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.$el.setAttribute(
				"style",
				"height: " + Math.max(41, this.$el.scrollHeight) + "px"
			);
		});
	},
};
</script>

<style scoped>
textarea {
	resize: none;
}
</style>