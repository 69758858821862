<template>
	<div>
		<form-wrapper
				ref="formWrapper"
				:store-url="storeUrl"
				:back-url="backUrl"
				:initial-flash="initialFlash"
				v-model="attributes"
				v-slot="{ errors }"
		>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
									label="Név"
									name="name"
									rules="required"
									v-model="attributes.name"
									:disabled="!allowEditing"
									:errors="errors"
							/>
							<input-field
									label="Telefonszám"
									name="phone"
									data-type="phone"
									v-model="attributes.phone"
									:disabled="!allowEditing"
									:errors="errors"
							/>
						</div>
						<div class="col-sm-6">
							<input-field
									label="E-mail cím"
									name="email"
									rules="email"
									v-model="attributes.email"
									:disabled="!allowEditing"
									:errors="errors"
							/>
							<input-field
									label="Weboldal"
									name="url"
									rules=""
									v-model="attributes.url"
									:disabled="!allowEditing"
									:errors="errors"
							/>
						</div>
					</div>
					<textarea-field
							label="Leírás"
							name="description"
							v-model="attributes.description"
							:disabled="!allowEditing"
							:errors="errors"
					></textarea-field>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Kapcsolat</h4>
					<table class="table">
						<thead>
						<tr>
							<th>Név</th>
							<th>Beosztás</th>
							<th>Telefon</th>
							<th>E-mail</th>
							<th width="1%">&nbsp;</th>
						</tr>
						</thead>
						<tbody>
						<template v-for="(contact, contactKey) in attributes.contact">
							<tr>
								<td><input type="text" class="form-control" v-model="contact.name" /></td>
								<td><input type="text" class="form-control" v-model="contact.position" /></td>
								<td><input type="text" class="form-control" v-model="contact.phone" /></td>
								<td><input type="text" class="form-control" v-model="contact.email" /></td>
								<td><button type="button" class="btn btn-danger" @click.prevent="removeContact(contactKey)"><i class="far fa-times"></i></button></td>
							</tr>
						</template>
						</tbody>
					</table>

					<div class="mt-3">
						<button type="button" class="btn btn-secondary btn-label" @click.prevent="addContact">
							<i class="far fa-plus label-icon"></i> Hozzáad
						</button>
					</div>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Dokumentumok</h4>
					<FileUploader
							field-name="document"
							v-model="attributes.documents"
							:file-upload-url="fileUploadUrl"
							file-type="document"
							:editable="allowEditing"
					></FileUploader>
				</div>
			</div>
		</form-wrapper>
	</div>
</template>

<script>
import FileUploader from "../../components/FileUploader.vue";
import InputField from "../../components/form/InputField.vue"
import SwitchField from "../../components/form/SwitchField.vue"
import FormWrapper from "../../components/FormWrapper.vue";
import TextareaField from "../../components/form/TextareaField.vue";

export default {
	components: {
		TextareaField,
		InputField,
		SwitchField,
		FormWrapper,
		FileUploader,
	},
	props: ['initialAttributes', 'storeUrl', 'backUrl', 'initialFlash', 'allowEditing', 'fileUploadUrl'],
	data(){
		let attributes = this.initialAttributes
		if (attributes.contact === undefined || attributes.contact === null){
			attributes.contact = []
		}
		return {
			attributes: attributes,
			loading: false,
			flash: []
		}
	},
	mounted() {
	},
	methods: {
		addContact: function(){
			let maxOrder = 0

			this.attributes.contact.forEach(function(item, index){
				if (item.order > maxOrder){
					maxOrder = item.order
				}
			})
			this.attributes.contact.push({
				order: maxOrder + 1,
			})
		},
		removeContact: function(index){
			this.attributes.contact.splice(index, 1);
		},
	}
}
</script>

<style scoped>

</style>