<template>
	<div v-if="currentUserLevel">
		<span :class="badgeClasses" :style="currentUserLevel.color ? 'background:' + currentUserLevel.color + ';' : ''">
			<i :class="'me-2 far fa-' + currentUserLevel.icon" v-if="currentUserLevel.icon"></i><span>{{ short ? currentUserLevel.name_short : currentUserLevel.name }}</span>
		</span>
	</div>
</template>

<script>
export default {
	props: {
		userLevelId: Number,
		userLevels: Array,
		small: Boolean,
		large: Boolean,
		short: Boolean,
	},
	data(){
		return {
			userLevelsById: _.keyBy(this.userLevels, 'id')
		}
	},
	methods: {
	},
	computed: {
		currentUserLevel: {
			get() {
				if (!this.userLevelId){
					return null;
				}
				return this.userLevelsById[this.userLevelId]
			}
		},
		badgeClasses: {
			get() {
				let classes = ['colored-badge']
				if (this.currentUserLevel.color){
					classes.push('colored-badge-' + this.currentUserLevel.luminosity)
				} else {
					classes.push('bg-primary')
				}
				if (this.small){
					classes.push('colored-badge-sm')
				}
				if (this.large){
					classes.push('colored-badge-xl')
				}
				return classes
			}
		},
	},
}
</script>

<style scoped>

</style>