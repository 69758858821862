<template>
	<div :class="{'mb-3': !fieldOnly && !noMargin}">
		<label :class="{required: isRequired}" v-if="!fieldOnly">{{ label }}</label>
		<slot></slot>
		<Field
			v-model="componentValue"
			:name="name"
			:readonly="disabled"
			:disabled="disabled"
			@input="onInput"
			:rules="rulesValue"
			:class="{'form-control': 1, 'is-invalid': errors[name]}">
			<TextareaAutosize
				v-model="componentValue"
				:editable="!disabled"
				:rows="rows"
			></TextareaAutosize>
		</Field>
		<div class="invalid-feedback" v-if="errors[name]">{{ errors[name] }}</div>
		<p class="text-muted mt-2" v-if="info" v-html="info"></p>
	</div>
</template>

<script>
import TextareaAutosize from "../TextareaAutosize.vue";
import { Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
export default {
	components: {Field, ErrorMessage, TextareaAutosize},
	props: {
		rules: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		label: String,
		name: {
			type: String,
			default: ''
		},
		modelValue: [String, Number],
		errors: {
			type: Object,
			default: function(){
				return {}
			}
		},
		info: {
			type: String,
			default: ''
		},
		fieldOnly: Boolean,
		noMargin: Boolean,
		rows: {
			type: Number,
			default: 1
		}
	},
	data() {
		return {
		}

	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		isRequired: {
			get(){
				return this.rules.indexOf('required') > -1
			}
		},
		rulesValue: {
			get() {
				let tmp = this.rules.split('|')
				let rules = yup.string()

				if (tmp.indexOf('required') > -1){
					rules = rules.required()
				} else {
					rules = rules.nullable()
				}
				_.forEach(tmp, function(rule){
					if (rule.indexOf('min:') > -1){
						rule = rule.replace('min:', '')
						rules = rules.min(parseInt(rule))
					}
				})
				rules = rules.label(this.label)

				return rules
			}
		},
	},
	methods: {
		onInput: function(){
			this.$emit('input')
		},
	}
}
</script>

<style scoped>

</style>