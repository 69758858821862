<template>
	<flash-display :flash="flash" ref="notifications"></flash-display>
	<div :class="{'overlay-form-wrapper': 1}">
		<div :class="{'overlay-form-wrapper--content': 1, 'loading': loading}">
			<div class="row">
				<div class="col-md-3">
					<div class="page-title-box">
						<h4 class="mb-sm-0 font-size-18">Projekt adatok</h4>
					</div>
					<ProjectInfoSidebar
						:project-fields="projectFields"
						:project-attributes="projectAttributes"
						:client="client"
						></ProjectInfoSidebar>
				</div>
				<div class="col-md-9">
					<div class="page-title-box">
						<h4 class="mb-sm-0 font-size-18">Ajánlat</h4>
					</div>
					<div class="card">
						<div class="card-body">
							<div class="details-box">
								<div v-if="quote.id !== undefined">
									<i class="far fa-hashtag"></i> Azonosító:
									<strong>{{ quote.public_id }}</strong>
								</div>
								<div v-if="quoteStatusLabels[quote.status] !== undefined">
									<i class="far fa-question"></i> Státusz:
									<span :class="'badge badge-xl bg-' + quoteStatusLabels[quote.status].color"><i :class="quoteStatusLabels[quote.status].icon"></i> {{ quoteStatusLabels[quote.status].name }}</span>
								</div>
								<div v-if="quote.valid_until !== undefined && quote.valid_until">
									<i class="far fa-calendar-check"></i> Érvényesség:
									<strong>{{ formatDate(quote.valid_until, false) }}</strong>
								</div>
								<template v-for="currency in currencies" v-if="quote.currency_values !== undefined">
									<div v-if="quote.currency_values['display_' + currency.id] !== undefined">
										<i class="far fa-dollar-sign"></i> {{ currency.name }} árfolyam:
										<strong>
											<Price
												:value="quote.currency_values['display_' + currency.id]"
												:currencies="currencies"
												:decimals="2"
											/>
										</strong>
									</div>
								</template>
								<template v-if="quote.id !== undefined">
									<div class="break"></div>
									<div v-if="quote.created_at">
										<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(quote.created_at) }}</strong>
									</div>
									<div v-if="quote.created_by_user_name">
										<i class="far fa-user"></i> Létrehozó: <strong>{{ quote.created_by_user_name }}</strong>
									</div>
									<div v-if="quote.updated_at">
										<i class="far fa-calendar-alt"></i> Módosítva: <strong>{{ formatDate(quote.updated_at) }}</strong>
									</div>
									<div class="break"></div>
									<div v-if="quote.approved_at">
										<i class="far fa-calendar-alt"></i> Jóváhagyva: <strong>{{ formatDate(quote.approved_at) }}</strong>
									</div>
									<div v-if="quote.approved_by_user_name">
										<i class="far fa-user"></i> Jóváhagyta: <strong>{{ quote.approved_by_user_name }}</strong>
									</div>
									<div class="break"></div>
									<div v-if="quote.sent_at">
										<i class="far fa-calendar-alt"></i> Elküldve: <strong>{{ formatDate(quote.sent_at) }}</strong>
									</div>
									<div v-if="quote.sent_by_user_name">
										<i class="far fa-user"></i> Küldő: <strong>{{ quote.sent_by_user_name }}</strong>
									</div>
									<div class="break"></div>
									<div v-if="quote.sent_at">
										<i class="far fa-calendar-alt"></i> Elolvasva: <strong>{{ quote.read_at ? formatDate(quote.read_at) : 'nincs' }}</strong>
									</div>
									<div class="break"></div>
									<div v-if="quote.answered_at">
										<i class="far fa-calendar-alt"></i> Megválaszolva: <strong>{{ formatDate(quote.answered_at) }}</strong>
									</div>
									<div v-if="quote.answered_by_user_name">
										<i class="far fa-user"></i> Választ rögzítette: <strong>{{ quote.answered_by_user_name }}</strong>
									</div>
								</template>
							</div>
							<LockMessage :lock="lock" :margin-top="true"></LockMessage>
							<div v-if="quote.files && quote.files.length" class="mt-3">
								<FileList :files="quote.files" title="Aláírt ajánlat" />
							</div>
							<div class="mt-3">
								<InputField
									label="Ajánlat címe"
									name="name"
									v-model="quote.title"
									:disabled="!isEditable"
									:errors="{}"
								/>
							</div>
							<div class="row">
								<div class="col-md-4">
									<InputField
										label="Kedvezmény"
										name="discount"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="quote.discount"
										:disabled="!isEditable || !canAddQuoteDiscountMaterial || !canAddQuoteDiscountWork"
										:errors="{}"
										@input="productChanged"
									/>
								</div>
								<div class="col-md-4">
									<InputField
										label="Kedvezmény anyag"
										name="discount_material"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="quote.discount_material"
										:disabled="!isEditable || !canAddQuoteDiscountMaterial"
										:errors="{}"
										@input="productChanged"
									/>
								</div>
								<div class="col-md-4">
									<InputField
										label="Kedvezmény munka"
										name="discount_work"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="quote.discount_work"
										:disabled="!isEditable || !canAddQuoteDiscountWork"
										:errors="{}"
										@input="productChanged"
									/>
								</div>
							</div>
							<div>
								<label>Megjegyzés/leírás</label>
								<TextareaAutosize
									v-model="quote.description"
									classes="form-control"
									:editable="isEditable"
									:rows="1"
								/>
							</div>
						</div>
					</div>
					<div class="mb-3">
						<div class="d-flex flex-wrap gap-2">
							<button type="submit" class="btn btn-primary btn-label waves-effect" :disabled="!allCategoriesValid" v-if="isEditable" @click.prevent="saveForm">
								<i class="fas fa-save label-icon"></i> Mentés
							</button>
							<button type="submit" class="btn btn-warning btn-label waves-effect" v-if="!editable && canStartEditing" @click.prevent="$emit('startEditing')">
								<i class="fas fa-pencil label-icon"></i> Szerkesztés
							</button>
							<button type="submit" class="btn btn-success btn-label waves-effect" :disabled="!allCategoriesValid" @click.prevent="approveQuote" v-if="canApproveQuote && quote.id && quote.status === 'draft'">
								<i class="fas fa-check label-icon"></i> Jóváhagyás
							</button>
							<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="cloneQuote" v-if="quote.id && editable && canCreate">
								<i class="far fa-clone label-icon"></i> Duplikálás
							</button>
							<a class="btn btn-secondary btn-label waves-effect" v-if="quote.id && quote.status !== 'draft'" :href="dataUrl + '/' + quote.id + '?action=download'">
								<i class="far fa-download label-icon"></i> Letöltés
							</a>
							<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="sendQuote" v-if="quote.status === 'approved' && editable">
								<i class="fas fa-envelope label-icon"></i> Küldés az ügyfélnek
							</button>
							<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
								<i class="fas fa-times label-icon"></i> Bezár
							</button>
						</div>
					</div>

					<ProjectProductList
						:data-url="dataUrl"
						:product-categories="productCategories"
						:products="products"
						:is-editable="isEditable"
						v-model="quoteProducts"
						:parent-model="quote"
						:product-types="productTypes"
						:currencies="currencies"
						:product-versions="quote.product_versions"
						mode="quote"
						ref="productList"
						>

						<div v-if="quote.payment_plan">
							<label>Fizetési konstrukció</label>
							<h5>{{ quote.payment_plan.name }}</h5>
							<p class="text-muted mt-2" v-if="quote.payment_plan.description" v-html="quote.payment_plan.description_html"></p>
						</div>
					</ProjectProductList>

					<div class="card">
						<div class="card-body">
							<h4 class="card-title d-flex align-items-center mb-4">
								Paraméterek

								<button type="button" class="btn btn-primary btn-label btn-sm ms-auto" v-if="isEditable" @click.prevent="addAttribute"><i class="far fa-plus label-icon"></i> Paraméter hozzáadása</button>
							</h4>
							<table class="table">
								<thead>
								<tr>
									<th width="49%">Paraméter</th>
									<th width="49%">Érték</th>
									<th width="2%" v-if="isEditable">&nbsp;</th>
								</tr>
								</thead>
								<tbody>
								<template v-for="(attribute, attributeKey) in quote.quote_attributes">
									<tr>
										<td>
											<input type="text" class="form-control" v-model="attribute.label" v-if="isEditable" />
											<template v-else>
												{{ attribute.label }}
											</template>
										</td>
										<td>
											<input type="text" class="form-control" v-model="attribute.value" v-if="isEditable" />
											<template v-else>
												{{ attribute.value }}
											</template>
										</td>
										<td v-if="isEditable">
											<div class="d-flex gap-2">
												<button type="button" @click.prevent="moveAttribute(attributeKey, 'up')" class="btn btn-secondary mx-1"><i class="far fa-arrow-up"></i></button>
												<button type="button" @click.prevent="moveAttribute(attributeKey, 'down')" class="btn btn-secondary mx-1"><i class="far fa-arrow-down"></i></button>
												<button type="button" @click.prevent="removeAttribute(attributeKey)" class="btn btn-danger mx-1"><i class="far fa-times"></i></button>
											</div>
										</td>
									</tr>
								</template>
								</tbody>
							</table>
						</div>
					</div>

					<div class="mt-3">
						<div class="d-flex flex-wrap gap-2">
							<button type="submit" class="btn btn-primary btn-label waves-effect" :disabled="!allCategoriesValid" v-if="isEditable" @click.prevent="saveForm">
								<i class="fas fa-save label-icon"></i> Mentés
							</button>
							<button type="submit" class="btn btn-warning btn-label waves-effect" v-if="!editable && canStartEditing" @click.prevent="$emit('startEditing')">
								<i class="fas fa-pencil label-icon"></i> Szerkesztés
							</button>
							<button type="submit" class="btn btn-success btn-label waves-effect" :disabled="!allCategoriesValid" @click.prevent="approveQuote" v-if="canApproveQuote && quote.id && quote.status === 'draft'">
								<i class="fas fa-check label-icon"></i> Jóváhagyás
							</button>
							<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="cloneQuote" v-if="quote.id && editable && canCreate">
								<i class="far fa-clone label-icon"></i> Duplikálás
							</button>
							<a class="btn btn-secondary btn-label waves-effect" v-if="quote.id && quote.status !== 'draft'" :href="dataUrl + '/' + quote.id + '?action=download'">
								<i class="far fa-download label-icon"></i> Letöltés
							</a>
							<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="sendQuote" v-if="quote.status === 'approved' && editable">
								<i class="fas fa-envelope label-icon"></i> Küldés az ügyfélnek
							</button>
							<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
								<i class="fas fa-times label-icon"></i> Bezár
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PhoneNumber from '../../components/PhoneNumber.vue';
import EmailAddress from '../../components/EmailAddress.vue';
import Price from '../../components/Price.vue';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import TextareaAutosize from "../../components/TextareaAutosize.vue";
import InputField from "../../components/form/InputField.vue";
import {formatDate, moveValueInArray} from "../../functions";
import FlashDisplay from "../../components/FlashDisplay.vue";
import File from "../../components/File.vue";
import FileList from "../../components/FileList.vue";
import ProjectProductList from "./ProjectProductList.vue";
import ProjectInfoSidebar from "./ProjectInfoSidebar.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import LockMessage from "../../components/LockMessage.vue";
export default {
	emits: ['close', 'closeAndReload', 'approve', 'send', 'clone', 'updateStats', 'startEditing'],
	components: {
		LockMessage,
		TableActionDropdown,
		ProjectInfoSidebar,
		ProjectProductList,
		FileList, File, InputField, PhoneNumber, EmailAddress, vSelect, Price, TextareaAutosize, FlashDisplay},
	props: {
		projectAttributes: Object,
		projectFields: Array,
		products: Array,
		productCategories: [Array, Object],
		productTypes: Object,
		currencies: Object,
		lock: Object,
		taxes: Object,
		editable: Boolean,
		canApproveQuote: Boolean,
		canStartEditing: Boolean,
		client: Object,
		dataUrl: String,
		quoteStatusLabels: Object,
		canAddQuoteDiscountWork: Boolean,
		canAddQuoteDiscountMaterial: Boolean,
		canCreate: Boolean,
		canUpdate: Boolean,
		canDelete: Boolean,
		quoteId: {
			type: [Number, String],
			default: 0
		}
	},
	mounted() {
		this.loadData()
	},
	data() {
		return {
			quote: {},
			quoteProducts: [],
			flash: [],
			loading: false,
		};
	},
	computed: {
		productsById: {
			get(){
				return _.keyBy(this.products, 'id')
			}
		},
		allCategoriesValid: {
			get(){
				return this.$refs.productList.allCategoriesValid()
			}
		},
		isEditable: {
			get(){
				if (!this.editable){
					return false
				}
				return this.quote.editable
			}
		}
	},
	methods: {
		formatDate: formatDate,
		moveValueInArray: moveValueInArray,
		loadData: function(){
			this.loading = true

			let url = this.dataUrl
			if (this.quoteId){
				url += '/' + this.quoteId
			}
			let that = this
			axios.get(url + '?action=load').then((response)=>{
				this.handleResponse(response)
			}).catch(function (error) {
				that.loading = false
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.status === undefined || response.data.status === 'success') {
				this.quote = response.data.quote
				this.quoteProducts = this.quote.products_pivot

				this.$refs.productList.setProductPrices(response.data.prices)

				if (response.data.status !== undefined && response.data.status === 'success') {
					this.$emit('closeAndReload')
				}
			}
			if (response.data.stats !== undefined){
				this.$emit('updateStats', response.data.stats)
			}
			if (response.data.flash !== undefined){
				//this.$emit('flash', response.data.flash)
				this.flash = response.data.flash
			}
		},
		approveQuote: function(){
			this.loading = true
			this.$emit('approve', this.quoteId);
		},
		sendQuote: function(){
			this.loading = true
			this.$emit('send', this.quoteId);
		},
		cloneQuote: function(){
			this.loading = true
			this.$emit('clone', this.quoteId);
		},
		emitClose: function(){
			this.$emit('close')
		},
		saveForm: function(e){
			if (this.allCategoriesValid){
				this.loading = true

				let payload = {
					quote: this.quote,
					products: this.$refs.productList.getFilteredProducts()
				}

				let url = this.dataUrl
				if (this.quoteId){
					url += '/' + this.quoteId
				}
				let that = this
				axios.post(url + '?action=save', payload).then((response)=>{
					this.handleResponse(response)
				}).catch(function (error) {
					that.flash = [{level: 'danger', message: error.response.data.message}]
				})
			}
		},
		productChanged: function(){
			this.$refs.productList.productChanged()
		},
		moveAttribute: function(key, dir){
			this.quote.quote_attributes = this.moveValueInArray(this.quote.quote_attributes, key, dir)
		},
		removeAttribute: function(key){
			this.quote.quote_attributes.splice(key, 1)
		},
		addAttribute: function(){
			this.quote.quote_attributes.push({
				label: '',
				value: ''
			})
		},
	}
}
</script>

<style scoped>

</style>