<template>
	<div class="fw-bold">{{ data.clientName }}</div>
	<div class="text-muted" v-if="data.addressZip">{{ address }}</div>
</template>

<script>
export default {
	props: {
		data: {}
	},
	computed: {
		address: {
			get(){
				let string = this.data.addressZip + ' ' + this.data.addressCity + ', ' + this.data.addressStreet + ' ' + this.data.addressHouseNumber
				if (this.data.addressFloor !== null && this.data.addressFloor.length){
					string += ', ' + this.data.addressFloor
				}
				if (this.data.addressDoor !== null && this.data.addressDoor.length){
					string += ', ' + this.data.addressDoor
				}

				return string
			}
		}
	}
}
</script>

<style scoped>

</style>