<template>
	<div :class="{'mb-3': !fieldOnly && !(inline || inlineReversed), 'd-flex': (inline || inlineReversed), 'gap-2': (inline || inlineReversed), 'align-items-center': (inline || inlineReversed)}">
		<label v-if="!fieldOnly && !inlineReversed" :class="{'mb-0': inline}">{{ label }}</label>
		<div class="switch-wrapper">
			<input
				type="checkbox"
				:id="componentId"
				switch="bool"
				v-model="componentValue"
				value="1"
				:readonly="disabled"
				:disabled="disabled"
			>
			<label :for="componentId" :class="{disabled: disabled}" data-on-label="" data-off-label=""></label>
		</div>
		<label v-if="!fieldOnly && inlineReversed" class="mb-0 ms-3">{{ label }}</label>
		<p class="text-muted mt-2" v-if="info" v-html="info"></p>
	</div>
</template>

<script>
export default {
	emits: ['update:modelValue', 'input'],
	props: {
		rules: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		inline: {
			type: Boolean,
			default: false
		},
		inlineReversed: Boolean,
		label: String,
		name: String,
		modelValue: Number|Boolean,
		info: {
			type: String,
			default: ''
		},
		fieldOnly: Boolean,
		invert: Boolean,
	},
	computed: {
		componentValue: {
			get() {
				return this.invert ? !this.modelValue : !!this.modelValue
			},
			set(value) {
				if (this.invert){
					value = value === true ? 0 : 1
				} else {
					value = value === true ? 1 : 0
				}
				this.$emit('update:modelValue', value)
				this.$emit('input')
			}
		},
		componentId: {
			get() {
				return this.name + '_checkbox'
			}
		},
	},
}
</script>

<style scoped>

</style>