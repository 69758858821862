<template>
	<div class="card-body">
		<h4 class="card-title mb-3">Termékkategóriák</h4>
		<table class="table">
			<thead>
			<tr>
				<th width="10%">Árajánlat nélkül</th>
				<th width="10%">Árajánlattal</th>
				<th width="10%">Kötelező</th>
				<th>Kategória</th>
				<th width="20%">Min termék</th>
				<th width="20%">Min termék fajta</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="category in productCategories">
				<td>
					<div>
						<input
							type="checkbox"
							:id="'productCategory_without_quote_' + category.id"
							switch="bool"
							v-model="componentValue[category.id].without_quote"
							value="1"
						>
						<label :for="'productCategory_without_quote_' + category.id" data-on-label="" data-off-label=""></label>
					</div>
				</td>
				<td>
					<div>
						<input
							type="checkbox"
							:id="'productCategory_with_quote_' + category.id"
							switch="bool"
							v-model="componentValue[category.id].with_quote"
							value="1"
						>
						<label :for="'productCategory_with_quote_' + category.id" data-on-label="" data-off-label=""></label>
					</div>
				</td>
				<td>
					<div v-if="componentValue[category.id].with_quote">
						<input
							type="checkbox"
							:id="'productCategory_required_' + category.id"
							switch="bool"
							v-model="componentValue[category.id].required"
							value="1"
						>
						<label :for="'productCategory_required_' + category.id" data-on-label="" data-off-label=""></label>
					</div>
				</td>
				<td>
					{{ category.name }}
				</td>
				<td>
					<input v-if="componentValue[category.id].required" type="text" class="form-control" v-model="componentValue[category.id].min_product_quantity" />
				</td>
				<td>
					<input v-if="componentValue[category.id].required" type="text" class="form-control" v-model="componentValue[category.id].min_product_type" />
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import SwitchField from "../../components/form/SwitchField.vue";
export default {
	emits: ['update:modelValue'],
	components: {
		SwitchField,
	},
	props: {
		modelValue: Object,
		productCategories: Array,
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit('update:modelValue', val)
			}
		},
	},
	data(){
		return {
		}
	},
	methods: {
	}
}
</script>

<style scoped>

</style>