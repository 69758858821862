<template>
	<form-wrapper
			ref="addProductForm"
			:initial-flash="[]"
			v-model="componentValue"
			:submit-function="formSubmitted"
			:show-buttons="false"
			v-slot="{ errors }"
	>
		<radio-field
				label="Típus"
				v-model="componentValue.type"
				:values="productTypes"
		/>

		<input-field
				label="Név"
				name="name"
				rules="required"
				v-model="componentValue.name"
				:errors="errors"
		/>

		<input-field
				label="Cikkszám"
				name="sku"
				rules="required|alpha_num"
				v-model="componentValue.sku"
				:errors="errors"
		/>
		<select-field
				label="Kategória"
				name="product_category_id"
				rules="required"
				v-model="componentValue.product_category_id"
				:options="productCategories"
				:errors="errors"
		/>

		<select-field
				label="Gyártó"
				name="product_manufacturer_id"
				v-model="componentValue.product_manufacturer_id"
				:options="productManufacturers"
				:errors="errors"
		/>
		<input-field
				label="Gyártói azonosító"
				name="manufacturer_code"
				rules=""
				v-model="componentValue.manufacturer_code"
				:errors="errors"
				v-if="componentValue.type === 'material'"
		/>

		<input-field
				label="Mennyiségi egység"
				name="unit"
				v-model="componentValue.unit"
				:errors="errors"
		/>

		<input-field
				label="Teljesítmény"
				name="power"
				data-type="number"
				v-model="componentValue.power"
				:suffix="componentValue.power_unit"
				:errors="errors"
				v-if="componentValue.type === 'material'"
		/>

		<input-field
				label="Teljesítmény mértékegység"
				name="power_unit"
				v-model="componentValue.power_unit"
				:errors="errors"
				v-if="componentValue.type === 'material'"
		/>
		<template v-if="componentValue.type === 'material' || componentValue.type === 'equipment'">
			<switch-field
					label="Sorozatszám követése"
					name="track_serial_number"
					v-model="componentValue.track_serial_number"
			/>
		</template>

		<switch-field
				label="Kiszállítást igényel"
				name="requires_delivery"
				v-model="componentValue.requires_delivery"
				v-if="componentValue.type === 'material'"
		/>
		<switch-field
				label="Opcionális"
				name="is_optional"
				v-model="componentValue.is_optional"
                v-if="componentValue.type !== 'equipment'"
		/>
	</form-wrapper>
</template>

<script>
import SelectField from "../../components/form/SelectField.vue";
import SwitchField from "../../components/form/SwitchField.vue";
import RadioField from "../../components/form/RadioField.vue";
import InputField from "../../components/form/InputField.vue";
import FormWrapper from "../../components/FormWrapper.vue";

export default {
	emits: ['update:modelValue', 'formSubmitted'],
	components: {FormWrapper, InputField, RadioField, SwitchField, SelectField},
	props: {
		modelValue: Object,
		productTypes: Object,
		productCategories: Array,
		productManufacturers: Array,
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	methods: {
		formSubmitted: function(){
			this.$emit('formSubmitted')
		},
		validate: function (){
			return this.$refs.addProductForm.validate()
		},
		setFlash: function (flash){
			this.$refs.addProductForm.addFlash(flash)
		}
	}
}
</script>

<style scoped>

</style>
