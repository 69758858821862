<template>
	<flash-display :flash="flash" ref="notifications"></flash-display>
	<div class="row mt-4">
		<div class="col-md-4">
			<ul class="list-group document-types-list-group">
				<template v-for="category in categories">
					<a href="#"
					   :key="category.id"
					   v-if="category.can_access"
					   :class="{'list-group-item': 1, 'list-group-item-action': 1, 'list-group-item-action-active': activeCategory === category.id}"
					   @click.prevent="getData(category.id)">
						<span class="list-group-item-border" :style="'background:' + (category.color || '#eff2f7') + ';'"></span>
						<i :class="'me-2 far fa-' + category.icon" v-if="category.icon"></i>{{ category.name }}
					</a>
				</template>
			</ul>
		</div>
		<div class="col-md-8">
			<div v-if="editCategoryUrl" class="mb-4 d-flex gap-2">
				<a :href="editCategoryUrl + '/' + activeCategory" class="btn btn-primary btn-label waves-effect"><i class="far fa-edit label-icon"></i> Kategória szerkesztése</a>
				<ConfirmButton
					:url="deleteCategoryUrl + '/' + activeCategory"
					text="Kategória törlése"
					:small="false"
					></ConfirmButton>
			</div>
			<div :class="{'loading': loading}">
				<div class="alert alert-info" v-if="!files.length">Nincs feltöltve file</div>
				<div class="card shadow-none border document" v-for="file in files">
					<div class="card-body p-3">
						<div class="d-flex align-items-center">
							<div class="me-4">
								<FileIcon :filename="file.file.extension" font-size="30px"></FileIcon>
							</div>
							<div>
								<a :href="file.file.download_url" target="_blank" class="fw-bold font-size-18">{{ file.name }}</a>
								<div class="d-flex gap-2">
									<div><i class="far fa-hashtag"></i> {{ file.public_id }}</div>
									<div class="file-size"><i class="far fa-file"></i> {{ formatFileSize(file.file.size) }}</div>
									<div v-if="file.width" class="file-resolution"><i class="far fa-image"></i> {{file.file.width}} x {{file.file.height}} px</div>
									<div><i class="far fa-calendar-alt"></i> {{formatDate(file.file.created_at)}}</div>
								</div>
							</div>
							<div v-if="editUrl || deleteUrl" class="ms-auto d-flex gap-2">
								<a :href="editUrl + '/' + file.id" class="btn btn-sm btn-primary" v-if="editUrl"><i class="far fa-edit"></i></a>
								<ConfirmButton
									v-if="deleteUrl"
									:url="deleteUrl + '/' + file.id"
								></ConfirmButton>
							</div>
						</div>
						<div v-if="file.description" class="mt-3" v-html="file.description_html"></div>
					</div>
				</div>
				<div class="mt-4">
					<nav>
						<ul class="pagination justify-content-center">
							<template v-for="link in paginationLinks">
								<li :class="{'page-item': 1, 'active': link.active}" v-if="link.url !== null">
									<a class="page-link" href="#" @click.prevent="getData(null, link.url)" v-html="link.label"></a>
								</li>
								<li class="page-item disabled" v-else>
									<a class="page-link" v-html="link.label"></a>
								</li>
							</template>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FileIcon from "../../components/FileIcon.vue";
import {formatDate, formatFileSize} from "../../functions";
import ConfirmButton from "../../components/ConfirmButton.vue";
import FlashDisplay from "../../components/FlashDisplay.vue";
export default {
	components: {FileIcon, ConfirmButton, FlashDisplay},
	props: {
		dataUrl: String,
		editUrl: String,
		deleteUrl: String,
		categories: Array,
		editCategoryUrl: String,
		deleteCategoryUrl: String,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data() {
		return {
			flash: [],
			activeCategory: this.categories.length ? this.categories[0].id : 0,
			loading: false,
			files: [],
			paginationLinks: []
		}
	},
	mounted() {
		if (this.activeCategory){
			this.getData(this.activeCategory)
		}
		this.flash = this.initialFlash

		this.$refs.notifications.update()
	},
	methods: {
		formatDate: formatDate,
		formatFileSize: formatFileSize,
		getData: function (categoryId, url){
			this.loading = true
			if (url !== undefined){
				axios.get(url).then((response) => {
					this.handleResponse(response)
				}).catch(function (error) {
				})
			} else {
				this.activeCategory = categoryId

				axios.get(this.dataUrl + '/' + this.activeCategory).then((response) => {
					this.handleResponse(response)
				}).catch(function (error) {
				})
			}
		},
		handleResponse: function(response){
			this.files = response.data.data
			this.paginationLinks = response.data.meta.links
			this.loading = false
		}
	}
}
</script>

<style scoped>

</style>