<template>
	<div>
		<teleport to="body" v-if="show">
			<div class="modal fade d-block show modal-top" id="vueModal" tabindex="-1" aria-hidden="true">
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h1 class="modal-title fs-5">{{ title }}</h1>
						</div>
						<div class="modal-body" v-html="content"></div>
						<div class="modal-footer">
							<button type="button" :class="noButtonClasses" v-on:click.prevent="noClicked"><i :class="noButtonIconClasses" v-if="noButtonIcon"></i>{{ noButtonText }}</button>
							<button type="button" :class="yesButtonClasses" v-on:click.prevent="yesClicked"><i :class="yesButtonIconClasses" v-if="yesButtonIcon"></i>{{ yesButtonText }}</button>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-backdrop modal-backdrop-top fade show"></div>
		</teleport>
	</div>
</template>

<script>

export default {
	name: "ConfirmModal",
	emits: ['no', 'yes'],
	props: {
		title: {
			default: ''
		},
		content: {
			default: ''
		},
		yesButtonText: {
			default: 'Igen'
		},
		yesButtonClass: {
			default: 'danger'
		},
		yesButtonIcon: {
			default: 'fas fa-check'
		},
		noButtonText: {
			default: 'Nem'
		},
		noButtonClass: {
			default: 'secondary'
		},
		noButtonIcon: {
			default: 'fas fa-times'
		},
	},
	data() {
		return {
			yesButtonClasses: 'btn btn-' + this.yesButtonClass,
			noButtonClasses: 'btn btn-' + this.noButtonClass,
			yesButtonIconClasses: this.yesButtonIcon ? 'me-2 ' + this.yesButtonIcon : '',
			noButtonIconClasses: this.noButtonIcon ? 'me-2 ' + this.noButtonIcon : '',
			bootstrapModal: null,
			show: true
		}
	},
	mounted() {
	},
	methods: {
		yesClicked: function(){
			this.$emit('yes', 1)
			this.show = false
		},
		noClicked: function(){
			this.$emit('no', 0)
			this.show = false
		},
	}
}
</script>

<style scoped>

</style>