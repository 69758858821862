<template>
	<div>
		<form-wrapper
			ref="formWrapper"
			:store-url="storeUrl"
			:back-url="backUrl"
			:initial-flash="initialFlash"
			v-model="attributes"
		>
			<template v-slot:top>
				<div class="card mb-3">
					<div class="card-body">
						<div class="mb-4 details-box">
							<div v-if="attributes.updated_at">
								<i class="far fa-calendar-alt"></i> Módosítva: <strong>{{ formatDate(attributes.updated_at) }}</strong>
							</div>
							<div v-if="attributes.updated_by_user_name">
								<i class="far fa-user"></i> Módosító: <strong>{{ attributes.updated_by_user_name }}</strong>
							</div>
						</div>
						<div class="details-box details-box--md">
							<h4>Változók</h4>
							<template v-for="(label, key) in attributes.variables">
								<div>
									<strong>{{ '{' + key + '}' }}</strong> - {{ label }}
									<CopyToClipboard :value="'{' + key + '}'" />
								</div>
							</template>
						</div>
					</div>
				</div>
			</template>
			<template #default="{activeTab, errors}">
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="card-title mb-4">Tartalom</h4>
						<div id="editor"></div>
						<div class="d-none">
							<textarea name="content" v-model="attributes.content"></textarea>
						</div>
					</div>
				</div>
				<template v-for="(fileTypeDetails, fileType) in attributes.file_types">
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-3">{{ fileTypeDetails.name }}</h4>
							<FileUploader
								:field-name="fileType"
								v-model="attributes[fileType]"
								:file-upload-url="fileUploadUrl"
								:editable="allowEditing"
								:multiple="fileTypeDetails.multiple"
							></FileUploader>
						</div>
					</div>
				</template>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import SwitchField from "../../components/form/SwitchField.vue"
import {formatDate} from "../../functions";
import Ckeditor from '../../ckeditor/build/ckeditor'
import FileUploader from "../../components/FileUploader.vue";
import FormWrapper from "../../components/FormWrapper.vue";
import CopyToClipboard from "../../components/CopyToClipboard.vue";
export default {
	props: ['initialAttributes', 'initialFiles', 'storeUrl', 'backUrl', 'initialFlash', 'allowEditing', 'fileUploadUrl'],
	components: {InputField, SwitchField, FileUploader, FormWrapper, CopyToClipboard},
	data(){
		let attributes = this.initialAttributes
		for (let i in this.initialFiles){
			attributes[i] = this.initialFiles[i]
		}
		return {
			attributes: attributes,
			loading: false,
			flash: [],
			clipboardCopyTimer: null,
			copiedToClipboard: ''
		}
	},
	mounted() {
		Ckeditor
			.create( document.querySelector( '#editor' ), {
			})
			.then( editor => {
				editor.setData(this.attributes.content)
				let that = this

				editor.model.document.on('change:data', function(e){
					that.attributes.content = editor.getData()
				});
			} )
			.catch( error => {
			} );
	},
	methods: {
		formatDate: formatDate,
	}
}
</script>

<style scoped>
</style>