<template>
	<teleport to="body" v-if="lightboxImage">
		<div class="lightbox" v-if="lightboxImage" @click.prevent="lightboxImage = ''">
			<div class="lightbox-image">
				<img :src="lightboxImage" class="img-responsive" />
			</div>
			<button class="btn btn-secondary btn-label" @click.prevent="lightboxImage = ''">
				<i class="far fa-times label-icon"></i> Bezár
			</button>
		</div>
	</teleport>
	<div class="file-upload-wrapper">
		<div class="text-center mb-4" v-if="multiple">
			Húzz ide fileokat a feltöltéshez, vagy kattints a gombra!
		</div>
		<div class="text-center mb-4" v-else>
			Húzz ide filet a feltöltéshez, vagy kattints a gombra!
		</div>

		<VueUploadComponent
			class="btn btn-primary"
			:post-action="fileUploadUrl"
			:extensions="allowedExtensions"
			:multiple="multiple"
			:size="1024*1024*64"
			:thread="1"
			:headers="uploadHeaders"
			:drop="true"
			:accept="allowedMimeTypes"
			v-model="uploadingFiles"
			@input-filter="uploadInputFilter"
			@input-file="uploadInputFile"
			:ref="'upload_' + fieldName"
			:input-id="'upload_' + fieldName">
			<i class="fa fa-upload"></i>
			Feltöltés
		</VueUploadComponent>
	</div>
	<div class="alert alert-info" v-if="!componentValue.length && !uploadingFiles.length && !uploadedFiles.length">Nincs feltöltve dokumentum.</div>
	<template v-if="errors.length">
		<div class="alert alert-danger mb-3" v-for="error in errors">{{ error }}</div>
	</template>
	<table class="table" v-if="componentValue.length || uploadingFiles.length || uploadedFiles.length">
		<thead>
		<tr>
			<th width="1%">Előnézet</th>
			<th>Adatok</th>
			<th v-if="allowDescription">Leírás</th>
			<th v-if="allowRemove && editable" width="1%"></th>
		</tr>
		</thead>
		<tbody>
		<tr v-for="(file, index) in uploadingFiles" :key="file.id">
			<td>
				<img class="td-image-thumb img-responsive" v-if="file.thumb" :src="file.thumb" @click="lightboxImage = file.thumb" />
				<div class="file-icon" v-else>
					<i :class="getFileIconClass(file.file.name)"></i>
				</div>
			</td>
			<td>
				<div class="filename fw-bold">
					{{file.name}}
				</div>
				<div class="progress mt-1 mb-1" v-if="file.active || file.progress !== '0.00'">
					<div :class="{'progress-bar': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
				</div>
				<div class="d-flex gap-2">
					<div class="file-size"><i class="far fa-file"></i> {{ formatFileSize(file.size)}}</div>
					<div v-if="file.width" class="file-resolution"><i class="far fa-image"></i> {{file.width}} x {{file.height}} px</div>
				</div>
			</td>
			<td v-if="allowDescription">&nbsp;</td>
			<td v-if="allowRemove && editable">&nbsp;</td>
		</tr>
		<tr v-for="(file, index) in uploadedFiles" :key="file.id">
			<td>
				<img class="td-image-thumb img-responsive" v-if="file.thumb" :src="file.thumb" @click="lightboxImage = file.thumb" />
				<div class="file-icon" v-else>
					<i :class="getFileIconClass(file.file.name)"></i>
				</div>
			</td>
			<td>
				<div class="filename fw-bold">
					{{file.name}}
				</div>
				<div class="progress mt-1 mb-1" v-if="file.active || file.progress !== '0.00'">
					<div :class="{'progress-bar': true, 'bg-success': 1}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
				</div>
				<div class="d-flex gap-2">
					<div class="file-size"><i class="far fa-file"></i> {{ formatFileSize(file.size)}}</div>
					<div v-if="file.width" class="file-resolution"><i class="far fa-image"></i> {{file.width}} x {{file.height}} px</div>
				</div>
			</td>
			<td v-if="allowDescription">
				<TextareaAutosize
					v-model="file.description"
					:classes="{'form-control': 1}"
					:rows="1"
				></TextareaAutosize>
			</td>
			<td v-if="allowDescription && editable">
			</td>
		</tr>
		<tr v-for="(file, index) in componentValue" :key="file.id">
			<td>
				<img class="td-image-thumb img-responsive" v-if="file.thumbnail" :src="file.thumbnail" @click="lightboxImage = file.download_url" />
				<div class="file-icon" v-else>
					<i :class="getFileIconClass(file.name, file)"></i>
				</div>
			</td>
			<td>
				<div class="filename fw-bold">
					<a :href="file.download_url" target="_blank" :title="file.name + '.' + file.extension" v-if="file.download_url"><i class="far fa-download"></i> {{file.name}}</a>
					<span v-else>{{file.name}}</span>
				</div>
				<div class="d-flex gap-2">
					<div class="file-size"><i class="far fa-file"></i> {{ formatFileSize(file.size) }}</div>
					<div v-if="file.width" class="file-resolution"><i class="far fa-image"></i> {{file.width}} x {{file.height}} px</div>
				</div>
				<div v-if="file.created_at"><i class="far fa-calendar-alt"></i> {{formatDate(file.created_at)}}</div>
				<div v-if="file.created_by_user_name"><i class="far fa-user"></i> {{file.created_by_user_name}}</div>
			</td>
			<td v-if="allowDescription">
				<div v-if="!editable" v-html="file.description_html"></div>
				<TextareaAutosize
					v-model="file.description"
					:classes="{'form-control': 1}"
					:rows="1"
					v-else
				></TextareaAutosize>
			</td>
			<td v-if="allowRemove && editable">
				<button type="button" class="btn btn-danger" @click.prevent="removeFile(index)"><i class="far fa-times"></i></button>
			</td>
		</tr>
		</tbody>
	</table>
</template>

<script>
import {formatFileSize, formatDate} from "../functions";
import TextareaAutosize from '../components/TextareaAutosize.vue'
const VueUploadComponent = require('vue-upload-component')
export default {
	components: {
		TextareaAutosize, VueUploadComponent
	},
    props: {
		url: String,
		fieldName: String,
		fileType: {
			type: String,
			default: ''
		},
		fileExtensions: {
			type: String,
			default: ''
		},
		fileMimeTypes: {
			type: String,
			default: ''
		},
		compact: {
			type: Boolean,
			default: false
		},
		allowRemove: {
			type: Boolean,
			default: true
		},
		editable: {
			type: Boolean,
			default: true
		},
		multiple: {
			type: Boolean,
			default: true
		},
		existingFiles: {
			type: Array,
			default: function(){
				return []
			}
		},
		modelValue: {
			type: Array,
			default: function(){
				return []
			}
		},
		fileUploadUrl: String,
		allowDescription: Boolean
	},
    data () {
        return {
			uploadedFileIdArray: this.value,
			uploadingFiles: [],
			uploadedFiles: [],
			uploadHeaders: {'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')},
			lightboxImage: '',
			errors: []
        }
    },
    mounted() {
    },
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		allowedExtensions: {
			get(){
				if (this.fileType === 'image'){
					return ['jpg', 'jpeg', 'png']
				}
				if (this.fileExtensions.length){
					return this.fileExtensions
				}

				return ''
			}
		},
		allowedMimeTypes: {
			get(){
				if (this.fileType === 'image'){
					return 'image/png,image/jpeg'
				}
				if (this.fileMimeTypes.length){
					return this.fileMimeTypes
				}

				return ''
			}
		},
	},
    methods: {
		formatFileSize: formatFileSize,
		formatDate: formatDate,
		uploadInputFilter: function(newFile, oldFile, prevent){
			if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
				newFile.blob = ''
				let URL = (window.URL || window.webkitURL)
				if (URL) {
					newFile.blob = URL.createObjectURL(newFile.file)
				}
				newFile.thumb = ''
				if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
					newFile.thumb = newFile.blob
				}
			}

			if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
				newFile.error = 'image parsing'
				let img = new Image();
				img.onload = () => {
					this.$refs['upload_' + this.fieldName].update(newFile, {error: '', height: img.height, width: img.width})
				}
				img.onerror = (e) => {
					this.$refs['upload_' + this.fieldName].update(newFile, { error: 'parsing image size'})
				}
				img.src = newFile.blob
			}
			if (newFile){
				this.errors = []
			}
		},
		uploadInputFile: function(newFile, oldFile){
			if (newFile !== undefined && newFile.success){
				if (newFile.response.status === 'error'){
					this.errors.push('Sikertelen feltöltés: ' + newFile.file.name)
					for (let i = 0; i < this.uploadingFiles.length; i++){
						if (this.uploadingFiles[i].response.status === 'error'){
							this.uploadingFiles.splice(i, 1)
						}
					}
				} else {
					this.$refs['upload_' + this.fieldName].remove(newFile)
					newFile.fileId = newFile.response.file.id
					this.uploadedFiles.push(newFile.response.file)

					if (this.multiple) {
						this.$emit('update:modelValue', _.union(_.values(this.modelValue), _.values(this.uploadedFiles)))
					} else {
						this.$emit('update:modelValue', this.uploadedFiles)
					}
				}
				this.uploadedFiles = []
			} else {
				if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
					if (!this.$refs['upload_' + this.fieldName].active) {
						this.$refs['upload_' + this.fieldName].active = true
					}
				}
			}
		},
		removeFile: function (index){
			let tmp = this.componentValue
			tmp.splice(index, 1)

			this.$emit('update:modelValue', tmp)
		},
		getFileIconClass: function(extension, f){
			if (extension.indexOf('.')){
				let tmp = extension.split('.')
				extension = tmp.pop()
			}
			const icons = {
				image: 'fa-file-image',
				pdf: 'fa-file-pdf',
				word: 'fa-file-word',
				powerpoint: 'fa-file-powerpoint',
				excel: 'fa-file-excel',
				csv: 'fa-file-csv',
				audio: 'fa-file-audio',
				video: 'fa-file-video',
				archive: 'fa-file-archive',
				code: 'fa-file-code',
				text: 'fa-file-alt',
				file: 'fa-file'
			}
			const extensions = {
				gif: icons.image,
				jpeg: icons.image,
				jpg: icons.image,
				png: icons.image,

				pdf: icons.pdf,

				doc: icons.word,
				docx: icons.word,

				ppt: icons.powerpoint,
				pptx: icons.powerpoint,

				xls: icons.excel,
				xlsx: icons.excel,

				csv: icons.csv,

				aac: icons.audio,
				mp3: icons.audio,
				ogg: icons.audio,

				avi: icons.video,
				flv: icons.video,
				mkv: icons.video,
				mp4: icons.video,

				gz: icons.archive,
				zip: icons.archive,

				css: icons.code,
				html: icons.code,
				js: icons.code,

				txt: icons.text
			}

			if (extensions[extension] !== undefined){
				return 'far ' + extensions[extension]
			}
			return 'far fa-file'
		}
    }
}
</script>

<style scoped>

</style>
