<template>
	<DataTable
			:data-url="dataUrl"
			:initial-flash="initialFlash"
			:columns="columns"
			sort-by="created_at"
			sort-type="desc"
			name="installations"
	>
		<template #filters="{serverOptions}">
			<div class="col-12 col-md-3">
				<v-select
						v-model="serverOptions.project_type_id"
						:options="projectTypes"
						:reduce="option => option.id"
						placeholder="Típus"
						label="name"
						:multiple="false"
						:clearable="true"
				>
					<template v-slot:option="option">
						<span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
					</template>
					<template v-slot:selected-option="option">
						<span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
					</template>
				</v-select>
			</div>
		</template>
		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
					:data="item"
					:edit-url="editUrl"
					:delete-url="''"
					:is-editable="isEditable"
					:is-deletable="false"
					v-slot="{ row }"
			>
			</TableActionCell>
		</template>
		<template #item-closed_at="item">
			<template v-if="item.closed_at">
				<span class="badge bg-success badge-xl"><i class="far fa-check me-2"></i> Lezárva</span>
			</template>
			<template v-else>
				<span class="badge bg-warning badge-xl"><i class="far fa-question me-2"></i> Függőben</span>
			</template>
		</template>
		<template #item-project="item">
			<div class="fw-bold">{{ item.public_id }}</div>
			<div v-if="item.handlerUserName"><i class="far fa-user"></i> {{ item.handlerUserName }}</div>
			<div><i class="far fa-clock"></i> {{ formatDate(item.created_at) }}</div>
		</template>
		<template #item-client="item">
			<div class="fw-bold">{{ item.clientName }}</div>
			<div class="fw-muted">{{ item.address }}</div>
		</template>
		<template #item-created_at="item">
			{{ formatDate(item.created_at) }}
		</template>
		<template #item-project_type_id="item">
			<ProjectTypeCell
					:data="item"
					:project-types="projectTypes"
			></ProjectTypeCell>
		</template>
		<template #item-delivered_products="item">
			<span :class="{
				'badge': 1,
				'badge-xl': 1,
				'bg-success': parseInt(item.contractQuantity) <= parseInt(item.deliveredQuantity),
				'bg-warning': 0 < parseInt(item.deliveredQuantity),
				'bg-danger': 1 > parseInt(item.deliveredQuantity)}">
				{{ item.deliveredQuantity + ' / ' + item.contractQuantity}}
			</span>
		</template>
	</DataTable>
</template>
<script>
import TableActionCell from "../../components/TableActionCell";
import BooleanBadge from "../../components/BooleanBadge.vue";
import DataTable from "../../components/DataTable.vue";
import vSelect from "vue-select";
import Price from "../../components/Price.vue";
import ClientCell from "../projects/ClientCell.vue";
import {formatDate} from "../../functions";
import ProjectTypeCell from "../projects/ProjectTypeCell.vue";

export default {
	components: {
		ProjectTypeCell,
		ClientCell,
		BooleanBadge,
		TableActionCell,
		DataTable,
		vSelect,
		Price
	},
	props: {
		dataUrl: String,
		editUrl: String,
		projectTypes: Array,
		userId: Number,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},

		productManufacturers: Object,
		productCategories: Object,
		productTypes: Object
	},
	data(){
		let columns = [
			{
				text: 'Projekt',
				value: 'project'
			},
			{
				text: 'Projekt típus',
				value: 'project_type_id'
			},
			{
				text: 'Ügyfél',
				value: 'client'
			},
			{
				text: 'Állapot',
				value: 'closed_at',
				sortable: true,
			},
			{
				text: 'Létrehozva',
				value: 'created_at',
				sortable: true
			},
			{
				text: 'Telepítés dátuma',
				value: 'installation_date',
				sortable: true,
			},
			{
				text: 'Kiszállított termékek',
				value: 'delivered_products'
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			productManufacturersArray: _.values(this.productManufacturers),
			productCategoriesArray: _.values(this.productCategories)
		}
	},
	computed: {
		productTypesSelectData: {
			get(){
				let payload = [];
				_.forEach(this.productTypes, function(value, key){
					value.value = key
					payload.push(value)
				})
				return payload
			}
		}
	},
	methods: {
		formatDate,
		viewButtonPressed: function(row){
		},
		isEditable: function(row){
			return row.editable
		}
	}
}
</script>

<style scoped>
</style>
