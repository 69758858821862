<template>
	<DataTable
		:data-url="dataUrl"
		:initial-flash="initialFlash"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="warehouses"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
				:data="item"
				:edit-url="editUrl"
				:is-editable="isEditable"
				:is-deletable="false"
			></TableActionCell>
		</template>
		<template #item-mobile="item">
			<BooleanBadge
					:data="item"
					name="mobile"
					:key="'mobile_' + item.id"
			></BooleanBadge>
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import BooleanBadge from "../../components/BooleanBadge.vue";

export default {
	components: {
		BooleanBadge,
		DataTable,
		TableActionCell
	},
	props: {
		dataUrl: String,
		editUrl: String,
		isEditable: Boolean,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'Mozgó raktár',
				value: 'mobile',
				sortable: true
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
		}
	},
}
</script>

<style scoped>
</style>
