<template>
	<div class="card">
		<div class="d-flex justify-content-start wrapper">
			<div class="card-body product-name">
				<h5>{{ product.name }}</h5>
			</div>
			<div class="d-flex justify-content-end product-details">
				<div class="card-body d-flex gap-2 ms-auto flex-grow-0" v-if="!installation.closed_at">
					<div class="text-center" v-if="contractProduct && componentValue.contractQuantity > 0">
						<p class="text-muted mb-2 font-size-11">Szerződésben</p>
						<h5 class="mb-0">{{ componentValue.contractQuantity + (product.unit ? ' ' + product.unit : '') }}</h5>
					</div>
					<div class="text-center" v-if="componentValue.deliveredQuantity > 0">
						<p class="text-muted mb-2 font-size-11">Kiszállítva</p>
						<h5 :class="{
											'mb-0': 1,
											'text-success': product.requires_delivery && componentValue.contractQuantity <= componentValue.deliveredQuantity,
											'text-warning': product.requires_delivery && componentValue.contractQuantity > componentValue.deliveredQuantity && componentValue.deliveredQuantity,
											'text-danger': product.requires_delivery && !componentValue.deliveredQuantity,
										}">{{ componentValue.deliveredQuantity + (product.unit ? ' ' + product.unit : '') }}</h5>
					</div>
					<div class="text-center" v-if="stock[componentValue.productId] !== undefined || product.type === 'consumable'">
						<p class="text-muted mb-2 font-size-11">Raktárban</p>
						<h5 class="mb-0">{{ (stock[componentValue.productId] !== undefined ? stock[componentValue.productId] : 0) + (product.unit ? ' ' + product.unit : '') }}</h5>
					</div>
				</div>
				<div class="card-body p-0 d-flex flex-grow-0" v-if="!product.track_serial_number">
					<button type="button" class="btn btn-secondary rounded-0 px-2" v-if="editable" :disabled="min >= componentValue.quantity" @click.prevent="updateQuantity(-1)"><i class="far fa-minus"></i></button>
					<div :class="{
												'bg-success': componentValue.quantity > 0,
												'bg-danger': componentValue.quantity < 0,
												'bg-dark': componentValue.quantity === 0,
												'text-white': 1,
												'p-2': 1,
												'text-center': 1,
												}">
						<div class="font-size-12 d-flex align-items-center installation-product-quantity-title">
							<p v-if="product.type === 'material'">
								<template v-if="componentValue.quantity > 0">
									{{ (componentValue.quantity + componentValue.deliveredQuantity) <= componentValue.contractQuantity ? 'Kiszállítás' : 'Többlet felhasználás' }}
								</template>
								<template v-else-if="componentValue.quantity < 0">
									Visszáru
								</template>
								<template v-else>
								</template>
							</p>
							<p v-else>
								<template v-if="componentValue.quantity > 0">
									Fogyóeszköz felhasználás
								</template>
								<template v-else-if="componentValue.quantity < 0">
									Visszáru
								</template>
								<template v-else>
								</template>
							</p>
						</div>
						<input type="text" v-model="componentValue.quantity" @change="quantityChanged(product)" :class="{
											'form-control': 1,
											'installation-product-quantity': 1
												}" :disabled="!editable">
					</div>
					<button type="button" class="btn btn-secondary rounded-0 px-2" v-if="editable" :disabled="maxQuantity <= componentValue.quantity" @click.prevent="updateQuantity(1)"><i class="far fa-plus"></i></button>
				</div>
			</div>
		</div>
		<template v-if="product.track_serial_number && productKey !== scannerProductIndex && editable">
			<div class="card-body p-2">
				<div class="p-2 bg-light">
					<div class="d-flex gap-3 align-items-center">
						<button type="button" class="btn btn-soft-danger btn-sm" @click.prevent="$emit('showScanner', productKey, 'remove')">Visszáru</button>
						<button type="button" class="btn btn-soft-success btn-sm" @click.prevent="$emit('showScanner', productKey, 'add')">Extra felhasználás</button>
					</div>
				</div>
			</div>
		</template>
		<template v-if="product.track_serial_number && productKey === scannerProductIndex && editable">
			<div class="card-body p-2">
				<div class="p-2 bg-light">
					<div class="d-flex gap-3 align-items-center flex-wrap">
						<form v-on:submit.prevent="barcodeSubmit">
							<input type="text" class="form-control" style="width: 250px" placeholder="Vonalkód" ref="barcodeInput" id="barcodeInput" v-model="barcode" />
						</form>
						<div class="btn-group">
							<button type="button" :class="{'btn': 1, 'btn-secondary': 1, 'bg-soft': 1, 'btn-danger': scannerMode === 'remove'}" @click.prevent="$emit('showScanner', productKey, 'remove')">
								<i class="far fa-minus label-icon"></i> Visszáru
							</button>
							<button type="button" :class="{'btn': 1, 'btn-secondary': 1, 'bg-soft': 1, 'btn-success': scannerMode === 'add'}" @click.prevent="$emit('showScanner', productKey, 'add')">
								<i class="far fa-plus label-icon"></i> Extra felhasználás
							</button>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-if="product.track_serial_number && (deliveredProducts[componentValue.productId] !== undefined || getAddedSerialNumbers())">
			<div class="card-body">
				<div class="d-flex flex-wrap gap-2">
					<template v-if="deliveredProducts[componentValue.productId] !== undefined">
						<template v-for="delivered in deliveredProducts[componentValue.productId]">
							<span :class="{
								'badge': 1,
								'bg-secondary': !isSerialNumberAdded(delivered.serial_number),
								'bg-danger': isSerialNumberAdded(delivered.serial_number),
								'badge-xl': 1
							}"
							@click.prevent="serialNumberClicked(delivered.serial_number)">
								{{ delivered.serial_number }}
								<i class="fas fa-times serial-number-remove-icon" @click="$emit('removeSerialNumber', componentValue, delivered.serial_number)" v-if="editable && isSerialNumberAdded(delivered.serial_number)"></i>
							</span>
						</template>
					</template>
					<template v-for="added in getAddedSerialNumbers()">
						<span :class="{'badge': 1, 'bg-success': 1, 'badge-xl': 1}">
							{{ added.serial_number }}
							<i class="fas fa-times serial-number-remove-icon" v-if="editable" @click="$emit('removeSerialNumber', componentValue, added.serial_number)"></i>
						</span>
					</template>
				</div>
				<div class="mt-2" v-if="product.requires_delivery && ((product.track_serial_number && componentValue.installationProducts.length) || (!product.track_serial_number && componentValue.quantity !== 0))">
					<SwitchField
							label="Szállítást igényel"
							:name="'requires_delivery_' + productKey"
							v-model="componentValue.requiresDelivery"
							:disabled="!editable"
							:inline-reversed="true"
							@change="$emit('saveDeliveryRequirement', product)"
					></SwitchField>
				</div>
			</div>
		</template>
		<template v-if="componentValue.quantityError !== undefined && componentValue.quantityError !== null">
			<div class="card-body">
				<div class="alert alert-danger">
					A termék mennyisége nagyobb volt, mint a készlet a raktárban ({{ componentValue.quantityError + (product.unit ? ' ' + product.unit : '') }}), ezért módosítva lett a maximálisan elérhető mennyiségre.
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import SwitchField from "../../components/form/SwitchField.vue";

export default {
	emits: ['update:modelValue', 'showScanner', 'quantityChanged', 'saveDeliveryRequirement', 'removeSerialNumber', 'barcodeSubmit'],
	components: {SwitchField},
	props: {
		modelValue: Object,
		product: Object,
		contractProduct: Object,
		stock: Object,
		installation: Object,
		deliveredProducts: Object,
		installationProducts: Array,
		editable: Boolean,
		min: Number,
		scannerMode: String,
		scannerProductIndex: [String, Number],
		productKey: [String, Number],
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		maxQuantity: {
			get(){
				if (this.stock[this.componentValue.productId] !== undefined){
					return this.stock[this.componentValue.productId]
				}

				return 0
			}
		}
	},
	data(){
		return {
			barcode: ''
		}
	},
	methods: {
		getAddedSerialNumbers: function (){
			let payload = []

			if (this.componentValue.installationProducts === undefined){
				return payload
			}
			for (let i = 0; i < this.componentValue.installationProducts.length; i++){
				if (this.componentValue.installationProducts[i].product_id === this.componentValue.productId && this.componentValue.installationProducts[i].quantity > 0){
					payload.push(this.componentValue.installationProducts[i])
				}
			}

			return payload
		},
		isSerialNumberAdded: function (serialNumber){
			if (this.componentValue.installationProducts === undefined){
				return false
			}
			for (let i = 0; i < this.componentValue.installationProducts.length; i++){
				if (this.componentValue.installationProducts[i].serial_number === serialNumber){
					return true
				}
			}
			return false
		},
		updateQuantity: function (mod){
			this.componentValue.quantity = parseInt(this.componentValue.quantity) + mod
			if (this.componentValue.deliveredQuantity){
				this.componentValue.quantity = Math.max(-1 * this.componentValue.deliveredQuantity, this.componentValue.quantity)
			} else {
				this.componentValue.quantity = Math.max(0, this.componentValue.quantity)
			}

			this.quantityChanged()
		},
		quantityChanged: function(){
			if (this.componentValue.quantity > 0){
				this.componentValue.quantity = Math.min(this.componentValue.quantity, this.stock[this.componentValue.productId] !== undefined ? this.stock[this.componentValue.productId] : 0);
			}
			this.componentValue.quantityChanged = true

			this.$emit('quantityChanged')
		},
		serialNumberClicked: function (serialNumber){
			if (this.product.track_serial_number && this.productKey === this.scannerProductIndex && this.editable && this.scannerMode === 'remove' && !this.isSerialNumberAdded(serialNumber)){
				this.barcode = serialNumber

				this.barcodeSubmit()
			}
		},
		barcodeSubmit: function(){
			this.$emit('barcodeSubmit', this.barcode, this.componentValue)
			this.barcode = ''
		}
	}
}
</script>

<style scoped>
.installation-product-quantity-title {
	height: 30px;
	width: 80px;
	justify-content: center;
}
.installation-product-quantity {
	width: 80px;
	border: none;
	text-align: center;
	background: transparent;
	font-size: 25px;
	font-weight: bold;
	padding: 0;
	color: #fff;
	height: auto;
}
.serial-number-remove-icon {
	cursor: pointer;
	padding: 0 0 0 4px;
}
@media (max-width: 600px) {
	.product-name {
		width: 100%;
		flex-basis: 100%;
		flex-shrink: 0;
	}
	.product-details {
		width: 100%;
	}
	.wrapper {
		flex-wrap: wrap;
	}
}
</style>