<template>
	<div>
		<div v-if="field.type === 'title'">
			<h4 class="card-title mb-0">
				{{ field.title }}
			</h4>
			<div v-if="field.description" class="mt-3">
				<div v-html="field.description_html"></div>
			</div>
		</div>
		<div class="project-attribute--wrapper" v-if="field.type !== 'title' && field.type !== 'table' && field.type !== 'address' && field.type !== 'multirow'">
			<div :class="{'project-attribute--label': 1, 'project-attribute--label-editable': componentEditable}" v-if="displayLabel">
				<label :class="{required: isRequired(field) && componentEditable}">
					{{ field.title }}
				</label>
				<div v-if="field.description && componentEditable" class="mt-3">
					<div v-html="field.description_html"></div>
				</div>
			</div>
			<div class="flex-grow-1">
				<div v-if="componentEditable">
					<div v-if="field.type === 'text'">
						<template v-if="field.prefix || field.suffix">
							<div class="input-group">
								<span class="input-group-text" v-if="field.prefix">{{ field.prefix }}</span>
								<input type="text" :class="{'form-control': 1, 'is-invalid': isInvalid}" @input="onFieldChange" v-model="componentValue.value">
								<span class="input-group-text" v-if="field.suffix">{{ field.suffix }}</span>
							</div>
						</template>
						<template v-else>
							<input type="text" :class="{'form-control': 1, 'is-invalid': isInvalid}" @input="onFieldChange" v-model="componentValue.value">
						</template>
					</div>
					<div v-if="field.type === 'textarea'">
						<TextareaAutosize
							v-model="componentValue.value"
							:classes="{'form-control': 1, 'is-invalid': isInvalid}"
							:rows="1"
							@changed="onFieldChange"
						></TextareaAutosize>
					</div>
					<div v-if="field.type === 'bool'">
						<SwitchField
							v-model="componentValue.value"
							:name="'bool_' + field.field_number"
							@input="onFieldChange"
							:field-only="true"
						/>
					</div>
					<div v-if="field.type === 'number'">
						<template v-if="field.prefix || field.suffix">
							<div class="input-group">
								<span class="input-group-text" v-if="field.prefix">{{ field.prefix }}</span>
								<input type="number" :class="{'form-control': 1, 'is-invalid': isInvalid}" @keypress="isNumber($event)" @input="onFieldChange" v-model="componentValue.value" :min="field.minValue" :max="field.maxValue > 0 ? field.maxValue : null" :step="field.step" />
								<span class="input-group-text" v-if="field.suffix">{{ field.suffix }}</span>
							</div>
						</template>
						<template v-else>
							<input type="number" :class="{'form-control': 1, 'is-invalid': isInvalid}" @keypress="isNumber($event)" @input="onFieldChange" v-model="componentValue.value" :min="field.minValue" :max="field.maxValue > 0 ? field.maxValue : null" :step="field.step" />
						</template>
					</div>
					<div v-if="field.type === 'date'">
						<div class="limited-width-input">
							<DatePicker
								v-model:value="componentValue.value"
								input-class="form-control"
								lang="hu"
								value-type="format"
							></DatePicker>
						</div>
					</div>
					<div v-if="field.type === 'select'">
						<div :class="{'select-invalid': isInvalid}" v-if="!field.alternative_display">
							<v-select
								v-model="componentValue.value"
								:options="field.options"
								v-on:option:selected="onFieldChange"
								v-on:option:deselected="onFieldChange"
								:reduce="option => option.id"
								label="value"
								:multiple="field.multiple === 1"
								:clearable="!isRequired(field)"
							></v-select>
						</div>
						<div v-else>
							<RadioField
								v-model="componentValue.value"
								:values="getRadioFieldOptions(field.options)"
								value-type="integer"
								:field-only="true"
								@input="onFieldChange"
								:name="'radio_' + field.field_number"
								/>
						</div>
					</div>
				</div>
				<div v-if="!componentEditable">
					<div v-html="componentValue.formatted_value"></div>
				</div>
			</div>
		</div>
		<div v-if="field.type === 'address'">
			<h4 :class="{'card-title': 1}">
				{{ field.title }}
			</h4>
			<div v-if="field.description" class="mb-3">
				<div v-html="field.description_html"></div>
			</div>

			<div v-if="componentEditable">
				<div class="row">
					<div class="col-md-6">
						<div class="mb-3 position-relative">
							<label :class="{'required' : isRequired(field) && componentEditable}">Település</label>

							<div :class="{'select-invalid': !addressPartsValid.city_id}">
								<v-select
									:options="cities"
									v-model="componentValue.city_id"
									placeholder="Kezd el gépelni a keresést"
									:reduce="item => item.id"
									label="formatted_name"
									:clearable="!isRequired(field)"
									v-on:option:selected="onFieldChange"
									v-on:option:deselected="onFieldChange"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="mb-3 position-relative">
							<label :class="{'required' : isRequired(field)}">Utca</label>
							<input v-model="componentValue.street" type="text" :class="{'form-control': 1, 'is-invalid': !addressPartsValid.street}" @input="onFieldChange" />
						</div>
					</div>
					<div class="col-sm-4 col-md-2">
						<div class="mb-3 position-relative">
							<label :class="{'required' : isRequired(field)}">Házszám</label>
							<input v-model="componentValue.house_number" type="text" :class="{'form-control': 1, 'is-invalid': !addressPartsValid.house_number}" @input="onFieldChange" />
						</div>
					</div>
					<div class="col-sm-4 col-md-2">
						<div class="mb-3 position-relative">
							<label>Emelet</label>
							<input v-model="componentValue.floor" type="text" :class="{'form-control': 1, 'is-invalid': 0}" @input="onFieldChange" />
						</div>
					</div>
					<div class="col-sm-4 col-md-2">
						<div class="mb-3 position-relative">
							<label>Ajtó</label>
							<input v-model="componentValue.door" type="text" :class="{'form-control': 1, 'is-invalid': 0}" @input="onFieldChange" />
						</div>
					</div>
				</div>
			</div>
			<div v-if="!componentEditable">
				<div v-html="componentValue.formatted_value"></div>
			</div>
		</div>
		<div v-if="field.type === 'table'">
			<h4 class="card-title mb-0">
				{{ field.title }}
			</h4>
			<div v-if="field.description" class="mb-3">
				<div v-html="field.description_html"></div>
			</div>

			<table class="table">
				<thead>
				<tr>
					<th>&nbsp;</th>
					<th v-for="column in field.columns">{{ column.value }}</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="row in field.rows">
					<td>{{ row.value }}</td>
					<td v-for="column in field.columns">
						<div v-if="componentEditable">
							<input type="text" class="form-control" @input="onFieldChange" v-model="componentValue.value[column.id][row.id]" />
						</div>
						<div v-if="!componentEditable">
							{{ componentValue.value[column.id][row.id] }}
						</div>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
		<div v-if="field.type === 'multirow'">
			<h4 class="card-title mb-0">
				{{ field.title }}
			</h4>
			<div v-if="field.description" class="mb-3">
				<div v-html="field.description_html"></div>
			</div>

			<div class="mt-3">
				<div v-for="(row, rowKey) in componentValue" class="row py-3 multirow-row ">
					<div class="row">
						<div class="col-md-1">
							<h4>{{ rowKey + 1 }}.</h4>
							<div class="mb-3" v-if="componentValue.length > 1 && componentEditable">
								<button type="button" class="btn btn-danger" @click.prevent="removeRow(rowKey)">
									<i class="far fa-times"></i>
								</button>
							</div>
						</div>
						<div class="col-md-11">
							<div class="row" v-if="field.children">
								<template v-for="(childField, childFieldKey) in field.children">
									<div class="col-md-6" v-if="visibleFields.indexOf(childField.id + '_' + rowKey) >= 0
									&& (!childField.private_only || (childField.private_only && !client.is_company))
									&& (!childField.company_only || (childField.company_only && client.is_company))">

										<div class="mb-2">
											<label :class="{required: isRequired(childField) && componentEditable}">
												{{ childField.title }}
											</label>

											<ProjectAttribute
												v-model="row[childField.id]"
												:field="childField"
												:field-key="childFieldKey"
												:parent-key="fieldKey"
												:parent-order="field.order"
												:editable="editable"
												:display-label="false"
												:ref="'field_' + field.id + '_' + rowKey + '_' + childField.id"
												:visible-fields="visibleFields"
												:client-input-required="clientInputRequired"
												v-if="childField.type === 'text' || childField.type === 'textarea' || childField.type === 'number' || childField.type === 'bool' || childField.type === 'select' || childField.type === 'date'"
												@value-changed="onFieldChange"
											></ProjectAttribute>
										</div>
									</div>
								</template>
							</div>
						</div>
					</div>
				</div>

				<div class="mt-3" v-if="componentEditable">
					<button type="button" class="btn btn-secondary btn-label" @click.prevent="addRow">
						<i class="far fa-plus label-icon"></i> Hozzáad
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ToggleButton from '../../components/ToggleButton.vue'
import DatePicker from 'vue-datepicker-next';
import moment from 'moment'
import vSelect from "vue-select";
import TextareaAutosize from "../../components/TextareaAutosize.vue";
import SwitchField from "../../components/form/SwitchField.vue";
import RadioField from "../../components/form/RadioField.vue";

export default {

	emits: ['update:modelValue', 'approveAnswer', 'valueChanged', 'revokeAnswerApproval'],
	props: ['modelValue', 'field', 'fieldKey', 'parentOrder', 'parentKey', 'editable', 'cities', 'displayLabel', 'visibleFields', 'client', 'userType', 'clientInputRequired'],
	components: {
		ToggleButton,
		DatePicker,
		vSelect,
		TextareaAutosize,
		SwitchField,
		RadioField,
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit('update:modelValue', val)
			}
		},
		componentEditable: {
			get() {
				return this.editable
			}
		},
		fieldKeyFormatted: {
			get() {
				return this.field.field_number
			}
		},
	},
	data(){
		return {
			csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
			isInvalid: false,
			addressPartsValid: {
				city_id: true,
				street: true,
				house_number: true,
			},
			validationChecked: false
		};
	},
	created: function () {
		this.moment = moment;
	},
	mounted: function(){
	},
	methods: {
		onFieldChange: function(){
			if (this.validationChecked){
				this.validateField()
			}
			if ((this.field.type === 'text' || this.field.type === 'textarea') && this.field.maxLength){
				if (this.componentValue.value.length >= this.field.maxLength) {
					this.componentValue.value = this.componentValue.value.substring(0,this.field.maxLength);
				}
			}

			this.$emit('valueChanged')
		},
		isRequired: function(field) {
			if (field.required){
				return this.clientInputRequired && field.client_fillable ? (this.userType === 'client') : true
			}

			return false
		},
		validateField: function(){
			if (this.userType === 'client' && !this.field.client_fillable){
				return true
			}
			this.validationChecked = true

			if (this.field.type === 'multirow'){
				let refs = Object.keys(this.$refs)

				if (!refs.length){
					return false
				}
				let valid = true
				for (let i = 0; i < refs.length; i++){
					if (this.$refs[refs[i]].length && !this.$refs[refs[i]][0].validateField()){
						valid = false
					}
				}

				return valid
			}

			if (this.isRequired(this.field)) {
				if (this.field.type === 'address'){
					let valid = true
					if (this.componentValue.city_id){
						this.addressPartsValid.city_id = true
					} else {
						this.addressPartsValid.city_id = false
						valid = false
					}
					if (this.componentValue.street){
						this.addressPartsValid.street = true
					} else {
						this.addressPartsValid.street = false
						valid = false
					}
					if (this.componentValue.house_number){
						this.addressPartsValid.house_number = true
					} else {
						this.addressPartsValid.house_number = false
						valid = false
					}

					return valid
				}

				if (this.isValid()) {
					this.isInvalid = false
					return true
				} else {
					this.isInvalid = true
				}

				return false
			}

			return true
		},
		isValid: function(){
			if (!this.isRequired(this.field) || this.field.type === 'title' || this.field.type === 'multirow'){
				return true
			}
			if (this.field.type === 'select'){
				if (this.field.multiple){
					if (this.componentValue.value.length){
						return true
					}
				} else {
					if (this.componentValue.value){
						return true
					}
				}

				return false
			} else if (this.field.type !== 'table'){
				return !!this.componentValue.value;
			}
			if (this.field.rows !== undefined) {
				for (const [rowId, row] of Object.entries(this.field.rows)) {
					for (const [columnId, column] of Object.entries(this.field.columns)) {
						if (this.componentValue.value[column.id][row.id] === undefined || !this.componentValue.value[column.id][row.id]) {
							return false
						}

					}
				}
			}

			return true
		},
		addRow: function(){
			this.componentValue.push(JSON.parse(JSON.stringify(this.field.empty_row)))
			this.$emit('valueChanged')
		},
		removeRow: function(rowIndex){
			if (this.componentValue.length > 1){
				this.componentValue.splice(rowIndex, 1)
			}
		},
		isNumber: function(e){
			let keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
			if (this.field.step < 1){
				keysAllowed.push('.')
			}
			const keyPressed = e.key;

			if (!keysAllowed.includes(keyPressed)) {
				e.preventDefault()
			}
		},
		getRadioFieldOptions: function(options){
			let payload = {}
			_.forEach(options, function(value){
				payload[value.id] = {
					label: value.value
				}
			})
			return payload
		}
	}
}
</script>

<style scoped>
	i .sup {
		vertical-align: top;
		font-size: 6px;
		color: var(--bs-danger);
	}
	.multirow-row {
		border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
	}
	.multirow-row:first-child {
		border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
	}

</style>