<template>
	<div>
		<form-wrapper
			ref="formWrapper"
			:store-url="storeUrl"
			:back-url="backUrl"
			:initial-flash="initialFlash"
			v-model="attributes"
			v-slot="{ errors }"
		>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>

					<div class="row">
						<div class="col-md-6">
							<input-field
								label="Cím"
								name="title"
								rules="required|max:200"
								v-model="attributes.title"
								:errors="errors"
							/>
						</div>
						<div class="col-md-6">
							<select-field
								label="Szerepek"
								name="roleIdArray"
								rules="required"
								v-model="attributes.requiredRoleIdArray"
								:options="roles"
								:multiple="true"
								:errors="errors"
							/>
						</div>
					</div>
					<div class="mb-3">
						<textarea-field
							label="Lead"
							name="lead"
							v-model="attributes.lead"
							:rows="3"
						/>
					</div>
				</div>
			</div>

			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Tartalom</h4>
					<div id="editor"></div>
					<div class="d-none">
						<textarea name="content" v-model="attributes.content"></textarea>
					</div>
				</div>
			</div>

			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Dokumentumok</h4>
					<FileUploader
						field-name="document"
						v-model="attributes.documents"
						:file-upload-url="fileUploadUrl"
						file-type="document"
						:editable="true"
					></FileUploader>
				</div>
			</div>

			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Képek</h4>
					<FileUploader
						field-name="image"
						v-model="attributes.images"
						:file-upload-url="fileUploadUrl"
						file-type="image"
						:editable="true"
					></FileUploader>
				</div>
			</div>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import SwitchField from "../../components/form/SwitchField.vue"
import Ckeditor from '../../ckeditor/build/ckeditor'
import FormWrapper from "../../components/FormWrapper.vue";
import SelectField from "../../components/form/SelectField.vue";
import FileUploader from "../../components/FileUploader.vue";
import TextareaField from "../../components/form/TextareaField.vue";

export default {
	components: {InputField, SwitchField, FormWrapper, SelectField, TextareaField, FileUploader},
	props: ['initialAttributes', 'storeUrl', 'backUrl', 'initialFlash', 'roles', 'fileUploadUrl'],
	data(){
		let attributes = this.initialAttributes
		attributes.requiredRoleIdArray = []
		_.forEach(attributes.required_roles, function(value){
			attributes.requiredRoleIdArray.push(value.id)
		})
		if (attributes.content === null){
			attributes.content = ''
		}
		return {
			attributes: attributes,
			loading: false,
			flash: []
		}
	},
	mounted() {
		Ckeditor
			.create( document.querySelector( '#editor' ) )
			.then( editor => {
				editor.setData(this.attributes.content)
				let that = this

				editor.model.document.on('change:data', function(e){
					that.attributes.content = editor.getData()
				});
			} )
			.catch( error => {
			} );
	},
	methods: {
	}
}
</script>

<style scoped>

</style>