<template>
	<flash-display :flash="flash" ref="notifications"></flash-display>
	<div :class="{'overlay-form-wrapper': 1}">
		<div :class="{'overlay-form-wrapper--content': 1, 'overlay-form-wrapper--content-small': 1, 'loading': loading}">
			<div class="card">
				<div class="card-body">
					<h4 class="card-title mb-3">{{ quote.public_id }} - {{ quote.title_formatted }}</h4>
					<div class="details-box details-box--narrow mb-3">
						<div v-if="quote.created_at">
							<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(quote.created_at) }}</strong>
						</div>
						<div v-if="quote.created_by_user_name">
							<i class="far fa-user"></i> Létrehozó: <strong>{{ quote.created_by_user_name }}</strong>
						</div>
						<div v-if="quote.approved_at">
							<i class="far fa-calendar-alt"></i> Jóváhagyva: <strong>{{ formatDate(quote.approved_at) }}</strong>
						</div>
						<div v-if="quote.approved_by_user_name">
							<i class="far fa-user"></i> Jóváhagyta: <strong>{{ quote.approved_by_user_name }}</strong>
						</div>
						<div v-if="quote.sent_at">
							<i class="far fa-calendar-alt"></i> Elküldve: <strong>{{ formatDate(quote.sent_at) }}</strong>
						</div>
						<div v-if="quote.sent_by_user_name">
							<i class="far fa-user"></i> Küldő: <strong>{{ quote.sent_by_user_name }}</strong>
						</div>
						<div v-if="quote.read_at">
							<i class="far fa-calendar-alt"></i> Ügyfél elolvasta: <strong>{{ formatDate(quote.read_at) }}</strong>
						</div>
						<div>
							<i class="far fa-dollar-sign"></i> Nettó végösszeg: <strong><Price
								:value="quote.total_price_net"
								:currency-id="quote.currency_id"
								:currencies="currencies"
							/></strong>
						</div>
						<div>
							<i class="far fa-dollar-sign"></i> Bruttó végösszeg: <strong><Price
								:value="quote.total_price_gross"
								:currency-id="quote.currency_id"
								:currencies="currencies"
							/></strong>
						</div>
					</div>
					<RadioField
						:values="radioFieldValues"
						label="Ügyfél válasza"
						name="answer"
						v-model="answer.status"
						/>

					<div class="mb-3">
						<label>Fizetési konstrukció</label>

						<v-select
							:options="paymentPlans"
							v-model="answer.project_type_payment_plan_id"
							label="name_public"
							:reduce="item => item.id"
							:clearable="false"
						>
						</v-select>
						<p class="text-muted mt-2" v-if="answer.project_type_payment_plan_id && paymentPlansById[answer.project_type_payment_plan_id].description" v-html="paymentPlansById[answer.project_type_payment_plan_id].description_html"></p>
					</div>

					<div v-if="answer.status === 'accepted'">
						<div v-if="optionalProducts.length" class="mb-1">
							<label>Opcionális termékek</label>
							<div class="bg-light p-3 mb-2" v-for="quoteProduct in optionalProducts">
								<h5>{{ quoteProduct.product.name }}</h5>

								<RadioField
									:values="optionalProductRadioFieldValues"
									:name="'pr_' + quoteProduct.id"
									v-model="answer.optionalProducts[quoteProduct.id]"
									value-type="boolean"
									:field-only="true"
								/>
							</div>
						</div>
						<label>Aláírt ajánlat csatolása</label>
						<FileUploader
							field-name="signedPages"
							v-model="answer.signedPages"
							:file-upload-url="fileUploadUrl"
							:editable="true"
							:multiple="true"
						></FileUploader>
					</div>
				</div>
			</div>
			<div class="mt-3">
				<div class="d-flex flex-wrap gap-2">
					<button type="submit" class="btn btn-primary btn-label waves-effect" :disabled="!valid" @click.prevent="saveForm">
						<i class="fas fa-save label-icon"></i> Mentés
					</button>
					<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
						<i class="fas fa-times label-icon"></i> Bezár
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FlashDisplay from "../../components/FlashDisplay.vue";
import {formatDate} from "../../functions";
import Price from "../../components/Price.vue";
import RadioField from "../../components/form/RadioField.vue";
import FileUploader from "../../components/FileUploader.vue";
import vSelect from "vue-select";
export default {
	components: {FileUploader, RadioField, FlashDisplay, Price, vSelect},
	emits: ['close', 'closeAndReload'],
	props: {
		dataUrl: String,
		fileUploadUrl: String,
		quoteId: [Number, String],
		currencies: Object
	},
	data() {
		return {
			loading: true,
			flash: [],
			quote: {},
			optionalProducts: [],
			paymentPlans: [],
			answer: {
				status: 'accepted',
				optionalProducts: {},
				project_type_payment_plan_id: false
			},
			radioFieldValues: {
				accepted: {
					label: 'Elfogad',
					icon: 'far fa-check',
					color: 'success'
				},
				rejected: {
					label: 'Elutasít',
					icon: 'far fa-times',
					color: 'danger'
				}
			},
			optionalProductRadioFieldValues: {
				yes: {
					label: 'Igen',
					icon: 'far fa-check',
					color: 'success'
				},
				no: {
					label: 'Nem',
					icon: 'far fa-times',
					color: 'danger'
				}
			}
		}
	},
	computed: {
		valid: {
			get() {
				if (this.answer.status === 'reject'){
					return true
				}
				return this.answer.signedPages !== undefined && this.answer.signedPages.length
			}
		},
		paymentPlansById: {
			get(){
				return _.keyBy(this.paymentPlans, 'id')
			}
		}
	},
	mounted() {
		this.loadData()
	},
	methods: {
		formatDate,
		loadData: function(){
			this.loading = true

			let url = this.dataUrl
			if (this.quoteId){
				url += '/' + this.quoteId
			}
			let that = this
			axios.get(url + '?action=loadAnswerForm').then((response)=>{
				this.handleResponse(response)
			}).catch(function (error) {

			})
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.status !== undefined && response.data.status === 'success') {
				this.$emit('closeAndReload')
			}
			if (response.data.quote !== undefined) {
				this.quote = response.data.quote
			}
			if (response.data.paymentPlans !== undefined) {
				this.paymentPlans = _.filter(response.data.paymentPlans, item => !item.contract_without_quote)

				if (!this.answer.project_type_payment_plan_id && this.paymentPlans.length){
					this.answer.project_type_payment_plan_id = _.first(this.paymentPlans).id
				}
			}
			if (response.data.optionalProducts !== undefined) {
				this.optionalProducts = response.data.optionalProducts

				this.answer.optionalProducts = {}
				for (let i in this.optionalProducts){
					this.answer.optionalProducts[this.optionalProducts[i].id] = false
				}
			}
			if (response.data.flash !== undefined){
				this.flash = response.data.flash
			}
		},
		saveForm: function(e){
			if (this.valid){
				this.loading = true

				let that = this
				axios.post(this.dataUrl + '/' + this.quoteId + '?action=saveAnswer', this.answer).then((response)=>{
					this.handleResponse(response)
				}).catch(function (error) {
					that.loading = false
					that.flash = [{level: 'danger', message: error.response.data.message}]
				})
			} else {
				//this.flash = [{level: 'danger', message: 'Kérlek töltsd ki a pirossal jelölt mezőket!'}]
			}
		},
		emitClose: function(){
			this.$emit('close')
		},
	}
}
</script>

<style scoped>

</style>