<template>
	<div class="table-row-operations dropdown">
		<button type="button" class="btn btn-secondary btn-sm" data-bs-toggle="dropdown" :id="'dropdownActions_' + data.id" aria-expanded="false">Műveletek <i class="far fa-angle-down"></i></button>
		<div class="dropdown-menu dropdown-menu-end" :aria-labelledby="'dropdownActions_' + data.id" role="menu">
			<div class="d-flex gap-2 flex-column">
				<template v-if="displayEditButton">
					<a v-if="editButtonLink" :href="editButtonLink" class="dropdown-item"><i class="fas fa-edit text-primary fa-fw me-1"></i> Szerkesztés</a>
					<a v-else href="#" @click.prevent="editButtonPressed" class="dropdown-item"><i class="fas fa-edit text-primary fa-fw me-1"></i> Szerkesztés</a>
				</template>
				<template v-if="displayViewButton">
					<a v-if="viewButtonLink" :href="viewButtonLink" class="dropdown-item"><i class="fas fa-eye text-primary fa-fw me-1"></i> Részletek</a>
					<a v-else href="#" @click.prevent="viewButtonPressed" class="dropdown-item"><i class="fas fa-eye text-primary fa-fw me-1"></i> Részletek</a>
				</template>

				<slot :row="data"></slot>

				<confirm-button
					v-if="displayDeleteButton"
					:data="data"
					text="Törlés"
					:emit-delete-event="emitDeleteEvent"
					:dropdown="true"
					:url="deleteButtonUrl"
					message="Biztos, hogy törölni akarod?"
					@confirmed="deleteButtonPressed"
				></confirm-button>
			</div>
		</div>
	</div>
</template>

<script>
import ConfirmButton from "./ConfirmButton";
export default {
	components: {ConfirmButton},
	props: {
		data: {
			type: Object
		},
		editUrl: {
			type: String,
			default: ''
		},
		deleteUrl: {
			type: String,
			default: ''
		},
		isEditable: {
			type: [Boolean, Function],
			default: false
		},
		isDeletable: {
			type: [Boolean, Function],
			default: false
		},
		emitEditEvent: {
			type: Boolean
		},
		emitViewEvent: {
			type: Boolean
		},
		emitDeleteEvent: {
			type: Boolean
		}
	},
	data(){
		return {
			open: false
		}
	},
	emits: ['editButtonPressed', 'viewButtonPressed', 'deleteButtonPressed'],
	computed: {
		displayEditButton: {
			get(){
				if (!this.editUrl && !this.emitEditEvent){
					return false
				}
				return !(this.isEditable instanceof Function ? !this.isEditable(this.data) : !this.isEditable);
			}
		},
		editButtonLink: {
			get(){
				return this.editUrl.length ? this.editUrl + '/' + this.data.id : ''
			}
		},
		displayViewButton: {
			get(){
				if (!this.editUrl && !this.emitViewEvent){
					return false
				}
				return this.isEditable instanceof Function ? !this.isEditable(this.data) : !this.isEditable;
			}
		},
		viewButtonLink: {
			get(){
				return this.editUrl.length ? this.editUrl + '/' + this.data.id : ''
			}
		},
		displayDeleteButton: {
			get(){
				if (!this.deleteUrl && !this.emitDeleteEvent){
					return false
				}
				return this.isDeletable instanceof Function ? this.isDeletable(this.data) : this.isDeletable;
			}
		},
		deleteButtonUrl: {
			get(){
				return this.deleteUrl.length ? this.deleteUrl + '/' + this.data.id : ''
			}
		},
	},
	methods: {
		editButtonPressed: function(){
			this.$emit('editButtonPressed', this.data.id)
		},
		viewButtonPressed: function(){
			this.$emit('viewButtonPressed', this.data.id)
		},
		deleteButtonPressed: function(){
			this.$emit('deleteButtonPressed', this.data.id, this.deleteButtonUrl)
		},
	}
}
</script>

<style scoped>

</style>