<template>
	<div v-if="!componentValue.length" class="alert alert-info">Nincs hozzáadva jogosultság</div>
	<table class="table table-collapse-mobile" v-else>
		<thead>
		<tr>
			<th width="34%">Jogosultság</th>
			<th width="33%">Felhasználó</th>
			<th>Szerep</th>
			<th width="1%" v-if="canDelete && editable">&nbsp;</th>
		</tr>
		</thead>
		<tbody>
		<template v-for="(role, roleKey) in componentValue">
			<tr>
				<td>
					<v-select
						:options="projectTypeRoles"
						v-model="role.project_type_role_id"
						label="name"
						:reduce="item => item.id"
						:clearable="false"
						:disabled="!editable || !canUpdate"
					>
					</v-select>
				</td>
				<td>
					<v-select
						:options="users"
						v-model="role.user_id"
						label="name"
						:reduce="item => item.id"
						:clearable="true"
						:disabled="!editable || !canUpdate"
					>
					</v-select>
				</td>
				<td>
					<v-select
						:options="roles"
						v-model="role.role_id"
						label="name"
						:reduce="item => item.id"
						:clearable="true"
						:disabled="!editable || !canUpdate"
					>
					</v-select>
				</td>
				<td v-if="canDelete && editable">
					<button type="button" class="btn btn-danger" @click.prevent="remove(roleKey)" title="Törlés"><i class="far fa-times"></i></button>
				</td>
			</tr>
		</template>
		</tbody>
	</table>

	<div class="mt-3" v-if="editable && canUpdate">
		<button type="button" class="btn btn-secondary btn-label" @click.prevent="add">
			<i class="far fa-plus label-icon"></i> Hozzáad
		</button>
	</div>
</template>

<script>
import vSelect from "vue-select";

export default {
	components: {vSelect},
	emits: ['update:modelValue'],
	props: {
		modelValue: Array,
		projectTypeRoles: Object,
		roles: Array,
		users: Array,
		editable: Boolean,
		canUpdate: Boolean,
		canDelete: Boolean,
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	methods: {
		add: function(){
			this.componentValue.push({
				project_type_role_id: '',
				user_id: '',
				role_id: '',
			})
		},
		remove: function(key){
			this.componentValue.splice(key, 1)
		}
	}
}
</script>

<style scoped>

</style>