<template>
	<div>
		<input
			type="checkbox"
			:id="componentId"
			switch="bool"
			v-model="componentValue"
			value="1"
		>
		<label class="mb-0" :for="componentId" data-on-label="" data-off-label=""></label>
	</div>
</template>

<script>
export default {

	props: {
		name: String,
		modelValue: Number|Boolean,
	},
	computed: {
		componentValue: {
			get() {
				return !!this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
		componentId: {
			get() {
				return this.name + '_checkbox'
			}
		},
	},
}
</script>

<style scoped>

</style>