<template>
	<div class="d-flex mb-3 gap-2 flex-wrap">
		<button type="button" class="btn btn-primary" v-if="!barcodeScannerActive" @click.prevent="enableBarcodeScanner"><i class="far fa-barcode-scan"></i> Vonalkódbeolvasás</button>
		<form v-else v-on:submit.prevent="barcodeSubmit">
			<input type="text" class="form-control" style="width: 300px" placeholder="Vonalkód" ref="barcodeInput" v-model="barcode" @blur="disableBarcodeScanner" />
		</form>
		<a :href="detailedUrl" class="btn btn-secondary btn-label"><i class="far fa-clipboard-list label-icon"></i> Részletes készlet</a>
		<a :href="shipmentsUrl" class="btn btn-secondary btn-label"><i class="far fa-truck label-icon"></i> Szállítmányok listája</a>
		<a :href="deliveredUrl" class="btn btn-secondary btn-label"><i class="far fa-truck-loading label-icon"></i> Kiszállított áruk</a>
		<button type="button" @click.prevent="showExportForm = true" v-if="!showExportForm" class="btn btn-secondary btn-label"><i class="far fa-file-export label-icon"></i> Készlet export</button>
		<button type="button" @click.prevent="showExportForm = false" v-if="showExportForm" class="btn btn-secondary btn-label"><i class="far fa-table label-icon"></i> Táblázat</button>
	</div>
    <div v-if="showExportForm">
        <h4 class="card-title mb-4">Exportálás</h4>
        <form :action="exportUrl" method="post">
            <input type="hidden" name="_token" :value="csrf">
            <div class="row">
                <div class="col-lg-6">
                    <select-field
                        label="Raktár"
                        :options="warehouses"
                        name="exportWarehouseId"
                        info="Amennyiben nincs kiválasztva, az összes raktár készelete belekerül az exportba."
                        ></select-field>
                </div>
            </div>
            <button type="submit" class="btn btn-primary btn-label"><i class="far fa-file-export label-icon"></i> Export</button>
        </form>
    </div>
	<DataTable
		:data-url="dataUrl"
		:initial-flash="initialFlash"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="inventory"
        v-if="!showExportForm"
	>
		<template #filters="{serverOptions}">
			<div class="col-12 col-md-3">
				<v-select
					:options="warehouses"
					v-model="serverOptions.warehouse_id"
					:reduce="item => item.id"
					label="name"
					placeholder="Raktárak"
				/>
			</div>
			<div class="col-12 col-md-3">
				<v-select
					:options="productCategories"
					v-model="serverOptions.product_category_id"
					:reduce="item => item.id"
					label="name"
					placeholder="Kategória"
				/>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed}">
			<div class="table-row-operations d-flex gap-2">
				<a :href="viewUrl + '/' + item.id" class="btn btn-primary btn-sm waves-effect" title="Részletek"><i class="far fa-eye"></i></a>
			</div>
		</template>
		<template #item-category="item">
			{{ productCategoriesById[item.product_category_id].name}}
		</template>
		<template #item-quantity="item">
			{{ formatNumber(item.quantity) }}
		</template>
		<template #item-reserved="item">
			{{ formatNumber(item.reserved) }}
		</template>
		<template #item-available="item">
			<span :class="'fw-bold badge badge-xl bg-' + (parseInt(item.quantity) > parseInt(item.reserved) ? 'success' : 'warning')">{{ formatNumber(item.quantity - item.reserved) }}</span>
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import BooleanBadge from "../../components/BooleanBadge.vue";
import vSelect from "vue-select";
import {formatNumber} from "../../functions";
import SelectField from "../../components/form/SelectField.vue";

export default {
	components: {
        SelectField,
		DataTable,
		BooleanBadge,
		TableActionCell,
		vSelect
	},
	props: {
		dataUrl: String,
		viewUrl: String,
		detailedUrl: String,
		shipmentsUrl: String,
		deliveredUrl: String,
		scanUrl: String,
        exportUrl: String,
		warehouses: Array,
		productCategories: Array,
		productManufacturers: Array,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Cikkszám',
				value: 'sku',
				sortable: true
			},
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'Kategória',
				value: 'category',
				sortable: true
			},
			{
				text: 'Mennyiség',
				value: 'quantity',
				sortable: true,
				align: 'right'
			},
			{
				text: 'Egység',
				value: 'unit',
				sortable: true
			},
			{
				text: 'Lefoglalva',
				value: 'reserved',
				align: 'right',
				sortable: true
			},
			{
				text: 'Szabad készlet',
				value: 'available',
				align: 'right',
				sortable: false
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			productCategoriesById: _.keyBy(this.productCategories, 'id'),
			productManufacturersById: _.keyBy(this.productManufacturers, 'id'),
			barcode: '',
			barcodeScannerActive: false,
			successSound: '',
			errorSound: '',
            showExportForm: false,
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            exportSettings: {
                warehouse_id: null
            }
		}
	},
	created() {
		this.successSound = new Audio('/audio/inventory-scan-success.mp3')
		this.successSound.preload = true

		this.errorSound = new Audio('/audio/inventory-scan-error.mp3')
		this.errorSound.preload = true
	},
	methods: {
		formatNumber,
		enableBarcodeScanner: function(){
			this.barcode = ''
			this.barcodeScannerActive = true

			let that = this

			setTimeout(function(){
				that.$refs.barcodeInput.focus()
			}, 100)
		},
		disableBarcodeScanner: function(){
			this.barcode = ''
			this.barcodeScannerActive = false
		},
		playSound: function(audio){
			if (!audio.paused){
				audio.pause()
			}
			audio.currentTime = 0
			audio.play()
		},
		barcodeSubmit: function(){
			if (this.barcode.length < 6){
				return
			}
			axios.get(this.scanUrl + '?barcode=' + this.barcode, {
			}).then((response) => {
				if (!response.data.success){
					this.playSound(this.errorSound)
				}

				if (response.data.redirect !== undefined) {
					location.href = response.data.redirect
				}

				this.flash = response.data.flash
			})
			.catch((error) => {
				this.barcode = ''

				this.flash = [{message: 'Hiba történt', level: 'danger'}]
			})
		},
	}
}
</script>

<style scoped>
</style>
