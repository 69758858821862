<template>
	<div :key="'productList_' + wrapperKey" v-if="!productError">
		<template v-for="productCategory in computedProductCategories" :key="productCategory.id">
			<div v-if="displayCategory(productCategory)" :class="{
								'card': 1,
								'border': 1,
								'border-danger': !validateProductCategory(productCategory) && isCategoryRequired(productCategory),
								'border-success': validateProductCategory(productCategory) && categoryHasProduct(productCategory.id)
							}">
				<div class="card-body">
					<h4 :class="{
										'card-title': 1,
										'mb-4': 1,
										'd-flex': 1,
										'align-items-center': 1,
										'text-danger': !validateProductCategory(productCategory) && isCategoryRequired(productCategory),
										'text-success': validateProductCategory(productCategory) && categoryHasProduct(productCategory.id),
										}">
						<i class="far fa-check font-size-18 me-3" v-if="validateProductCategory(productCategory) && categoryHasProduct(productCategory.id)"></i>

						{{ productCategory.name }}

						<span class="badge bg-danger badge-xl ms-3" v-if="!validateProductCategory(productCategory) && isCategoryRequired(productCategory)">Hiányzó termékek</span>

						<button type="button" class="btn btn-primary btn-label btn-sm ms-auto" v-if="isEditable" @click.prevent="addProduct(productCategory.id)"><i class="far fa-plus label-icon"></i> Termék hozzáadása</button>
					</h4>

					<table class="table">
						<thead>
						<tr>
							<th>Termék</th>
							<th width="160">Menny.</th>
							<th width="15%">Infó</th>
							<th width="10%" class="text-end text-nowrap">Nettó egys.</th>
							<th width="10%" class="text-end">Bruttó ár</th>
							<th width="1%" v-if="isEditable">&nbsp;</th>
						</tr>
						</thead>
						<tbody>
						<tr v-if="!categoryHasProduct(productCategory.id)">
							<td colspan="5">Nincs termék hozzáadva</td>
						</tr>
						<template v-for="(product, productIndex) in componentValue">
							<template v-if="productDisplayableInCategory(product, productCategory)">
								<tr :class="{'text-muted': productsById[product.product_id] !== undefined
									&& productsById[product.product_id].is_optional
									&& (mode === 'quote' || mode === 'foreignContract') && parentModel.optionals_selected[product.product_id] === undefined}">
									<td>
										<v-select
											v-model="product.product_id"
											:options="filteredProducts(productCategory.id, product.product_id)"
											:reduce="option => option.id"
											label="name"
											:multiple="false"
											:clearable="false"
											v-if="isEditable"
											@option:selected="productChanged"
										></v-select>
										<div v-else>
											{{ productsById[product.product_id].name }}
										</div>
									</td>
									<td class="vertical-align-middle">
										<template v-if="productsById[product.product_id] !== undefined
											&& productsById[product.product_id].type === 'bundle'
											&& productsById[product.product_id].bundle_use_custom_unit">
											<InputField
												v-model="product.quantity"
												v-if="isEditable"
												data-type="number"
												:name="getInputName(productCategory, productIndex, 'quantity')"
												:step="1"
												:min="getProductMinQuantity(product)"
												:max="getProductMaxQuantity(product)"
												:field-only="true"
												:zero-allowed="false"
												:disabled="!isEditable"
												:suffix="productsById[product.product_id].bundle_unit"
												@update:modelValue="productChanged"
											>
											</InputField>
											<template v-else>
												{{ product.quantity }} {{ productsById[product.product_id].bundle_unit }}
											</template>
										</template>
										<template v-else>
											<InputField
												v-model="product.quantity"
												v-if="isEditable"
												data-type="number"
												:name="getInputName(productCategory, productIndex, 'quantity')"
												:field-only="true"
												:zero-allowed="false"
												:step="1"
												:min="getProductMinQuantity(product)"
												:max="getProductMaxQuantity(product)"
												:disabled="!isEditable"
												:spinner-buttons="true"
												@update:modelValue="productChanged"
											>
											</InputField>
											<template v-else>
												{{ product.quantity }} {{ productsById[product.product_id] !== undefined ? productsById[product.product_id].unit : 'db' }}
											</template>
										</template>
									</td>
									<td class="vertical-align-middle">
										<div v-html="getProductType(product)"></div>
										<div v-if="mode === 'quote' && productsById[product.product_id] !== undefined && productsById[product.product_id].is_optional" class="mt-2">
											<span class="badge badge-xl bg-warning">Opcionális</span>
											<div v-if="parentModel.status === 'accepted'" class="mt-1">
												<span class="badge badge-xl bg-success" v-if="parentModel.optionals_selected[product.product_id] !== undefined">Kéri</span>
												<span class="badge badge-xl bg-danger" v-else>Nem kéri</span>
											</div>
										</div>
									</td>
									<td class="text-end vertical-align-middle">
										<Price
											v-if="product.product_id"
											:value="(product.quantity < 0 ? -1 : 1) * (getUnitPrice(product.index, false, false) - getUnitPrice(product.index, false, true))"
											:currencies="currencies"
											:loading="pricesLoading"
										/>
									</td>
									<td class="text-end vertical-align-middle">
										<Price
											v-if="product.product_id"
											:value="getProductTotalPrice(product, true) - getProductTotalPrice(product, true, true)"
											:currencies="currencies"
											:loading="pricesLoading"
										/>
									</td>
									<td class="text-end vertical-align-middle" v-if="isEditable">
										<button type="button" @click.prevent="removeProduct(product.index)" class="btn btn-danger mx-1"><i class="far fa-times"></i></button>
									</td>
								</tr>
								<template v-if="productsById[product.product_id] !== undefined && productsById[product.product_id].type === 'bundle'">
									<template v-for="bundleProduct in getBundleProducts(product.product_id, product.version)">
										<template v-if="bundleProduct.pivot.selectable && (getBundleProductId(product, bundleProduct) || isEditable)">
											<template v-if="getBundleItemIsDisplayable(product, bundleProduct.pivot.order)">
												<tr :class="{'text-muted': bundleProduct.optional && !isOptionalSelected(bundleProduct)}" :key="'bundleProduct_' + [productIndex, product.product_id, bundleProduct.pivot.order].join('_')">
													<td class="border-bottom-0 bg-light">
														<v-select
															v-model="product.bundle_products[bundleProduct.pivot.order]"
															:options="getBundleItemSelectableProducts(bundleProduct.productIds)"
															:reduce="option => option.id"
															label="name"
															:placeholder="bundleProduct.pivot.label"
															:multiple="false"
															:clearable="bundleProduct.pivot.optional === 1"
															:disabled="!isEditable"
															@update:modelValue="productChanged"
															v-if="isEditable"
														></v-select>
														<template v-else-if="getBundleProductId(product, bundleProduct)">
															{{ productsById[getBundleProductId(product, bundleProduct)].name }}
														</template>
													</td>
													<td class="text-nowrap vertical-align-middle text-end fw-bold border-bottom-0 bg-light">
														<div v-if="getBundleProductId(product, bundleProduct)">
															{{ getBundleItemQuantity(product, bundleProduct.pivot.order) }} {{ getBundleItemUnit(getBundleProductId(product, bundleProduct)) }}
														</div>
													</td>
													<td class="text-nowrap vertical-align-middle fw-bold border-bottom-0 bg-light">
														<div v-if="getBundleProductId(product, bundleProduct)">
															{{ getBundleItemPower(product, bundleProduct.pivot.order) }}
														</div>
														<div v-if="bundleProduct.optional">
															<span class="badge badge-xl bg-warning">Opcionális</span>
															<div v-if="parentModel.status === 'accepted'" class="mt-1">
																<span class="badge badge-xl bg-success" v-if="isOptionalSelected(bundleProduct)">Kéri</span>
																<span class="badge badge-xl bg-danger" v-else>Nem kéri</span>
															</div>
														</div>
													</td>
													<td class="border-bottom-0 bg-light text-end">
														<Price
															v-if="getBundleProductId(product, bundleProduct)"
															:value="getBundleItemPrice(product, false, false, bundleProduct.pivot.order) - getBundleItemPrice(product, false, true, bundleProduct.pivot.order)"
															:currencies="currencies"
															:loading="pricesLoading"
														/>
													</td>
													<td class="border-bottom-0 bg-light text-end">
														<Price
															v-if="getBundleProductId(product, bundleProduct)"
															:value="(getBundleItemPrice(product, true, false, bundleProduct.pivot.order) - getBundleItemPrice(product, true, true, bundleProduct.pivot.order)) * getBundleItemQuantity(product, bundleProduct.pivot.order)"
															:currencies="currencies"
															:loading="pricesLoading"
														/>
													</td>
													<td class="border-bottom-0 bg-light" v-if="isEditable">

													</td>
												</tr>
											</template>
										</template>
										<template v-else>
											<template v-if="getBundleItemIsDisplayable(product, bundleProduct.pivot.order)">
												<tr :class="{'text-muted': bundleProduct.optional && isOptionalSelected(bundleProduct)}">
													<td class="border-bottom-0 bg-light">
														{{ getBundleItemName(bundleProduct) }}
													</td>
													<td class="text-nowrap vertical-align-middle text-end fw-bold border-bottom-0 bg-light">
														{{ getBundleItemQuantity(product, bundleProduct.pivot.order) }} {{ getBundleItemUnit(bundleProduct.productId) }}
													</td>
													<td class="text-nowrap vertical-align-middle fw-bold border-bottom-0 bg-light">
														{{ getBundleItemPower(product, bundleProduct.pivot.order) }}
														<div v-if="bundleProduct.optional">
															<span class="badge badge-xl bg-warning">Opcionális</span>
															<div v-if="parentModel.status === 'accepted'" class="mt-1">
																<span class="badge badge-xl bg-success" v-if="isOptionalSelected(bundleProduct)">Kéri</span>
																<span class="badge badge-xl bg-danger" v-else>Nem kéri</span>
															</div>
														</div>
													</td>
													<td class="border-bottom-0 bg-light text-end">
														<Price
															:value="getBundleItemPrice(product, false, false, bundleProduct.pivot.order) - getBundleItemPrice(product, false, true, bundleProduct.pivot.order)"
															:currencies="currencies"
															:loading="pricesLoading"
														/>
													</td>
													<td class="border-bottom-0 bg-light text-end">
														<Price
															:value="(getBundleItemPrice(product, true, false, bundleProduct.pivot.order) - getBundleItemPrice(product, true, true, bundleProduct.pivot.order)) * getBundleItemQuantity(product, bundleProduct.pivot.order)"
															:currencies="currencies"
															:loading="pricesLoading"
														/>
													</td>
													<td class="border-bottom-0 bg-light" v-if="isEditable">

													</td>
												</tr>
											</template>
										</template>
									</template>
								</template>
							</template>
						</template>
						</tbody>
						<tfoot>
						<tr>
							<th colspan="4" class="text-end">Végösszeg</th>
							<th class="text-end">
								<Price
									:value="getCategoryTotalPrice(productCategory.id, true)"
									:currencies="currencies"
									:loading="pricesLoading"
								/>
							</th>
							<th v-if="isEditable">&nbsp;</th>
						</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</template>
		<div class="card">
			<div class="card-body">
				<table class="table">
					<thead>
					<tr>
						<th>&nbsp;</th>
						<th width="15%" class="text-end">Nettó</th>
						<th width="15%" class="text-end">ÁFA</th>
						<th width="15%" class="text-end">Bruttó</th>
					</tr>
					</thead>
					<tbody>
					<tr v-if="getTotalPrice(false, true, 'work') > 0">
						<td class="text-end">
							<strong>Kedvezmény a munka jellegű tételekből</strong>
						</td>
						<td class="text-end">
							<Price
								:value="getTotalPrice(false, true, 'work')"
								:currencies="currencies"
								:loading="pricesLoading"
							/>
						</td>
						<td class="text-end">
							<Price
								:value="getTotalPrice(true, true, 'work') - getTotalPrice(false, true, 'work')"
								:currencies="currencies"
								:loading="pricesLoading"
							/>
						</td>
						<td class="text-end">
							<Price
								:value="getTotalPrice(true, true, 'work')"
								:currencies="currencies"
								:loading="pricesLoading"
							/>
						</td>
					</tr>
					<tr v-if="getTotalPrice(false, true, 'material') > 0">
						<td class="text-end">
							<strong>Kedvezmény az anyag jellegű tételekből</strong>
						</td>
						<td class="text-end">
							<Price
								:value="getTotalPrice(false, true, 'material')"
								:currencies="currencies"
								:loading="pricesLoading"
							/>
						</td>
						<td class="text-end">
							<Price
								:value="getTotalPrice(true, true, 'material') - getTotalPrice(false, true, 'material')"
								:currencies="currencies"
								:loading="pricesLoading"
							/>
						</td>
						<td class="text-end">
							<Price
								:value="getTotalPrice(true, true, 'material')"
								:currencies="currencies"
								:loading="pricesLoading"
							/>
						</td>
					</tr>
					<tr>
						<td class="text-end">
							<strong>Végösszeg</strong>
						</td>
						<td class="text-end">
							<Price
								:value="calculateTotalPrice('net')"
								:currencies="currencies"
								:loading="pricesLoading"
							/>
						</td>
						<td class="text-end">
							<Price
								:value="calculateTotalPrice('vat')"
								:currencies="currencies"
								:loading="pricesLoading"
							/>
						</td>
						<td class="text-end">
							<Price
								:value="calculateTotalPrice('gross')"
								:currencies="currencies"
								:loading="pricesLoading"
							/>
						</td>
					</tr>
					</tbody>
				</table>

				<slot></slot>
			</div>
		</div>
	</div>
	<div class="alert alert-danger" v-else>
		Az ajánlat egyik terméke megváltozott, ezért az ajánlatot nem lehet betöllteni.
	</div>
</template>

<script>
import Price from '../../components/Price.vue';
import vSelect from "vue-select";
import InputField from "../../components/form/InputField.vue";
export default {
	components: {Price, vSelect, InputField},
	emits: ['update:modelValue', 'flash'],
	props: {
		dataUrl: String,
		productCategories: Array,
		products: Array,
		isEditable: Boolean,
		modelValue: Array,
		parentModel: Object,
		productTypes: Object,
		currencies: Object,
		productVersions: Object,
		limitedProductQuantities: {
			type: Object,
			default: function(){
				return {}
			}
		},
		mode: {
			type: String,
			default: 'quote'
		}
	},
	mounted() {
	},
	data(){
		return {
			productPrices: {},
			pricesLoading: false,
			pricesLoadingCancelToken: null,
			priceCalculationTimeout: null,
			wrapperKey: 0,
			productError: false,
		}
	},
	computed: {
		computedProductCategories: {
			get() {
				if (this.mode === 'contractAmendment'){
					return [{
						id: null,
						name: 'Termékek'
					}]
				}

				return this.productCategories
			}
		},
		productsById: {
			get() {
				return _.keyBy(this.products, 'id')
			}
		},
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	methods: {
		setProductPrices: function(prices){
			this.productPrices = prices
			this.wrapperKey++
		},
		validateProductCategory: function(productCategory){
			if (!this.displayCategory(productCategory)){
				return true
			}
			if (this.mode === 'contractAmendment' || productCategory.pivot.required || this.mode === 'foreignContract'){
				let stats = this.getProductStatForCategory(productCategory.id)
				if (!stats.count){
					return false
				}
				if (this.mode === 'foreignContract'){
					return true
				}
				if (productCategory.id !== null) {
					if (productCategory.pivot.min_product_type && productCategory.pivot.min_product_type > stats.count) {
						return false
					}
					if (productCategory.pivot.min_product_quantity && productCategory.pivot.min_product_quantity > stats.quantity) {
						return false
					}
				}
			}

			return true
		},
		displayCategory: function(category){
			if (this.mode === 'foreignContract' || this.mode === 'contractAmendment'){
				return true
			}
			if (this.mode === 'quote' || (this.mode === 'contract' && this.parentModel.quote_id)){
				return category.pivot.with_quote === 1
			}

			return category.pivot.without_quote === 1
		},
		allCategoriesValid: function(){
			if (this.mode === 'foreignContract'){
				return true
			}
			for (let i = 0; i < this.computedProductCategories.length; i++) {
				if (!this.validateProductCategory(this.computedProductCategories[i])) {
					return false
				}
			}
			return true
		},
		filteredProducts: function(categoryId, productId){
			let productIds = [];
			_.forEach(this.componentValue, function(row){
				if (row.product_id && row.product_id !== productId){
					productIds.push(row.product_id)
				}
			})
			return _.filter(this.products, function(product){
				if (product === undefined){
					return false
				}
				if (categoryId === null){
					return product.type !== 'bundle'
				}
				return product.product_category_id === categoryId
			})
		},
		categoryHasProduct: function(categoryId){
			let result = false
			let that = this
			_.forEach(this.componentValue, function(row){
				if (
					that.mode === 'contractAmendment' ||
					(that.productsById[row.product_id] !== undefined && that.productsById[row.product_id].product_category_id === categoryId) ||
					(row.product_category_id !== undefined && row.product_category_id === categoryId)){
					result = true
				}
			})

			return result
		},
		productChanged: function(selected){
			if (selected !== undefined && selected !== null) {
				for (let i = 0; i < this.componentValue.length; i++) {
					if (this.productsById[this.componentValue[i].product_id] !== undefined && this.componentValue[i].product_id === selected.id) {
						let that = this

						this.componentValue[i].version = this.productsById[this.componentValue[i].product_id].version

						if (this.productsById[this.componentValue[i].product_id].type === 'bundle') {
							that.componentValue[i].bundle_products = []
							_.forEach(this.productsById[this.componentValue[i].product_id].bundle_products, function (tmp) {
								if (tmp.pivot.selectable
									&& (that.componentValue[i].bundle_products[tmp.pivot.order] === undefined
										|| !that.componentValue[i].bundle_products[tmp.pivot.order])
									&& (!tmp.pivot.optional || (tmp.pivot.optional && tmp.pivot.select_first_by_default))) {
									that.componentValue[i].bundle_products[tmp.pivot.order] = tmp.id
								}
							})
						}
					}
				}
			}

			this.calculatePricesDebounce()
		},
		calculatePricesDebounce: function(){
			this.pricesLoading = true

			if (this.priceCalculationTimeout !== null){
				clearTimeout(this.priceCalculationTimeout)
				this.priceCalculationTimeout = null
			}

			this.priceCalculationTimeout = setTimeout(this.calculatePrices, 300)
		},
		calculatePrices: function(){
			let url = this.dataUrl
			if (this.parentModel.id){
				url += '/' + this.parentModel.id
			}

			if (this.pricesLoadingCancelToken !== null) {
				this.pricesLoadingCancelToken.cancel("Operation canceled due to new request.")
			}

			this.pricesLoadingCancelToken = axios.CancelToken.source()

			let that = this
			let payload = {}
			if (this.parentModel.status === 'draft'){
				payload = {
					products: this.getFilteredProducts(),
				}
				payload[(this.mode === 'quote' ? 'quote' : 'contract')] = {
					discount: this.parentModel.discount || 0,
					discount_material: this.parentModel.discount_material || 0,
					discount_work: this.parentModel.discount_work || 0,
					is_foreign: this.parentModel.is_foreign !== undefined ? this.parentModel.is_foreign : 0
				}
				if (this.mode === 'contractAmendment'){
					payload.contract.amendment_to_contract_id = this.parentModel.amendment_to_contract_id
				}
			}

			axios.post(url + '?action=calculatePrices', payload,
				{
					cancelToken: this.pricesLoadingCancelToken.token
				}).then((response)=>{
				if (response.data.flash !== undefined){
					this.$emit('flash', response.data.flash)
				}
				if (response.data.prices !== undefined) {
					this.pricesLoading = false
					this.productPrices = response.data.prices
				}
			}).catch(function (error) {
				that.$emit('flash', [{level: 'danger', message: error.response.data.message}]);
			})
		},
		getBundleItemSelectableProducts: function(productIds){
			let payload = []
			for (let i = 0; i < productIds.length; i++){
				for (let j = 0; j < this.products.length; j++){
					if (this.products[j].id === productIds[i]){
						payload.push(this.products[j])
					}
				}
			}

			return payload
		},
		getCategoryTotalPrice: function(categoryId, gross) {
			let total = 0
			let that = this
			_.forEach(this.componentValue, function(productRow){
				if (that.productsById[productRow.product_id] !== undefined && (categoryId === null || that.productsById[productRow.product_id].product_category_id === categoryId)) {
					if (!that.productsById[productRow.product_id].is_optional || that.mode === 'contractAmendment' || (that.parentModel.status === 'accepted' && that.parentModel.optionals_selected[productRow.product_id] === undefined)) {

						total += that.getProductTotalPrice(productRow, gross, false) - that.getProductTotalPrice(productRow, gross, true)
					}
				}
			})

			return total
		},
		getTotalPrice: function(gross, discount, type) {
			type = type || 'all'
			let total = 0
			let that = this

			_.forEach(this.productPrices, function(productPrice, productPriceKey){
				if (productPrice.bundle || (type !== 'all' && productPrice.type !== type) || (productPrice.optional && !productPrice.optionalSelected)){
					return
				}
				if (discount){
					total += (gross ? productPrice.discountGross : productPrice.discountNet) * productPrice.quantity
				} else {
					total += (gross ? productPrice.gross : productPrice.net) * productPrice.quantity
				}
			})

			return total
		},
		calculateTotalPrice: function(type){
			let base, subtract

			if (type === 'net'){
				base = this.getTotalPrice(false, false)
				subtract = this.getTotalPrice(false, true)
			}
			if (type === 'gross'){
				base = this.getTotalPrice(true, false)
				subtract = this.getTotalPrice(true, true)
			}
			if (type === 'vat'){
				base = this.calculateTotalPrice('gross')
				subtract = this.calculateTotalPrice('net')
			}
			if (base > 0){
				return base - subtract
			}

			return -1 * (Math.abs(base) - Math.abs(subtract))
		},
		getBundleItemQuantity(productRow, order){
			let key = productRow.index + '_' + order
			if (this.productPrices !== undefined && this.productPrices[key] !== undefined){
				return this.productPrices[key].quantity
			}
			return 0
		},
		getBundleItemIsDisplayable(productRow, order){
			let key = productRow.index + '_' + order
			if (this.productPrices !== undefined && this.productPrices[key] !== undefined){
				if (!this.isEditable && (this.productPrices[key].hidden && !this.productPrices[key].optional)){
					return false
				}
				if (this.isEditable && (this.productPrices[key].hidden && !this.productPrices[key].optional)){
					return false
				}
				return true
			}
			return false
		},
		getBundleItemPower(productRow, order){
			let product = this.getBundleItemProduct(productRow, order)
			if (product === null || ((product.power * this.getBundleItemQuantity(productRow, order)) < 1 ) || !product.power_unit){
				return ''
			}

			return (product.power * this.getBundleItemQuantity(productRow, order)) + ' ' + product.power_unit
		},
		getBundleItemUnit(productId){
			if (!productId || !this.productsById[productId]){
				return 'db'
			}
			const product = this.productsById[productId]

			return product.type === 'bundle'
			&& product.bundle_use_custom_unit
				? product.bundle_unit
				: product.unit
		},
		addProduct: function(categoryId) {
			this.componentValue.push({
				index: this.getNewProductIndex(),
				product_id: null,
				quantity: 1,
				product_category_id: categoryId,
				bundle_products: {}
			})

		},
		getNewProductIndex: function (){
			let index = 1
			for (let i in this.componentValue){
				if (this.componentValue[i].index >= index){
					index = this.componentValue[i].index + 1
				}
			}

			return index
		},
		removeProduct: function(productIndex){
			for (let i in this.componentValue){
				if (this.componentValue[i].index === productIndex){
					this.componentValue.splice(i, 1)
				}
			}

			this.calculatePricesDebounce()
		},
		getProductType: function(productRow){
			if (!productRow.product_id){
				return ''
			}
			return '<span class="badge badge-xl bg-' + this.productTypes[this.productsById[productRow.product_id].type].color + '">' +
				'<i class="' + this.productTypes[this.productsById[productRow.product_id].type].icon + '"></i> ' + this.productTypes[this.productsById[productRow.product_id].type].label +
				'</span>'
		},
		getBundleItemPrice(productRow, gross, discount, order){
			return this.getUnitPrice(productRow.index, gross, discount, order)
		},
		getFilteredProducts: function(){
			let products = _.clone(this.componentValue)
			for (let i in products){
				products[i].quantity = parseInt(products[i].quantity)
				if (products[i] !== undefined && (products[i].product_id === undefined || !products[i].product_id)){
					delete products[i]
				}
				if (products[i] !== undefined && products[i].id !== undefined) {
					delete products[i].price_gross
					delete products[i].price_net
					delete products[i].quote_id
					delete products[i].contract_id
					delete products[i].id
					delete products[i].updated_at
					delete products[i].created_at
					delete products[i].product_category_id
				}
			}

			return _.compact(_.values(products))
		},
		getBundleProducts: function(productId, version){
			let tmp = {}
			const product = this.productsById[productId]
			let bundleProducts = product.bundle_products
			if (version !== undefined && version !== null && this.productVersions[productId + '_' + version] !== undefined){
				bundleProducts = this.productVersions[productId + '_' + version]
			}

			for (let i in bundleProducts){
				if (bundleProducts[i].pivot.selectable){
					if (tmp[bundleProducts[i].pivot.order] === undefined){
						tmp[bundleProducts[i].pivot.order] = {
							productIds: [bundleProducts[i].id],
							pivot: bundleProducts[i].pivot,
							optional: bundleProducts[i].is_optional
						}
					} else {
						tmp[bundleProducts[i].pivot.order].productIds.push(bundleProducts[i].id)
					}
				} else {
					tmp[bundleProducts[i].pivot.order] = {
						productId: bundleProducts[i].id,
						pivot: bundleProducts[i].pivot,
						optional: bundleProducts[i].is_optional
					}
				}
			}

			return tmp
		},
		getUnitPrice: function(productIndex, gross, discount, order){
			discount = discount || false
			let key = productIndex
			if (order !== undefined){
				key += '_' + order
			}

			if (this.productPrices === undefined || this.productPrices[key] === undefined) {
				return 0
			}
			if (discount){
				return gross ? this.productPrices[key].discountGross : this.productPrices[key].discountNet
			}
			return gross ? this.productPrices[key].gross : this.productPrices[key].net
		},
		getProductTotalPrice: function(productRow, gross, discount){
			if (!productRow.product_id){
				return 0
			}
			return this.getUnitPrice(productRow.index, gross, discount) * productRow.quantity
		},
		getProductStatForCategory: function (categoryId){
			let payload = {
				count: 0,
				quantity: 0
			}
			let that = this
			_.forEach(this.componentValue, function(row){
				if (row.product_id && (categoryId === null || that.productsById[row.product_id].product_category_id === categoryId)){
					payload.count++

					payload.quantity += Math.ceil(row.quantity)
				}
			})

			return payload
		},
		getBundleItemProduct(productRow, order){
			const product = this.productsById[productRow.product_id]
			let bundleProducts = product.bundle_products

			if (productRow.version !== undefined && productRow.version !== null && this.productVersions[productRow.product_id + '_' + productRow.version] !== undefined){
				bundleProducts = this.productVersions[productRow.product_id + '_' + productRow.version]
			}

			for (let i in bundleProducts){
				if (bundleProducts[i].pivot.order === order){
					if (!bundleProducts[i].pivot.selectable
						|| (productRow.bundle_products[order] !== undefined && productRow.bundle_products[order] === bundleProducts[i].id)) {
						return bundleProducts[i]
					}
				}
			}

			return null
		},
		isCategoryRequired: function(productCategory){
			if (this.mode === 'foreignContract'){
				return false
			}
			if (this.mode === 'contractAmendment'){
				return true
			}
			if (this.mode === 'contract' && this.parentModel.quote_id){
				return false
			}
			return productCategory.pivot.required
		},
		getBundleProductId: function(product, bundleProduct){
			if (product.bundle_products[bundleProduct.pivot.order] === undefined){
				if (!bundleProduct.pivot.optional) {
					this.productError = true;
				}

				return 0
			}
			return product.bundle_products[bundleProduct.pivot.order]
		},
		getInputName: function(productCategory, productIndex, name){
			return name + '_' + productCategory.id + '_' + productIndex
		},
		getBundleItemName: function(bundleProduct){
			if (this.productsById[bundleProduct.productId] === undefined){
				return ''
			}
			return this.productsById[bundleProduct.productId].name
		},
		isOptionalSelected: function(bundleProduct){
			if (bundleProduct.productId !== undefined){
				return this.parentModel.optionals_selected[bundleProduct.productId] !== undefined
			}
			if (bundleProduct.productIds !== undefined){
				for (let i = 0; i < bundleProduct.productIds.length; i++){
					if (this.parentModel.optionals_selected[bundleProduct.productIds[i]] !== undefined){
						return true
					}
				}
			}

			return false
		},
		productDisplayableInCategory: function(product, productCategory){
			if (this.mode === 'contractAmendment'){
				return true
			}
			if (product.product_id !== undefined && product.product_id && this.productsById[product.product_id] !== undefined){
				return this.productsById[product.product_id].product_category_id === productCategory.id
			}

			return product.product_category_id !== undefined && product.product_category_id === productCategory.id
		},
		getProductMinQuantity: function(product){
			if (product.product_id === undefined || !product.product_id){
				return 1
			}
			if (this.limitedProductQuantities[product.product_id] !== undefined && this.limitedProductQuantities[product.product_id].min !== undefined){
				return this.limitedProductQuantities[product.product_id].min
			}

			return 1
		},
		getProductMaxQuantity: function(product){
			if (product.product_id === undefined || !product.product_id || this.productsById[product.product_id] === undefined){
				return null
			}
			return this.productsById[product.product_id].max_quantity
		},
	}
}
</script>

<style scoped>

</style>