<template>
	<div class="dropdown d-inline-block">
		<button type="button" class="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown"
				data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<i class="far fa-bell"></i>
			<span class="badge bg-danger rounded-pill" v-if="notificationCount">{{ notificationCount }}</span>
		</button>
		<div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
			 aria-labelledby="page-header-notifications-dropdown">
			<div class="p-3">
				<div class="row align-items-center">
					<div class="col">
						<h6 class="m-0" key="t-notifications"> Értesítések </h6>
					</div>
				</div>
			</div>
			<div data-simplebar style="max-height: 300px;" id="notificationDropdown">
				<a :href="'/notifications/redirect/' + notification.id" class="text-reset notification-item" v-for="notification in notifications">
					<div class="d-flex">
						<div class="avatar-xs me-3">
							<span :class="'avatar-title bg-' + notification.color + ' rounded-circle font-size-16'">
								<i :class="notification.icon"></i>
							</span>
						</div>
						<div class="flex-grow-1">
							<h6 class="mt-0 mb-1">{{ notification.title }}</h6>
							<div class="font-size-12 text-muted">
								<p class="mb-1">{{ notification.description }}</p>
								<p class="mb-0">
									<i class="far fa-clock"></i> <RelativeTime v-model="notification.created_at" />
								</p>
							</div>
						</div>
					</div>
				</a>
			</div>
			<div class="p-2 border-top d-grid">
				<a class="btn btn-sm btn-link font-size-14 text-center" href="/notifications">
					<i class="far fa-angle-right me-1"></i> <span key="t-view-more">Összes megtekintése</span>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import SimpleBar from "simplebar"
import RelativeTime from "./RelativeTime.vue";

export default {
	components: {RelativeTime},
	data(){
		return {
			notifications: [],
			notificationCount: 0,
			simpleBarInstance: null
		}
	},
	mounted() {
		this.simpleBarInstance = new SimpleBar(document.getElementById('notificationDropdown'));

		this.update()
		this.setNextUpdate()
	},
	methods: {
		setNextUpdate: function() {
			let that = this
			setTimeout(function(){
				that.update()
				that.setNextUpdate()
			}, 60000)
		},
		update: function(){
			axios.get('/notifications/get').then((response) => {
				_.forEach(response.data.badges, function (value, key) {
					let element = document.getElementById('menuBadge_' + key)
					element.innerHTML = value
					element.classList.remove('d-none')
				})

				this.notifications = response.data.notifications
				this.notificationCount = response.data.notificationCount

				this.simpleBarInstance.recalculate();
			}).catch(function (error) {
			})
		},
	}
}
</script>

<style scoped>

</style>