<template>
	<div class="project-form">
		<div class="inactivity-overlay-bg" v-if="isInactive">
			<div class="inactivity-overlay" v-if="isInactive">
				<p>Túl sokáig voltál inaktív, ezért a projekt csak újratöltés után lesz szerkeszthető!</p>
				<a href="#" class="btn btn-primary" @click.prevent="reloadPage">Oldal újratöltése</a>
			</div>
		</div>
		<flash-display :flash="flash" ref="notifications"></flash-display>

		<div class="mb-3">
			<div class="d-flex flex-wrap gap-2">
				<a :href="backUrl" v-if="backUrl" class="btn btn-secondary btn-label"><i class="fas fa-angle-left label-icon"></i> Vissza</a>
			</div>
		</div>
		<div class="card mb-3">
			<div class="card-body">
				<h4>
					<strong v-if="project.public_id" class="me-1">{{ project.public_id }}</strong>
					{{ installation.contract.project.client.name }}
					<small v-if="clientAddress" class="ms-1">{{ clientAddress }}</small>
				</h4>
				<div class="d-flex gap-2">
					<span :class="'project-type-badge project-type-badge-' + projectType.luminosity" :style="'background:' + projectType.color + ';'"><i :class="'far fa-' + projectType.icon"></i><span>{{ projectType.name }}</span></span>
					<button type="button" class="btn" @click.prevent="detailsVisible = !detailsVisible">Részletek <i :class="'far fa-' + (detailsVisible ? 'chevron-up' : 'chevron-down')"></i></button>
				</div>
				<LockMessage :lock="lock" :margin-bottom="false" :margin-top="true"></LockMessage>
				<div class="mt-3 details-box" v-if="project.id !== undefined && detailsVisible">
					<div v-if="project.created_by_user_name">
						<i class="far fa-user"></i> Létrehozó: <strong>{{ project.created_by_user_name }}</strong>
					</div>
					<div v-if="project.created_at">
						<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(project.created_at) }}</strong>
					</div>
					<div v-if="project.updated_by_user_name">
						<i class="far fa-user"></i> Módosító: <strong>{{ project.updated_by_user_name }}</strong>
					</div>
					<div v-if="project.updated_at">
						<i class="far fa-calendar-alt"></i> Módosítva: <strong>{{ formatDate(project.updated_at) }}</strong>
					</div>
					<div v-if="project.handler_user_name">
						<i class="far fa-user-tie"></i> Tanácsadó: <strong>{{ project.handler_user_name }}</strong>
					</div>
					<div v-if="project.client_input_required || project.client_input_at">
						<i class="far fa-clipboard-check"></i> Ügyfél adatkitöltés: <strong>{{ project.client_input_at ? formatDate(project.client_input_at) : '-' }}</strong>
					</div>
				</div>
				<div class="mt-3 details-box" v-if="detailsVisible">
					<h4>Ügyfél</h4>
					<div>
						<i class="far fa-hashtag"></i> Azonosító:
						<a :href="clientUrl" v-if="clientUrl">{{ client.public_id }}</a>
						<strong v-else>{{ client.public_id }}</strong>
					</div>
					<div>
						<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(client.created_at) }}</strong>
					</div>
					<div v-if="client.created_by_user_name">
						<i class="far fa-user"></i> Létrehozó: <strong>{{ client.created_by_user_name }}</strong>
					</div>
					<div><i class="far fa-envelope"></i> E-mail cím: <EmailAddress :value="client.email"></EmailAddress></div>
					<div><i class="far fa-phone"></i> Telefonszám: <PhoneNumber :value="client.phone"></PhoneNumber></div>
				</div>
				<div class="mt-3 details-box" v-if="project.inquiry && detailsVisible">
					<h4>Érdeklődés</h4>
					<div v-if="project.inquiry">
						<i class="far fa-hashtag"></i> Azonosító:
						<a :href="inquiryUrl" v-if="inquiryUrl">{{ project.inquiry.public_id }}</a>
						<strong v-else>{{ project.inquiry.public_id }}</strong>
					</div>
					<div v-if="project.inquiry">
						<i class="far fa-calendar-alt"></i> Időpont: <strong>{{ formatDate(project.inquiry.created_at) }}</strong>
					</div>
					<div v-if="project.inquiry && project.inquiry.handler">
						<i class="far fa-user-friends"></i> Tanácsadó: <strong>{{ project.inquiry.handler.name }}</strong>
					</div>
				</div>
			</div>
		</div>

		<div>
			<div class="card" v-if="project.id">
				<div class="card-body">
					<ul class="nav nav-pills nav-justified mb-0" role="tablist">
						<template v-for="(tab, tabKey) in tabs">
							<li class="nav-item" role="presentation">
								<a :class="{'nav-link': 1, 'active': activeTab === tabKey}" @click.prevent="setActiveTab(tabKey)" href="#" role="tab" :aria-selected="activeTab === tabKey ? 'true' : 'false'">
									<div class="icon">
										<i :class="'far fa-' + tab.icon"></i>
										<span class="badge rounded-pill bg-danger ms-1" v-if="tabBadges[tabKey] !== undefined">{{ tabBadges[tabKey] }}</span>
									</div>
									<div class="label">
										{{ tab.name }}
									</div>
								</a>
							</li>
						</template>
					</ul>
				</div>
			</div>
			<div :class="{'d-none': activeTab !== 'attributes'}">
				<ProjectFormAttributes
						v-model="project"
						:client="client"
						:project-type="projectType"
						:cities="cities"
						:editable="false"
						user-type="user"
						ref="projectAttributes"
				></ProjectFormAttributes>
			</div>
			<div v-if="activeTab === 'documents'">
				<div class="card">
					<div class="card-body">
						<div class="row mb-3">
							<div class="col-md-3">
								<ul class="list-group document-types-list-group mb-3">
									<template v-for="documentType in project.project_type.document_types">
										<a href="#"
										   :class="{
											'list-group-item': 1,
											'list-group-item-action': 1,
											'list-group-item-action-active': typeof activeDocumentType === 'object' && activeDocumentType.id === documentType.id,
											'd-flex': 1,
											'justify-content-between': 1,
											'align-items-start': 1}"
										   v-if="documentType.installation || documentType.installer_can_view"
										   @click.prevent="activeDocumentType = documentType">
											<div class="ms-2 me-auto">
												<span class="list-group-item-border" :style="'background:' + (documentType.color || '#eff2f7') + ';'"></span>
												<i :class="'me-2 far fa-' + documentType.icon" v-if="documentType.icon"></i>{{ documentType.name }}
											</div>
											<span class="badge bg-danger rounded-pill" v-if="getDocumentTypeCount(documentType)">{{ getDocumentTypeCount(documentType) }}</span>
										</a>
									</template>
								</ul>
							</div>
							<div class="col-md-9">
								<ProjectFormDocumentCategory
										:can-upload="false"
										:can-delete="false"
										:can-update="false"
										:file-upload-url="fileUploadUrl"
										:document-type="activeDocumentType"
										v-bind:existing-files="project.documents[activeDocumentType.id]"
										v-bind:uploaded-files="uploadedFiles"
										:key="'documentCategory_' + activeDocumentType.id"
								></ProjectFormDocumentCategory>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="activeTab === 'comments'">
				<Comments
						:data-url="commentsDataUrl"
						:can-create="true"
						@comment-count="commentCountUpdated"
				></Comments>
			</div>
			<div v-if="activeTab === 'installations'">
				<div class="card">
					<div class="card-body">
						<ProjectInstallationList
								:list-url="installationListUrl"
								:active-tab-command="activeTabCommand"
								:active-tab-parameter="activeTabParameter"
								:file-upload-url="fileUploadUrl"
								:products="products"
								:user-levels="userLevels"
								:editable="editable && installation.editable"
								:can-start-editing="canStartEditing"
								@start-editing="startEditing"
								:installation-data-url="installationDataUrl"
								:installation-document-types="installationDocumentTypes"
								:lock="lock"
						></ProjectInstallationList>
					</div>
				</div>
			</div>
			<div class="mt-3 mb-4">
				<div class="d-flex flex-wrap gap-2">
					<a :href="backUrl" v-if="backUrl" class="btn btn-secondary btn-label"><i class="fas fa-angle-left label-icon"></i> Vissza</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ClientForm from '../clients/ClientForm.vue'
import {formatDate, formatFileSize} from "../../functions";
import Comments from "../../components/Comments.vue";
import FlashDisplay from "../../components/FlashDisplay.vue";
import AddressInput from "../../components/AddressInput.vue";
import ProjectFormAttributes from "../projects/ProjectFormAttributes.vue";
import ProjectFormDocumentCategory from "../projects/ProjectFormDocumentCategory.vue";
import EmailAddress from "../../components/EmailAddress.vue";
import PhoneNumber from "../../components/PhoneNumber.vue";
import ProjectInstallationList from "../projects/ProjectInstallationList.vue";
import InvoiceList from "../projects/InvoiceList.vue";
import LockMessage from "../../components/LockMessage.vue";

export default {
	components: {
		LockMessage,
		InvoiceList,
		ProjectInstallationList,
		ClientForm,
		Comments,
		FlashDisplay,
		AddressInput,
		ProjectFormAttributes,
		ProjectFormDocumentCategory,
		EmailAddress,
		PhoneNumber,
	},
	props: {
		installation: Object,
		initialProject: Object,
		dataUrl: String,
		backUrl: String,
		initialFlash: Array,
		cities: Array,
		projectTypes: Array,
		productCategories: Array,
		users: Array,
		fileUploadUrl: String,
		installationListUrl: String,
		installationDataUrl: String,
		products: Array,
		userLevels: Array,
		currencies: Object,
		taxes: Object,
		quoteStatusLabels: Object,
		productTypes: Object,
		commentsDataUrl: String,
		initialStats: Object,
		installationDocumentTypes: Array,
		roles: Array,
	},
	data(){
		return {
			project: this.initialProject,
			loading: false,
			flash: [],
			client: this.initialProject.client !== undefined ? this.initialProject.client : null,
			projectType: this.initialProject.project_type !== undefined ? this.initialProject.project_type : null,
			uploadedFiles: {},
			fields: [],
			alertTimeout: null,
			unsavedChanged: false,
			activityTimer: null,
			isInactive: false,
			activeTab: 'attributes',
			handlerUser: this.initialProject.handler_user_id,
			activeDocumentType: null,
			activeTabCommand: null,
			activeTabParameter: null,
			unloadHandler: null,
			detailsVisible: false,
			tabBadges: this.initialStats,
			highlightComment: 0,
			lock: {
				locked: false,
				lockedByCurrentUser: false,
				lockedByUserName: '',
				lockedByClientName: '',
				lockedAt: false,
			},
			tabs: {
				attributes: {
					name: 'Adatlap',
					icon: 'file-spreadsheet'
				},
				comments: {
					name: 'Üzenőfal',
					icon: 'comments'
				},
				documents: {
					name: 'Dokumentumok',
					icon: 'folder-open'
				},
				installations: {
					name: 'Telepítések',
					icon: 'tools'
				},
			}
		}
	},
	created: function () {
		this.moment = moment

		setInterval(this.updateLock, 10000);

		window.onmousemove = this.resetTimer;
		window.onmousedown = this.resetTimer;
		window.ontouchstart = this.resetTimer;
		window.ontouchmove = this.resetTimer;
		window.onclick = this.resetTimer;
		window.onkeydown = this.resetTimer;
		window.addEventListener('scroll', this.resetTimer, true);

		window.addEventListener("hashchange", this.onHashChange, false)

		this.onHashChange()

		for (let i = 0; i < this.projectType.document_types.length; i++) {
			if (this.activeDocumentType === null) {
				this.activeDocumentType = this.projectType.document_types[i]
			}
		}

		this.updateLock()
	},
	mounted() {
		this.flash = this.initialFlash
	},
	computed: {
		clientAddress: {
			get() {
				for (let i = 0; i < this.projectType.fields_data.length; i++){
					if (this.projectType.fields_data[i].type === 'address'){
						if (this.project.attribute_values[this.projectType.fields_data[i].id] !== undefined){
							return this.project.attribute_values[this.projectType.fields_data[i].id].formatted_value
						}
					}
				}
				return ''
			}
		},
		editable: {
			get() {
				return this.lock.locked && this.lock.lockedByCurrentUser
			}
		},
		canStartEditing: {
			get() {
				return !this.lock.locked && this.installation.editable
			}
		}
	},
	methods: {
		formatDate: formatDate,
		formatFileSize: formatFileSize,
		hasAccess: function(category){
			return (this.project.rights[category] !== undefined && this.project.rights[category].indexOf('read') > -1)
		},
		showLoading: function(){
			document.getElementsByTagName('html')[0].classList.add('loading')
		},
		hideLoading: function(){
			document.getElementsByTagName('html')[0].classList.remove('loading')
		},
		onHashChange: function(){
			if (location.hash && location.hash.length > 1){
				let tmp = location.hash.substring(1).split('-')
				if (tmp[0] !== undefined) {
					this.activeTabCommand = null
					this.activeTabParameter = null
					if (tmp[1] !== undefined){
						let t = tmp[1].split(':')
						this.activeTabCommand = t[0]
						this.activeTabParameter = t[1] !== undefined ? t[1] : null
					}
					this.setActiveTab(tmp[0], tmp[1] || '')
				}
			}
		},
		resetTimer: function(){
			clearTimeout(this.activityTimer);
			let that = this

			this.activityTimer = setTimeout(function(){
				if (that.editable) {
					that.isInactive = true
				}
			}, 3600000);
		},
		onSearch(search, loading) {
			if(search.length) {
				loading(true);
				this.search(loading, search, this);
			}
		},
		search: function(loading, search, vm) {
			fetch(
				this.dataUrl + '?action=clientSearch&q=' + search
			).then(res => {
				res.json().then(json => (vm.clientOptions = json.results));
				loading(false);
			});
		},
		lockResponse: function(response){
			this.lock.lockedAt = response.data.lockedAt
			this.lock.locked = response.data.locked
			this.lock.lockedByCurrentUser = response.data.lockedByCurrentUser
			this.lock.lockedByUserName = response.data.lockedByUserName
			this.lock.lockedByClientName = response.data.lockedByClientName
		},
		updateLock: function(){
			if (this.isInactive){
				return
			}
			if (this.project !== undefined && this.project.id) {
				let that = this
				axios.post(this.dataUrl + '?action=updateLock').then((response) => {
					this.lockResponse(response)
				}).catch(function (error) {
					that.flash = [{level: 'danger', message: error.response.data.message}]
				})
			}
		},
		reloadPage: function (){
			this.formClean()
			location.reload()
		},
		startEditing: function(){
			this.showLoading()
			let that = this
			axios.post(this.dataUrl + '?action=lock').then((response)=>{
				this.lockResponse(response)
				this.hideLoading()
			}).catch(function (error) {
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		handleResponse: function (response){
			if (response.data.redirect !== undefined){
				location.href = response.data.redirect
			} else {
				this.hideLoading()
			}

			if (response.data.flash !== undefined){
				this.flash = response.data.flash
			}

			this.$refs.projectAttributes.updateVisibleFields()
		},
		formFlash: function(flash){
			this.flash = flash
		},
		commentCountUpdated: function(count){
			this.tabBadges.comments = count
		},
		updateStats: function (stats){
			this.tabBadges = stats
		},
		getDocumentTypeCount: function(type){
			if (type === null){
				return 0
			}
			if (typeof type === 'object') {
				return this.project.documents[type.id].length
			}

			return 0
		},
		setActiveTab: function(tab, params){
			params = params || ''
			this.activeTab = tab
			location.hash = tab + (params ? '-' + params : '')
		},
	}
}
</script>