<template>
	<DataTable
		:data-url="dataUrl"
		:initial-flash="initialFlash"
		:columns="columns"
		sort-by="name"
		sort-type="asc"
		name="users"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
				:data="item"
				:edit-url="editUrl"
				:delete-url="deleteUrl"
				:is-editable="isEditable"
				:is-deletable="true"
				:emit-delete-event="true"
				@deleteButtonPressed="deleteButtonPressed"
			></TableActionCell>
		</template>
		<template #item-active="item">
			<BooleanBadge
				:data="item"
				name="active"
				:key="'active_' + item.id"
			></BooleanBadge>
		</template>
		<template #item-last_login="item">
			{{ formatDate(item.last_login) }}
		</template>
		<template #item-level="item">
			<UserLevelBadge
				:user-level-id="item.user_level_id"
				:user-levels="userLevels"
				:small="true"
				:short="true"
			/>
		</template>
		<template #item-commission="item">
			<Price
				:value="item.commission"
				:currencies="currencies"
			/>
		</template>
		<template #item-type="item">
			<ColorBadge
				:value="typeLabels[item.type]" :small="true"></ColorBadge>
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import {formatDate} from "../../functions";
import BooleanBadge from "../../components/BooleanBadge.vue";
import UserLevelBadge from "../../components/UserLevelBadge.vue";
import Price from "../../components/Price.vue";
import ColorBadge from "../../components/ColorBadge.vue";

export default {
	components: {
		ColorBadge,
		Price,
		UserLevelBadge,
		DataTable,
		BooleanBadge,
		TableActionCell
	},
	props: {
		dataUrl: String,
		editUrl: String,
		deleteUrl: String,
		isEditable: Boolean,
		showUserLevels: Boolean,
		showCommissions: Boolean,
		userLevels: Array,
		currencies: Array,
		typeLabels: Object,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Aktív',
				value: 'active',
				sortable: true,
			}
		]

		columns.push({
			text: 'Név',
			value: 'name',
			sortable: true
		})
		columns.push({
			text: 'Típus',
			value: 'type',
			sortable: true
		})

		if (this.showUserLevels){
			columns.push({
				text: 'Szint',
				value: 'level',
				sortable: true
			})
		}

		columns.push({
			text: 'E-mail cím',
			value: 'email',
			sortable: true
		})
		columns.push({
			text: 'Telefonszám',
			value: 'phone',
			sortable: true
		})
		columns.push({
			text: 'Utolsó belépés',
			value: 'last_login',
			sortable: true,
		})

		if (this.showCommissions){
			columns.push({
				text: 'Jutalék',
				value: 'commission',
				sortable: true
			})
		}

		columns.push({
			label: '',
			value: 'operations',
			width: 5,
		})

		return {
			columns: columns,
		}
	},
	methods: {
		formatDate: formatDate,
	}
}
</script>

<style scoped>
</style>
