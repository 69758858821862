<template>
	<flash-display :flash="inlineFlashes" :inline="true"></flash-display>
	<div class="card">
		<div class="card-body">
			<div class="details-box mb-3">
				<h4>Projekt - <span :class="'project-type-badge project-type-badge-' + projectType.luminosity" :style="'background:' + projectType.color + ';'"><i :class="'far fa-' + projectType.icon"></i><span>{{ projectType.name }}</span></span></h4>
				<div v-if="contract.project.id !== undefined">
					<i class="far fa-hashtag"></i> Azonosító:
					<a :href="projectViewUrl + '/' + contract.project.id" target="_blank">{{ contract.project.public_id }}</a>
				</div>
				<div v-if="contract.project.created_by_user_name">
					<i class="far fa-user"></i> Létrehozó: <strong>{{ contract.project.created_by_user_name }}</strong>
				</div>
				<div v-if="contract.project.created_at">
					<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(contract.project.created_at) }}</strong>
				</div>
				<div v-if="contract.project.handler_user_name">
					<i class="far fa-user-tie"></i> Tanácsadó: <strong>{{ contract.project.handler_user_name }}</strong>
				</div>
			</div>

			<div class="details-box mb-0">
				<h4>Szerződés</h4>
				<div v-if="contract.id !== undefined">
					<i class="far fa-hashtag"></i> Azonosító:
					<a :href="projectViewUrl + '/' + contract.project.id + '#contracts-highlight:' + contract.id" target="_blank">{{ contract.public_id }}</a>
				</div>
				<div v-if="contract.created_at">
					<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(contract.created_at) }}</strong>
				</div>
				<div v-if="contract.created_by_user_name">
					<i class="far fa-user"></i> Létrehozó: <strong>{{ contract.created_by_user_name }}</strong>
				</div>
				<div v-if="contract.updated_at">
					<i class="far fa-calendar-alt"></i> Módosítva: <strong>{{ formatDate(contract.updated_at) }}</strong>
				</div>
			</div>
		</div>
	</div>

	<div class="card">
		<div class="card-body">
			<DataTable
					:data-url="dataUrl"
					:initial-flash="initialFlash"
					:columns="columns"
					sort-by="order"
					sort-type="asc"
					name="contractDeliveries"
					ref="table"
					:default-filters="defaultFilters"
					@data-loaded="tableDataLoaded"
			>

				<template #filters="{serverOptions}">
					<div class="col-12 col-md-3">
						<v-select
								:options="productCategories"
								v-model="serverOptions.product_category_id"
								:reduce="item => item.id"
								label="name"
								placeholder="Kategória"
						/>
					</div>
					<div class="col-12 col-md-2">
						<v-select
								:options="productManufacturers"
								v-model="serverOptions.product_manufacturer_id"
								:reduce="item => item.id"
								label="name"
								placeholder="Gyártó"
						/>
					</div>
					<div class="col-md-12 d-flex gap-2 align-items-center">
						<div class="d-flex flex-wrap gap-2 p-2 align-items-center rounded bg-light">
							<button type="button" class="btn btn-primary btn-label" :disabled="!selectedRowsReserve.length" @click="reserveSelected()"><i class="far fa-plus label-icon"></i> Kijelöltek lefoglalása</button>
							<button type="button" class="btn btn-primary btn-label" :disabled="!hasReservableItems" @click="reserveAll()"><i class="far fa-plus-square label-icon"></i> Összes lefoglalása</button>
						</div>
						<div class="d-flex flex-wrap gap-2 p-2 align-items-center rounded bg-light">
							<button type="button" class="btn btn-warning btn-label" :disabled="!selectedRowsRelease.length" @click="releaseSelected()"><i class="far fa-minus label-icon"></i> Kijelöltek feloldása</button>
							<button type="button" class="btn btn-warning btn-label" :disabled="!hasReservedItems" @click="releaseAll()"><i class="far fa-minus-square label-icon"></i> Összes feloldása</button>
						</div>


						<div style="width: 250px">
							<v-select
								:options="warehouseOptions"
								v-model="warehouseId"
								:reduce="item => item.id"
								:clearable="false"
								label="name"
								placeholder="Raktár"
								@input="tableDataLoaded"
							/>
						</div>
					</div>
				</template>

				<template #item-productName="item">
					<div class="fw-bold">{{ item.productName }}</div>
					<div v-if="productCategoriesObject[item.product_category_id]" class="text-muted"><i class="far fa-sitemap"></i> {{ productCategoriesObject[item.product_category_id].name }}</div>
					<div class="text-muted"><i class="far fa-hashtag"></i> {{ item.sku }}</div>
				</template>
				<template #item-clientName="item">
					<div class="fw-bold">{{ item.clientName }}</div>
					<div v-if="item.address" class="text-muted"><i class="far fa-map-marker"></i> {{ item.address }}</div>
					<div class="text-muted"><i class="far fa-hashtag"></i> {{ item.public_id }}</div>
				</template>
				<template #item-reserved="item">
					<span :class="'fw-bold badge badge-xl bg-' + (item.reserved >= item.demand ? 'success' : 'warning')">{{ formatNumber(item.reserved) }}</span>
				</template>
				<template #item-reservedWarehouse="item">
					{{ formatNumber(getReserved(item)) }}
				</template>
				<template #item-rowSelectReserve="item">
					<div v-if="getReservableQuantity(item)" class="d-flex align-items-center gap-2">
						<div class="single-checkbox" @click.prevent="selectRowReserve(item)">
							<i :class="selectedRowsReserve.indexOf(item.id) > -1 ? 'fas fa-check-square text-primary' : 'far fa-square'"></i>
						</div>
						<div v-if="selectedRowsReserve.indexOf(item.id) > -1">
							<input-field
								label="Mennyiség"
								:field-only="true"
								:name="'quantity_' + item.id"
								data-type="number"
								input-type="text"
								:step="1"
								:min="1"
								:max="getReservableQuantity(item)"
								v-model="selectedQuantitiesReserve[item.id]"
								:errors="{}"
								:fixed-width="40"
								align="center"
							/>
						</div>
					</div>
				</template>
				<template #item-rowSelectRelease="item">
					<div v-if="getReserved(item)" class="d-flex align-items-center gap-2">
						<div class="single-checkbox" @click.prevent="selectRowRelease(item)">
							<i :class="selectedRowsRelease.indexOf(item.id) > -1 ? 'fas fa-check-square text-primary' : 'far fa-square'"></i>
						</div>
						<div v-if="selectedRowsRelease.indexOf(item.id) > -1">
							<input-field
								label="Mennyiség"
								:field-only="true"
								:name="'quantity_' + item.id"
								data-type="number"
								input-type="text"
								:step="1"
								:min="1"
								:max="getReserved(item)"
								v-model="selectedQuantitiesRelease[item.id]"
								:errors="{}"
								:fixed-width="40"
								align="center"
							/>
						</div>
					</div>
				</template>
				<template #item-demand="item">
					{{ formatNumber(item.demand) }}
				</template>
				<template #item-inStock="item">
					<div :class="{'text-success': getStock(item) >= item.demand, 'fw-bold': 1}">
						{{ formatNumber(getStock(item)) }}
					</div>
				</template>
				<template #item-required_amount_paid_at="item">
					{{ formatDate(item.required_amount_paid_at) }}
				</template>
			</DataTable>
		</div>
	</div>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import {formatDate, formatNumber} from "../../functions";
import BooleanBadge from "../../components/BooleanBadge.vue";
import vSelect from "vue-select";
import FlashDisplay from "../../components/FlashDisplay.vue";
import InputField from "../../components/form/InputField.vue";
import FileList from "../../components/FileList.vue";
import Price from "../../components/Price.vue";

export default {
	components: {
		Price, FileList,
		InputField,
		FlashDisplay,
		BooleanBadge,
		TableActionCell,
		DataTable,
		vSelect
	},
	props: {
		dataUrl: String,
		storeUrl: String,
		contractViewUrl: String,
		projectViewUrl: String,
		userId: Number,
		contract: Object,
		productCategories: Array,
		productManufacturers: Array,
		warehouses: Array,
		productStockInitial: Object,
		projectType: Object,
		productReservations: Object,
		contractStatusLabels: Object,
		responses: [],
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
		milestones: Array,
	},
	watch: {
		warehouseId(newValue, oldValue) {
			if (newValue !== oldValue){
				this.tableDataLoaded()
			}
		}
	},
	data(){
		let columns = [
			{
				text: 'Termék',
				value: 'productName',
				sortable: true
			},
			{
				text: 'Igény',
				value: 'demand',
				align: 'right',
				sortable: true
			},
			{
				text: 'Raktáron',
				value: 'inStock',
				align: 'right',
				sortable: false
			},
			{
				text: 'Lefoglal',
				value: 'rowSelectReserve',
				width: 5,
			},
			{
				text: 'Lefoglalva',
				value: 'reserved',
				align: 'right',
				sortable: true
			},
			{
				text: 'Felold',
				value: 'rowSelectRelease',
				width: 5,
			},
			{
				text: 'Lefoglalva raktárból',
				value: 'reservedWarehouse',
				align: 'right',
				sortable: false
			},
		]
		return {
			columns: columns,
			defaultFilters: {
				status: 'pending'
			},
			productManufacturersObject: _.keyBy(this.productManufacturers, 'id'),
			productCategoriesObject: _.keyBy(this.productCategories, 'id'),
			warehousesObject: _.keyBy(this.warehouses, 'id'),
			warehouseId: 0,
			selectedRowsReserve: [],
			selectedQuantitiesReserve: {},
			selectedRowsRelease: [],
			selectedQuantitiesRelease: {},
			inlineFlashes: [],
			productStock: this.productStockInitial,
			hasReservableItems: false,
			hasReservedItems: false,
		}
	},
	computed: {
		statusLabelsExtended: {
			get(){
				let payload = []
				for (let i in this.statusLabels){
					payload.push({
						value: i,
						name: this.statusLabels[i].name,
						icon: this.statusLabels[i].icon,
						color: this.statusLabels[i].color,
					})
				}

				return payload
			}
		},
		projectTypesArray: {
			get(){
				return _.values(this.projectTypes)
			}
		},
		warehouseOptions: {
			get() {
				let payload = this.warehouses
				payload.unshift({name: 'Összes raktár', id: 0})

				return payload
			}
		}
	},
	methods: {
		formatDate: formatDate,
		formatNumber: formatNumber,
		showLoading: function(){
			document.getElementsByTagName('html')[0].classList.add('loading')
		},
		hideLoading: function(){
			document.getElementsByTagName('html')[0].classList.remove('loading')
		},
		getStock: function(item){
			if (this.productStock[item.product_id] !== undefined){
				let sum = 0
				for (let i in this.productStock[item.product_id]){
					if (this.warehouseId === parseInt(i) || !this.warehouseId){
						sum += this.productStock[item.product_id][i]
					}
				}

				return sum
			}
		},
		sendReservationRequest: function (data){
			this.showLoading()

			let that = this
			axios.post(this.storeUrl, data).then((response) => {
				this.hideLoading()
				this.inlineFlashes = response.data.messages
				this.$refs.table.getData()
				this.selectedRowsReserve = []
				this.selectedQuantitiesReserve = {}
				this.selectedRowsRelease = []
				this.selectedQuantitiesRelease = {}

				this.productStock = response.data.productStock
			}).catch(function (error) {
				that.hideLoading()
			})
		},
		reserveSelected: function (){
			if (!this.selectedRowsReserve.length){
				return
			}

			this.sendReservationRequest({
				reserve: this.selectedQuantitiesReserve,
				warehouseId: this.warehouseId
			})
		},
		reserveAll: function (){
			this.sendReservationRequest({
				reserve: 'all',
				warehouseId: this.warehouseId
			})
		},
		releaseSelected: function (){
			if (!this.selectedRowsRelease.length){
				return
			}

			this.sendReservationRequest({
				release: this.selectedQuantitiesRelease,
				warehouseId: this.warehouseId
			})
		},
		releaseAll: function (){
			this.sendReservationRequest({
				release: 'all',
				warehouseId: this.warehouseId
			})
		},
		getReservableQuantity: function(item){
			return Math.min(item.demand - item.reserved, this.getStock(item))

		},
		selectRowReserve: function(item){
			if (this.selectedRowsReserve.indexOf(item.id) > -1){
				this.selectedRowsReserve.splice(this.selectedRowsReserve.indexOf(item.id), 1)
				delete this.selectedQuantitiesReserve[item.id]
			} else {
				this.selectedRowsReserve.push(item.id)
				this.selectedQuantitiesReserve[item.id] = this.getReservableQuantity(item)
			}
		},
		selectRowRelease: function(item){
			if (this.selectedRowsRelease.indexOf(item.id) > -1){
				this.selectedRowsRelease.splice(this.selectedRowsRelease.indexOf(item.id), 1)
				delete this.selectedQuantitiesRelease[item.id]
			} else {
				this.selectedRowsRelease.push(item.id)
				this.selectedQuantitiesRelease[item.id] = this.getReserved(item)
			}
		},
		tableDataLoaded: function(e){
			this.hasReservableItems = false
			this.hasReservedItems = false

			for (let i = 0; i < this.$refs.table.items.length; i++){
				if (this.getReservableQuantity(this.$refs.table.items[i]) > 0){
					this.hasReservableItems = true
				}
				if (this.$refs.table.items[i].reserved > 0){
					let reservedInWarehouse = this.$refs.table.items[i].reservedInWarehouses.split(',')
					reservedInWarehouse = reservedInWarehouse.map(str => {
						return parseInt(str, 10);
					})
					if (!this.warehouseId || reservedInWarehouse.indexOf(this.warehouseId) > -1) {
						this.hasReservedItems = true
					}
				}
			}
		},
		getReserved: function(item){
			if (item.reservationDetails === null){
				return 0
			}
			let reservations = item.reservationDetails.split(',')
			let payload = 0

			for (let i = 0; i < reservations.length; i++){
				let tmp = reservations[i].split(':')
				let warehouseId = parseInt(tmp[0])

				if (!this.warehouseId || this.warehouseId === warehouseId){
					payload += parseInt(tmp[1])
				}
			}

			return payload
		}
	}
}
</script>

<style scoped>
</style>

