<template>
	<div>
		<form-wrapper
			ref="formWrapper"
			:store-url="storeUrl"
			:back-url="backUrl"
			:initial-flash="initialFlash"
			v-model="attributes"
			v-slot="{ errors }"
		>
			<div class="card mb-3" v-if="attributes.id !== undefined">
				<div class="card-body">
					<div class="details-box">
						<div v-if="attributes.created_at">
							<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(attributes.created_at) }}</strong>
						</div>
						<div v-if="attributes.modified_at">
							<i class="far fa-calendar-alt"></i> Módosítva: <strong>{{ formatDate(attributes.modified_at) }}</strong>
						</div>
					</div>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
									label="Név"
									name="name"
									rules="required"
									v-model="attributes.name"
									:disabled="!allowEditing"
									:errors="errors"
							/>
							<input-field
									label="Rövid név"
									name="name_short"
									rules="required"
									v-model="attributes.name_short"
									:disabled="!allowEditing"
									:errors="errors"
							/>
							<input-field
									label="Sorrend"
									name="order"
									rules="required"
									data-type="number"
									v-model="attributes.order"
									:disabled="!allowEditing"
									:errors="errors"
							/>
							<iconpicker-field
									v-model="attributes.icon"
									label="Ikon"
									name="icon"
							/>
							<div class="mb-3">
								<label>Szín</label>
								<input type="color" class="form-control form-control-color" v-model="attributes.color"  />
							</div>

							<input-field
									label="Jutalom (pénz)"
									name="reward_money"
									data-type="number"
									v-model="attributes.reward_money"
									suffix="Ft"
									:disabled="!allowEditing"
									:errors="errors"
							/>
							<input-field
									label="Jutalom (ajándék)"
									name="reward_gift"
									v-model="attributes.reward_gift"
									:disabled="!allowEditing"
									:errors="errors"
							/>
						</div>
						<div class="col-sm-6">
							<input-field
									label="Szükséges egység"
									name="required_units"
									v-model="attributes.required_units"
									data-type="number"
									:step="1"
									:disabled="!allowEditing"
									:errors="errors"
							/>
							<input-field
									label="Szintlépés egységszámítás időablak"
									suffix="hónap"
									name="time_window_months"
									rules="required"
									v-model="attributes.time_window_months"
									data-type="number"
									:step="1"
									:min="1"
									:disabled="!allowEditing"
									:errors="errors"
							/>
							<input-field
									label="Egy beosztott egységeinek maximum aránya"
									suffix="%"
									name="max_ratio_per_underling"
									rules="required"
									v-model="attributes.max_ratio_per_underling"
									data-type="number"
									:step="0.1"
									:min="0"
									:disabled="!allowEditing"
									:errors="errors"
							/>
							<input-field
									label="Direkt jutalék"
									name="direct_commission"
									rules="required"
									v-model="attributes.direct_commission"
									data-type="number"
									suffix="%"
									:step="0.1"
									:min="0"
									:disabled="!allowEditing"
									:errors="errors"
							/>
							<input-field
									label="Csapat jutalék"
									name="indirect_commission"
									rules="required"
									v-model="attributes.indirect_commission"
									data-type="number"
									suffix="%"
									:step="0.1"
									:min="0"
									:disabled="!allowEditing"
									:errors="errors"
							/>
							<switch-field
								label="Szintépésnél felettes feljebbléptetése"
								name="propagate_to_superior"
								v-model="attributes.propagate_to_superior"
								/>
						</div>
					</div>
				</div>
			</div>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import FormWrapper from "../../components/FormWrapper.vue";
import {formatDate} from "../../functions";
import SwitchField from "../../components/form/SwitchField.vue";
import IconpickerField from "../../components/form/IconpickerField.vue";
export default {
	props: ['initialAttributes', 'storeUrl', 'backUrl', 'initialFlash', 'allowEditing'],
	components: {IconpickerField, SwitchField, InputField, FormWrapper},
	data(){
		return {
			attributes: this.initialAttributes,
			loading: false,
			flash: [],
		}
	},
	mounted() {
	},
	methods: {
		formatDate: formatDate,
	}
}
</script>

<style scoped>

</style>