<template>
	<select-field
		:label="label"
		:name="name"
		v-model="componentValue"
		:get-option-label="formatLabel"
		:options="options"
		:errors="errors"
		:filter-options="filterOptions"
		:search="search"
		:display-search-info-bottom="true"
	/>
</template>

<script>
import SelectField from "./SelectField.vue";
import {formatDate} from "../../functions";

export default {
	emits: ['update:modelValue'],
	components: {SelectField},
	props: {
		modelValue: Number,
		errors: Object,
		name: String,
		label: String,
		typeFilter: String,
		rules: {
			type: String,
			default: ''
		},
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	mounted() {
		this.search(null, '')
	},
	data(){
		return {
			options: []
		}
	},
	methods: {
		search: function (loading, search){
			let that = this
			let url = '/projects/contractSearch?q=' + search
			if (this.typeFilter){
				url += '&type=' + this.typeFilter
			}
			if (this.componentValue){
				url += '&id=' + this.componentValue
			}

			fetch(
				url
			).then(res => {
				res.json().then(json => (that.options = json.results));
				if (loading !== null) {
					loading(false)
				}
			});
		},
		formatLabel: function(item){
			return item.public_id + ' (' + item.clientName + ' / ' + item.address + ')'
		},
		filterOptions: function(options, search){
			return options
		}
	}
}
</script>

<style scoped>

</style>