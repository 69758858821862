<template>
	<div :class="{'card': 1, 'd-none': !show}">
		<div class="card-body">
			<h4 class="card-title mb-4">Szerződésmódosítások</h4>
			<DataTable
					:data-url="dataUrl"
					:initial-flash="[]"
					:columns="columns"
					sort-by="created_at"
					ref="contractAmendmentsTable"
					sort-type="desc"
					name="contractAmendments"
					@no-results-found="show = false"
					:compact="true"
			>

				<template #item-operations="{item, deleteButtonPressed}">
					<TableActionDropdown
							:data="item"
							:emit-edit-event="true"
							:emit-view-event="true"
							:emit-delete-event="true"
							:is-editable="item.status === 'draft' && item.editable && editable"
							:is-deletable="item.deletable && editable"
							@edit-button-pressed="openContract"
							@view-button-pressed="openContract"
							@delete-button-pressed="deleteContract"
							v-slot="{ row }"
					>
						<a class="dropdown-item" href="#" @click.prevent="generateContract(row.id)" v-if="editable && row.id && row.status === 'draft' && canUpdate">
							<i class="fas fa-file-contract fa-fw label-icon text-warning"></i> Lezárás
						</a>
						<a href="#" class="dropdown-item" @click.prevent="openResponseForm(row.id)" v-if="editable && row.status === 'generated' && canAnswer">
							<i class="far fa-reply fa-fw me-1 text-warning"></i> Ügyfél válasz rögzítése
						</a>
						<a class="dropdown-item" :href="formDataUrl + '/' + row.id + '?action=download'"  v-if="row.file_id">
							<i class="far fa-download fa-fw me-1 text-secondary"></i> Letöltés
						</a>
					</TableActionDropdown>
				</template>

				<template #item-public_id="item">
					<div class="fw-bold">{{ item.public_id }}</div>
					{{ item.installationId ? 'Telepítés' : item.description }}
				</template>

				<template #item-total_price_net="item">
					<Price
							:value="item.total_price_net"
							:currency-id="item.currency_id"
							:currencies="currencies"
							v-if="item.total_price_net"
					/>
				</template>

				<template #item-total_price_gross="item">
					<Price
							:value="item.total_price_gross"
							:currency-id="item.currency_id"
							:currencies="currencies"
					/>
				</template>

				<template #item-created_at="item">
					<div class="text-end text-nowrap">
						{{ formatDate(item.created_at) }}
					</div>
				</template>

				<template #item-status="item">
					<span :class="'badge badge-xl bg-' + contractStatusLabels[item.status].color">
						<i :class="contractStatusLabels[item.status].icon"></i> {{ contractStatusLabels[item.status].name }}
					</span>
				</template>
			</DataTable>
		</div>
	</div>

	<teleport to="body" v-if="formOpen">
		<ContractAmendmentForm
				:original-contract-id="contract.id"
				:contract-id="formId"
				:product-categories="productCategories"
				:client="client"
				:products="products"
				:taxes="taxes"
				:data-url="formDataUrl"
				:product-types="productTypes"
				:delivery-type-labels="deliveryTypeLabels"
				:contract-status-labels="contractStatusLabels"
				:file-upload-url="fileUploadUrl"
				:currencies="currencies"
				:editable="editable"
				:can-start-editing="canStartEditing"
				:lock="lock"
				@generate="generateContract"
				@close="closeForm"
				@close-and-reload="closeFormAndUpdateList"
				@start-editing="$emit('startEditing')"
		></ContractAmendmentForm>
	</teleport>
	<teleport to="body" v-if="responseFormId">
		<ContractResponseForm
				:contract-id="responseFormId"
				:data-url="formDataUrl"
				:currencies="currencies"
				:file-upload-url="fileUploadUrl"
				@close="responseFormId = null"
				@close-and-reload="closeContractResponseFormAndUpdateList"
		></ContractResponseForm>
	</teleport>
</template>

<script>
import {formatDate} from "../../functions";
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import Price from "../../components/Price.vue";
import DataTable from "../../components/DataTable.vue";
import ContractAmendmentForm from "./ContractAmendmentForm.vue";
import ContractResponseForm from "./ContractResponseForm.vue";
import ProjectInstallationList from "./ProjectInstallationList.vue";

export default {
	components: {ProjectInstallationList, ContractResponseForm, ContractAmendmentForm, Price, TableActionDropdown, DataTable},
	emits: ['formFlash', 'changed', 'startEditing'],
	props: {
		dataUrl: String,
		formDataUrl: String,
		contract: Object,
		contractStatusLabels: Object,
		currencies: Object,
		lock: Object,
		editable: Boolean,
		canStartEditing: Boolean,
		productCategories: [Array, Object],
		products: Array,
		client: Object,
		taxes: Object,
		productTypes: Object,
		activeTabCommand: [String, null],
		activeTabParameter: [String, null],
		deliveryTypeLabels: Object,
		fileUploadUrl: String,
		canUpdate: Boolean,
		canAnswer: Boolean,
		canCreateContractAmendments: Boolean
	},
	mounted() {
		if (this.activeTabCommand === 'amendment' && this.activeTabParameter){
			let tmp = this.activeTabParameter.split('|')
			this.openContract(tmp[1])
		}
	},
	data(){
		return {
			formOpen: false,
			formId: null,
			show: true,
			responseFormId: null,
			columns: [
				{
					text: 'Azonosító',
					value: 'public_id',
					sortable: true,
				},
				{
					text: 'Állapot',
					value: 'status',
					sortable: false,
				},
				{
					text: 'Termékek',
					value: 'productsSum',
					align: 'right',
					sortable: true,
					width: 10
				},
				{
					text: 'Nettó',
					value: 'total_price_net',
					align: 'right',
					sortable: true,
					width: 10
				},
				{
					text: 'Bruttó',
					value: 'total_price_gross',
					align: 'right',
					sortable: true,
					width: 10
				},
				{
					text: 'Létrehozva',
					value: 'created_at',
					sortable: true,
					width: 10
				},
				{
					label: '',
					value: 'operations',
					width: 5,
				}
			]
		}
	},
	methods: {
		formatDate,
		closeFormAndUpdateList: function(){
			this.closeForm()
			this.show = true
			this.$refs.contractAmendmentsTable.getData()
		},
		closeForm: function(){
			this.formOpen = false
			this.formId = null
		},
		openContract: function(contractId){
			this.formId = contractId !== null ? contractId : null
			this.formOpen = true
		},
		openResponseForm: function (id){
			this.responseFormId = id
		},
		deleteContract: function(contractId){
			let that = this
			axios.post(this.formDataUrl + '/' + contractId + '?action=delete').then((response)=>{
				if (response.data.flash !== undefined){
					this.$emit('formFlash', response.data.flash);
				}
				if (response.data.status === 'success'){
					this.$refs.contractAmendmentsTable.getData()
					this.$emit('changed');
				}
			}).catch(function (error) {
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		generateContract: function(contractId){
			let that = this
			axios.post(this.formDataUrl + '/' + contractId + '?action=generate').then((response)=>{
				if (response.data.flash !== undefined){
					this.$emit('formFlash', response.data.flash);
				}
				if (response.data.status === 'success'){
					this.closeForm()
					this.$refs.contractAmendmentsTable.getData()
					this.$emit('changed');
				}
			}).catch(function (error) {
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		closeContractResponseFormAndUpdateList: function(){
			this.responseFormId = null
			this.$refs.contractAmendmentsTable.getData()
		},
	}
}
</script>

<style scoped>

</style>