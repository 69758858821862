<template>
	<div>
		<form-wrapper
			ref="formWrapper"
			:store-url="storeUrl"
			:back-url="backUrl"
			:initial-flash="initialFlash"
			v-model="attributes"
			v-slot="{ errors }"
		>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-4">Adatok</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!allowEditing"
								:errors="errors"
							/>
							<input-field
								label="Cím"
								name="address"
								rules="required"
								v-model="attributes.address"
								:disabled="!allowEditing"
								:errors="errors"
							/>
						</div>
						<div class="col-sm-6">
							<input-field
								label="Cégnév"
								name="company_name"
								rules="required"
								v-model="attributes.company_name"
								:disabled="!allowEditing"
								:errors="errors"
							/>
							<switch-field
								label="Mozgó raktár"
								name="mobile"
								v-model="attributes.mobile"
								></switch-field>
						</div>
					</div>
				</div>
			</div>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import FormWrapper from "../../components/FormWrapper.vue";
import SwitchField from "../../components/form/SwitchField.vue";

export default {
	components: {SwitchField, InputField, FormWrapper},
	props: ['initialAttributes', 'storeUrl', 'backUrl', 'initialFlash', 'allowEditing'],
	data(){
		return {
			attributes: this.initialAttributes,
			loading: false,
			flash: []
		}
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style scoped>

</style>