<template>
	<DataTable
			:data-url="dataUrl"
			:initial-flash="initialFlash"
			:columns="columns"
			sort-by="created_at"
			sort-type="desc"
			name="userLevelLogList"
			ref="table"
	>
		<template #item-created_at="item">
			{{ formatDate(item.created_at) }}
		</template>
		<template #item-level="item">
			<UserLevelBadge :user-level-id="item.user_level_id" :user-levels="userLevels" />
		</template>
		<template #item-reward_paid="item">
			<template v-if="!item.reward_paid">
				<button class="btn btn-success btn-sm btn-label" @click.prevent="markAsPaid(item.id)"><i class="far fa-check label-icon"></i> Kifizetettre állít</button>
			</template>
			<template v-else>
				<BooleanBadge :value="true" />
			</template>
		</template>
		<template #item-direction="item">
			<span class="badge badge-xl bg-success" v-if="item.action === 'up'">
				<i class="fas fa-arrow-up"></i> Fel
			</span>
			<span class="badge badge-xl bg-danger" v-else>
				<i class="fas fa-arrow-down"></i> Le
			</span>
		</template>
		<template #item-reward="item">
			<template v-if="item.selected_reward">
				{{ item.reward_name }}


			</template>
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import {formatDate} from "../../functions";
import BooleanBadge from "../../components/BooleanBadge.vue";
import UserLevelBadge from "../../components/UserLevelBadge.vue";
import Price from "../../components/Price.vue";

export default {
	emits: ['flash'],
	components: {
		Price,
		UserLevelBadge,
		DataTable,
		BooleanBadge,
		TableActionCell
	},
	props: {
		dataUrl: String,
		storeUrl: String,
		isEditable: Boolean,
		showUserLevels: Boolean,
		showCommissions: Boolean,
		userLevelsPermission: Boolean,
		userLevels: Array,
		currencies: Array,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Időpont',
				value: 'created_at',
			},
			{
				text: 'Szint',
				value: 'level',
			},
			{
				text: 'Irány',
				value: 'direction',
			},
			{
				text: 'Jutalom',
				value: 'reward',
			},
		]
		if (this.userLevelsPermission){
			columns.push({
				text: 'Kifizetve',
				value: 'reward_paid',
			})
		}

		return {
			columns: columns,
		}
	},
	methods: {
		formatDate: formatDate,
		markAsPaid: function(id){
			if (!this.userLevelsPermission){
				return
			}

			let that = this
			axios.post(this.storeUrl + '?action=levelRewardPaid', {id: id}).then((response)=>{
				if (response.data.success !== undefined && response.data.success) {
					this.$refs.table.getData()
				}
				if (response.data.flash){
					that.$emit('flash', response.data.flash)
				}
			}).catch(function (error) {
				that.$emit('flash', [{level: 'danger', message: error.response.data.message}])
			})
		}
	}
}
</script>

<style scoped>
</style>
