<template>
	<div class="card bg-secondary text-white">
		<div class="card-body">
			<h4 class="card-title mb-4">Ajánlói linkek</h4>
			<p>Az alábbi linkek egyikét küld el leendő ügyfeleidnek, hogy be tudják magukat regisztrálni.</p>
			<div class="row">
				<div class="col-md-6">
					<div>
						<i class="fas fa-user me-2"></i> Ajánlói link magánszemély: <a :href="consumerRefererLink" target="_blank">[link]</a> <CopyToClipboard :value="consumerRefererLink" />
					</div>
				</div>
				<div class="col-md-6">
					<div>
						<i class="fas fa-industry me-2"></i> Ajánlói link céges: <a :href="companyRefererLink" target="_blank">[link]</a> <CopyToClipboard :value="companyRefererLink" />
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="mb-4 card bg-primary bg-soft" v-if="pendingReward !== undefined && pendingReward.user_level_id !== undefined">
		<div class="card-body">
			<h4><i class="far fa-exclamation-circle"></i> Szintet léptél: {{ userLevelsObject[pendingReward.user_level_id].name }}</h4>
			<p>Az alábbi gombokkal válaszd ki, melyik jutalmat kéred:</p>
			<div class="d-flex gap-2">
				<button type="button" class="btn btn-secondary" @click.prevent="selectLevelReward('money')"><Price :value="userLevelsObject[pendingReward.user_level_id].reward_money" :currencies="currencies" /></button>
				<button type="button" class="btn btn-secondary" @click.prevent="selectLevelReward('gift')">{{ userLevelsObject[pendingReward.user_level_id].reward_gift }}</button>
			</div>
		</div>
	</div>
	<div class="row" v-if="userLevelPoints !== null">
		<div class="col-lg-6">
			<div class="card">
				<div class="card-body">
					<h4 class="card-title mb-4">Teljesítmény</h4>
					<p class="text-muted">Utolsó 3 hónap</p>
					<div class="row">
						<div class="col-6">
							<h3 class="text-primary fw-bold mb-0">
								{{ formatNumber(userLevelPoints.direct) }}
							</h3>
							<p class="text-muted">Saját egység</p>
						</div>
						<div class="col-6">
							<h3 class="text-warning fw-bold mb-0">
								{{ formatNumber(userLevelPoints.indirectSum) }}
							</h3>
							<p class="text-muted">Csoport egység</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-6" v-if="userLevelPoints.targetLevelId">
			<div class="card">
				<div class="card-body">
					<h4 class="card-title mb-4">Következő szint</h4>
					<div class="row">
						<div class="col-sm-6">
							<p class="text-muted">{{ userLevelsObject[userLevelPoints.targetLevelId].name }}</p>
							<h3>{{ formatNumber(userLevelsObject[userLevelPoints.targetLevelId].required_units) }}</h3>
						</div>
						<div class="col-sm-6">
							<div class="mt-4 mt-sm-0">
								<VueApexCharts
										type="radialBar"
										:height="'auto'"
										:options="chartOptions"
										:series="[userLevelPoints.targetProgress]"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card">
		<div class="card-body">
			<h4 class="card-title mb-4">Team</h4>
			<table class="table">
				<thead>
				<tr>
					<th>Név</th>
					<th class="text-end">SE</th>
					<th class="text-end">CSE</th>
					<th class="text-end">Összes</th>
					<th class="text-end">Csoport méret</th>
				</tr>
				</thead>
				<tbody>
				<tr v-for="user in userLevelPoints.indirect">
					<td>{{ user.userName }}</td>
					<td class="text-end">{{ formatNumber(user.direct) }}</td>
					<td class="text-end">{{ formatNumber(user.indirectSum) }}</td>
					<td class="text-end">{{ formatNumber(user.sum) }} ({{ formatNumber(user.usable) }})</td>
					<td class="text-end">{{ formatNumber(user.teamSize) }}</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<h4 class="card-title mb-4">Jutalékok</h4>
			<UserCommissionList
					:data-url="commissionListDataUrl"
					:currencies="currencies"
					:user-bonuses="userBonuses"
			/>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<h4 class="card-title mb-4">Eladások</h4>
			<UserSaleList
					:data-url="salesListDataUrl"
					:store-url="storeUrl"
					:currencies="currencies"
					:user-level-points="userLevelPoints"
					:user-levels="userLevels"
					:pending-reward="pendingReward"
					:user-levels-enabled="true"
			/>
		</div>
	</div>
</template>

<script>
import Price from "../../components/Price.vue";
import VueApexCharts from "vue3-apexcharts";
import {formatNumber} from "../../functions";
import UserCommissionList from "../users/UserCommissionList.vue";
import UserSaleList from "../users/UserSaleList.vue";
import CopyToClipboard from "../../components/CopyToClipboard.vue";

export default {
	props: {
		storeUrl: String,
		commissionListDataUrl: String,
		salesListDataUrl: String,
		consumerRefererLink: String,
		companyRefererLink: String,
		pendingReward: Object,
		userLevelsEnabled: Boolean,
		userLevels: Array,
		userBonuses: Array,
		currencies: Array,
		userLevelPoints: Object,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	computed: {
		userLevelsObject: {
			get(){
				return _.keyBy(this.userLevels, 'id')
			}
		}
	},
	data(){
		return {
			chartOptions: {
				labels: ['Haladás'],
				chart: {
					type: 'radialBar',
					offsetY: -15,
					sparkline: {
						enabled: true
					}
				},
				plotOptions: {
					radialBar: {
						startAngle: -90,
						endAngle: 90,
						track: {
							background: "#e7e7e7",
							strokeWidth: '97%',
							margin: 2, // margin is in pixels
						},
						dataLabels: {
							name: {
								show: false
							},
							value: {
								offsetY: -1,
								fontSize: '20px'
							}
						}
					}
				}
			},
		}
	},
	components: {CopyToClipboard, UserSaleList, UserCommissionList, VueApexCharts, Price},
	methods: {
		formatNumber,
		selectLevelReward: function(type){
			let that = this
			axios.post(this.storeUrl + '?action=selectLevelReward', {reward: type}).then((response)=>{
				if (response.data.success !== undefined && response.data.success) {
					this.pendingReward = response.data.pendingReward
				}
				if (response.data.flash){
					that.$emit('flash', response.data.flash)
				}
			}).catch(function (error) {
				that.$emit('flash', [{level: 'danger', message: error.response.data.message}])
			})
		}
	}
}
</script>

<style scoped>

</style>