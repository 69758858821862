<template>
	<div class="dropdown d-inline-block">
		<button type="button" class="btn header-item noti-icon waves-effect" id="page-header-tasks-dropdown"
				data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<i class="far fa-tasks"></i>
			<span class="badge bg-danger rounded-pill" v-if="taskCount">{{ taskCount }}</span>
		</button>
		<div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
			 aria-labelledby="page-header-tasks-dropdown">
			<div class="p-3">
				<div class="row align-items-center">
					<div class="col">
						<h6 class="m-0" key="t-tasks"> Feladatok </h6>
					</div>
				</div>
			</div>
			<div data-simplebar style="max-height: 300px;" id="taskDropdown">
				<a :href="'/tasks/redirect/' + task.id" class="text-reset task-item" v-for="task in tasks">
					<div class="d-flex">
						<div class="avatar-xs me-3">
							<span :class="'avatar-title bg-' + task.color + ' rounded-circle font-size-16'">
								<i :class="task.icon"></i>
							</span>
						</div>
						<div class="flex-grow-1">
							<h6 class="mt-0 mb-1">{{ task.title }}</h6>
							<div class="font-size-12 text-muted">
								<p class="mb-1">{{ task.description }}</p>
								<p class="mb-0">
									<i class="far fa-clock"></i> <RelativeTime v-model="task.created_at" />
								</p>
							</div>
						</div>
					</div>
				</a>
				<div class="p-3 text-muted" v-if="!tasks.length">
					Jelenleg nincs feladatod.
				</div>
			</div>
			<div class="p-2 border-top d-grid">
				<a class="btn btn-sm btn-link font-size-14 text-center" href="/tasks">
					<i class="far fa-angle-right me-1"></i> <span key="t-view-more">Összes megtekintése</span>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import SimpleBar from "simplebar"
import RelativeTime from "./RelativeTime.vue";

export default {
	components: {RelativeTime},
	data(){
		return {
			tasks: [],
			taskCount: 0,
			simpleBarInstance: null
		}
	},
	mounted() {
		this.simpleBarInstance = new SimpleBar(document.getElementById('taskDropdown'));

		this.update()
		this.setNextUpdate()
	},
	methods: {
		setNextUpdate: function() {
			let that = this
			setTimeout(function(){
				that.update()
				that.setNextUpdate()
			}, 60000)
		},
		update: function(){
			axios.get('/tasks/get').then((response) => {
				this.tasks = response.data.tasks
				this.taskCount = response.data.taskCount

				this.simpleBarInstance.recalculate();
			}).catch(function (error) {
			})
		},
	}
}
</script>

<style scoped>

</style>