<template>
	<div>
		<label :for="id + '_button'" :class="{'active': componentValue}" class="toggle__button">
			<span v-if="offValue">{{ offValue }}</span>
			<input type="checkbox" :id="id + '_button'" v-model="componentValue" value="1" :disabled="disabled">
			<span class="toggle__switch"></span>
			<span v-if="onValue">{{ onValue }}</span>
		</label>
	</div>
</template>

<script>
export default {
	emits: ['input', 'valueChanged'],
	props: {
		value: { default: "" },
		id: { type: String },
		onValue: { default: "" },
		offValue: { default: "" },
		disabled: { default: false }
	},
	computed: {
		componentValue: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
				this.$emit('valueChanged')
			}
		}
	},
	methods: {
		updateInput(event) {
			if (!this.disabled) {
				this.value = event.target.checked
			}
		}
	}
}
</script>

<style scoped>

.toggle__button {
	vertical-align: middle;
	user-select: none;
	cursor: pointer;
	margin: 0;
	display: flex;
	align-items: center;
}
.toggle__button input[type="checkbox"] {
	opacity: 0;
	position: absolute;
	width: 1px;
	height: 1px;
}
.toggle__button .toggle__switch {
	display:inline-block;
	height:12px;
	border-radius:6px;
	width:30px;
	background: #BFCBD9;
	box-shadow: inset 0 0 1px #BFCBD9;
	position:relative;
	margin: 0 10px;
	transition: all .25s;
}

.toggle__button .toggle__switch::after,
.toggle__button .toggle__switch::before {
	content: "";
	position: absolute;
	display: block;
	height: 18px;
	width: 18px;
	border-radius: 50%;
	left: 0;
	top: -3px;
	transform: translateX(0);
	transition: all .25s cubic-bezier(.5, -.6, .5, 1.6);
}

.toggle__button .toggle__switch::after {
	background: #4D4D4D;
	box-shadow: 0 0 1px #666;
}
.toggle__button .toggle__switch::before {
	background: #4D4D4D;
	box-shadow: 0 0 0 3px rgba(0,0,0,0.1);
	opacity:0;
}

.active .toggle__switch {
	background: rgba(85, 110, 230, 0.6);
	box-shadow: inset 0 0 1px rgba(85, 110, 230, 0.6);
}

.active .toggle__switch::after,
.active .toggle__switch::before{
	transform:translateX(30px - 18px);
}

.active .toggle__switch::after {
	left: 13px;
	background: #556ee6;
	box-shadow: 0 0 1px #556ee6;
}
</style>