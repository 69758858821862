<template>
	<div class="project-type--field-inner">
		<div class="project-type--field-left">
			<div class="fixed-number"><i class="far fa-hashtag"></i> {{ componentValue.field_number }}</div>
			<template v-if="isOpen">
				<button type="button" @click.prevent="moveFieldInternal(fieldKey, 'up', parentKey)" class="btn btn-secondary mx-1 mt-2"><i class="far fa-arrow-up"></i></button>
				<button type="button" @click.prevent="moveFieldInternal(fieldKey, 'down', parentKey)" class="btn btn-secondary mx-1"><i class="far fa-arrow-down"></i></button>
				<button type="button" @click.prevent="removeFieldInternal(fieldKey, parentKey)" class="btn btn-danger mx-1"><i class="far fa-times"></i></button>
			</template>
		</div>

		<div class="project-type--field-right">
			<div class="h5 mb-0" v-if="isOpen">
				<button type="button" @click.prevent="toggleFieldVisibilityInternal(fieldKey, parentKey, 'close')" class="btn p-1"><i class="far fa-eye-slash"></i></button>
				{{ fieldTypes[componentValue.type].name }}
			</div>
			<div class="h5 mb-0" v-if="!isOpen">
				<button type="button" @click.prevent="toggleFieldVisibilityInternal(fieldKey, parentKey, 'open')" class="btn p-1"><i class="far fa-eye"></i></button>
				{{componentValue.title}}
			</div>
			<div v-if="isOpen" class="mt-2">
				<div class="project-type--field-input">
					<label class="col-form-label">Cím</label>
					<div class="col">
						<input v-model="componentValue.title" type="text" class="form-control" required />
					</div>
				</div>
				<div class="project-type--field-input">
					<label class="col-form-label">Leírás</label>
					<div class="col">
						<textarea v-model="componentValue.description" class="form-control" />
					</div>
				</div>
				<template v-if="componentValue.type !== 'title' && componentValue.type !== 'multirow'">
					<div class="project-type--field-input">
						<label class="col-form-label">Kötelező</label>
						<div class="col d-flex align-items-center">
							<ProjectTypeCheckbox
								v-model="componentValue.required"
								:name="'requiredSwitch_' + fieldKeyFormatted"
								/>
						</div>
					</div>
				</template>
				<div class="project-type--field-input" v-if="!componentValue.filledBySender">
					<label class="col-form-label">Feltételes megjelenés</label>
					<div class="col">
						<input type="text" class="form-control" v-model="componentValue.conditions" />
						<p class="form-text">Formátum: (Kérdés sorszáma) (= / !=) (Érték), feltételek összefűzése lehetséges ÉS kapcsolattal (&&) és VAGY kapcsolattal (||). Egy mezőnél csak egy fajta logikai kapcsolat használható. Pl.: 10.1=1 && 10.2!=3</p>
					</div>
				</div>
				<div class="project-type--field-input" v-if="getPossibleRoles().length">
					<label class="col-form-label">Szerep</label>
					<div class="col">
						<v-select
							v-model="componentValue.role"
							:options="getPossibleRoles()"
							:reduce="option => option.value"
							label="label"
							:multiple="false"
							:clearable="true"
							></v-select>
					</div>
				</div>
				<div v-if="componentValue.type === 'text' || componentValue.type === 'textarea'">
					<div class="project-type--field-input">
						<label class="col-form-label">Max hossz</label>
						<div class="col">
							<input type="number" class="form-control" v-model="componentValue.maxLength" step="1" />
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col col-sm-6" v-if="!componentValue.company_only">
						<div class="project-type--field-input">
							<label class="col-form-label">Csak magánszemély</label>
							<div class="col d-flex align-items-center">
								<ProjectTypeCheckbox
									v-model="componentValue.private_only"
									:name="'private_onlySwitch_' + fieldKeyFormatted"
								/>
							</div>
						</div>
					</div>
					<div class="col col-sm-6" v-if="!componentValue.private_only">
						<div class="project-type--field-input">
							<label class="col-form-label">Csak cég</label>
							<div class="col d-flex align-items-center">
								<ProjectTypeCheckbox
									v-model="componentValue.company_only"
									:name="'company_onlySwitch_' + fieldKeyFormatted"
								/>
							</div>
						</div>
					</div>
					<div class="col col-sm-6">
						<div class="project-type--field-input">
							<label class="col-form-label">Árajánlatban megjelenít</label>
							<div class="col d-flex align-items-center">
								<ProjectTypeCheckbox
									v-model="componentValue.include_in_quote"
									:name="'include_in_quoteSwitch_' + fieldKeyFormatted"
								/>
							</div>
						</div>
					</div>
					<div class="col col-sm-6">
						<div class="project-type--field-input">
							<label class="col-form-label">Ügyfél láthatja</label>
							<div class="col d-flex align-items-center">
								<ProjectTypeCheckbox
									v-model="componentValue.client_can_see"
									:name="'client_can_seeSwitch_' + fieldKeyFormatted"
								/>
							</div>
						</div>
					</div>
					<div class="col col-sm-6" v-if="componentValue.client_can_see">
						<div class="project-type--field-input">
							<label class="col-form-label">Ügyfél kitöltheti</label>
							<div class="col d-flex align-items-center">
								<ProjectTypeCheckbox
									v-model="componentValue.client_fillable"
									:name="'client_fillableSwitch_' + fieldKeyFormatted"
								/>
							</div>
						</div>
					</div>
				</div>
				<div v-if="componentValue.type === 'text' || componentValue.type === 'number'">
					<div class="row">
						<div class="col col-sm-6">
							<div class="project-type--field-input">
								<label class="col-form-label">Előtag</label>
								<div class="col">
									<input type="text" class="form-control" v-model="componentValue.prefix" />
								</div>
							</div>
						</div>
						<div class="col col-sm-6">
							<div class="project-type--field-input">
								<label class="col-form-label">Utótag</label>
								<div class="col">
									<input type="text" class="form-control" v-model="componentValue.suffix" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="componentValue.type === 'number'">
					<div class="row">
						<div class="col col-sm-6">
							<div class="project-type--field-input">
								<label class="col-form-label">Min érték</label>
								<div class="col">
									<input type="number" class="form-control" v-model="componentValue.minValue">
								</div>
							</div>
						</div>
						<div class="col col-sm-6">
							<div class="project-type--field-input">
								<label class="col-form-label">Max érték</label>
								<div class="col">
									<input type="number" class="form-control" v-model="componentValue.maxValue">
								</div>
							</div>
						</div>
						<div class="col col-sm-6">
							<div class="project-type--field-input">
								<label class="col-form-label">Lépésköz</label>
								<div class="col">
									<input type="number" class="form-control" v-model="componentValue.step">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="componentValue.type === 'select'">
					<div class="project-type--field-input">
						<label class="col-form-label">Több darab kiválasztható</label>
						<div class="col d-flex align-items-center">
							<ProjectTypeCheckbox
								v-model="componentValue.multiple"
								:name="'multipleSwitch_' + fieldKeyFormatted"
							/>
						</div>
					</div>
					<div class="project-type--field-input">
						<label class="col-form-label">Nyomógombos megjelenés</label>
						<div class="col d-flex align-items-center">
							<ProjectTypeCheckbox
								v-model="componentValue.alternative_display"
								:name="'alternative_displaySwitch_' + fieldKeyFormatted"
							/>
						</div>
					</div>
					<label class="col-form-label">Válaszok</label>
					<div class="col">
						<div class="mb-2">
							<div v-for="(option, optionKey) in componentValue.options" class="py-2 d-flex">
								<div class="me-2 align-items-center d-flex" style="flex-basis: 20px">
									{{ option.order }}
								</div>
								<div class="me-2 flex-grow-1">
									<input type="text" class="form-control" v-model="option.value" />
								</div>
								<div class="row-actions">
									<button type="button" @click.prevent="moveFieldValueInternal(fieldKey, 'options', optionKey, 'up', parentKey)" class="btn btn-secondary mx-1"><i class="far fa-arrow-up"></i></button>
									<button type="button" @click.prevent="moveFieldValueInternal(fieldKey, 'options', optionKey, 'down', parentKey)" class="btn btn-secondary mx-1"><i class="far fa-arrow-down"></i></button>
									<button type="button" @click.prevent="removeFieldValueInternal(fieldKey, 'options', optionKey, parentKey)" class="btn btn-danger mx-1"><i class="far fa-times"></i></button>
								</div>
							</div>
						</div>
						<button type="button" class="btn btn-secondary" @click.prevent="addEmptyFieldValueInternal(fieldKey, 'options', parentKey)"><i class="far fa-plus"></i> Hozzáad</button>
					</div>
				</div>
				<div v-if="componentValue.type === 'table'">
					<div class="row">
						<div class="col col-md-6">
							<label class="col-form-label">Sorok</label>
							<div class="col">
								<div class="mb-2">
									<div v-for="(row, rowKey) in componentValue.rows" class="py-2 d-flex">
										<div class="me-2 flex-grow-1">
											<input type="text" class="form-control" v-model="row.value" />
										</div>
										<div class="row-actions">
											<button type="button" @click.prevent="moveFieldValueInternal(fieldKey, 'rows', rowKey, 'up', parentKey)" class="btn btn-secondary mx-1"><i class="far fa-arrow-up"></i></button>
											<button type="button" @click.prevent="moveFieldValueInternal(fieldKey, 'rows', rowKey, 'down', parentKey)" class="btn btn-secondary mx-1"><i class="far fa-arrow-down"></i></button>
											<button type="button" @click.prevent="removeFieldValueInternal(fieldKey, 'rows', rowKey, parentKey)" class="btn btn-danger mx-1"><i class="far fa-times"></i></button>
										</div>
									</div>
								</div>
								<button type="button" class="btn btn-secondary" @click.prevent="addEmptyFieldValueInternal(fieldKey, 'rows', parentKey)"><i class="far fa-plus"></i> Hozzáad</button>
							</div>
						</div>
						<div class="col col-md-6">
							<label class="col-form-label">Oszlopok</label>
							<div class="col">
								<div class="mb-2">
									<div v-for="(col, colKey) in componentValue.columns" class="py-2 d-flex">
										<div class="me-2 flex-grow-1">
											<input type="text" class="form-control" v-model="col.value" />
										</div>
										<div class="row-actions">
											<button type="button" @click.prevent="moveFieldValueInternal(fieldKey, 'columns', colKey, 'up', parentKey)" class="btn btn-secondary mx-1"><i class="far fa-arrow-up"></i></button>
											<button type="button" @click.prevent="moveFieldValueInternal(fieldKey, 'columns', colKey, 'down', parentKey)" class="btn btn-secondary mx-1"><i class="far fa-arrow-down"></i></button>
											<button type="button" @click.prevent="removeFieldValueInternal(fieldKey, 'columns', colKey, parentKey)" class="btn btn-danger mx-1"><i class="far fa-times"></i></button>
										</div>
									</div>
								</div>
								<button type="button" class="btn btn-secondary" @click.prevent="addEmptyFieldValueInternal(fieldKey, 'columns', parentKey)"><i class="far fa-plus"></i> Hozzáad</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ToggleButton from '../../components/ToggleButton.vue'
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import 'vue-datepicker-next/locale/hu';
import moment from 'moment'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ProjectTypeCheckbox from './ProjectTypeCheckbox.vue'

export default {
	emits: ['input', 'moveField', 'removeField', 'moveFieldValue', 'removeFieldValue', 'addEmptyFieldValue', 'addSubfield', 'toggleFieldVisibility'],
	props: ['modelValue', 'fieldKey', 'parentOrder', 'parentKey', 'isOpen', 'fieldTypes'],
	components: {
		ToggleButton,
		DatePicker,
		vSelect,
		ProjectTypeCheckbox,
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit('update:modelValue', val)
			}
		},
		fieldOrderNumber: {
			get() {
				return this.parentOrder ? this.parentOrder + '.' + this.componentValue.order + '.' : this.componentValue.order + '.'
			}
		},
		fieldKeyFormatted: {
			get() {
				return this.parentKey !== null ? this.parentKey + '_' + this.fieldKey : this.fieldKey
			}
		}
	},
	data(){
		return {
		};
	},
	created: function () {
		this.moment = moment;
	},
	updated(){
	},
	methods: {
		moveFieldInternal: function (fieldKey, direction, parentKey) {
			this.$emit('moveField', fieldKey, direction, parentKey)
		},
		removeFieldInternal: function (fieldKey, parentKey) {
			this.$emit('removeField', fieldKey, parentKey)
		},
		toggleFieldVisibilityInternal: function (fieldKey, parentKey, setTo) {
			this.$emit('toggleFieldVisibility', fieldKey, parentKey, setTo)
		},
		moveFieldValueInternal: function (fieldKey, arrayName, valueKey, direction, parentKey) {
			this.$emit('moveFieldValue', fieldKey, arrayName, valueKey, direction, parentKey)
		},
		removeFieldValueInternal: function (fieldKey, arrayName, valueKey, parentKey) {
			this.$emit('removeFieldValue', fieldKey, arrayName, valueKey, parentKey)
		},
		addEmptyFieldValueInternal: function (fieldKey, arrayName, parentKey) {
			this.$emit('addEmptyFieldValue', fieldKey, arrayName, parentKey)
		},
		getPossibleRoles: function(){
			if (this.componentValue.type === 'address'){
				return [
					{
						value: 'billing',
						label: 'Számlázási cím'
					},
					{
						value: 'deploy',
						label: 'Telepítési cím'
					},
				]
			}
			if (this.componentValue.type === 'text'){
				return [
					{
						value: 'billingLastName',
						label: 'Számlázás - vezetéknév'
					},
					{
						value: 'billingFirstName',
						label: 'Számlázás - keresztnév'
					},
					{
						value: 'billingCompanyName',
						label: 'Számlázás - cégnév'
					},
					{
						value: 'billingTaxNumber',
						label: 'Számlázás - adószám'
					},
					{
						value: 'billingEmail',
						label: 'Számlázás - e-mail cím'
					},
					{
						value: 'billingPhone',
						label: 'Számlázás - telefonszám'
					},
				]
			}

			return []
		},
		addSubfieldInternal: function (type, fieldKey) {
			this.$emit('addSubfield', type, fieldKey)
		},
	}
}
</script>

<style scoped>
.project-type--field-left {
	flex-basis: 100px;
	flex-shrink: 0;
	padding: 5px 10px;
	border-right: 1px solid #eff2f7;
	display: flex;
	flex-direction: column;
}
.project-type--field-left .number {
	font-size: 14px;
	color: var(--bs-secondary)
}
.project-type--field-left .fixed-number {
	font-size: 16px;
	font-weight: bold;
}
.project-type--field-left button {
	margin-bottom: 10px;
}
.project-type--field-right {
	flex-basis: calc(100% - 100px);
	padding: 5px 10px 5px 10px;
}
.project-type--field-input {
	display: flex;
	margin-bottom: 20px;
}
.col-form-label {
	flex-basis: 150px;
	flex-grow: 0;
}
.project-type--add-field .btn,
.project-type--add-field div {
	margin-right: 15px;
}
.project-type--add-field div {
	font-size: 15px;
}
</style>