<template>
	<DataTable
			:data-url="dataUrl"
			:initial-flash="initialFlash"
			:columns="columns"
			sort-by="created_at"
			sort-type="desc"
			name="userSales"
	>
		<template #item-created_at="item">
			{{ formatDate(item.created_at, false) }}
		</template>
		<template #item-amount="item">
			<Price
					:value="item.amount"
					:currencies="currencies"
			/>
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import {formatDate, formatNumber} from "../../functions";
import BooleanBadge from "../../components/BooleanBadge.vue";
import UserLevelBadge from "../../components/UserLevelBadge.vue";
import Price from "../../components/Price.vue";
import VueApexCharts from "vue3-apexcharts";

export default {
	emits: ['flash'],
	components: {
		Price,
		UserLevelBadge,
		DataTable,
		BooleanBadge,
		TableActionCell,
		VueApexCharts
	},
	props: {
		dataUrl: String,
		storeUrl: String,
		isEditable: Boolean,
		currencies: Array,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Időpont',
				value: 'created_at',
				sortable: true,
			},
			{
				text: 'Összeg',
				value: 'amount',
				align: 'right',
				sortable: true,
			},
			{
				text: 'Szerződés',
				value: 'contractId',
				sortable: true,
			},
			{
				text: 'Projekt',
				value: 'projectId',
				sortable: true,
			},
		]

		return {
			columns: columns,
		}
	},
	methods: {
		formatDate: formatDate,
		formatNumber,
	}
}
</script>

<style scoped>
</style>
