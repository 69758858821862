<template>
	<div>
		<form-wrapper
			ref="formWrapper"
			:store-url="storeUrl"
			:back-url="backUrl"
			:initial-flash="initialFlash"
			v-model="attributes"
			@before-submit="beforeSubmit"
			v-slot="{ errors }"
		>

			<div class="row" v-if="barcodeUrl !== ''">
				<div class="col-md-6">
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-3">Vonalkód</h4>
							<div class="mb-3 d-flex flex-column align-items-center">
								<div class="mb-2">
									<img :src="barcodeUrl" />
								</div>
								<div class="d-flex gap-2">
									<button type="button" class="btn btn-primary" @click.prevent="printBarcode"><i class="far fa-print"></i> Nyomtatás</button>
									<a :href="barcodeDownloadUrl + '?action=download'" class="btn btn-secondary"><i class="far fa-download"></i> Letöltés</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Adatok</h4>
					<radio-field
						label="Típus"
						v-model="attributes.type"
						:disabled="!allowEditing"
						:values="productTypes"
						:hide-non-active="attributes.id !== undefined"
					/>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Név"
								name="name"
								rules="required"
								v-model="attributes.name"
								:disabled="!allowEditing"
								:errors="errors"
							/>

							<input-field
								label="Cikkszám"
								name="sku"
								rules="required|alpha_num"
								v-model="attributes.sku"
								:disabled="!allowEditing"
								:errors="errors"
							/>
							<input-field
								label="Vonalkód"
								name="barcode"
								rules="alpha_num"
								v-model="attributes.barcode"
								:disabled="!allowEditing"
								:errors="errors"
							/>
							<input-field
								label="Gyártói azonosító"
								name="manufacturer_code"
								rules=""
								v-model="attributes.manufacturer_code"
								:disabled="!allowEditing"
								:errors="errors"
								v-if="attributes.type === 'material'"
							/>
							<input-field
								label="Garancia ideje"
								name="warranty_months"
								data-type="numeric"
								v-model="attributes.warranty_months"
								:disabled="!allowEditing"
								suffix="hónap"
								:errors="errors"
								v-if="attributes.type === 'material'"
							/>

						</div>
						<div class="col-sm-6">
							<select-field
								label="Kategória"
								name="product_category_id"
								rules="required"
								v-model="attributes.product_category_id"
								:disabled="!allowEditing"
								:options="productCategories"
								:errors="errors"
							/>

							<select-field
								label="Gyártó"
								name="product_manufacturer_id"
								v-model="attributes.product_manufacturer_id"
								:disabled="!allowEditing"
								:options="productManufacturers"
								:errors="errors"
								v-if="attributes.type === 'material'"
							/>

							<input-field
								label="Ömlesztett termék szorzó"
								name="bulk_multiplier"
								rules="required"
								data-type="number"
								v-model="attributes.bulk_multiplier"
								:disabled="!allowEditing"
								:errors="errors"
								v-if="attributes.type === 'material'"
							/>

							<input-field
								label="Mennyiségi egység"
								name="unit"
								v-model="attributes.unit"
								:disabled="!allowEditing"
								:errors="errors"
							/>

							<input-field
								label="Max mennyiség árajánlatban"
								name="max_quantity"
								v-model="attributes.max_quantity"
								:disabled="!allowEditing"
								data-type="number"
								:step="1"
								:errors="errors"
                                v-if="attributes.type !== 'equipment'"
							/>

							<input-field
								label="Teljesítmény"
								name="power"
								data-type="number"
								v-model="attributes.power"
								:disabled="!allowEditing"
								:suffix="attributes.power_unit"
								:errors="errors"
								v-if="attributes.type === 'material'"
							/>

							<input-field
								label="Teljesítmény mértékegység"
								name="power_unit"
								v-model="attributes.power_unit"
								:disabled="!allowEditing"
								:errors="errors"
								v-if="attributes.type === 'material'"
							/>

							<switch-field
								label="Egyedi mennyiségi egység"
								name="bundle_use_custom_unit"
								v-model="attributes.bundle_use_custom_unit"
								:disabled="!allowEditing"
								v-if="attributes.type === 'bundle'"
							/>

							<switch-field
								label="Kiszállítást igényel"
								name="requires_delivery"
								v-model="attributes.requires_delivery"
								:disabled="!allowEditing"
								v-if="attributes.type === 'material'"
							/>

							<input-field
								label="Mennyiségi egység"
								name="bundle_unit"
								v-model="attributes.bundle_unit"
								:disabled="!allowEditing"
								rules="required"
								:errors="errors"
								v-if="attributes.type === 'bundle' && attributes.bundle_use_custom_unit"
							/>

						</div>
					</div>
					<div class="row">
						<div class="col-sm-6" v-if="attributes.type === 'material' || attributes.type === 'equipment'">
							<switch-field
								label="Raktárkészlet követése"
								name="track_inventory"
								v-model="attributes.track_inventory"
								:disabled="!!trackInventory || !allowEditing"
							/>

							<switch-field
								label="Sorozatszám követése"
								name="track_serial_number"
								v-model="attributes.track_serial_number"
								:disabled="!!trackInventory || !allowEditing"
							/>
						</div>
						<div class="col-sm-6">
							<switch-field
								label="Opcionális"
								name="is_optional"
								v-model="attributes.is_optional"
								:disabled="!allowEditing"
								v-if="attributes.type !== 'bundle' && attributes.type !== 'equipment'"
							/>

							<switch-field
								label="Részletek mutatása az árajánlatban"
								name="show_details_in_quote"
								v-model="attributes.show_details_in_quote"
								:disabled="!allowEditing"
								v-if="attributes.type !== 'bundle' && attributes.type !== 'equipment'"
							/>

							<switch-field
								v-if="attributes.type === 'bundle'"
								label="Árajánlatban kibontás"
								name="expand_in_quote"
								v-model="attributes.expand_in_quote"
								:disabled="!allowEditing"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="card mb-3" v-if="attributes.type !== 'bundle' && attributes.type !== 'equipment'">
				<div class="card-body">
					<h4 class="card-title mb-3">Ár</h4>
					<div class="row">
						<div class="col-sm-6">
							<input-field
								label="Beszerzési ár"
								name="price"
								data-type="number"
								:step="0.01"
								v-model="attributes.price"
								:disabled="!allowEditing"
								:errors="errors"
								v-if="attributes.type === 'material'"
							/>
							<input-field
								label="Ár"
								name="price"
								data-type="number"
								:step="0.01"
								v-model="attributes.price"
								:disabled="!allowEditing"
								:errors="errors"
								v-else
							/>
							<select-field
								label="Pénznem"
								name="currency_id"
								rules="required"
								v-model="attributes.currency_id"
								:disabled="!allowEditing"
								:options="currencies"
								:errors="errors"
							/>
						</div>
						<div class="col-sm-6">
							<select-field
								label="ÁFA"
								name="tax_id"
								rules="required"
								v-model="attributes.tax_id"
								:disabled="!allowEditing"
								:options="taxes"
								:errors="errors"
							/>
							<input-field
								label="Haszonkulcs"
								name="profit_margin"
								data-type="number"
								:step="0.1"
								v-model="attributes.profit_margin"
								:disabled="!allowEditing"
								suffix="%"
								:errors="errors"
								v-if="attributes.type !== 'work'"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="card mb-3" v-if="attributes.type === 'bundle'">
				<div class="card-body">
					<h4 class="card-title mb-3">Termékcsomag</h4>
					<div class="mb-3 d-flex flex-wrap gap-2" v-if="allowEditing">
						<button type="button" class="btn btn-secondary" @click.prevent="addProductToBundle"><i class="fas fa-plus"></i> Hozzáad</button>
					</div>
					<table class="table table-striped table-bordered">
						<thead>
						<tr>
							<th>Termék</th>
							<th width="10%">Szorzó</th>
							<th width="10%">Választható</th>
							<th width="10%">Fix mennyiség</th>
							<th width="10%">Min</th>
							<th width="10%">Max</th>
							<th width="5%">&nbsp;</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="(item, itemKey) in bundleProducts">
							<td :key="'bundleItem_' + item.uniqueId">
								<v-select
									:options="products"
									v-model="item.product_id"
									:reduce="x => x.id"
									label="formattedName"
									:clearable="false"
									placeholder="Válassz terméket"
									:name="'bundle_products_' + item.uniqueId + '_product'"
									:multiple="item.selectable === 1 || item.selectable === true"
									:key="'bundleItemSelect_' + item.uniqueId"
									v-if="!hideBundleSelects"
								/>
								<div class="invalid-tooltip mt-1" v-if="item.error !== undefined && item.error && item.error.length" style="display:block">
									{{ item.error }}
								</div>
								<div class="mt-2" v-if="item.selectable === 1 || item.selectable === true">
									<input-field
										label="Megnevezés"
										:name="'bundle_products_' + itemKey + '_label'"
										v-model="item.label"
										v-if="item.optional === 1 || item.optional === true"
										:errors="errors"
										:field-only="true"
										:show-placeholder="true"
									/>

									<SwitchField
											v-model="item.optional"
											label="Opcionális"
											:inline="true"
											:name="'bundle_product_optional_' + itemKey"
									/>

									<SwitchField
											v-model="item.select_first_by_default"
											label="Elsőt kiválaszt"
											:inline="true"
											:name="'bundle_product_select_first_by_default_' + itemKey"
									/>
								</div>
							</td>
							<td>
								<InputField
									v-model="item.multiplier"
									:name="'bundle_product_multiplier_' + itemKey"
									:field-only="true"
									data-type="number"
									rules="required"
									:step="0.01"
									:min="0.01"
									/>
							</td>
							<td style="vertical-align: middle; text-align:center;">
								<SwitchField
									v-model="item.selectable"
									:field-only="true"
									:name="'bundle_product_selectable_' + itemKey"
								/>
							</td>
							<td style="vertical-align: middle; text-align:center;">
								<SwitchField
									v-model="item.fixed_quantity"
									:field-only="true"
									:name="'bundle_product_fixed_quantity_' + itemKey"
								/>
							</td>
							<td>
								<InputField
									v-model="item.min_quantity"
									:name="'bundle_product_min_quantity_' + itemKey"
									:field-only="true"
									data-type="number"
									:step="1"
									:min="1"
									/>
							</td>
							<td>
								<InputField
									v-model="item.max_quantity"
									:name="'bundle_product_max_quantity_' + itemKey"
									:field-only="true"
									data-type="number"
									:step="1"
									/>
							</td>
							<td>
								<div class="d-flex gap-2" v-if="allowEditing">
									<button type="button" @click.prevent="hideBundleSelects = true; moveBundleItem(itemKey, 'up'); hideBundleSelects = false" class="btn btn-secondary"><i class="far fa-arrow-up"></i></button>
									<button type="button" @click.prevent="moveBundleItem(itemKey, 'down')" class="btn btn-secondary"><i class="far fa-arrow-down"></i></button>
									<button type="button" class="btn btn-danger" @click.prevent="removeBundleItem(itemKey)"><i class="far fa-times"></i></button>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Leírás</h4>
					<textarea rows="6" v-model="attributes.description" class="form-control"></textarea>
				</div>
			</div>
			<div class="card mb-3" v-if="attributes.type === 'material'">
				<div class="card-body">
					<h4 class="card-title mb-3">Beszállítók</h4>
					<div class="row py-2 border-bottom mx-0">
						<div class="col-md-4 fw-bold">
							Beszállító
						</div>
						<div class="col-md-4 fw-bold">
							Cikkszám
						</div>
						<div class="col-md-4 fw-bold">
							Vonalkód
						</div>
					</div>
					<div class="row py-2 border-bottom mx-0" v-for="supplier in suppliers">
						<div class="col-md-4 d-flex align-items-center">
							{{ supplier.name }}
						</div>
						<div class="col-md-4">
							<input type="text" class="form-control" v-model="attributes.supplier_products[supplier.id].supplier_code" />
						</div>
						<div class="col-md-4">
							<input type="text" class="form-control" v-model="attributes.supplier_products[supplier.id].supplier_barcode" />
						</div>
					</div>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Dokumentumok</h4>
					<FileUploader
						field-name="document"
						v-model="attributes.documents"
						:file-upload-url="fileUploadUrl"
						file-type="document"
						:editable="allowEditing"
					></FileUploader>
				</div>
			</div>
			<div class="card mb-3">
				<div class="card-body">
					<h4 class="card-title mb-3">Képek</h4>
					<FileUploader
						field-name="image"
						v-model="attributes.images"
						:file-upload-url="fileUploadUrl"
						file-type="image"
						:editable="allowEditing"
					></FileUploader>
				</div>
			</div>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import SwitchField from "../../components/form/SwitchField.vue"
import RadioField from "../../components/form/RadioField.vue"
import SelectField from "../../components/form/SelectField.vue"
import FormButtons from "../../components/FormButtons";
import FileUploader from "../../components/FileUploader.vue";
import FormWrapper from "../../components/FormWrapper.vue";
import vSelect from 'vue-select'
import printJS from 'print-js'
import {moveValueInArray} from "../../functions";
export default {
	components: {FormButtons, InputField, SwitchField, RadioField, SelectField, FileUploader, FormWrapper, vSelect},
	props: [
		'initialAttributes',
		'storeUrl',
		'backUrl',
		'fileUploadUrl',
		'trackInventory',
		'trackSerialNumber',
		'productManufacturers',
		'productCategories',
		'suppliers',
		'initialDocuments',
		'initialImages',
		'initialFlash',
		'products',
		'allowEditing',
		'currencies',
		'taxes',
		'productTypes',
		'barcodeUrl',
		'barcodeDownloadUrl',
	],
	data(){
		let attributes = this.initialAttributes
		if (attributes.supplier_products === undefined){
			attributes.supplier_products = {}
		}
		let tmp = attributes.supplier_products || []
		attributes.supplier_products = {}

		for (let i in tmp){
			attributes.supplier_products[tmp[i].supplier_id] = {
				supplier_code: tmp[i].supplier_code,
				supplier_barcode: tmp[i].supplier_barcode
			}
		}

		for (let i in this.suppliers){
			if (attributes.supplier_products[this.suppliers[i].id] === undefined){
				attributes.supplier_products[this.suppliers[i].id] = {
					supplier_code: '',
					supplier_barcode: ''
				}
			}
		}

		if (!parseFloat(attributes.last_acquisition_price)){
			attributes.last_acquisition_price = ''
		}
		if (!parseFloat(attributes.price)){
			attributes.price = ''
		}
		let bundleProducts = []
		let bundleTmp = []
		if (attributes.bundle_products !== undefined && attributes.bundle_products.length){
			let uniqueId = 1;
			_.forEach(attributes.bundle_products, function(item){
				item.pivot.selectable = item.pivot.selectable === 1
				item.pivot.uniqueId = uniqueId++
				if (item.pivot.selectable){
					if (bundleTmp[item.pivot.order] === undefined){
						item.pivot.product_id = [item.pivot.product_id]
						bundleTmp[item.pivot.order] = item.pivot
					} else {
						bundleTmp[item.pivot.order].product_id.push(item.pivot.product_id)
					}
				} else {
					bundleTmp[item.pivot.order] = item.pivot
				}
			})
			bundleProducts = _.compact(_.values(bundleTmp))
			delete attributes.bundle_products
		}

		return {
			attributes: attributes,
			loading: false,
			flash: [],
			bundleProducts: bundleProducts,
			hideBundleSelects: false
		}
	},
	mounted() {
	},
	methods: {
		moveValueInArray,
		beforeSubmit: function(){
			this.attributes.bundleProducts = []
			for (let i in this.bundleProducts){
				if (this.bundleProducts[i].error !== undefined){
					delete this.bundleProducts[i].error
				}
				if (this.bundleProducts[i].selectable){
					let order = 1
					for (let j in this.bundleProducts[i].product_id){
						let row = _.clone(this.bundleProducts[i])
						row.product_id = this.bundleProducts[i].product_id[j]
						row.value_order = order++
						this.attributes.bundleProducts.push(row)
					}
				} else {
					let row = _.clone(this.bundleProducts[i])
					row.value_order = 1
					this.attributes.bundleProducts.push(row)
				}
			}
		},
		isNumber: function(e){
			const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
			const keyPressed = e.key;

			if (!keysAllowed.includes(keyPressed)) {
				e.preventDefault()
			}
		},
		removeBundleItem: function(itemKey){
			this.bundleProducts.splice(itemKey, 1)
		},
		addProductToBundle: function(){
			let order = 0
			let uniqueId = 1
			for (let i = 0; i < this.bundleProducts.length; i++){
				if (this.bundleProducts[i].order > order){
					order = this.bundleProducts[i].order
				}
				if (this.bundleProducts[i].uniqueId > uniqueId){
					uniqueId = this.bundleProducts[i].uniqueId
				}
			}
			this.bundleProducts.push({
				product_id: '',
				multiplier: 1,
				selectable: false,
				order: order + 1,
				uniqueId: uniqueId + 1,
			})
		},
		moveBundleItem: function(index, direction){
			this.bundleProducts = this.moveValueInArray(this.bundleProducts, index, direction)

			let order = 0
			this.bundleProducts.forEach(function(item, index){
				item.order = order
				order++
			})
		},
		printBarcode: function (){
			printJS({printable: this.barcodeDownloadUrl, type: 'pdf'})
		}
	}
}
</script>

<style scoped>

</style>
