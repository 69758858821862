<template>
	<DataTable
		:data-url="dataUrl"
		:initial-flash="initialFlash"
		:columns="columns"
		sort-by="public_id"
		sort-type="desc"
		name="clients"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
				:data="item"
				:edit-url="editUrl"
				:delete-url="deleteUrl"
				:is-editable="isEditable"
				:is-deletable="isDeletable"
				:emit-delete-event="true"
				@deleteButtonPressed="deleteButtonPressed"
			></TableActionCell>
		</template>
		<template #item-public_id="item">
			<div class="fw-bold">{{ item.public_id }}</div>
			<div v-if="item.handlerUserName"><i class="far fa-user"></i> {{ item.handlerUserName }}</div>
			<div><i class="far fa-clock"></i> {{ formatDate(item.created_at) }}</div>
		</template>
		<template #item-is_company="item">
			<span :class="'badge badge-xl bg-' + (item.is_company ? 'dark' : 'light')"><i :class="item.is_company ? 'far fa-industry' : 'far fa-user'"></i> {{ item.is_company ? 'Cég' : 'Magánszemély' }}</span>
		</template>
		<template #item-phone="item">
			{{ item.formatted_phone_number }}
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import {formatDate} from "../../functions";

export default {
	components: {
		TableActionCell,
		DataTable
	},
	props: {
		dataUrl: String,
		editUrl: String,
		deleteUrl: String,
		projectTypes: Object,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
		clientId: {
			type: Number,
			default: 0
		},
		milestones: Array,
		projectTypesArray: Array,
	},
	data(){
		let columns = [
			{
				text: 'Azonosító',
				value: 'public_id',
				sortable: true
			},
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'Típus',
				value: 'is_company',
				sortable: true
			},
			{
				text: 'E-mail cím',
				value: 'email',
				sortable: true
			},
			{
				text: 'Telefonszám',
				value: 'phone',
				sortable: true
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
		}
	},
	methods: {
		formatDate: formatDate,
		isEditable: function(row){
			return row.editable
		},
		isDeletable: function(row){
			return row.deletable
		}
	}
}
</script>

<style scoped>
</style>
