<template>
	<DataTable
		:data-url="dataUrl"
		:initial-flash="initialFlash"
		:columns="columns"
		sort-by="created_at"
		sort-type="desc"
		name="posts"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
				:data="item"
				:edit-url="editUrl"
				:delete-url="deleteUrl"
				:is-editable="canModify"
				:is-deletable="canModify"
				:emit-delete-event="true"
				@deleteButtonPressed="deleteButtonPressed"
			>
				<a :href="viewUrl + '/' + item.id" class="btn btn-info btn-sm" title="Olvas"><i class="far fa-eye"></i></a>
			</TableActionCell>
		</template>
		<template #item-created_at="item">
			{{ formatDate(item.created_at) }}
		</template>
		<template #item-roles="item">
			{{ getRoles(item) }}
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import BooleanBadge from "../../components/BooleanBadge.vue";
import {formatDate} from "../../functions";

export default {
	components: {
		DataTable,
		BooleanBadge,
		TableActionCell
	},
	props: {
		dataUrl: String,
		editUrl: String,
		viewUrl: String,
		deleteUrl: String,
		isEditable: Boolean,
		canModify: Boolean,
		roles: Object,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Cím',
				value: 'title',
				sortable: true
			},
			{
				text: 'Létrehozva',
				value: 'created_at',
				sortable: true
			}
		]

		if (this.canModify){
			columns.push({
				text: 'Szerepek',
				value: 'roles',
				sortable: false
			})
		}
		columns.push({
			label: '',
			value: 'operations',
			width: 5,
		})

		return {
			columns: columns,
		}
	},
	methods: {
		formatDate,
		getRoles: function(item) {
			if (item.requiredRoles === null){
				return ''
			}
			let roleIdArray = item.requiredRoles.split(',')
			let payload = []
			for (let i in roleIdArray){
				if (this.roles[roleIdArray[i]] !== undefined){
					payload.push(this.roles[roleIdArray[i]].name)
				}
			}

			return payload.join(', ')
		}
	},
}
</script>

<style scoped>
</style>
