<template>
	<DataTable
			:data-url="dataUrl"
			:initial-flash="initialFlash"
			:columns="columns"
			sort-by="name"
			sort-type="asc"
			name="userBonuses"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
					:data="item"
					:edit-url="editUrl"
					:delete-url="deleteUrl"
					:is-editable="isEditable"
					:is-deletable="true"
					:emit-delete-event="true"
					@deleteButtonPressed="deleteButtonPressed"
			></TableActionCell>
		</template>
		<template #item-label="item">
			<color-badge :value="item"></color-badge>
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import BooleanBadge from "../../components/BooleanBadge.vue";
import ColorBadge from "../../components/ColorBadge.vue";

export default {
	components: {
		ColorBadge,
		DataTable,
		BooleanBadge,
		TableActionCell
	},
	props: {
		dataUrl: String,
		editUrl: String,
		deleteUrl: String,
		isEditable: Boolean,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Név',
				value: 'name',
			},
			{
				text: 'Cimke',
				value: 'label',
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
		}
	},
}
</script>

<style scoped>
</style>
