<template>
	<div>

		<form-wrapper
			v-model="client"
			:initial-flash="flash"
			:tabs="tabs"
			:tab-badges="tabBadges"
			:display-tabs="!!client.id"
			:hidden-attributes="['created_by_user', 'locked_by']"
			:back-url="backUrl"
			:store-url="storeUrl + '?action=saveClient'"
			:update-lock-url="storeUrl + '?action=updateLock'"
			:lock-url="storeUrl + '?action=lock'"
			@attributes-updated="setAttributes"
		>
			<template v-slot:top>
				<div class="card mb-3" v-if="client.id !== undefined">
					<div class="card-body">
						<div>
							<div class="details-box">
								<div>
									<i class="far fa-hashtag"></i> Azonosító: <strong>{{ client.public_id  }}</strong>
								</div>
								<div v-if="client.created_by_user_name">
									<i class="far fa-user"></i> Létrehozó: <strong>{{ client.created_by_user_name }}</strong>
								</div>
								<div v-if="client.handler_user_name">
									<i class="far fa-user-tie"></i> Tanácsadó: <strong>{{ client.handler_user_name }}</strong>
								</div>
								<div>
									<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(client.created_at)  }}</strong>
								</div>
								<div>
									<i class="far fa-calendar-alt"></i> Módosítva: <strong>{{ formatDate(client.updated_at)  }}</strong>
								</div>
								<div v-if="clientLoginUrl">
									<i class="far fa-sign-in"></i> Ügyfél belépés: <a :href="clientLoginUrl" target="_blank">[link]</a> <CopyToClipboard :value="clientLoginUrl" />
								</div>
								<div class="text-danger" v-if="client.is_locked">
									<i class="far fa-lock"></i> Éppen szerkeszti: <strong>{{ client.locked_by_client_name ? 'Ügyfél: ' + client.locked_by_client_name : client.locked_by_user_name }}</strong> ({{ formatDate(locked_at) }})
								</div>
							</div>
							<div class="mt-4 details-box" v-if="inquiry">
								<h4>Érdeklődés</h4>
								<div>
									<i class="far fa-hashtag"></i> Azonosító:
									<a :href="inquiryUrl" v-if="inquiryUrl">{{ inquiry.public_id }}</a>
									<strong v-else>{{ inquiry.public_id }}</strong>
								</div>
								<div>
									<i class="far fa-calendar-alt"></i> Időpont: <strong>{{ formatDate(inquiry.created_at) }}</strong>
								</div>
								<div>
									<i class="far fa-file-alt"></i> Tárgy: <strong>{{ inquiry.project_type.name }}</strong>
								</div>
								<div v-if="inquiry.handler">
									<i class="far fa-user-friends"></i> Tanácsadó: <strong>{{ inquiry.handler.name }}</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template #default="{activeTab, errors, editable}">
				<div v-if="activeTab === 'form'">
					<div class="card mb-3">
						<div class="card-body">
							<h4 class="card-title mb-4">Adatok</h4>
							<radio-field
								label="Típus"
								v-model="client.is_company"
								:disabled="!editable"
								:values="typeValues"
								:hide-non-active="client.id !== undefined"
							/>
							<template v-if="!client.is_company">
								<div class="row">
									<div class="col-sm-6">
										<input-field
											label="Vezetéknév"
											name="last_name"
											rules="required"
											v-model="client.last_name"
											:disabled="!editable"
											:errors="errors"
										/>
									</div>
									<div class="col-sm-6">
										<input-field
											label="Keresztnév"
											name="first_name"
											rules="required"
											v-model="client.first_name"
											:disabled="!editable"
											:errors="errors"
										/>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-6">
										<input-field
											label="E-mail cím"
											name="email"
											rules="required|email"
											v-model="client.email"
											:disabled="!editable"
											:errors="errors"
										/>

										<select-field
											name="project_type_id"
											label="Elsődleges szolgáltatás"
											:options="projectTypes"
											v-model="client.project_type_id"
											rules="required"
											:disabled="!editable"
											:errors="errors"
										/>
									</div>
									<div class="col-sm-6">
										<input-field
											label="Telefonszám"
											name="phone"
											rules="required"
											data-type="phone"
											v-model="client.phone"
											:disabled="!editable"
											:errors="errors"
										/>
									</div>
								</div>
								<template v-if="false">
									<div class="row">
										<div class="col-sm-6">
											<input-field
												label="Édesanyja neve"
												name="mothers_name"
												rules=""
												v-model="client.mothers_name"
												:disabled="!editable"
												:errors="errors"
											/>
										</div>
									</div>
									<div class="row">
										<div class="col-sm-6">
											<input-field
												label="Személyi igazolvány szám"
												name="personal_id_number"
												rules=""
												v-model="client.personal_id_number"
												:disabled="!editable"
												:errors="errors"
											/>
										</div>
										<div class="col-sm-6">
											<input-field
												label="Adóazonosító"
												name="personal_tax_number"
												rules=""
												v-model="client.personal_tax_number"
												:disabled="!editable"
												:errors="errors"
											/>
										</div>
									</div>
									<div class="row">
										<div class="col-sm-4">
											<input-field
												label="Születési név"
												name="birth_name"
												rules=""
												v-model="client.birth_name"
												:disabled="!editable"
												:errors="errors"
											/>
										</div>
										<div class="col-sm-4">
											<input-field
												label="Születési hely"
												name="place_of_birth"
												rules=""
												v-model="client.place_of_birth"
												:disabled="!editable"
												:errors="errors"
											/>
										</div>
										<div class="col-sm-4">
											<date-field
												label="Születési idő"
												name="date_of_birth"
												rules="required"
												v-model="client.date_of_birth"
												:disabled="!editable"
												:errors="errors"
											/>
										</div>
									</div>
								</template>
							</template>
							<template v-else>
								<div class="row">
									<div class="col-sm-4">
										<input-field
											label="Cégnév"
											name="company_name"
											rules="required"
											v-model="client.company_name"
											:disabled="!editable"
											:errors="errors"
										/>
									</div>
									<div class="col-sm-4">
										<input-field
											label="Adószám"
											name="company_tax_number"
											rules=""
											v-model="client.company_tax_number"
											:disabled="!editable"
											:errors="errors"
										/>
									</div>
									<div class="col-sm-4">
										<input-field
											label="Cégjegyzékszám"
											name="company_id_number"
											rules=""
											v-model="client.company_id_number"
											:disabled="!editable"
											:errors="errors"
										/>
									</div>
								</div>
							</template>
							<switch-field
								label="Értesítések tiltása"
								name="disable_notifications"
								:disabled="!editable"
								v-model="client.disable_notifications"
								/>
						</div>
					</div>
					<div class="card mb-3" v-if="client.has_password">
						<div class="card-body">
							<h4 class="card-title mb-4">Belépés</h4>
							<div class="row">
								<div class="col-sm-6">
									<input-field
											label="Új jelszó"
											name="password"
											data-type="password"
											rules="min:8"
											v-model="client.password"
											:disabled="!editable"
											info="Csak akkor töltsd ki, ha új jelszót akarsz beállítani"
											:errors="errors"
									/>
								</div>
								<div class="col-sm-6">
									<input-field
											label="Új jelszó újra"
											name="password_confirmation"
											data-type="password"
											:equal-to="client.password"
											v-model="client.password_confirmation"
											:disabled="!editable"
											info="Csak akkor töltsd ki, ha új jelszót akarsz beállítani"
											:errors="errors"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="card mb-3" v-if="client.is_company">
						<div class="card-body">
							<h4 class="card-title mb-4">Cégképviseletre jogosult személy</h4>
							<div class="row">
								<div class="col-sm-6">
									<input-field
										label="Vezetéknév"
										name="last_name"
										rules="required"
										v-model="client.last_name"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
								<div class="col-sm-6">
									<input-field
										label="Keresztnév"
										name="first_name"
										rules="required"
										v-model="client.first_name"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6">
									<input-field
										label="E-mail cím"
										name="email"
										data-type="email"
										rules="required"
										v-model="client.email"
										:disabled="!editable"
										:errors="errors"
									/>

									<select-field
										name="project_type_id"
										label="Elsődleges szolgáltatás"
										:options="projectTypes"
										v-model="client.project_type_id"
										rules="required"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
								<div class="col-sm-6">
									<input-field
										label="Telefonszám"
										name="phone"
										rules="required"
										data-type="phone"
										v-model="client.phone"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
							</div>
							<template v-if="false">
								<div class="row">
									<div class="col-sm-6">
										<input-field
											label="Édesanyja neve"
											name="mothers_name"
											v-model="client.mothers_name"
											:disabled="!editable"
											:errors="errors"
										/>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-6">
										<input-field
											label="Személyi igazolvány szám"
											name="personal_id_number"
											rules=""
											v-model="client.personal_id_number"
											:disabled="!editable"
											:errors="errors"
										/>
									</div>
									<div class="col-sm-6">
										<input-field
											label="Adóazonosító"
											name="personal_tax_number"
											rules=""
											v-model="client.personal_tax_number"
											:disabled="!editable"
											:errors="errors"
										/>
									</div>
								</div>
								<div class="row">
									<div class="col-sm-4">
										<input-field
											label="Születési név"
											name="birth_name"
											rules=""
											v-model="client.birth_name"
											:disabled="!editable"
											:errors="errors"
										/>
									</div>
									<div class="col-sm-4">
										<input-field
											label="Születési hely"
											name="place_of_birth"
											rules=""
											v-model="client.place_of_birth"
											:disabled="!editable"
											:errors="errors"
										/>
									</div>
									<div class="col-sm-4">
										<date-field
											label="Születési idő"
											name="date_of_birth"
											rules="required"
											v-model="client.date_of_birth"
											:disabled="!editable"
											:errors="errors"
										/>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="card mb-3" v-if="false">
						<div class="card-body">
							<h4 class="card-title mb-4">{{ client.is_company ? 'Székhely' : 'Lakcím' }}</h4>
							<address-input
								v-model="client.address"
								:cities="cities"
								:required="true"
								attribute-name="address"
								v-if="editable"
								:errors="errors"
								></address-input>
							<div v-else-if="client.address.id">
								{{ client.address.formatted_value }}
							</div>
							<div v-else>
								Nincs megadva
							</div>
						</div>
					</div>
					<div class="card mb-3" v-if="false">
						<div class="card-body">
							<h4 class="card-title mb-4">Postacím</h4>
							<address-input
								v-model="client.mail_address"
								:cities="cities"
								:required="false"
								attribute-name="mail_address"
								v-if="editable"
								:errors="errors"
								></address-input>
							<div v-else-if="client.mail_address.id">
								{{ client.mail_address.formatted_value }}
							</div>
							<div v-else>
								Nincs megadva
							</div>
						</div>
					</div>
					<div class="card mb-3" v-if="false">
						<div class="card-body">
							<h4 class="card-title mb-4">Kapcsolattartó</h4>
							<div class="row">
								<div class="col-sm-6">
									<input-field
										label="Vezetéknév"
										name="contact_last_name"
										v-model="client.contact_last_name"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
								<div class="col-sm-6">
									<input-field
										label="Keresztnév"
										name="contact_first_name"
										v-model="client.contact_first_name"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6">
									<input-field
										label="E-mail cím"
										name="contact_email"
										data-type="email"
										v-model="client.contact_email"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
								<div class="col-sm-6">
									<input-field
										label="Telefonszám"
										name="contact_phone"
										data-type="phone"
										v-model="client.contact_phone"
										:disabled="!editable"
										:errors="errors"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="activeTab === 'projects'">
					<div class="card">
						<div class="card-body">
							<div class="mb-3 d-flex gap-2">
								<a :href="createProjectUrl" class="btn btn-primary btn-label waves-effect"><i class="far fa-plus label-icon"></i> Projekt létrehozása</a>
							</div>
							<project-list
								:data-url="projectListUrl"
								:edit-url="projectEditUrl"
								:delete-url="projectDeleteUrl"
								:project-types="projectTypes"
								:client-id="client.id"
								:milestones="milestones"
							></project-list>
						</div>
					</div>
				</div>
				<div v-if="activeTab === 'comments'">
					<Comments
						:data-url="commentsDataUrl"
						@comment-count="commentCountUpdated"
					></Comments>
				</div>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import SwitchField from "../../components/form/SwitchField.vue"
import RadioField from "../../components/form/RadioField.vue"
import SelectField from "../../components/form/SelectField.vue"
import DateField from "../../components/form/DateField.vue";
import FormButtons from "../../components/FormButtons.vue";
import AddressInput from "../../components/AddressInput.vue";
import ProjectList from "../projects/ProjectList.vue";
import FormWrapper from "../../components/FormWrapper.vue";
import CopyToClipboard from "../../components/CopyToClipboard.vue";
import Comments from "../../components/Comments.vue";
import {formatDate} from "../../functions";

export default {
	components: {
		FormButtons,
		InputField,
		SwitchField,
		RadioField,
		SelectField,
		DateField,
		AddressInput,
		ProjectList,
		FormWrapper,
		CopyToClipboard,
		Comments
	},
	props: [
		'initialClient',
		'initialStats',
		'storeUrl',
		'backUrl',
		'initialFlash',
		'cities',
		'disableRedirect',
		'clientLoginUrl',
		'createProjectUrl',
		'inquiry',
		'inquiryUrl',
		'projectListUrl',
		'projectEditUrl',
		'projectDeleteUrl',
		'projectTypes',
		'milestones',
		'commentsDataUrl',
	],
	data(){
		let client = this.initialClient

		if (client.address === null){
			client.address = {}
		}
		if (client.mail_address === null){
			client.mail_address = {}
		}
		return {
			client: client,
			stats: this.initialStats,
			flash: [],
			typeValues: [
				{
					label: 'Magánszemély',
					icon: 'far fa-user'
				},
				{
					label: 'Cég',
					icon: 'far fa-industry'
				},
			],
			tabBadges: {
				projects: this.initialStats.projects,
				comments: this.initialStats.comments,
			},
			tabs: [
				{
					label: 'Adatlap',
					icon: 'far fa-file-spreadsheet',
					name: 'form'
				},
				{
					label: 'Projektek',
					icon: 'far fa-tasks',
					name: 'projects',
				},
				{
					label: 'Üzenőfal',
					icon: 'far fa-comments',
					name: 'comments',
				},
				{
					label: 'Dokumentumok',
					icon: 'far fa-folder-open',
					name: 'documents'
				},
			]
		}
	},
	mounted() {
		this.setAttributes()

		this.flash = this.initialFlash
	},
	methods: {
		formatDate: formatDate,
		setAttributes: function(){
			if (this.client.address === undefined || this.client.address === null){
				this.client.address = {}
			}
			if (this.client.mail_address === undefined || this.client.mail_address === null){
				this.client.mail_address = {}
			}
		},
		commentCountUpdated: function(count){
			this.tabBadges.comments = count
		}
	}
}
</script>

<style scoped>

</style>