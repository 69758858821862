<template>
	<button type="button" class="btn btn-sm px-3 font-size-16 header-item" @click.prevent="toggle">
		<i class="fa fa-fw fa-bars"></i>
	</button>
</template>

<script>
export default {
	created() {
		if (this.uiSettings.sidebarCollapsed !== undefined && this.uiSettings.sidebarCollapsed){
			this.toggle()
		}
	},
	watch: {
		uiSettings: {
			handler(newValue, oldValue) {
				localStorage.setItem('uiSettings', JSON.stringify(newValue));
			},
			deep: true
		}
	},
	data(){
		let uiSettings = JSON.parse(localStorage.getItem('uiSettings')) || {}
		return {
			uiSettings: uiSettings
		}
	},
	methods: {
		toggle: function(){
			if (document.body.clientWidth < 992){
				document.querySelector('body').classList.toggle('sidebar-enable')
			} else {
				document.querySelector('body').classList.toggle('vertical-collpsed')
				if (document.querySelector('body').classList.contains('vertical-collpsed')){
					this.uiSettings.sidebarCollapsed = true
				} else {
					this.uiSettings.sidebarCollapsed = false
				}
			}
		}
	}
}
</script>

<style scoped>

</style>