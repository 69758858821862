<template>
	<DataTable
		:data-url="dataUrl"
		:initial-flash="initialFlash"
		:columns="columns"
		sort-by="sku"
		sort-type="asc"
		name="products"
		>
		<template #filters="{serverOptions}">
			<div class="col-12 col-md-3">
				<v-select
					:options="productCategoriesArray"
					v-model="serverOptions.product_category_id"
					:reduce="item => item.id"
					label="name"
					placeholder="Kategória"
				/>
			</div>
			<div class="col-12 col-md-2">
				<v-select
					:options="productManufacturersArray"
					v-model="serverOptions.product_manufacturer_id"
					:reduce="item => item.id"
					label="name"
					placeholder="Gyártó"
				/>
			</div>
			<div class="col-12 col-md-2">
				<v-select
					:options="productTypesSelectData"
					v-model="serverOptions.type"
					:reduce="item => item.value"
					placeholder="Típus"
				>
					<template v-slot:option="option">
						<span :class="'badge bg-' + option.color"><i :class="option.icon"></i> {{ option.label }}</span>
					</template>
					<template v-slot:selected-option="option">
						<span :class="'badge bg-' + option.color"><i :class="option.icon"></i> {{ option.label }}</span>
					</template>
				</v-select>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
				:data="item"
				:edit-url="editUrl"
				:delete-url="deleteUrl"
				:is-editable="!!canEdit"
				:is-deletable="!!deleteUrl"
				:emit-delete-event="true"
				@deleteButtonPressed="deleteButtonPressed"
				v-slot="{ row }"
			>
				<a type="button" class="btn btn-secondary btn-sm waves-effect" title="Duplikálás" v-if="cloneUrl.length" :href="cloneUrl + '/' + row.id"><i class="far fa-clone"></i></a>
			</TableActionCell>
		</template>
		<template #item-product_manufacturer_id="item">
			<div v-if="productManufacturers[item.product_manufacturer_id] !== undefined">
				{{productManufacturers[item.product_manufacturer_id].name}}
			</div>
		</template>
		<template #item-product_category_id="item">
			<div v-if="productCategories[item.product_category_id] !== undefined">
				{{productCategories[item.product_category_id].name}}
			</div>
		</template>
		<template #item-track_inventory="item">
			<BooleanBadge
				:data="item"
				name="track_inventory"
				:key="'track_inventory_' + item.id"
			></BooleanBadge>
		</template>
		<template #item-requires_delivery="item">
			<BooleanBadge
				:data="item"
				name="requires_delivery"
				:key="'requires_delivery_' + item.id"
			></BooleanBadge>
		</template>
		<template #item-track_serial_number="item">
			<BooleanBadge
				:data="item"
				name="track_serial_number"
				:key="'track_serial_number_' + item.id"
			></BooleanBadge>
		</template>
		<template #item-price_in_primary_currency="item">
			<Price
				:value="item.price_in_primary_currency"
				:currencies="currencies"
				:key="'price_in_primary_currency_' + item.id"
			></Price>
		</template>
		<template #item-type="item">
			<span :class="'badge bg-' + productTypes[item.type].color"><i :class="productTypes[item.type].icon"></i> {{ productTypes[item.type].label }}</span>
		</template>
	</DataTable>
</template>
<script>
import TableActionCell from "../../components/TableActionCell";
import BooleanBadge from "../../components/BooleanBadge.vue";
import DataTable from "../../components/DataTable.vue";
import vSelect from "vue-select";
import Price from "../../components/Price.vue";

export default {
	components: {
		BooleanBadge,
		TableActionCell,
		DataTable,
		vSelect,
		Price
	},
	props: {
		dataUrl: String,
		editUrl: String,
		cloneUrl: String,
		deleteUrl: String,
		projectTypes: Object,
		currencies: Object,
		editAll: Boolean,
		deleteAll: Boolean,
		userId: Number,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
		clientId: {
			type: Number,
			default: 0
		},
		milestones: Array,
		projectTypesArray: Array,
		productManufacturers: Object,
		productCategories: Object,
		canEdit: Boolean,
		productTypes: Object
	},
	data(){
		let columns = [
			{
				text: 'Típus',
				value: 'type',
				sortable: true
			},
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'Cikkszám',
				value: 'sku',
				sortable: true
			},
			{
				text: 'Gyártó',
				value: 'product_manufacturer_id',
				sortable: true,
			},
			{
				text: 'Kategória',
				value: 'product_category_id',
				sortable: true,
			},
			{
				text: 'Készlet',
				value: 'track_inventory',
				sortable: true,
			},
			{
				text: 'Kiszállítás',
				value: 'requires_delivery',
				sortable: true,
			},
			{
				text: 'Ár',
				value: 'price_in_primary_currency',
				align: 'right',
				sortable: true,
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			productManufacturersArray: _.values(this.productManufacturers),
			productCategoriesArray: _.values(this.productCategories)
		}
	},
	computed: {
		productTypesSelectData: {
			get(){
				let payload = [];
				_.forEach(this.productTypes, function(value, key){
					value.value = key
					payload.push(value)
				})
				return payload
			}
		}
	}
}
</script>

<style scoped>
</style>
