export function formatDate(date, time) {
	time = time === undefined ? true : time
	if (!date){
		return ''
	}
	return time ? window.moment(date).format('YYYY-MM-DD HH:mm') : window.moment(date).format('YYYY-MM-DD')
}

export function formatNumber(number){
	return number.toLocaleString('hu-HU')
}

export function formatFileSize(size) {
	if (size > 1024 * 1024 * 1024 * 1024) {
		return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
	} else if (size > 1024 * 1024 * 1024) {
		return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
	} else if (size > 1024 * 1024) {
		return (size / 1024 / 1024).toFixed(2) + ' MB'
	} else if (size > 1024) {
		return (size / 1024).toFixed(2) + ' KB'
	}
	return size.toString() + ' B'
}
export function objectToArray(obj){
	let payload = []
	_.forEach(obj, function(details, key){
		let detailsClone = _.clone(details)
		detailsClone.id = key
		payload.push(detailsClone)
	})

	return payload
}
export function moveValueInArray(array, valueKey, direction){
	let prev = null
	let prevItem = null
	let nextItem = null
	array.forEach(function(item, index){
		if (index === valueKey){
			prevItem = prev
		} else if (prev === valueKey){
			nextItem = index
		}
		prev = index
	})

	if (direction === 'up' && prevItem !== null){
		let tmp = array[valueKey]
		array[valueKey] = array[prevItem]
		array[prevItem] = tmp
	}
	if (direction === 'down' && nextItem !== null){
		let tmp = array[valueKey]
		array[valueKey] = array[nextItem]
		array[nextItem] = tmp
	}

	return array
}