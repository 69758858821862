<template>
	<div :class="{'overlay-form-wrapper': 1}">
		<div :class="{'overlay-form-wrapper--content': 1, 'overlay-form-wrapper--content-small': 1, 'loading': loading}">
			<div class="card">
				<div class="card-body">
					<flash-display :flash="flash" ref="contractPaymentFormNotifications" :inline="true"></flash-display>
					<template v-if="contract.id !== undefined">
						<h4 class="card-title mb-3">Számla létrehozása: {{ contract.public_id }} - {{ contract.title_formatted }}</h4>

						<RadioField
								:values="paymentMethodRadioFieldValues"
								label="Fizetési mód"
								name="payment_method"
								v-model="invoice.payment_method"
						/>

						<template v-if="!contract.custom_payment && !invoice.custom_payment">
							<div class="mb-3">
								<label>Következő részlet</label>
								<div class="form-control-plaintext">{{ nextPaymentPlanStep.name }}</div>
							</div>

							<div class="mb-3">
								<label>Következő részlet összege</label>
								<div class="form-control-plaintext">
									<Price
											:value="nextPaymentPlanStepPrice"
											:currencies="currencies"
											:currency-id="contract.currency_id"
									></Price>
								</div>
							</div>
						</template>
						<template v-else>
							<input-field
								label="Összeg"
								name="amount"
								v-model="invoice.amount"
								data-type="number"
								:min="1"
								:max="Math.max(0, contract.total_price_gross - contract.paid_amount)"
								:step="1"
								rules="required"
								:prefix="String(currencies[contract.currency_id].prefix || '').replace('_', '')"
								:suffix="String(currencies[contract.currency_id].suffix || '').replace('_', '')"
								/>
						</template>

						<SwitchField
								label="Egyedi számlázás"
								name="custom_payment"
								v-model="invoice.custom_payment"
								v-if="canEnableCustomBilling && !contract.custom_payment"
						/>
					</template>
				</div>
			</div>
			<div class="mt-3">
				<div class="d-flex flex-wrap gap-2">
					<button type="submit" class="btn btn-primary btn-label waves-effect" @click.prevent="saveForm" :disabled="!valid" v-if="contract.id !== undefined">
						<i class="fas fa-save label-icon"></i> {{ invoice.payment_method === 'cash' ? 'Számla létrehozása' : 'Díjbekérő létrehozása'}}
					</button>
					<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
						<i class="fas fa-times label-icon"></i> Mégse
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FlashDisplay from "../../components/FlashDisplay.vue";
import {formatDate} from "../../functions";
import Price from "../../components/Price.vue";
import RadioField from "../../components/form/RadioField.vue";
import FileUploader from "../../components/FileUploader.vue";
import vSelect from "vue-select";
import SwitchField from "../../components/form/SwitchField.vue";
import InputField from "../../components/form/InputField.vue";
export default {
	components: {InputField, SwitchField, FileUploader, RadioField, FlashDisplay, Price, vSelect},
	emits: ['close', 'closeAndReload', 'updateStats'],
	props: {
		dataUrl: String,
		fileUploadUrl: String,
		contractId: [Number, String],
		currencies: Object,
		canEnableCustomBilling: Boolean,
	},
	data() {
		return {
			loading: true,
			flash: [],
			contract: {},
			nextPaymentPlanStep: null,
			nextPaymentPlanStepPrice: null,
			invoice: {
				payment_method: 'wire_transfer',
				custom_payment: false,
				amount: 0
			},
			paymentMethodRadioFieldValues: {
				wire_transfer: {
					label: 'Átutalás',
					icon: 'far fa-credit-card',
					color: 'primary'
				},
				cash: {
					label: 'Készpénz',
					icon: 'far fa-money-bill',
					color: 'primary'
				}
			},
		}
	},
	computed: {
		valid: {
			get() {
				if (this.contract.custom || this.invoice.custom_payment){
					return this.invoice.amount > 0
				}

				return true
			}
		}
	},
	mounted() {
		this.loadData()
	},
	methods: {
		formatDate,
		loadData: function(){
			this.loading = true

			let url = this.dataUrl + '/' + this.contractId
			let that = this
			axios.get(url + '?action=loadInvoiceForm').then((response)=>{
				this.handleResponse(response)
			}).catch(function (error, a) {
				that.loading = false
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.status !== undefined && response.data.status === 'success') {
				this.$emit('closeAndReload')
			}
			if (response.data.contract !== undefined) {
				this.contract = response.data.contract
				this.invoice.amount = Math.max(0, this.contract.total_price_gross - this.contract.paid_amount)
				if (this.contract.custom_payment){
					this.invoice.custom_payment = 1
				}
			}
			if (response.data.nextPaymentPlanStep !== undefined) {
				this.nextPaymentPlanStep = response.data.nextPaymentPlanStep
				this.nextPaymentPlanStepPrice = response.data.nextPaymentPlanStepPrice
			}
			if (response.data.flash !== undefined){
				this.flash = response.data.flash
			}
			if (response.data.stats !== undefined){
				this.$emit('updateStats', response.data.stats)
			}
		},
		saveForm: function(e){
			if (this.valid){
				this.loading = true
				let that = this
				axios.post(this.dataUrl + '/' + this.contractId + '?action=createInvoice', this.invoice).then((response)=>{
					this.handleResponse(response)
				}).catch(function (error) {
					that.loading = false
					that.flash = [{level: 'danger', message: error.response.data.message}]
				})
			}
		},
		emitClose: function(){
			this.$emit('close')
		},
	}
}
</script>

<style scoped>

</style>