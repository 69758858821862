<template>
	<flash-display :flash="flash" ref="notifications"></flash-display>
	<div :class="{'overlay-form-wrapper': 1}">
		<div :class="{'overlay-form-wrapper--content': 1, 'loading': loading}">
			<div class="row">
				<div class="col-md-3">
					<div class="page-title-box">
						<h4 class="mb-sm-0 font-size-18">Projekt adatok</h4>
					</div>
					<ProjectInfoSidebar
							:project-fields="projectFields"
							:project-attributes="projectAttributes"
							:client="client"
					></ProjectInfoSidebar>
				</div>
				<div class="col-md-9">
					<div class="page-title-box">
						<h4 class="mb-sm-0 font-size-18">Szerződés</h4>
					</div>
					<div class="card" v-if="contract.id !== undefined">
						<div class="card-body">
							<div class="details-box mb-3">
								<div>
									<i class="far fa-hashtag"></i> Azonosító:
									<strong>{{ contract.public_id }}</strong>
								</div>
								<div v-if="contractStatusLabels[contract.status] !== undefined">
									<i class="far fa-question"></i> Státusz:
									<span :class="'badge badge-xl bg-' + contractStatusLabels[contract.status].color"><i :class="contractStatusLabels[contract.status].icon"></i> {{ contractStatusLabels[contract.status].name }}</span>
								</div>
								<div v-if="contract.deadline !== undefined && contract.deadline">
									<i class="far fa-calendar-check"></i> Érvényesség:
									<strong>{{ formatDate(contract.deadline, false) }}</strong>
								</div>
								<div v-if="deliveryTypeLabels[contract.delivery] !== undefined">
									<i class="far fa-truck"></i> Szállítás:
									<span :class="'badge badge-xl bg-' + deliveryTypeLabels[contract.delivery].color"><i :class="deliveryTypeLabels[contract.delivery].icon"></i> {{ deliveryTypeLabels[contract.delivery].name }}</span>
								</div>
								<div class="break"></div>
								<div v-if="contract.created_at">
									<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(contract.created_at) }}</strong>
								</div>
								<div v-if="contract.created_by_user_name">
									<i class="far fa-user"></i> Létrehozó: <strong>{{ contract.created_by_user_name }}</strong>
								</div>
								<div v-if="contract.updated_at">
									<i class="far fa-calendar-alt"></i> Módosítva: <strong>{{ formatDate(contract.updated_at) }}</strong>
								</div>
							</div>
							<div v-if="contract.files && contract.files.length" class="mb-3">
								<FileList :files="contract.files" title="Aláírt szerződés" />
							</div>
						</div>
					</div>
					<Form @submit="saveForm" v-slot="{ errors }" ref="form" enctype="multipart/form-data">
						<div class="mb-3">
							<div class="d-flex flex-wrap gap-2">
								<button type="submit" class="btn btn-primary btn-label waves-effect" v-if="isEditable">
									<i class="fas fa-save label-icon"></i> Mentés
								</button>
								<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
									<i class="fas fa-times label-icon"></i> Bezár
								</button>
							</div>
						</div>

						<div class="card">
							<div class="card-body">
								<h4 class="card-title mb-4">Adatok</h4>
								<div class="row">
									<div class="col-md-12">
										<InputField
											label="Megnevezés"
											name="title"
											rules="required"
											v-model="contract.title"
											:disabled="!isEditable"
											:errors="errors"
										/>
									</div>
									<div class="col-md-6">
										<InputField
											label="Bruttó végösszeg"
											name="total_price_gross"
											data-type="number"
											:step="0.1"
											rules="required"
											v-model="contract.total_price_gross"
											:disabled="!isEditable"
											:errors="errors"
										/>
									</div>
									<div class="col-md-6">
										<select-field
											label="Pénznem"
											name="currency_id"
											rules="required"
											v-model="contract.currency_id"
											:disabled="!isEditable"
											:options="currenciesArray"
											:errors="errors"
										/>
									</div>
									<div class="col-md-6">
										<DateField
											label="Aláírás dátuma"
											name="generated_at"
											rules="required"
											v-model="contract.generated_at"
											:disabled="!isEditable"
											:errors="errors"
										/>
									</div>
									<div class="col-md-6">
										<InputField
											label="Azonosító"
											name="foreign_id"
											v-model="contract.foreign_id"
											:disabled="!isEditable"
											:errors="errors"
										/>
									</div>
									<div class="col-md-6">
										<div class="mb-3">
											<label class="required">Fizetési konstrukció</label>
											<v-select
												:options="paymentPlans"
												v-model="contract.project_type_payment_plan_id"
												label="name_public"
												:reduce="item => item.id"
												:disabled="!isEditable"
												:clearable="false"
											>
											</v-select>
										</div>
									</div>
								</div>
								<label class="required">Aláírt szerződés csatolása</label>
								<FileUploader
									field-name="signedPages"
									v-model="signedPages"
									:file-upload-url="fileUploadUrl"
									:editable="true"
									:multiple="true"
								></FileUploader>
							</div>
						</div>

						<ProjectProductList
							:data-url="dataUrl"
							:product-categories="productCategories"
							:products="products"
							:is-editable="isEditable"
							v-model="contractProducts"
							:parent-model="contract"
							:product-types="productTypes"
							:currencies="currencies"
							:product-versions="contract.product_versions"
							mode="foreignContract"
							ref="productList"
							@flash="setFlash"
						>
						</ProjectProductList>

						<div class="mt-3">
							<div class="d-flex flex-wrap gap-2">
								<button type="submit" class="btn btn-primary btn-label waves-effect" v-if="isEditable">
									<i class="fas fa-save label-icon"></i> Mentés
								</button>
								<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
									<i class="fas fa-times label-icon"></i> Bezár
								</button>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {formatDate, moveValueInArray} from "../../functions";
import ProjectInfoSidebar from "./ProjectInfoSidebar.vue";
import FlashDisplay from "../../components/FlashDisplay.vue";
import InputField from "../../components/form/InputField.vue";
import ProjectProductList from "./ProjectProductList.vue";
import Price from "../../components/Price.vue";
import vSelect from "vue-select";
import FileList from "../../components/FileList.vue";
import SelectField from "../../components/form/SelectField.vue";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import yupLocaleHun from '../../validationMessages'
import DateField from "../../components/form/DateField.vue";
import FileUploader from "../../components/FileUploader.vue";

yup.setLocale(yupLocaleHun);

export default {
	components: {FileUploader, DateField, Form, Field, ErrorMessage, SelectField, FileList, Price, ProjectProductList, InputField, FlashDisplay, ProjectInfoSidebar, vSelect},
	emits: ['close', 'closeAndReload', 'generate', 'updateStats', 'enableDelivery'],
	props: {
		productCategories: [Array, Object],
		productTypes: Object,
		currencies: Object,
		taxes: Object,
		editable: Boolean,
		products: Array,
		client: Object,
		dataUrl: String,
		fileUploadUrl: String,
		projectAttributes: Object,
		projectFields: Array,
		contractStatusLabels: Object,
		deliveryTypeLabels: Object,
		canSetDeliveryForContract: Boolean,
		canCreate: Boolean,
		canUpdate: Boolean,
		canDelete: Boolean,
		contractId: {
			type: [Number, String],
			default: 0
		}
	},
	mounted() {
		this.loadData()
	},
	computed: {
		isEditable: {
			get(){
				if (!this.editable){
					return false
				}
				return this.contract.editable
			}
		},
		allCategoriesValid: {
			get(){
				return this.$refs.productList.allCategoriesValid()
			}
		},
		paymentPlansById: {
			get(){
				return _.keyBy(this.paymentPlans, 'id')
			}
		},
		productsById: {
			get() {
				return _.keyBy(this.products, 'id')
			}
		},
		currenciesArray: {
			get(){
				return _.values(this.currencies)
			}
		}
	},
	data() {
		return {
			contract: {},
			contractProducts: [],
			flash: [],
			loading: false,
			productPrices: {},
			pricesLoading: false,
			pricesLoadingCancelToken: null,
			priceCalculationRequired: false,
			paymentPlans: [],
			signedPages: [],
		};
	},
	methods: {
		formatDate: formatDate,
		moveValueInArray: moveValueInArray,
		loadData: function () {
			this.loading = true

			let url = this.dataUrl
			if (this.contractId) {
				url += '/' + this.contractId
			}
			let that = this
			axios.get(url + '?action=loadForeign').then((response) => {
				this.handleResponse(response)
			}).catch(function (error) {
				that.loading = false
				that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
			})
		},
		productChanged: function(){
			this.$refs.productList.productChanged()
		},
		emitClose: function(){
			this.$emit('close')
		},
		saveForm: function(e){
			if (this.signedPages){
				this.loading = true

				let payload = {
					contract: this.contract,
					products: this.$refs.productList.getFilteredProducts(),
					foreign: true,
					signedPages: this.signedPages
				}

				let url = this.dataUrl
				if (this.contractId){
					url += '/' + this.contractId
				}
				let that = this
				axios.post(url + '?action=save', payload).then((response)=>{
					this.handleResponse(response)
				}).catch(function (error) {
					that.loading = false
					that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
				})
			} else {
				this.flash = [{level: 'danger', message: 'Az aláírt szerződés feltöltése kötelező!'}]
			}
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.status === undefined || response.data.status === 'success') {
				this.contract = response.data.contract
				this.productPrices = response.data.prices
				this.contractProducts = this.contract.products_pivot
				this.paymentPlans = response.data.paymentPlans

				this.$refs.productList.setProductPrices(response.data.prices)

				if (response.data.status !== undefined && response.data.status === 'success') {
					this.$emit('closeAndReload')
				}
			}
			if (response.data.stats !== undefined){
				this.$emit('updateStats', response.data.stats)
			}
			if (response.data.flash !== undefined){
				this.flash = response.data.flash
			}
		},
		setFlash: function(flash){
			this.flash = flash
		},
	}
}
</script>

<style scoped>

</style>