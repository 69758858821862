<template>
	<div>
		<span :class="{'badge': 1, 'bg-success': isTrue, 'bg-danger': !isTrue, 'text-white': 1}">
			<span v-if="isTrue">Igen</span>
			<span v-if="!isTrue">Nem</span>
		</span>
	</div>
</template>

<script>
export default {
	name: "BooleanBadge",
	props: {
		value: {
			type: Boolean,
			default: false
		},
		data: Object,
		name: String
	},
	data() {
		return {
			isTrue: this.value
		}
	},
	mounted() {
		if (this.name !== '' && this.data !== undefined && this.data[this.name] !== undefined) {
			this.isTrue = !!this.data[this.name]
		}
	},
}
</script>

<style scoped>

</style>