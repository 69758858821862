<template>
	<div>
		<div class="row">
			<div class="col-md-6">
				<div class="mb-3 position-relative">
					<label :class="{'required' : required}">Település</label>

					<div :class="{'select-invalid': errors[attributeName + '.city_id']}">
						<Field
							v-model="componentValue.city_id"
							:name="attributeName + '.city_id'"
							:rules="required ? requiredInteger.label('Település') : optionalInteger.label('Település')"
							v-slot="{ field }"
							:class="{'form-control': 1}"
						>
							<v-select
								:options="cities"
								placeholder="Kezd el gépelni a keresést"
								:reduce="item => item.id"
								label="formatted_name"
								:clearable="!required"
								:select-on-tab="true"
								v-model="componentValue.city_id"
							/>
						</Field>
						<div class="invalid-feedback" v-if="errors[attributeName + '.city_id']">{{ errors[attributeName + '.city_id'] }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="mb-3 position-relative">
					<label :class="{'required' : required}">Utca</label>
					<Field
						v-model="componentValue.street"
						:name="attributeName + '.street'"
						:rules="required ? requiredString.label('Utca') : optionalString.label('Utca')"
						v-slot="{ field }"
						:class="{'form-control': 1}"
					>
						<input v-bind="field" type="text" :class="{'form-control': 1, 'is-invalid': errors[attributeName + '.street']}" />
					</Field>
					<div class="invalid-feedback" v-if="errors[attributeName + '.street']">{{ errors[attributeName + '.street'] }}</div>
				</div>
			</div>
			<div class="col-sm-4 col-md-2">
				<div class="mb-3 position-relative">
					<label :class="{'required' : required}">Házszám</label>
					<Field
						v-model="componentValue.house_number"
						:name="attributeName + '.house_number'"
						:rules="required ? requiredString.label('Házszám') : optionalString.label('Házszám')"
						v-slot="{ field }"
						:class="{'form-control': 1}"
					>
						<input v-bind="field" type="text" :class="{'form-control': 1, 'is-invalid': errors[attributeName + '.house_number']}" />
					</Field>
					<div class="invalid-feedback" v-if="errors[attributeName + '.house_number']">{{ errors[attributeName + '.house_number'] }}</div>
				</div>
			</div>
			<div class="col-sm-4 col-md-2">
				<div class="mb-3 position-relative">
					<label>Emelet</label>
					<input v-model="componentValue.floor" type="text" :class="{'form-control': 1, 'is-invalid': errors.length}" />
				</div>
			</div>
			<div class="col-sm-4 col-md-2">
				<div class="mb-3 position-relative">
					<label>Ajtó</label>
					<input type="text" :class="{'form-control': 1, 'is-invalid': errors.length}" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Field, ErrorMessage, useField, useForm } from 'vee-validate';
import vSelect from "vue-select";
import * as yup from 'yup';
export default {
	components: { Field, ErrorMessage, useField, useForm, vSelect },
	props: {
		modelValue: Object,
		cities: Array,
		required: Boolean,
		attributeName: String,
		errors: {
			type: Object
		},
	},
	data() {
		const optionalString = yup.string()
		const requiredString = yup.string().required()
		const optionalInteger = yup.number()
		const requiredInteger = yup.number().required()
		return {
			requiredString: requiredString,
			requiredInteger: requiredInteger,
			optionalString: optionalString,
			optionalInteger: optionalInteger,
		}
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	methods: {
		emitChange: function(){
			//this.$emit('fieldChange', this.content)
		},
		isNumber: function(e){
			const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
			const keyPressed = e.key;

			if (!keysAllowed.includes(keyPressed)) {
				e.preventDefault()
			}
		},
		getRules: function(required, integer = false){
			let rules
			if (integer){
				rules = yup.number()
			} else {
				rules = yup.string()
			}
			if (required){
				rules.required()
			}
			return rules
		}
	}
}
</script>

<style scoped>

</style>