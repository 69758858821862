<template>
	<flash-display :flash="flash" ref="notifications"></flash-display>
	<div :class="{'overlay-form-wrapper': 1}">
		<div :class="{'overlay-form-wrapper--content': 1, 'loading': loading}">
			<div :class="{'d-none': !loaded}">
				<div class="page-title-box">
					<h4 class="mb-sm-0 font-size-18">{{ contract.id !== undefined ? 'Szerződésmódosítás' : 'Szerződésmódosítás létrehozása'}}</h4>
				</div>
				<div class="card">
					<div class="card-body">
						<div class="details-box mb-3" v-if="contract.id !== undefined">
							<div>
								<i class="far fa-hashtag"></i> Azonosító:
								<strong>{{ contract.public_id }}</strong>
							</div>
							<div v-if="contractStatusLabels[contract.status] !== undefined">
								<i class="far fa-question"></i> Státusz:
								<span :class="'badge badge-xl bg-' + contractStatusLabels[contract.status].color"><i :class="contractStatusLabels[contract.status].icon"></i> {{ contractStatusLabels[contract.status].name }}</span>
							</div>
							<div v-if="contract.created_at">
								<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(contract.created_at) }}</strong>
							</div>
							<div v-if="contract.created_by_user_name">
								<i class="far fa-user"></i> Létrehozó: <strong>{{ contract.created_by_user_name }}</strong>
							</div>
							<div v-if="contract.updated_at">
								<i class="far fa-calendar-alt"></i> Módosítva: <strong>{{ formatDate(contract.updated_at) }}</strong>
							</div>
						</div>
						<div class="details-box mb-3" v-if="originalContract !== null">
							<h4>Eredeti szerződés</h4>
							<div>
								<i class="far fa-hashtag"></i> Azonosító:
								<strong>{{ originalContract.public_id }}</strong>
							</div>
							<div v-if="originalContract.created_at">
								<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(originalContract.created_at) }}</strong>
							</div>
							<div v-if="originalContract.generated_at">
								<i class="far fa-calendar-alt"></i> Generálva: <strong>{{ formatDate(originalContract.generated_at) }}</strong>
							</div>
							<div v-if="originalContract.created_by_user_name">
								<i class="far fa-user"></i> Létrehozó: <strong>{{ originalContract.created_by_user_name }}</strong>
							</div>
						</div>
						<template v-if="installation !== undefined && installation !== null && installation.id !== undefined">
							<div class="details-box mb-3">
								<h4>Telepítés</h4>
								<div v-if="installation.installation_date">
									<i class="far fa-calendar"></i> Telepítés dátuma:
									<strong>{{ formatDate(installation.installation_date, false) }}</strong>
								</div>
								<div v-if="installation.installer !== undefined">
									<i class="far fa-user"></i> Telepítő:
									<strong>{{ installation.installer.name }}</strong>
								</div>
								<div v-if="installation.closed_at">
									<i class="far fa-calendar"></i> Lezárva:
									<strong>{{ formatDate(installation.closed_at, true) }}</strong>
								</div>
								<div v-if="installation.closed_by_user !== undefined">
									<i class="far fa-user"></i> Lezárta:
									<strong>{{ installation.closed_by_user.name }}</strong>
								</div>
							</div>
						</template>
						<LockMessage :lock="lock" :margin-bottom="true"></LockMessage>
						<div class="row">
							<div class="col-md-4">
								<InputField
										label="Kedvezmény"
										name="discount"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="contract.discount"
										:disabled="!isEditable"
										:errors="{}"
										@input="productChanged"
								/>
							</div>
							<div class="col-md-4">
								<InputField
										label="Kedvezmény anyag"
										name="discount_material"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="contract.discount_material"
										:disabled="!isEditable"
										:errors="{}"
										@input="productChanged"
								/>
							</div>
							<div class="col-md-4">
								<InputField
										label="Kedvezmény munka"
										name="discount_work"
										data-type="number"
										:step="0.1"
										suffix-icon="far fa-percent"
										v-model="contract.discount_work"
										:disabled="!isEditable"
										:errors="{}"
										@input="productChanged"
								/>
							</div>
							<div class="col-12">
								<TextareaField
										name="description"
										:rows="4"
										v-model="contract.description"
										:disabled="!isEditable"
										label="Megjegyzés"
								></TextareaField>
							</div>
						</div>
					</div>
				</div>
				<div class="mb-3">
					<div class="d-flex flex-wrap gap-2">
						<button type="submit" class="btn btn-primary btn-label waves-effect" v-if="isEditable" :disabled="!allCategoriesValid" @click.prevent="saveForm">
							<i class="fas fa-save label-icon"></i> Mentés
						</button>
						<button type="submit" class="btn btn-warning btn-label waves-effect" v-if="!editable && canStartEditing" @click.prevent="$emit('startEditing')">
							<i class="fas fa-pencil label-icon"></i> Szerkesztés
						</button>
						<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="generateContract" v-if="editable && contract.id && contract.status === 'draft'">
							<i class="fas fa-check label-icon"></i> Lezárás
						</button>
						<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="enableDelivery" v-if="editable && contract.id && contract.status === 'signed' && contract.delivery === 'none' && canSetDeliveryForContract">
							<i class="fas fa-truck label-icon"></i> Kiszállítás engedélyezése
						</button>
						<a class="btn btn-secondary btn-label waves-effect" v-if="contract.id && contract.status !== 'draft' && contract.file_id" :href="dataUrl + '/' + contract.id + '?action=download'">
							<i class="far fa-download label-icon"></i> Letöltés
						</a>
						<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
							<i class="fas fa-times label-icon"></i> Bezár
						</button>
					</div>
				</div>

				<ProjectProductList
						:data-url="dataUrl"
						:product-categories="productCategories"
						:products="products"
						:is-editable="isEditable"
						v-model="contractProducts"
						:parent-model="contract"
						:product-types="productTypes"
						:currencies="currencies"
						:product-versions="contract.product_versions"
						:limited-product-quantities="limitedProductQuantities"
						mode="contractAmendment"
						ref="productList"
						@flash="setFlash"
				>
				</ProjectProductList>

				<div class="card border" v-if="originalContract !== null">
					<div class="card-body">
						<h4 class="card-title mb-4">{{ contract.status !== 'draft' ? 'Eredeti szerződésben foglalt termékek' : 'Szerződésben foglalt termékek' }}</h4>

						<table class="table">
							<thead>
							<tr>
								<th>Termék</th>
								<th width="160" class="text-end">Menny.</th>
								<th width="15%">Típus</th>
								<th width="10%" class="text-end text-nowrap">Nettó egys.</th>
								<th width="10%" class="text-end">Bruttó ár</th>
							</tr>
							</thead>
							<tbody>
							<template v-for="product in originalProducts">
								<tr>
									<td>{{ productsById[product.product_id].name }}</td>
									<td class="text-end">{{ product.quantity }} {{ productsById[product.product_id] !== undefined ? productsById[product.product_id].unit : 'db' }}</td>
									<td>
										<div v-html="getProductType(product)"></div>
									</td>
									<td class="text-end vertical-align-middle">
										<Price
											v-if="product.product_id && originalProductPrices[(product.order + 1)] !== undefined"
											:value="originalProductPrices[(product.order + 1)].net - originalProductPrices[(product.order + 1)].discountNet"
											:currencies="currencies"
											:loading="pricesLoading"
										/>
									</td>
									<td class="text-end vertical-align-middle">
										<Price
											v-if="product.product_id && originalProductPrices[(product.order + 1)] !== undefined"
											:value="(originalProductPrices[(product.order + 1)].gross - originalProductPrices[(product.order + 1)].discountGross) * product.quantity"
											:currencies="currencies"
											:loading="pricesLoading"
										/>
									</td>
								</tr>
							</template>
							</tbody>
						</table>
					</div>
				</div>

				<div class="mt-3">
					<div class="d-flex flex-wrap gap-2">
						<button type="submit" class="btn btn-primary btn-label waves-effect" v-if="isEditable" :disabled="!allCategoriesValid" @click.prevent="saveForm">
							<i class="fas fa-save label-icon"></i> Mentés
						</button>
						<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="generateContract" v-if="editable && contract.id && contract.status === 'draft'">
							<i class="fas fa-check label-icon"></i> Lezárás
						</button>
						<button type="submit" class="btn btn-warning btn-label waves-effect" @click.prevent="enableDelivery" v-if="editable && contract.id && contract.status === 'signed' && contract.delivery === 'none' && canSetDeliveryForContract">
							<i class="fas fa-truck label-icon"></i> Kiszállítás engedélyezése
						</button>
						<a class="btn btn-secondary btn-label waves-effect" v-if="contract.id && contract.status !== 'draft' && contract.file_id" :href="dataUrl + '/' + contract.id + '?action=download'">
							<i class="far fa-download label-icon"></i> Letöltés
						</a>
						<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
							<i class="fas fa-times label-icon"></i> Bezár
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {formatDate, moveValueInArray} from "../../functions";
import ProjectInfoSidebar from "./ProjectInfoSidebar.vue";
import FlashDisplay from "../../components/FlashDisplay.vue";
import InputField from "../../components/form/InputField.vue";
import ProjectProductList from "./ProjectProductList.vue";
import Price from "../../components/Price.vue";
import vSelect from "vue-select";
import FileList from "../../components/FileList.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import LockMessage from "../../components/LockMessage.vue";

export default {
	components: {LockMessage, TextareaField, FileList, Price, ProjectProductList, InputField, FlashDisplay, ProjectInfoSidebar, vSelect},
	emits: ['close', 'closeAndReload', 'generate', 'updateStats', 'startEditing'],
	props: {
		productCategories: [Array, Object],
		productTypes: Object,
		currencies: Object,
		taxes: Object,
		editable: Boolean,
		products: Array,
		client: Object,
		lock: Object,
		dataUrl: String,
		fileUploadUrl: String,
		projectAttributes: Object,
		projectFields: Array,
		contractStatusLabels: Object,
		deliveryTypeLabels: Object,
		originalContractId: Number,
		canSetDeliveryForContract: Boolean,
		canStartEditing: Boolean,
		canCreate: Boolean,
		canUpdate: Boolean,
		canDelete: Boolean,
		contractId: {
			type: [Number, String],
			default: 0
		}
	},
	mounted() {
		this.loadData()
	},
	computed: {
		isEditable: {
			get(){
				if (!this.editable){
					return false
				}
				return this.contract.editable
			}
		},
		allCategoriesValid: {
			get(){
				return this.$refs.productList !== undefined && this.$refs.productList.allCategoriesValid()
			}
		},
		limitedProductQuantities: {
			get(){
				let payload = {}
				if (this.originalProducts !== null && this.originalProducts !== undefined) {
					_.forEach(this.originalProducts, function(product){
						payload[product.product_id] = {
							min: -1 * product.quantity
						}
					})
				}

				return payload
			}
		},
		productsById: {
			get() {
				return _.keyBy(this.products, 'id')
			}
		},
	},
	data() {
		return {
			contract: {
				product_versions: []
			},
			flash: [],
			loading: false,
			installation: {},
			productPrices: {},
			originalProductPrices: {},
			pricesLoading: false,
			pricesLoadingCancelToken: null,
			priceCalculationRequired: false,
			contractProducts: [],
			paymentPlans: [],
			originalContract: null,
			originalProducts: null,
			loaded: false
		};
	},
	methods: {
		formatDate: formatDate,
		moveValueInArray: moveValueInArray,
		getDataUrl: function(){
			let url = this.dataUrl
			if (this.contractId) {
				url += '/' + this.contractId
			} else if (this.originalContractId) {
				url += '/' + this.originalContractId
			}

			return url
		},
		loadData: function () {
			this.loading = true

			let that = this
			axios.get(this.getDataUrl() + '?action=loadAmendment').then((response) => {
				this.handleResponse(response)
			}).catch(function (error) {
				that.flash = [{level: 'danger', message: error.response.data.message}]
			})
		},
		productChanged: function(){
			this.$refs.productList.productChanged()
		},
		generateContract: function(){
			this.loading = true
			this.$emit('generate', this.contractId)
		},
		enableDelivery: function(){
			this.loading = true
			this.$emit('enableDelivery', this.contractId)
		},
		emitClose: function(){
			this.$emit('close')
		},
		saveForm: function(e){
			if (this.allCategoriesValid){
				this.loading = true

				let payload = {
					contract: this.contract,
					products: this.$refs.productList.getFilteredProducts()
				}

				let that = this
				axios.post(this.getDataUrl() + '?action=saveAmendment', payload).then((response)=>{
					this.handleResponse(response)
				}).catch(function (error) {
					that.flash = [{level: 'danger', message: error.response.data.message}]
				})
			} else {
				this.flash = [{level: 'danger', message: 'Kérlek töltsd ki a pirossal jelölt mezőket!'}]
			}
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.status === undefined || response.data.status === 'success') {
				this.loaded = true
				this.originalContract = response.data.originalContract
				this.originalProductPrices = response.data.originalPrices
				this.originalProducts = response.data.originalProducts
				this.contract = response.data.contract
				this.productPrices = response.data.prices
				this.contractProducts = this.contract.products_pivot
				this.paymentPlans = response.data.paymentPlans
				if (response.data.installation !== undefined){
					this.installation = response.data.installation
				}

				this.$refs.productList.setProductPrices(response.data.prices)

				if (response.data.status !== undefined && response.data.status === 'success') {
					this.$emit('closeAndReload')
				}
			}
			if (response.data.stats !== undefined){
				this.$emit('updateStats', response.data.stats)
			}
			if (response.data.flash !== undefined){
				this.flash = response.data.flash
			}
		},
		setFlash: function(flash){
			this.flash = flash
		},
		getProductType: function(productRow){
			if (!productRow.product_id){
				return ''
			}
			return '<span class="badge badge-xl bg-' + this.productTypes[this.productsById[productRow.product_id].type].color + '">' +
				'<i class="' + this.productTypes[this.productsById[productRow.product_id].type].icon + '"></i> ' + this.productTypes[this.productsById[productRow.product_id].type].label +
				'</span>'
		},
		getTotalPrice: function(gross, discount, type) {
			type = type || 'all'
			let total = 0

			_.forEach(this.productPrices, function(productPrice, productPriceKey){
				if (productPrice.bundle || (type !== 'all' && productPrice.type !== type)){
					return
				}
				if (discount){
					total += (gross ? productPrice.discountGross : productPrice.discountNet) * productPrice.quantity
				} else {
					total += (gross ? productPrice.gross : productPrice.net) * productPrice.quantity
				}
			})

			return total
		},
	}
}
</script>

<style scoped>

</style>