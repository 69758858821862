<template>
	<select-field
		:label="label"
		:name="name"
		v-model="componentValue"
		:get-option-label="formatLabel"
		:options="options"
		:errors="errors"
		:filter-options="filterOptions"
		:rules="rules"
		:search="search"
		@change="change"
		:display-search-info-bottom="true"
	>

		<template #option="option">
			<div class="d-flex gap-2">
				{{ option.option.name }} <UserLevelBadge v-if="option.option.user_level_id" :user-level-id="option.option.user_level_id" :user-levels="userLevels" :small="true" :short="true" />
			</div>
		</template>
		<template #selected-option="option">
			<div class="d-flex gap-2">
				{{ option.option.name }} <UserLevelBadge v-if="option.option.user_level_id" :user-level-id="option.option.user_level_id" :user-levels="userLevels" :small="true" :short="true" />
			</div>
		</template>
	</select-field>
</template>

<script>
import SelectField from "./SelectField.vue";
import {formatDate} from "../../functions";
import vSelect from "vue-select";
import UserLevelBadge from "../UserLevelBadge.vue";

export default {
	emits: ['update:modelValue', 'change'],
	components: {UserLevelBadge, vSelect, SelectField},
	props: {
		modelValue: Number,
		errors: Object,
		userLevels: Array,
		name: String,
		label: String,
		typeFilter: String,
		excludedId: Number,
		rules: {
			type: String,
			default: ''
		},
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	mounted() {
		this.search(null, '')
	},
	data(){
		return {
			options: []
		}
	},
	methods: {
		search: function (loading, search){
			let that = this
			let url = '/users/userSearch?q=' + search
			if (this.typeFilter){
				url += '&type=' + this.typeFilter
			}
			if (this.componentValue){
				url += '&id=' + this.componentValue
			}
			if (this.excludedId !== undefined && this.excludedId !== null){
				url += '&exclude=' + this.excludedId
			}

			fetch(
				url
			).then(res => {
				res.json().then(json => (that.options = json.results));
				if (loading !== null) {
					loading(false)
				}
			});
		},
		formatLabel: function(item){
			return item.name + ' (' + item.clientName + ' / ' + item.address + ')'
		},
		filterOptions: function(options, search){
			return options
		},
		change: function (e){
			this.$emit('change', e)
		}
	}
}
</script>

<style scoped>

</style>