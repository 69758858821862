<template>
	<DataTable
			:data-url="dataUrl"
			:initial-flash="initialFlash"
			:columns="columns"
			sort-by="name"
			sort-type="asc"
			:name="'inventoryDetailed' + (delivered ? '_delivered' : '')"
	>
		<template #filters="{serverOptions}">
			<div class="col-12 col-md-3">
				<v-select
						:options="warehouseArray"
						v-model="serverOptions.warehouse_id"
						:reduce="item => item.id"
						label="name"
						placeholder="Raktárak"
				/>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed}">
			<div class="table-row-operations d-flex gap-2">
				<a :href="(item.serial_number ? inventoryUrl + '/' + item.serial_number : productUrl + '/' + item.product_id)" class="btn btn-primary btn-sm waves-effect" title="Részletek"><i class="far fa-eye"></i></a>
			</div>
		</template>
		<template #item-stock="item">
			{{ item.stock }}
		</template>
		<template #item-warehouse_id="item">
			{{ warehouses[item.warehouse_id].name }}
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import BooleanBadge from "../../components/BooleanBadge.vue";
import vSelect from "vue-select";

export default {
	components: {
		DataTable,
		BooleanBadge,
		TableActionCell,
		vSelect
	},
	props: {
		dataUrl: String,
		inventoryUrl: String,
		productUrl: String,
		warehouses: Object,
		delivered: Boolean,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	computed: {
		warehouseArray: {
			get() {
				return _.values(this.warehouses)
			}
		}
	},
	data(){
		let columns = [
			{
				text: 'Terméknév',
				value: 'productName',
				sortable: true
			},
			{
				text: 'Cikkszám',
				value: 'sku',
				sortable: true
			},
			{
				text: 'Sorozatszám',
				value: 'serial_number',
				sortable: true
			},
			{
				text: 'Raktár',
				value: 'warehouse_id',
				sortable: true
			},
			{
				text: 'Mennyiség',
				value: 'stock',
				align: 'right',
				sortable: true
			}
		]

		if (this.delivered){
			columns.push({
				text: 'Ügyfél',
				value: 'clientName',
				sortable: true
			})
			columns.push({
				text: 'Szerződés',
				value: 'contractId',
				sortable: true
			})
		}
		columns.push({
				label: '',
				value: 'operations',
				width: 5,
			})

		return {
			columns: columns,
			productCategoriesById: _.keyBy(this.productCategories, 'id'),
			productManufacturersById: _.keyBy(this.productManufacturers, 'id'),
		}
	},
}
</script>

<style scoped>
</style>
