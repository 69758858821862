import _ from 'lodash';
window._ = _;

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
window.Modal = bootstrap.Modal;

import Moment from 'moment';
window.moment = Moment

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import MetisMenu from 'metismenujs';

import 'node-waves';

Waves.attach('.btn', ['waves-effect']);
Waves.init();


let menu = document.getElementById('side-menu')
if (menu && !menu.classList.contains('inited')) {
	menu.classList.add('inited')
	setTimeout(function(){
		window.mm = new MetisMenu('#side-menu', {});
	}, 500)
}
