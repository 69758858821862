<template>
	<div v-for="(field, fieldKey) in projectType.fields_data" :key="field.id">
		<div class="card" v-if="canDisplayField(field)">
			<div class="card-body">
				<div class="project-attribute" :data-field-id="field.id">
					<ProjectAttribute
						v-model="componentValue.attribute_values[field.id]"
						:field="field"
						:field-key="fieldKey"
						:parent-key="null"
						:parent-order="0"
						:editable="isFieldEditable(field)"
						:cities="cities"
						:ref="'field_' + field.id"
						:visible-fields="visibleFields"
						:client="client"
						:display-label="true"
						:user-type="userType"
						:client-input-required="!!componentValue.client_input_required"
						@value-changed="valueChanged"
					></ProjectAttribute>
				</div>

				<div v-if="field.type !== 'multirow' && field.children !== undefined && field.children.length" class="project-attribute--sublist">
					<div class="row">
						<template v-for="(childField, childFieldKey) in field.children" :key="childField.id">
							<div :class="getSubfieldClass(childField)" v-if="canDisplayField(childField)">
								<div class="project-attribute" :data-field-id="childField.id">
									<ProjectAttribute
										v-model="componentValue.attribute_values[childField.id]"
										:field="childField"
										:field-key="childFieldKey"
										:parent-key="fieldKey"
										:parent-order="field.order"
										:editable="isFieldEditable(childField)"
										:cities="cities"
										:ref="'field_' + childField.id"
										:visible-fields="visibleFields"
										:client="client"
										:display-label="true"
										:user-type="userType"
										:client-input-required="!!componentValue.client_input_required"
										@value-changed="valueChanged"
									></ProjectAttribute>
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ProjectAttribute from "./ProjectAttribute.vue";
export default {
	components: {ProjectAttribute},
	emits: ['update:modelValue', 'valueChanged'],
	props: {
		projectType: Object,
		modelValue: Object,
		client: Object,
		cities: Array,
		editable: Boolean,
		userType: String,
	},
	mounted() {
		this.updateVisibleFields()
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		},
	},
	data() {
		return {
			visibleFields: []
		}
	},
	methods: {

		canDisplayField: function(field){
			if (this.visibleFields.indexOf(field.id) < 0) {
				return false
			}
			if (field.private_only && this.client.is_company){
				return false
			}
			if (field.company_only && !this.client.is_company){
				return false
			}

			return true
		},
		updateVisibleFields: function(){
			if (this.projectType === null){
				return
			}
			for (let i = 0; i < this.projectType.fields_data.length; i++){
				if (this.isFieldVisible(this.projectType.fields_data[i])){
					if (this.visibleFields.indexOf(this.projectType.fields_data[i].id) < 0){
						this.visibleFields.push(this.projectType.fields_data[i].id)
					}
				} else {
					if (this.visibleFields.indexOf(this.projectType.fields_data[i].id) > -1){
						this.visibleFields.splice(this.visibleFields.indexOf(this.projectType.fields_data[i].id), 1)
					}
				}
				if (this.projectType.fields_data[i].type === 'multirow'){
					for (let rowKey = 0; rowKey < this.componentValue['attribute_values'][this.projectType.fields_data[i].id].length; rowKey++){

						for (let j = 0; j < this.projectType.fields_data[i].children.length; j++) {
							if (this.isFieldVisible(this.projectType.fields_data[i].children[j], rowKey)) {
								if (this.visibleFields.indexOf(this.projectType.fields_data[i].children[j].id + '_' + rowKey) < 0) {
									this.visibleFields.push(this.projectType.fields_data[i].children[j].id + '_' + rowKey)
								}
							} else {
								if (this.visibleFields.indexOf(this.projectType.fields_data[i].children[j].id + '_' + rowKey) > -1) {
									this.visibleFields.splice(this.visibleFields.indexOf(this.projectType.fields_data[i].children[j].id + '_' + rowKey), 1)
								}
							}
						}
					}
				} else {
					for (let j = 0; j < this.projectType.fields_data[i].children.length; j++) {
						if (this.isFieldVisible(this.projectType.fields_data[i].children[j])) {
							if (this.visibleFields.indexOf(this.projectType.fields_data[i].children[j].id) < 0) {
								this.visibleFields.push(this.projectType.fields_data[i].children[j].id)
							}
						} else {
							if (this.visibleFields.indexOf(this.projectType.fields_data[i].children[j].id) > -1) {
								this.visibleFields.splice(this.visibleFields.indexOf(this.projectType.fields_data[i].children[j].id), 1)
							}
						}
					}
				}
			}
		},
		isFieldVisible: function(field, rowKey = null){
			if (!field.client_can_see && this.userType === 'client'){
				return false
			}
			if (field.parsed_conditions.fields !== undefined && field.parsed_conditions.fields.length){
				for (let i = 0; i < field.parsed_conditions.fields.length; i++){
					let conditionField = this.getFieldByNumber(field.parsed_conditions.fields[i].fieldNumber)
					if (!conditionField || (conditionField.type !== 'bool' && conditionField.type !== 'select')){
						return false
					}
					let answer = 0;
					let conditionResult = false
					if (conditionField.type === 'bool'){
						answer = this.componentValue['attribute_values'][conditionField.id] !== undefined && this.componentValue['attribute_values'][conditionField.id].value ? this.componentValue['attribute_values'][conditionField.id].value : 0
						conditionResult = field.parsed_conditions.fields[i].relation === 'neq' ? !answer !== !field.parsed_conditions.fields[i].value : !answer === !field.parsed_conditions.fields[i].value
					} else {
						answer = []
						let answerValueIdArray = this.componentValue['attribute_values'][conditionField.id] !== undefined && this.componentValue['attribute_values'][conditionField.id].value ? this.componentValue['attribute_values'][conditionField.id].value : []

						if (rowKey !== null){
							let conditionFieldParent = this.getFieldById(conditionField.parent_id)
							answerValueIdArray = this.componentValue['attribute_values'][conditionFieldParent.id] !== undefined
							&& this.componentValue['attribute_values'][conditionFieldParent.id][rowKey][conditionField.id] !== undefined
							&& this.componentValue['attribute_values'][conditionFieldParent.id][rowKey][conditionField.id].value
								? this.componentValue['attribute_values'][conditionFieldParent.id][rowKey][conditionField.id].value
								: []
						}
						if (!Array.isArray(answerValueIdArray)){
							answerValueIdArray = [answerValueIdArray]
						}
						let answerValueOrderArray = []
						for (let j = 0; j < answerValueIdArray.length; j++){
							for (let k = 0; k < conditionField.options.length; k++){
								if (conditionField.options[k].id === answerValueIdArray[j]){
									answerValueOrderArray.push(conditionField.options[k].order)
								}
							}
						}

						conditionResult = field.parsed_conditions.fields[i].relation === 'neq'
						if (answerValueOrderArray.indexOf(field.parsed_conditions.fields[i].value) > -1){
							conditionResult = field.parsed_conditions.fields[i].relation !== 'neq'
						}
					}

					if (field.parsed_conditions.relation === 'and' && !conditionResult){
						return false
					}
					if (field.parsed_conditions.relation === 'or' && conditionResult){
						return true
					}
				}

				return field.parsed_conditions.relation === 'and'
			}

			return true
		},
		getFieldByNumber: function(fieldNumber){
			for (let i = 0; i < this.projectType.fields_data.length; i++){
				if (this.projectType.fields_data[i].field_number === fieldNumber){
					return this.projectType.fields_data[i]
				}
				for (let j = 0; j < this.projectType.fields_data[i].children.length; j++){
					if (this.projectType.fields_data[i].children[j].field_number === fieldNumber){
						return this.projectType.fields_data[i].children[j]
					}
				}
			}
		},
		getFieldById: function(id){
			for (let i = 0; i < this.projectType.fields_data.length; i++){
				if (this.projectType.fields_data[i].id === id){
					return this.projectType.fields_data[i]
				}
				for (let j = 0; j < this.projectType.fields_data[i].children.length; j++){
					if (this.projectType.fields_data[i].children[j].id === id){
						return this.projectType.fields_data[i].children[j]
					}
				}
			}
		},
		getSubfieldClass: function(field){
			if (field.type === 'title' || field.type === 'textarea' || field.type === 'table' || field.type === 'address' || this.userType === 'client'){
				return 'col-12'
			}

			return 'col-md-6'
		},
		valueChanged: function(e){
			this.updateVisibleFields()
			this.$emit('valueChanged')
		},
		validateForm: function(){
			let refs = Object.keys(this.$refs)
			if (!refs.length){
				return false
			}
			let valid = true
			for (let i = 0; i < refs.length; i++){
				if (refs[i].indexOf('field_') === 0 && this.$refs[refs[i]] !== undefined && this.$refs[refs[i]][0] !== undefined && !this.$refs[refs[i]][0].validateField()){
					valid = false
				}
			}

			return valid
		},
		isFieldEditable: function(field){
			if (!this.editable){
				return false
			}
			if (this.userType === 'client' && !field.client_fillable){
				return false
			}

			return true
		},
		onUnload: function(){

		}
	}
}
</script>

<style scoped>

.project-attribute--sublist {
	padding: 30px 0 0 0;
}
.project-attribute--sublist .project-attribute {
	margin-bottom: 20px;
}
.project-attribute--sublist div:last-child > .project-attribute {
	margin-bottom: 0;
}
.project-attribute-highlight {
	border: 1px solid var(--bs-primary);
	border-radius: 0.25rem;
	padding: 10px;
}
.form-info {
	display: flex;
	align-items: center;
}
.form-info + .form-info {
	margin-top: 3px;
}
.form-info i {
	margin-right: 8px;
	width: 14px;
	text-align: center;
}
.form-info > strong,
.form-info > a {
	margin: 0 6px;
}
</style>