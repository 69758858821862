<template>
	<div class="table-row-operations d-flex gap-2">
		<template v-if="displayEditButton">
			<a v-if="editButtonLink" :href="editButtonLink" class="btn btn-primary btn-sm waves-effect" title="Szerkesztés"><i class="fas fa-edit"></i></a>
			<button v-else type="button" @click.prevent="editButtonPressed" class="btn btn-primary btn-sm waves-effect" title="Szerkesztés"><i class="fas fa-edit"></i></button>
		</template>
		<template v-if="displayViewButton">
			<a v-if="viewButtonLink" :href="viewButtonLink" class="btn btn-primary btn-sm waves-effect" title="Részletek"><i class="fas fa-eye"></i></a>
			<button v-else type="button" @click.prevent="viewButtonPressed" class="btn btn-primary btn-sm waves-effect" title="Részletek"><i class="fas fa-eye"></i></button>
		</template>

		<slot :row="data"></slot>

		<confirm-button
			v-if="displayDeleteButton"
			:data="data"
			:emit-delete-event="emitDeleteEvent"
			:url="deleteButtonUrl"
			message="Biztos, hogy törölni akarod?"
			@confirmed="deleteButtonPressed"
		></confirm-button>
	</div>
</template>

<script>
import ConfirmButton from "./ConfirmButton";
export default {
	components: {ConfirmButton},
	props: {
		data: {
			type: Object
		},
		editUrl: {
			type: String,
			default: ''
		},
		deleteUrl: {
			type: String,
			default: ''
		},
		isEditable: {
			type: [Boolean, Function],
			default: false
		},
		isDeletable: {
			type: [Boolean, Function],
			default: false
		},
		emitEditEvent: {
			type: Boolean
		},
		emitViewEvent: {
			type: Boolean
		},
		emitDeleteEvent: {
			type: Boolean
		}
	},
	emits: ['editButtonPressed', 'viewButtonPressed', 'deleteButtonPressed'],
	computed: {
		displayEditButton: {
			get(){
				if (!this.editUrl && !this.emitEditEvent){
					return false
				}
				if (this.data.editable !== undefined && this.data.editable){
					return true
				}
				return !(this.isEditable instanceof Function ? !this.isEditable(this.data) : !this.isEditable);
			}
		},
		editButtonLink: {
			get(){
				return this.editUrl.length ? this.editUrl + '/' + this.data.id : ''
			}
		},
		displayViewButton: {
			get(){
				if (!this.editUrl && !this.emitViewEvent){
					return false
				}
				if (this.data.editable !== undefined && this.data.editable){
					return false
				}
				return this.isEditable instanceof Function ? !this.isEditable(this.data) : !this.isEditable;
			}
		},
		viewButtonLink: {
			get(){
				return this.editUrl.length ? this.editUrl + '/' + this.data.id : ''
			}
		},
		displayDeleteButton: {
			get(){
				if (!this.deleteUrl && !this.emitDeleteEvent){
					return false
				}
				if (this.data.deletable !== undefined && this.data.deletable){
					return true
				}
				return this.isDeletable instanceof Function ? this.isDeletable(this.data) : this.isDeletable;
			}
		},
		deleteButtonUrl: {
			get(){
				return this.deleteUrl.length ? this.deleteUrl + '/' + this.data.id : ''
			}
		},
	},
	methods: {
		editButtonPressed: function(){
			this.$emit('editButtonPressed', this.data.id)
		},
		viewButtonPressed: function(){
			this.$emit('viewButtonPressed', this.data.id)
		},
		deleteButtonPressed: function(){
			this.$emit('deleteButtonPressed', this.data.id, this.deleteButtonUrl)
		},
	}
}
</script>

<style scoped>

</style>