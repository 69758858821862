<template>
	<span :class="badgeClasses" :style="bgColor ? 'background:' + bgColor + ';' : ''">
		<i v-if="iconClasses.length" :class="iconClasses"></i><span>{{contentString}}</span>
	</span>
</template>

<script>
export default {
	props: {
		value: {
			type: [Object]
		},
		content: String,
		icon: String,
		color: String,
		luminosity: String,
		small: Boolean,
		large: Boolean
	},
	computed: {
		contentString: {
			get() {
				if (this.value !== undefined && this.value.name !== undefined && this.value.name){
					return this.value.name
				}

				return this.content
			}
		},
		bgColor: {
			get() {
				if (this.value !== undefined && this.value.color !== undefined && this.value.color && this.value.luminosity !== undefined && this.value.luminosity){
					return this.value.color
				}

				return this.color
			}
		},
		iconClasses: {
			get() {
				let tmp = []
				if (this.value !== undefined && this.value.icon !== undefined && this.value.icon){
					tmp.push('me-2')
					tmp.push('far')
					tmp.push('fa-' + this.value.icon)
				} else if (this.icon){
					tmp.push('me-2')
					tmp.push('far')
					tmp.push('fa-' + this.icon)
				}

				return tmp
			}
		},
		badgeClasses: {
			get() {
				let classes = ['colored-badge']
				if (this.value !== undefined && this.value.color !== undefined && this.value.color && this.value.luminosity !== undefined && this.value.luminosity){
					classes.push('colored-badge-' + this.value.luminosity)
				} else if (this.luminosity){
					classes.push('colored-badge-' + this.luminosity)
				} else if (this.value !== undefined && this.value.color !== undefined && this.value.color){
					classes.push('bg-' + this.value.color)
				} else {
					classes.push('bg-primary')
				}
				if (this.small){
					classes.push('colored-badge-sm')
				}
				if (this.large){
					classes.push('colored-badge-xl')
				}
				return classes
			}
		},
	},
	data() {
		return {
			isTrue: this.value
		}
	},
}
</script>

<style scoped>

</style>