<template>
	<DataTable
		:data-url="dataUrl"
		:initial-flash="initialFlash"
		:columns="columns"
		sort-by="priority"
		sort-type="desc"
		name="projectTypes"
	>
		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
				:data="item"
				:edit-url="editUrl"
				:delete-url="deleteUrl"
				:is-editable="true"
				:is-deletable="true"
				:emit-delete-event="true"
				@deleteButtonPressed="deleteButtonPressed"
			>
				<a :href="duplicateUrl + '/' + item.id" class="btn btn-warning btn-sm" title="Duplikálás"><i class="far fa-clone"></i></a>
			</TableActionCell>
		</template>
		<template #item-active="item">
			<BooleanBadge
				:data="item"
				name="active"
				:key="'active_' + item.id"
			></BooleanBadge>
		</template>
		<template #item-public="item">
			<BooleanBadge
				:data="item"
				name="public"
				:key="'public_' + item.id"
			></BooleanBadge>
		</template>
	</DataTable>
</template>
<script>
import TableActionCell from "../../components/TableActionCell";
import DataTable from "../../components/DataTable.vue";
import BooleanBadge from "../../components/BooleanBadge.vue";

export default {
	components: {
		DataTable,
		BooleanBadge,
		TableActionCell
	},
	props: {
		dataUrl: String,
		editUrl: String,
		duplicateUrl: String,
		deleteUrl: String,
		isEditable: Boolean,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Név',
				value: 'name',
			},
			{
				text: 'Aktív',
				value: 'active',
			},
			{
				text: 'Publikus',
				value: 'public',
			},
			{
				text: 'Prioritás',
				value: 'priority',
			},
			{
				label: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
		}
	},
}
</script>

<style scoped>
</style>
