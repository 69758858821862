<template>
	{{ value }}
</template>

<script>
export default {
	props: {
		modelValue: [Date, Number, String]
	},
	computed: {
		date: {
			get(){
				if (typeof this.modelValue === 'string'){
					return new Date(this.modelValue)
				}
				if (typeof this.modelValue === 'number'){
					return this.modelValue.valueOf()
				}

				return this.modelValue
			}
		},
	},
	data(){
		return {
			strings: {
				secondsAgo: (seconds) => `pár másodperce`,
				inSeconds: (seconds) => `pár másodperc múlva`,
				minutesAgo: (minutes) => `${minutes} perce`,
				inMinutes: (minutes) => `${minutes} perc múlva`,
				hoursAgo: (hours) => `${hours} órája`,
				inHours: (hours) => `${hours} óra múlva`,
				daysAgo: (days) => `${days} napja`,
				inDays: (days) => `${days} nap múlva`,
				monthsAgo: (months) => `${months} hónapja`,
				inMonths: (months) => `${months} hónap múlva`,
				yearsAgo: (years) => `${years} éve`,
				inYears: (years) => `${years} év múlva`
			},
			value: ''
		}
	},
	mounted() {
		this.value = this.getValue()

		this.setNextUpdate()
	},
	methods: {
		setNextUpdate: function() {
			let that = this
			setTimeout(function(){
				that.value = that.getValue()
				that.setNextUpdate()
			}, 60000)
		},
		getValue: function (){
			const value = this.date
			const offset = value - Date.now()

			const seconds = Math.round(Math.abs(offset) / 1000.0)
			if (seconds < 45) {
				return offset < 0 ? this.strings.secondsAgo(seconds) : this.strings.inSeconds(seconds)
			}

			const minutes = Math.round(seconds / 60)
			if (minutes < 60) {
				return offset < 0 ? this.strings.minutesAgo(minutes) : this.strings.inMinutes(minutes)
			}

			const hours = Math.round(minutes / 60)
			if (hours < 24) {
				return offset < 0 ? this.strings.hoursAgo(hours) : this.strings.inHours(hours)
			}

			const days = Math.round(hours / 24)
			if (days < 30) {
				return offset < 0 ? this.strings.daysAgo(days) : this.strings.inDays(days)
			}

			const months = Math.round(days / 30)
			if (months < 12) {
				return offset < 0 ? this.strings.monthsAgo(months) : this.strings.inMonths(months)
			}

			const years = Math.round(days / 365)
			return offset < 0 ? this.strings.yearsAgo(years) : this.strings.inYears(years)
		}
	}
}
</script>

<style scoped>

</style>