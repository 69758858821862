<template>
	<DataTable
		:data-url="dataUrl"
		:initial-flash="initialFlash"
		:columns="columns"
		sort-by="created_at"
		sort-type="desc"
		name="notifications"
		:search="false"
	>
		<template #item-title="item">
			<span :class="'badge badge-xl bg-' + item.color"><i :class="item.icon"></i> {{ item.title }}</span>
		</template>
		<template #item-created_at="item">
			{{ formatDate(item.created_at) }}
		</template>
		<template #item-read_at="item">
			<div class="text-center">
				<i class="far fa-times text-danger" v-if="!item.read_at"></i>
				<span v-else>{{ formatDate(item.read_at) }}</span>
			</div>
		</template>
		<template #item-operations="{item, deleteButtonPressed}">
			<a :href="'notifications/redirect/' + item.id" class="btn btn-primary btn-sm"><i class="far fa-eye"></i></a>
		</template>
	</DataTable>
</template>

<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import {formatDate} from "../../functions";
export default {

	components: {
		TableActionCell,
		DataTable
	},
	props: {
		dataUrl: String,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	data(){
		let columns = [
			{
				text: 'Létrehozva',
				value: 'created_at',
				sortable: true
			},
			{
				text: 'Cím',
				value: 'title',
				sortable: false,
			},
			{
				text: 'Részletek',
				value: 'description',
				sortable: false,
			},
			{
				text: 'Olvasva',
				value: 'read_at',
				sortable: true
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
		}
	},
	methods: {
		formatDate: formatDate,
	}
}
</script>

<style scoped>

</style>