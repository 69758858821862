<template>
	<flash-display :flash="flash" ref="notifications"></flash-display>

	<div :class="{'card': 1,'loading': loading}">
		<div class="card-body">
			<div v-if="storeUrl" class="mb-4">
				<button type="button" class="btn btn-primary btn-label waves-effect" @click.prevent="editingId = null; editing = true"><i class="far fa-plus label-icon"></i> Hozzáadás</button>
			</div>
			<div v-if="editing && editingId === null" class="mb-4">
				<div class="card shadow-none border document">
					<div class="card-body">
						<InputField
							name="name"
							label="Név"
							rules="required"
							v-model="attributes.name" />

						<InputField
							name="url"
							label="Youtube url"
							rules="required"
							prefix-icon="far fa-link"
							v-model="attributes.url" />

						<InputField
							name="priority"
							label="Prioritás"
							rules="required"
							data-type="number"
							:step="1"
							:min="1"
							v-model="attributes.priority" />

						<textarea-field
							label="Leírás"
							name="description"
							v-model="attributes.description"
						/>
						<div class="d-flex gap-2 flex-wrap">
							<button type="button" class="btn btn-primary btn-label waves-effect" @click.prevent="saveItem"><i class="far fa-save label-icon"></i> Mentés</button>
							<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="editing = false"><i class="far fa-times label-icon"></i> Mégse</button>
						</div>
					</div>
				</div>
			</div>
			<template v-for="video in videos">
				<div class="card shadow-none border document">
					<div class="card-body">
						<template v-if="editing && editingId === video.id">
							<InputField
								name="name"
								label="Név"
								rules="required"
								v-model="video.name" />

							<InputField
								name="url"
								label="Youtube url"
								rules="required"
								prefix-icon="far fa-link"
								v-model="video.url" />

							<InputField
								name="priority"
								label="Prioritás"
								rules="required"
								data-type="number"
								:step="1"
								:min="1"
								v-model="video.priority" />

							<textarea-field
								label="Leírás"
								name="description"
								v-model="video.description"
							/>

							<div class="d-flex gap-2 flex-wrap">
								<button type="button" class="btn btn-primary btn-label waves-effect" @click.prevent="saveItem"><i class="far fa-save label-icon"></i> Mentés</button>
								<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="editing = false"><i class="far fa-times label-icon"></i> Mégse</button>
							</div>
						</template>
						<template v-else>
							<div class="mb-4 d-flex align-items-center">
								<h4 class="card-title">{{ video.name }}</h4>
								<div class="d-flex gap-2 ms-auto">
									<button v-if="storeUrl" class="btn btn-sm btn-primary" @click.prevent="editing = true; editingId = video.id"><i class="far fa-edit"></i></button>
									<ConfirmButton
										v-if="deleteUrl"
										:data="video"
										:emit-delete-event="true"
										@confirmed="deleteItem"
									></ConfirmButton>
								</div>
							</div>
							<div class="mb-4" v-if="video.description_html !== ''" v-html="video.description_html"></div>
							<div class="ratio ratio-16x9">
								<iframe class="embed-responsive-item" :src="'https://www.youtube.com/embed/' + video.foreign_id + '?rel=0'" allowfullscreen></iframe>
							</div>
						</template>
					</div>
				</div>
			</template>

			<div class="mt-4">
				<nav>
					<ul class="pagination justify-content-center">
						<template v-for="link in paginationLinks">
							<li :class="{'page-item': 1, 'active': link.active}" v-if="link.url !== null">
								<a class="page-link" href="#" @click.prevent="getData(link.url)" v-html="link.label"></a>
							</li>
							<li class="page-item disabled" v-else>
								<a class="page-link" v-html="link.label"></a>
							</li>
						</template>
					</ul>
				</nav>
			</div>
		</div>
	</div>
</template>

<script>
import {formatDate} from "../../functions";
import InputField from "../../components/form/InputField.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import FlashDisplay from "../../components/FlashDisplay.vue";
import ConfirmButton from "../../components/ConfirmButton.vue";

export default {
	components: {InputField, TextareaField, FlashDisplay, ConfirmButton},
	props: {
		dataUrl: String,
		storeUrl: String,
		deleteUrl: String,
		initialFlash: Array,
	},
	data() {
		let defaultAttributes = {
			priority: 1,
			name: '',
			description: '',
			url: ''
		}
		return {
			flash: [],
			loading: false,
			videos: [],
			paginationLinks: [],
			editing: false,
			editingId: null,
			attributes: defaultAttributes,
			defaultAttributes: defaultAttributes,
			currentUrl: null
		}
	},
	mounted() {
		this.getData()

		this.flash = this.initialFlash

		this.$refs.notifications.update()
	},
	methods: {
		formatDate: formatDate,
		getData: function (url){
			this.loading = true

			if (url === undefined){
				if (this.currentUrl !== null){
					url = this.currentUrl
				} else {
					url = this.dataUrl
				}
			}
			this.currentUrl = url
			axios.get(url).then((response) => {
				this.handleResponse(response)
			}).catch(function (error) {
			})
		},
		handleResponse: function(response){
			this.videos = response.data.data
			this.paginationLinks = response.data.meta.links
			this.loading = false
		},
		saveItem: function(){
			let attributes = null
			if (this.editingId === null){
				if (this.attributes.name === '' || this.attributes.url === ''){
					return;
				}
				attributes = this.attributes
			} else {
				for (let i in this.videos){
					if (this.videos[i].id === this.editingId){
						attributes = this.videos[i]
					}
				}
			}

			this.loading = true
			let url = this.storeUrl
			if (attributes.id !== undefined){
				url += '/' + attributes.id
			}
			axios.post(url, attributes).then((response) => {
				if (response.data.flash !== undefined){
					this.flash = response.data.flash
				}
				this.editing = false
				this.getData()
				this.attributes = this.defaultAttributes
			}).catch(function (error) {
			})
		},
		deleteItem: function(videoId){
			axios.get(this.deleteUrl + '/' + videoId).then((response) => {
				if (response.data.flash !== undefined){
					this.flash = response.data.flash
				}
				this.getData()
			}).catch(function (error) {
			})
		}
	}

}
</script>

<style scoped>

</style>