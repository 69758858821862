<template>
	<div>
		<form-wrapper
			ref="formWrapper"
			:store-url="storeUrl"
			:back-url="backUrl"
			:initial-flash="initialFlash"
			v-model="attributes"
		>
			<template v-slot:top>
				<div class="card mb-3" v-if="attributes.id !== undefined">
					<div class="card-body">
						<div class="details-box">
							<div v-if="attributes.created_at">
								<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(attributes.created_at) }}</strong>
							</div>
							<div v-if="attributes.id !== undefined">
								<i class="far fa-dollar-sign"></i> Érték: <strong>{{ attributes.value }}</strong>
							</div>
							<div v-if="attributes.updated_from_remote_at">
								<i class="far fa-recycle"></i> Érték frissítve: <strong>{{ formatDate(attributes.updated_from_remote_at) }}</strong>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template #default="{activeTab, errors}">
				<div class="card mb-3">
					<div class="card-body">
						<h4 class="card-title mb-4">Adatok</h4>
						<div class="row">
							<div class="col-sm-6">
								<switch-field
									label="Elsődleges"
									name="primary"
									v-model="attributes.primary"
									:disabled="!allowEditing"
								/>

								<input-field
									label="Név"
									name="name"
									rules="required"
									v-model="attributes.name"
									:disabled="!allowEditing"
									:errors="errors"
								/>

								<input-field
									label="Kód"
									name="code"
									rules="required"
									v-model="attributes.code"
									:disabled="!allowEditing"
									:errors="errors"
								/>

								<input-field
									label="Előtag"
									name="prefix"
									rules=""
									v-model="attributes.prefix"
									:disabled="!allowEditing"
									:errors="errors"
									info="A '_' karakter szóközre cserélődik"
								/>

								<input-field
									label="Utótag"
									name="suffix"
									rules=""
									v-model="attributes.suffix"
									:disabled="!allowEditing"
									:errors="errors"
									info="A '_' karakter szóközre cserélődik"
								/>
							</div>
							<div class="col-sm-6">
								<input-field
									label="Érték"
									name="value"
									rules="required"
									v-model="attributes.value"
									data-type="number"
									:step="0.01"
									:disabled="!allowEditing"
									v-if="attributes.id === undefined"
									:errors="errors"
								/>
								<input-field
									label="Érték módosítása"
									name="value_offset"
									rules=""
									v-model="attributes.value_offset"
									data-type="number"
									:step="0.01"
									:disabled="!allowEditing"
									:errors="errors"
								/>
								<input-field
									label="Tizedesjegyek"
									name="decimals"
									data-type="number"
									rules="required"
									v-model="attributes.decimals"
									:disabled="!allowEditing"
									:errors="errors"
								/>
								<input-field
									label="Prioritás"
									name="priority"
									data-type="number"
									v-model="attributes.priority"
									:disabled="!allowEditing"
									:errors="errors"
								/>
								<input-field
									label="Frissítési küszöb"
									name="threshold"
									data-type="number"
									:step="0.1"
									v-model="attributes.threshold"
									:disabled="!allowEditing"
									info="Csak akkor módosítja az értékét, ha a jelenlegi és az importált összeg különbsége nagyobb, mint ez az érték"
									:errors="errors"
								/>
							</div>
						</div>
					</div>
				</div>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import SwitchField from "../../components/form/SwitchField.vue"
import FormWrapper from "../../components/FormWrapper.vue";
import {formatDate} from "../../functions";
export default {
	props: ['initialAttributes', 'storeUrl', 'backUrl', 'initialFlash', 'allowEditing'],
	components: {InputField, SwitchField, FormWrapper},
	data(){
		return {
			attributes: this.initialAttributes,
			loading: false,
			flash: [],
		}
	},
	mounted() {
	},
	methods: {
		formatDate: formatDate,
	}
}
</script>

<style scoped>

</style>