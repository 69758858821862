<template>
	<div class="card-body">
		<h4 class="card-title mb-3">Jogosultságok</h4>
		<div class="card border shadow-none card-body" v-for="(role, roleKey) in componentValue" v-bind:key="'role_' + role.order">
			<div class="d-flex">
				<div class="sortable-form-card--left">
					<button type="button" @click.prevent="move(roleKey, 'up')" class="btn btn-secondary mx-1 mt-2"><i class="far fa-arrow-up"></i></button>
					<button type="button" @click.prevent="move(roleKey, 'down')" class="btn btn-secondary mx-1"><i class="far fa-arrow-down"></i></button>
					<button type="button" @click.prevent="remove(roleKey)" class="btn btn-danger mx-1"><i class="far fa-times"></i></button>
				</div>
				<div class="sortable-form-card--right">
					<input-field
							label="Név"
							:name="'role_name_' + roleKey"
							rules="required"
							v-model="role.name"
							:errors="errors"
					></input-field>
					<h4 class="mb-3">Jogok</h4>
					<table class="table mb-4">
						<thead>
						<tr>
							<th width="20%">Kategória</th>
							<th>Jog</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="(category, categoryKey) in roleRights">
							<th>{{ category.name }}</th>
							<td>
								<div class="d-flex gap-2 flex-wrap">
									<template v-for="(rightName, rightKey) in category.rights">
										<div class="px-2 bg-light">
											<switch-field
												:label="rightName"
												:name="'role_right_' + roleKey + '_' + categoryKey + '_' + rightKey"
												:inline="true"
												v-model="role.rights[categoryKey][rightKey]"
												/>
										</div>
									</template>
								</div>
							</td>
						</tr>
						</tbody>
					</table>

					<h4 class="mb-3">Értestések</h4>

					<div class="d-flex gap-2 flex-wrap">
						<template v-for="(name, key) in roleNotifications">
							<div class="px-2 bg-light">
								<switch-field
									:label="name"
									:name="'role_notification_' + roleKey + '_' + key"
									:inline="true"
									v-model="role.notifications[key]"
								/>
							</div>
						</template>
					</div>
				</div>
			</div>
		</div>

		<div class="mt-3">
			<button type="button" class="btn btn-secondary btn-label" @click.prevent="add">
				<i class="far fa-plus label-icon"></i> Hozzáad
			</button>
		</div>
	</div>
</template>

<script>
import SwitchField from "../../components/form/SwitchField.vue";
import InputField from "../../components/form/InputField.vue";
import TextareaField from "../../components/form/TextareaField.vue";
import SelectField from "../../components/form/SelectField.vue";
import vSelect from "vue-select";
import {moveValueInArray} from "../../functions";
import CopyToClipboard from "../../components/CopyToClipboard.vue";
export default {
	emits: ['update:modelValue', 'validate'],
	components: {
		CopyToClipboard,
		SwitchField,
		InputField,
		SelectField,
		TextareaField,
		vSelect,
	},
	props: {
		modelValue: Array,
		roleRights: Object,
		roleNotifications: Object,
		errors: Object
	},
	computed: {
		componentValue: {
			get() {
				return this.modelValue;
			},
			set(val) {
				this.$emit('update:modelValue', val)
			}
		},
	},
	data(){
		return {
		}
	},
	methods: {
		moveValueInArray,
		add: function(){
			let maxOrder = 0

			this.componentValue.forEach(function(item, index){
				if (item.order > maxOrder){
					maxOrder = item.order
				}
			})
			let rights = {}
			let notifications = {}
			_.forEach(this.roleRights, function(category, categoryKey){
				rights[categoryKey] = {}

				_.forEach(category.rights, function(right, rightKey){
					rights[categoryKey][rightKey] = 0
				})
			})
			_.forEach(this.roleNotifications, function(name, key){
				notifications[key] = 0;
			})

			this.componentValue.push({
				order: maxOrder + 1,
				name: '',
				rights: rights,
				notifications: notifications
			})

			this.$emit('validate')
		},
		move: function(index, direction){
			this.componentValue = this.moveValueInArray(this.componentValue, index, direction)
			let order = 0
			this.componentValue.forEach(function(item, index){
				item.order = order
				order++
			})
		},
		remove: function(index){
			this.componentValue.splice(index, 1);

			this.$emit('validate')
		},
	}
}
</script>

<style scoped>

</style>