<template>
	<div>
		<DataTable
				:data-url="listUrl"
				:initial-flash="initialFlash"
				:columns="columns"
				sort-by="created_at"
				ref="table"
				sort-type="desc"
				:row-class-callback="getRowClass"
				@results-found="$emit('resultsFound')"
				:compact="true"
				name="projectInstallationList"
		>
			<template #item-operations="{item, deleteButtonPressed}">
				<div class="table-row-operations d-flex gap-2">
					<button v-if="item.editable && editable" type="button" class="btn btn-primary btn-sm waves-effect" title="Szerkesztés" @click.prevent="openInstallationDialog(item)">
						<i class="far fa-edit label-icon"></i>
					</button>
					<button v-else type="button" class="btn btn-primary btn-sm waves-effect" title="Részletek" @click.prevent="openInstallationDialog(item)">
						<i class="far fa-eye label-icon"></i>
					</button>
				</div>
			</template>
			<template #item-contract_public_id="item">
				<div class="fw-bold">{{ item.contract_public_id }}</div>
				<div><i class="far fa-clock"></i> {{ formatDate(item.contract_signed_at) }}</div>
			</template>
			<template #item-installation_date="item">
				<template v-if="!item.closed_at">
					<template v-if="!item.installation_date">
						<span class="badge bg-danger badge-xl">Nincs</span>
					</template>
					<template v-else-if="Date(item.installation_date) > Date()">
						<span class="badge bg-warning badge-xl">{{ item.installation_date }}</span>
					</template>
					<template v-else>
						<span class="badge bg-success badge-xl">{{ item.installation_date }}</span>
					</template>
				</template>
				<template v-else>
					<template v-if="!item.installation_date">
						<span class="badge bg-success badge-xl">Telepítve</span>
					</template>
					<template v-else>
						<span class="badge bg-success badge-xl">{{ item.installation_date }}</span>
					</template>
				</template>
			</template>
			<template #item-closed_at="item">
				<template v-if="item.closed_at">
					<span class="badge bg-success badge-xl"><i class="far fa-check me-2"></i> Lezárva: {{ formatDate(item.closed_at, false) }}</span>
				</template>
				<template v-else>
					<span class="badge bg-warning badge-xl"><i class="far fa-question me-2"></i> Függőben</span>
				</template>
			</template>
			<template #item-delivered_products="item">
			<span :class="{
				'badge': 1,
				'badge-xl': 1,
				'bg-success': parseInt(item.contractQuantity) <= parseInt(item.deliveredQuantity),
				'bg-warning': 0 < parseInt(item.deliveredQuantity),
				'bg-danger': 1 > parseInt(item.deliveredQuantity)}">
				{{ item.deliveredQuantity + ' / ' + item.contractQuantity}}
			</span>
			</template>
		</DataTable>
	</div>
	<teleport to="body" v-if="installationDialogId">
		<ProjectInstallationForm
			:contract-id="installationDialogContractId"
			:file-upload-url="fileUploadUrl"
			:installation-id="installationDialogId"
			:editable="editable"
			:products="products"
			:data-url="installationDataUrl"
			:can-start-editing="canStartEditing"
			:user-levels="userLevels"
			:document-types="installationDocumentTypes"
			:lock="lock"
			@close="installationDialogId = false"
			@close-and-reload="closeInstallationFormAndUpdateList"
			@start-editing="$emit('startEditing')"
		/>
	</teleport>
</template>
<script>
import TableActionCell from "../../components/TableActionCell";
import QuoteForm from './QuoteForm.vue'
import {formatDate} from "../../functions";
import Price from "../../components/Price.vue";
import DataTable from "../../components/DataTable.vue";
import QuoteResponseForm from "./QuoteResponseForm.vue";
import TableActionDropdown from "../../components/TableActionDropdown.vue";
import ProjectInstallationForm from "./ProjectInstallationForm.vue";

export default {
	components: {
		ProjectInstallationForm,
		TableActionDropdown,
		QuoteResponseForm,
		Price,
		TableActionCell,
		QuoteForm,
		DataTable,
	},
	emits: ['formFlash', 'openContract', 'resultsFound', 'startEditing'],
	props: {
		taxes: [Array, Object],
		listUrl: String,
		dataUrl: String,
		fileUploadUrl: String,
		invoiceTypeLabels: Object,
		products: Array,
		userLevels: Array,
		installationDocumentTypes: Array,
		activeTabCommand: String,
		installationDataUrl: String,
		activeTabParameter: [String, Number],
		currencies: Object,
		editable: Boolean,
		lock: Object,
		canStartEditing: Boolean,
		hideContract: Boolean,
		canRegenerateProformaInvoice: Boolean,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
	},
	mounted() {
		if (this.activeTabCommand === 'installation' && this.activeTabParameter){
			let tmp = this.activeTabParameter.split('|')
			this.openInstallationDialog({id: tmp[1], contract_id: tmp[0]})
		}
	},
	data(){
		let columns = []
		if (!this.hideContract){
			columns.push({
				text: 'Szerződés',
				value: 'contract_public_id',
				sortable: true,
			})
		}
		columns.push({
			text: 'Állapot',
			value: 'closed_at',
			sortable: true,
		})
		columns.push({
			text: 'Telepítés dátuma',
			value: 'installation_date',
			sortable: true,
		})
		columns.push({
			text: 'Telepítő',
			value: 'installerUserName',
			sortable: true,
		})
		columns.push({
			text: 'Kiszállított termékek',
			value: 'delivered_products'
		})
		columns.push({
			label: '',
			value: 'operations',
			width: 5,
		})

		return {
			columns: columns,
			formOpen: false,
			quoteFormId: null,
			installationDialogId: null,
			installationDialogContractId: null,
			responseFormOpen: false,
			paymentMethods: {
				wire_transfer: {
					label: 'Átutalás',
					icon: 'far fa-credit-card',
					color: 'light'
				},
				cash: {
					label: 'Készpénz',
					icon: 'far fa-money-bill',
					color: 'dark'
				}
			},
		}
	},
	methods: {
		formatDate: formatDate,
		getRowClass: function (item, rowNumber){
			let classes = []
			if (this.activeTabCommand === 'installation' && parseInt(this.activeTabParameter) === item.id){
				classes.push('table-info')
			}
			return classes.join(' ')
		},
		openInstallationDialog: function(item){
			this.installationDialogId = item.id
			this.installationDialogContractId = item.contract_id
		},
		closeInstallationFormAndUpdateList: function(){
			this.installationDialogId = false
			this.installationDialogContractId = false
			this.$refs.table.getData()
		},
	}
}
</script>

<style scoped>
</style>