<template>
	<flash-display :flash="inlineFlashes" :inline="true"></flash-display>

	<DataTable
			:data-url="dataUrl"
			:initial-flash="initialFlash"
			:columns="columns"
			sort-by="signed_at"
			sort-type="asc"
			name="deliveries"
			ref="table"
			:default-filters="defaultFilters"
	>

		<template #filters="{serverOptions}">
			<div class="col-12 col-md-3">
				<v-select
					:options="deliveryFilters"
					v-model="serverOptions.delivery_filter"
					:reduce="item => item.id"
					label="name"
					placeholder="Szállítás"
				/>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed}">
			<div class="table-row-operations d-flex gap-2">
				<a :href="detailsUrl  + '/' + item.id" class="btn btn-primary btn-sm waves-effect" title="Részletek"><i class="far fa-eye"></i></a>
				<div class="dropdown" v-if="item.reservedQuantity > 0">
					<button type="button" class="btn btn-secondary btn-sm waves-effect" data-bs-toggle="dropdown" :id="'dropdownActions_' + item.id" aria-expanded="false" title="Szállítmány létrehozása"><i class="far fa-truck"></i></button>
					<div class="dropdown-menu dropdown-menu-end" :aria-labelledby="'dropdownActions_' + item.id" role="menu">
						<div class="d-flex gap-2 flex-column">
							<template v-for="warehouse in getAvailableWarehouses(item)">
								<a :href="createShipmentUrl  + '/' + item.id + '/' + warehouse.id" class="dropdown-item">{{ warehouse.name }}</a>
							</template>
						</div>
					</div>

				</div>
			</div>
		</template>
		<template #item-clientName="item">
			<div class="fw-bold">{{ item.clientName }}</div>
			<div v-if="item.address" class="text-muted"><i class="far fa-map-marker"></i> {{ item.address }}</div>
			<div class="text-muted"><i class="far fa-hashtag"></i> {{ item.public_id }}</div>
		</template>
		<template #item-reservedQuantity="item">
			<span v-if="!item.reservedQuantity" class="badge badge-xl bg-danger">{{ formatNumber(item.reservedQuantity) }}</span>
			<span v-else-if="item.reservedQuantity < item.requiredQuantity" class="badge badge-xl bg-warning">{{ formatNumber(item.reservedQuantity) }}</span>
			<span v-else class="badge badge-xl bg-success">{{ formatNumber(item.reservedQuantity) }}</span>
		</template>
		<template #item-requiredQuantity="item">
			{{ formatNumber(item.requiredQuantity) }}
		</template>
		<template #item-signed_at="item">
			{{ formatDate(item.signed_at) }}
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import {formatDate, formatNumber} from "../../functions";
import BooleanBadge from "../../components/BooleanBadge.vue";
import vSelect from "vue-select";
import FlashDisplay from "../../components/FlashDisplay.vue";
import ConfirmButton from "../../components/ConfirmButton.vue";

export default {
	components: {
		ConfirmButton,
		FlashDisplay,
		BooleanBadge,
		TableActionCell,
		DataTable,
		vSelect
	},
	props: {
		dataUrl: String,
		storeUrl: String,
		detailsUrl: String,
		createShipmentUrl: String,
		responses: [],
		warehouses: Object,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
		milestones: Array,
	},
	data(){
		let columns = [
			{
				text: 'Ügyfél',
				value: 'clientName',
				sortable: true
			},
			{
				text: 'Igény',
				value: 'requiredQuantity',
				sortable: true,
				align: 'right',
			},
			{
				text: 'Lefoglalva',
				value: 'reservedQuantity',
				align: 'right',
				sortable: true
			},
			{
				text: 'Keletkezés',
				value: 'signed_at',
				sortable: true
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]
		return {
			columns: columns,
			inlineFlashes: [],
			defaultFilters: {
				delivery_filter: 'all'
			},
			deliveryFilters: [
				{
					id: 'all',
					name: 'Összes'
				},
				{
					id: 'deliverable',
					name: 'Szállítható'
				},
			]
		}
	},
	computed: {
	},
	methods: {
		formatDate: formatDate,
		formatNumber: formatNumber,
		getAvailableWarehouses: function(row){
			if (!row.reservedInWarehouses){
				return []
			}
			let payload = []
			let idArray = row.reservedInWarehouses.split(',')
			for (let i in idArray){
				if (this.warehouses[idArray[i]] !== undefined){
					payload.push(this.warehouses[idArray[i]])
				}
			}

			return payload
		}
	}
}
</script>

<style scoped>
</style>

