<template>
	<flash-display :flash="flash" ref="notifications"></flash-display>
	<div :class="{'overlay-form-wrapper': 1}">
		<div :class="{'overlay-form-wrapper--content': 1, 'overlay-form-wrapper--content-small': 1, 'loading': loading}">
			<div class="card" v-if="contract.id !== undefined">
				<div class="card-body">
					<h4 class="card-title mb-3">{{ contract.public_id }} - {{ contract.title_formatted }}</h4>
					<div class="details-box details-box--narrow mb-3">
						<div v-if="contract.created_at">
							<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(contract.created_at) }}</strong>
						</div>
						<div v-if="contract.created_by_user_name">
							<i class="far fa-user"></i> Létrehozó: <strong>{{ contract.created_by_user_name }}</strong>
						</div>
						<div v-if="contract.generated_at">
							<i class="far fa-calendar-alt"></i> Generálva: <strong>{{ formatDate(contract.generated_at, false) }}</strong>
						</div>
						<div>
							<i class="far fa-dollar-sign"></i> Nettó végösszeg: <strong><Price
								:value="contract.total_price_net"
								:currency-id="contract.currency_id"
								:currencies="currencies"
						/></strong>
						</div>
						<div>
							<i class="far fa-dollar-sign"></i> Bruttó végösszeg: <strong><Price
								:value="contract.total_price_gross"
								:currency-id="contract.currency_id"
								:currencies="currencies"
						/></strong>
						</div>
					</div>

					<RadioField
							:values="answerRadioFieldValues"
							label="Ügyfél válasza"
							name="answer"
							v-model="answer.status"
					/>

					<template v-if="!contract.amendment_to_contract_id">
						<SwitchField
							label="Egyedi számlázás"
								name="custom_payment"
								v-model="answer.custom_payment"
								v-if="canEnableCustomBilling"
						/>

						<RadioField
								:values="paymentMethodRadioFieldValues"
								label="Fizetési mód az első díjbekérőhöz"
								name="payment_method"
								v-model="answer.payment_method"
						/>

						<div class="mb-3">
							<label>Fizetési konstrukció</label>

							<input type="text" readonly class="form-control-plaintext" v-model="contract.payment_plan.name" />
						</div>
					</template>
					<template v-else>
						<RadioField
								:values="paymentMethodRadioFieldValues"
								label="Fizetési mód"
								name="payment_method"
								v-model="answer.payment_method"
						/>
					</template>

					<div v-if="answer.status === 'signed'">
						<label class="required">Aláírt szerződés csatolása</label>
						<FileUploader
								field-name="signedPages"
								v-model="answer.signedPages"
								:file-upload-url="fileUploadUrl"
								:editable="true"
								:multiple="true"
						></FileUploader>
					</div>
				</div>
			</div>
			<div class="mt-3">
				<div class="d-flex flex-wrap gap-2">
					<button type="submit" class="btn btn-primary btn-label waves-effect" :disabled="!valid" @click.prevent="saveForm">
						<i class="fas fa-save label-icon"></i> Mentés
					</button>
					<button type="button" class="btn btn-secondary btn-label waves-effect" @click.prevent="emitClose">
						<i class="fas fa-times label-icon"></i> Bezár
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FlashDisplay from "../../components/FlashDisplay.vue";
import {formatDate} from "../../functions";
import Price from "../../components/Price.vue";
import RadioField from "../../components/form/RadioField.vue";
import FileUploader from "../../components/FileUploader.vue";
import vSelect from "vue-select";
import SwitchField from "../../components/form/SwitchField.vue";
export default {
	components: {SwitchField, FileUploader, RadioField, FlashDisplay, Price, vSelect},
	emits: ['close', 'closeAndReload', 'updateStats'],
	props: {
		dataUrl: String,
		fileUploadUrl: String,
		contractId: [Number, String],
		currencies: Object,
		canEnableCustomBilling: Boolean,
	},
	data() {
		return {
			loading: true,
			flash: [],
			contract: {},
			paymentPlans: [],
			answer: {
				status: 'signed',
				payment_method: 'wire_transfer',
				custom_payment: false
			},
			answerRadioFieldValues: {
				signed: {
					label: 'Aláírva',
					icon: 'far fa-check',
					color: 'success'
				},
				rejected: {
					label: 'Elutasít',
					icon: 'far fa-times',
					color: 'danger'
				}
			},
			paymentMethodRadioFieldValues: {
				wire_transfer: {
					label: 'Átutalás',
					icon: 'far fa-credit-card',
					color: 'primary'
				},
				cash: {
					label: 'Készpénz',
					icon: 'far fa-money-bill',
					color: 'primary'
				}
			},
		}
	},
	computed: {
		valid: {
			get() {
				if (this.answer.status === 'rejected'){
					return true
				}
				return this.answer.signedPages !== undefined && this.answer.signedPages.length
			}
		},
		paymentPlansById: {
			get(){
				return _.keyBy(this.paymentPlans, 'id')
			}
		}
	},
	mounted() {
		this.loadData()
	},
	methods: {
		formatDate,
		loadData: function(){
			this.loading = true

			let url = this.dataUrl + '/' + this.contractId
			let that = this
			axios.get(url + '?action=loadAnswerForm').then((response)=>{
				this.handleResponse(response)
			}).catch(function (error) {
				that.loading = false
				that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
			})
		},
		handleResponse: function(response){
			this.loading = false
			if (response.data.status !== undefined && response.data.status === 'success') {
				this.$emit('closeAndReload')
			}
			if (response.data.contract !== undefined) {
				this.contract = response.data.contract
			}
			if (response.data.paymentPlans !== undefined) {
				this.paymentPlans = response.data.paymentPlans
			}
			if (response.data.flash !== undefined){
				this.flash = response.data.flash
			}
			if (response.data.stats !== undefined){
				this.$emit('updateStats', response.data.stats)
			}
		},
		saveForm: function(e){
			if (this.valid){
				this.loading = true
				let that = this
				axios.post(this.dataUrl + '/' + this.contractId + '?action=saveAnswer', this.answer).then((response)=>{
					this.handleResponse(response)
				}).catch(function (error) {
					that.loading = false
					that.$emit('formFlash', [{level: 'danger', message: error.response.data.message}]);
				})
			}
		},
		emitClose: function(){
			this.$emit('close')
		},
	}
}
</script>

<style scoped>

</style>