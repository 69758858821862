import './bootstrap';
import Moment from 'moment';
import Vue, { createApp } from 'vue'
import ClientForm from "./modules/clients/ClientForm.vue";
import ClientList from "./modules/clients/ClientList.vue";
import ClientInviteForm from "./modules/clients/ClientInviteForm.vue";
import CurrencyForm from "./modules/currencies/CurrencyForm.vue";
import CurrencyList from "./modules/currencies/CurrencyList.vue";
import DocumentForm from "./modules/documents/DocumentForm.vue";
import DocumentCategoryForm from "./modules/documents/DocumentCategoryForm.vue";
import DocumentList from "./modules/documents/DocumentList.vue";
import DocumentTemplateForm from "./modules/documentTemplates/DocumentTemplateForm.vue";
import DocumentTemplateList from "./modules/documentTemplates/DocumentTemplateList.vue";
import InquiryForm from "./modules/inquiries/InquiryForm.vue";
import InquiryList from "./modules/inquiries/InquiryList.vue";
import InventoryList from "./modules/inventory/InventoryList.vue";
import InventoryShipment from "./modules/inventory/InventoryShipment.vue";
import InventoryShipmentList from "./modules/inventory/InventoryShipmentList.vue";
import InventoryProductHistory from "./modules/inventory/InventoryProductHistory.vue";
import InventoryDetailedList from "./modules/inventory/InventoryDetailedList.vue";
import PostForm from "./modules/posts/PostForm.vue";
import PostList from "./modules/posts/PostList.vue";
import ProductCategoryForm from "./modules/productCategories/ProductCategoryForm.vue";
import ProductCategoryList from "./modules/productCategories/ProductCategoryList.vue";
import ProductManufacturerForm from "./modules/productManufacturers/ProductManufacturerForm.vue";
import ProductManufacturerList from "./modules/productManufacturers/ProductManufacturerList.vue";
import ProductForm from "./modules/products/ProductForm.vue";
import ProductList from "./modules/products/ProductList.vue";
import ProjectForm from "./modules/projects/ProjectForm.vue";
import ProjectList from "./modules/projects/ProjectList.vue";
import ProjectTypeForm from "./modules/projectTypes/ProjectTypeForm.vue";
import ProjectTypeList from "./modules/projectTypes/ProjectTypeList.vue";
import RoleForm from "./modules/roles/RoleForm.vue";
import RoleList from "./modules/roles/RoleList.vue";
import SupplierForm from "./modules/suppliers/SupplierForm.vue";
import SupplierList from "./modules/suppliers/SupplierList.vue";
import TaxForm from "./modules/taxes/TaxForm.vue";
import TaxList from "./modules/taxes/TaxList.vue";
import UserForm from "./modules/users/UserForm.vue";
import UserList from "./modules/users/UserList.vue";
import VideoList from "./modules/videos/VideoList.vue";
import WarehouseList from "./modules/warehouses/WarehouseList.vue";
import WarehouseForm from "./modules/warehouses/WarehouseForm.vue";

import NotificationList from "./modules/notifications/NotificationList.vue";
import DashboardNotifications from "./modules/dashboard/DashboardNotifications.vue";
import DashboardPosts from "./modules/dashboard/DashboardPosts.vue";
import DashboardTasks from "./modules/dashboard/DashboardTasks.vue";
import Notifications from "./components/Notifications.vue";
import MenuButton from "./components/MenuButton.vue";
import PartnerForm from "./modules/partners/PartnerForm.vue";
import PartnerList from "./modules/partners/PartnerList.vue";
import SettingsForm from "./modules/settings/SettingsForm.vue";
import DeliveryList from "./modules/deliveries/DeliveryList.vue";
import TaskList from "./modules/tasks/TaskList.vue";
import Tasks from "./components/Tasks.vue";
import DeliveryContractList from "./modules/deliveries/DeliveryContractList.vue";
import UserLevelForm from "./modules/userLevels/UserLevelForm.vue";
import UserLevelList from "./modules/userLevels/UserLevelList.vue";
import UserBonusForm from "./modules/userBonuses/UserBonusForm.vue";
import UserBonusList from "./modules/userBonuses/UserBonusList.vue";
import CareerDashboard from "./modules/career/CareerDashboard.vue";
import InstallationList from "./modules/installations/InstallationList.vue";
import InstallationForm from "./modules/installations/InstallationForm.vue";

window.moment = Moment

const app = createApp()
app.component('ClientForm', ClientForm)
app.component('ClientList', ClientList)
app.component('ClientInviteForm', ClientInviteForm)
app.component('CurrencyForm', CurrencyForm)
app.component('CurrencyList', CurrencyList)
app.component('DeliveryList', DeliveryList)
app.component('DeliveryContractList', DeliveryContractList)
app.component('DocumentForm', DocumentForm)
app.component('DocumentCategoryForm', DocumentCategoryForm)
app.component('DocumentList', DocumentList)
app.component('DocumentTemplateForm', DocumentTemplateForm)
app.component('DocumentTemplateList', DocumentTemplateList)
app.component('InquiryForm', InquiryForm)
app.component('InquiryList', InquiryList)
app.component('InstallationForm', InstallationForm)
app.component('InstallationList', InstallationList)
app.component('InventoryList', InventoryList)
app.component('InventoryShipment', InventoryShipment)
app.component('InventoryShipmentList', InventoryShipmentList)
app.component('InventoryProductHistory', InventoryProductHistory)
app.component('InventoryDetailedList', InventoryDetailedList)
app.component('PostForm', PostForm)
app.component('PostList', PostList)
app.component('PartnerForm', PartnerForm)
app.component('PartnerList', PartnerList)
app.component('ProductCategoryForm', ProductCategoryForm)
app.component('ProductCategoryList', ProductCategoryList)
app.component('ProductManufacturerForm', ProductManufacturerForm)
app.component('ProductManufacturerList', ProductManufacturerList)
app.component('ProductForm', ProductForm)
app.component('ProductList', ProductList)
app.component('ProjectForm', ProjectForm)
app.component('ProjectList', ProjectList)
app.component('ProjectTypeForm', ProjectTypeForm)
app.component('ProjectTypeList', ProjectTypeList)
app.component('RoleForm', RoleForm)
app.component('RoleList', RoleList)
app.component('SettingsForm', SettingsForm)
app.component('SupplierForm', SupplierForm)
app.component('SupplierList', SupplierList)
app.component('TaxForm', TaxForm)
app.component('TaxList', TaxList)
app.component('UserBonusForm', UserBonusForm)
app.component('UserBonusList', UserBonusList)
app.component('UserLevelForm', UserLevelForm)
app.component('UserLevelList', UserLevelList)
app.component('UserForm', UserForm)
app.component('UserList', UserList)
app.component('VideoList', VideoList)
app.component('WarehouseList', WarehouseList)
app.component('WarehouseForm', WarehouseForm)
app.component('NotificationList', NotificationList)
app.component('TaskList', TaskList)
app.component('DashboardNotifications', DashboardNotifications)
app.component('DashboardPosts', DashboardPosts)
app.component('DashboardTasks', DashboardTasks)
app.component('CareerDashboard', CareerDashboard)

app.component('Notifications', Notifications)
app.component('Tasks', Tasks)
app.component('MenuButton', MenuButton)
app.mount('#vue')