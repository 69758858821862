<template>
	<div>
		<form-wrapper
			ref="formWrapper"
			:store-url="storeUrl"
			:back-url="backUrl"
			:tabs="tabs"
			:display-tabs="!!attributes.id"
			:initial-flash="initialFlash"
			v-model="attributes"
			:hidden-attributes="['roles']"
			:show-back-button="!profileForm"
		>

			<template v-slot:top>
				<div class="card mb-3" v-if="attributes.id !== undefined">
					<div class="card-body">
						<div>
							<div class="details-box">
								<div v-if="attributes.created_by_user_name">
									<i class="far fa-user"></i> Létrehozó: <strong>{{ attributes.created_by_user_name }}</strong>
								</div>
								<div>
									<i class="far fa-calendar-alt"></i> Létrehozva: <strong>{{ formatDate(attributes.created_at)  }}</strong>
								</div>
								<div>
									<i class="far fa-calendar-alt"></i> Utolsó belépés: <strong>{{ attributes.last_login ? formatDate(attributes.last_login) : 'Még nem lépett be' }}</strong>
								</div>
								<div>
									<i class="far fa-user"></i> Ajánlói link magánszemély: <a :href="consumerRefererLink" target="_blank">[link]</a> <CopyToClipboard :value="consumerRefererLink" />
								</div>
								<div>
									<i class="far fa-industry"></i> Ajánlói link céges: <a :href="companyRefererLink" target="_blank">[link]</a> <CopyToClipboard :value="companyRefererLink" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template #default="{activeTab, errors, editable}">
				<div v-if="activeTab === 'form'">
					<div class="card">
						<div class="card-body">
							<h4 class="card-title mb-4">Adatok</h4>
							<div class="row">
								<div class="col-sm-6">
									<switch-field
										label="Aktív"
										name="active"
										v-model="attributes.active"
										:disabled="!allowEditing"
										v-if="!profileForm"
									/>

									<select-field
										label="Típus"
										name="type"
										v-model="attributes.type"
										:options="typeLabels"
										:errors="errors"
										rules="required"
										:display-badge="true"
										data-type="string"
										v-if="allowEditing && !profileForm"
									>
									</select-field>

									<input-field
										label="Vezetéknév"
										name="last_name"
										rules="required"
										v-model="attributes.last_name"
										:disabled="!allowEditing"
										:errors="errors"
									/>

									<input-field
										label="Keresztnév"
										name="first_name"
										rules="required"
										v-model="attributes.first_name"
										:disabled="!allowEditing"
										:errors="errors"
									/>

									<select-field
										label="Szerepek"
										name="roleIdArray"
										rules="required"
										v-model="attributes.roleIdArray"
										:disabled="!allowEditing"
										:options="roles"
										:multiple="true"
										:errors="errors"
										v-if="!profileForm"
									/>

									<radio-field
										label="E-mail értesítés"
										name="notification_email_level"
										rules="required"
										v-model="attributes.notification_email_level"
										:disabled="!allowEditing"
										:errors="errors"
										:values="{all: {label: 'Összes'},important: {label: 'Fontos'}}"
									/>

									<switch-field
											label="Telepítő"
											name="installer"
											v-model="attributes.installer"
											:disabled="!allowEditing"
											v-if="!profileForm"
									/>

									<warehouse-select-field
											label="Raktár"
											name="warehouse_id"
											rules="required"
											v-model="attributes.warehouse_id"
											:disabled="!allowEditing"
											:errors="errors"
											v-if="!profileForm && attributes.installer"
									/>
								</div>
								<div class="col-sm-6">
									<input-field
										label="E-mail cím"
										name="email"
										data-type="email"
										rules="required"
										v-model="attributes.email"
										:disabled="!allowEditing"
										:errors="errors"
									/>

									<input-field
										label="Telefonszám"
										name="phone"
										rules="required"
										data-type="phone"
										v-model="attributes.phone"
										:disabled="!allowEditing"
										:errors="errors"
									/>

									<input-field
										label="Cégnév"
										name="company_name"
										v-model="attributes.company_name"
										rules="required"
										:disabled="!allowEditing"
										:errors="errors"
										v-if="['company'].indexOf(attributes.type) > -1"
									/>

									<input-field
										label="Adószám"
										name="tax_number"
										v-model="attributes.tax_number"
										rules="required"
										:disabled="!allowEditing"
										:errors="errors"
										v-if="['independentContractor', 'company'].indexOf(attributes.type) > -1"
									/>

									<input-field
										label="Cégjegyzékszám"
										name="company_id"
										v-model="attributes.company_id"
										rules="required"
										:disabled="!allowEditing"
										:errors="errors"
										v-if="['company'].indexOf(attributes.type) > -1"
									/>

									<input-field
										label="Bankszámlaszám"
										name="bank_account_id"
										v-model="attributes.bank_account_id"
										:disabled="!allowEditing"
										:errors="errors"
										v-if="['independentContractor', 'company'].indexOf(attributes.type) > -1"
									/>

									<template v-if="attributes.id !== undefined">
										<input-field
											label="Új jelszó"
											name="password"
											data-type="password"
											rules="min:8"
											v-model="attributes.password"
											:disabled="!allowEditing"
											info="Csak akkor töltsd ki, ha új jelszót akarsz beállítani"
											:errors="errors"
										/>

										<input-field
											label="Új jelszó újra"
											name="password_confirmation"
											data-type="password"
											:equal-to="attributes.password"
											v-model="attributes.password_confirmation"
											:disabled="!allowEditing"
											info="Csak akkor töltsd ki, ha új jelszót akarsz beállítani"
											:errors="errors"
										/>
									</template>
									<template v-else>
										<input-field
											label="Jelszó"
											name="password"
											data-type="password"
											rules="min:8|required"
											v-model="attributes.password"
											:disabled="!allowEditing"
											:errors="errors"
										/>

										<input-field
											label="Jelszó újra"
											name="password_confirmation"
											data-type="password"
											rules="required"
											:equal-to="attributes.password"
											v-model="attributes.password_confirmation"
											:disabled="!allowEditing"
											:errors="errors"
										/>
									</template>
								</div>
							</div>
						</div>
					</div>
					<div class="card" v-if="['independentContractor', 'company'].indexOf(attributes.type) > -1">
						<div class="card-body">
							<h4 class="card-title mb-4">Cím</h4>
							<address-input
								v-model="attributes.address"
								:cities="cities"
								attribute-name="address"
								v-if="allowEditing"
								:required="true"
								:errors="errors"
								/>
							<div v-else-if="attributes.address.id">
								{{ attributes.address.formatted_value }}
							</div>
							<div v-else>
								Nincs megadva
							</div>
						</div>
					</div>
					<div class="card" v-if="userLevelsPermission || (profileForm && attributes.user_levels_enabled)">
						<div class="card-body">
							<h4 class="card-title mb-4">Karrier</h4>
							<div class="row">
								<div class="col-sm-6">
									<template v-if="profileForm">
										<div class="mb-3">
											<h4>Felhasználói szint</h4>
											<user-level-badge :user-level-id="attributes.user_level_id" :user-levels="userLevels"></user-level-badge>
										</div>
									</template>
									<template v-if="userLevelsPermission && !profileForm">
										<user-select-field
												label="Felettes"
												name="superior_user_id"
												v-model="attributes.superior_user_id"
												:disabled="!allowEditing"
												:user-levels="userLevels"
												:excluded-id="attributes.id"
												:errors="errors"
										/>
										<switch-field
												label="Felhasználói szintek engedélyezése"
												name="user_levels_enabled"
												v-model="attributes.user_levels_enabled"
												:disabled="!allowEditing"
												v-if="displayUserLevelsEnableCheckbox"
										/>
										<template v-if="attributes.user_levels_enabled">
											<switch-field
													label="Fix felhasználói szint"
													name="fixed_user_level"
													v-model="attributes.fixed_user_level"
													:disabled="!allowEditing"
											/>
											<template v-if="!attributes.fixed_user_level">
												<div v-if="attributes.user_level_id" class="mb-3">
													<h4>Felhasználói szint</h4>
													<user-level-badge :user-level-id="attributes.user_level_id" :user-levels="userLevels"></user-level-badge>
												</div>
											</template>
											<select-field
													label="Felhasználói szint"
													label-field="name"
													name="user_level_id"
													rules=""
													v-model="attributes.user_level_id"
													:disabled="!allowEditing"
													:options="userLevels"
													:errors="errors"
													v-else
											/>
										</template>
									</template>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="activeTab === 'commissions'">
					<div class="card">
						<div class="card-body">
							<UserCommissionList
								:data-url="commissionListDataUrl"
								:currencies="currencies"
								:user-bonuses="userBonuses"
								/>
						</div>
					</div>
				</div>
				<div v-if="activeTab === 'sales'">
					<div class="card">
						<div class="card-body">
							<UserSaleList
								:data-url="salesListDataUrl"
								:store-url="storeUrl"
								:currencies="currencies"
								@flash="formFlash"
							/>
						</div>
					</div>
				</div>
				<div v-if="activeTab === 'levelLog'">
					<div class="card">
						<div class="card-body">
							<UserLevelLogList
								:data-url="userLevelListDataUrl"
								:store-url="storeUrl"
								:currencies="currencies"
								:user-levels="userLevels"
								:user-levels-permission="userLevelsPermission"
								@flash="formFlash"
							/>
						</div>
					</div>
				</div>
			</template>
		</form-wrapper>
	</div>
</template>

<script>
import InputField from "../../components/form/InputField.vue"
import SwitchField from "../../components/form/SwitchField.vue"
import SelectField from "../../components/form/SelectField.vue"
import RadioField from "../../components/form/RadioField.vue";
import {formatDate} from "../../functions";
import FormWrapper from "../../components/FormWrapper.vue";
import CopyToClipboard from "../../components/CopyToClipboard.vue";
import UserLevelBadge from "../../components/UserLevelBadge.vue";
import UserSelectField from "../../components/form/UserSelectField.vue";
import UserCommissionList from "./UserCommissionList.vue";
import UserSaleList from "./UserSaleList.vue";
import UserLevelLogList from "./UserLevelLogList.vue";
import AddressInput from "../../components/AddressInput.vue";
import WarehouseSelectField from "../../components/form/WarehouseSelectField.vue";

export default {
	components: {
		WarehouseSelectField,
		AddressInput, UserLevelLogList, UserSaleList, UserCommissionList, UserSelectField, UserLevelBadge, RadioField, InputField, SwitchField, SelectField, FormWrapper, CopyToClipboard},
	props: {
		initialAttributes: Object,
		storeUrl: String,
		backUrl: String,
		userProfileUrl: String,
		commissionListDataUrl: String,
		salesListDataUrl: String,
		userLevelListDataUrl: String,
		pendingReward: Object,
		roles: Array,
		initialFlash: Array,
		userLevels: Array,
		superiors: Array,
		currencies: Array,
		cities: Array,
		typeLabels: Object,
		allowEditing: Boolean,
		userLevelsPermission: Boolean,
		consumerRefererLink: String,
		companyRefererLink: String,
		profileForm: Boolean,
		isSuperadmin: Boolean,
	},
	computed: {
	},
	data(){
		let attributes = this.initialAttributes
		attributes.roleIdArray = []
		_.forEach(attributes.roles, function(value){
			attributes.roleIdArray.push(value.id)
		})

		if (attributes.address === null){
			attributes.address = {}
		}

		let tabs = [{
				label: 'Adatlap',
				icon: 'far fa-file-spreadsheet',
				name: 'form'
			}
		]

		if (attributes.user_levels_enabled) {
			tabs.push({
				label: 'Jutalékok',
				icon: 'far fa-file-invoice-dollar',
				name: 'commissions',
			})
		}
		tabs.push({
			label: 'Eladások',
			icon: 'far fa-file-contract',
			name: 'sales',
		})
		if (attributes.user_levels_enabled){
			tabs.push({
				label: 'Szintek',
				icon: 'far fa-level-up',
				name: 'levelLog',
			})
		}

		return {
			attributes: attributes,
			loading: false,
			flash: [],
			displayUserLevelsEnableCheckbox: !attributes.user_levels_enabled,
			tabs: tabs
		}
	},
	created: function () {
		this.moment = window.moment;
	},
	mounted() {
	},
	methods: {
		formatDate:formatDate,
		formFlash: function(flash){
			this.$refs.formWrapper.addFlash(flash)
		}
	}
}
</script>

<style scoped>

</style>