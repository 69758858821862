<template>
	<div class="mb-3" v-if="canUpload">
		<div class="file-upload-wrapper">
			Húzz ide fileokat a feltöltéshez, vagy kattints a gombra!<br /><br />

			<VueUploadComponent
					class="btn btn-primary"
					:post-action="fileUploadUrl"
					:extensions="documentType.file_types"
					:multiple="true"
					:size="1024*1024*64"
					:thread="1"
					:headers="uploadHeaders"
					:drop="true"
					:accept="documentType.file_types_formatted"
					v-model="uploadingFiles"
					@input-filter="uploadInputFilter"
					@input-file="uploadInputFile"
					ref="upload">
				<i class="fa fa-upload"></i>
				Feltöltés
			</VueUploadComponent>
		</div>
	</div>
	<div class="alert alert-info" v-if="!getDocumentTypeCount(documentType) && !uploadingFiles.length">Nincs feltöltve dokumentum.</div>
	<table class="table table-collapse-mobile" v-if="typeof documentType === 'object' && (getDocumentTypeCount(documentType) || uploadingFiles.length)">
		<thead>
		<tr>
			<th width="1%">Előnézet</th>
			<th>Adatok</th>
			<th>Leírás</th>
		</tr>
		</thead>
		<tbody>
		<template v-for="(file, index) in uploadingFiles">
			<tr v-if="file.documentTypeId === documentType.id" :key="file.id">
				<td>
					<div class="d-flex justify-content-center align-items-center w-100">
						<img class="td-image-thumb img-responsive" v-if="file.thumb" :src="file.thumb" @click="lightboxImage = file.thumb" />
						<div class="file-icon" v-else>
							<FileIcon :filename="file.name"></FileIcon>
						</div>
					</div>
					<div class="text-center mt-2" v-if="canUpload">
						<a href="#" class="text-danger" @click.prevent="uploadingFiles.splice(index, 1)"><i class="far fa-times"></i> töröl</a>
					</div>
				</td>
				<td class="column-on-mobile">
					<div class="filename fw-bold">
						{{file.name}}
					</div>
					<div class="progress mt-1 mb-1" v-if="file.active || file.progress !== '0.00'">
						<div :class="{'progress-bar': true, 'bg-danger': file.error, 'progress-bar-animated': file.active}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
					</div>
					<div class="d-flex gap-2">
						<div class="file-size"><i class="far fa-file"></i> {{ formatFileSize(file.size)}}</div>
						<div v-if="file.width" class="file-resolution"><i class="far fa-image"></i> {{file.width}} x {{file.height}} px</div>
					</div>
				</td>
				<td>&nbsp;</td>
			</tr>
		</template>
		<tr v-for="(file, index) in uploadedFilesObj" :key="file.id">
			<td>
				<div class="d-flex justify-content-center align-items-center w-100">
					<img class="td-image-thumb img-responsive" v-if="file.thumb" :src="file.thumb" @click="lightboxImage = file.thumb" />
					<div class="file-icon" v-else>
						<FileIcon :filename="file.name"></FileIcon>
					</div>
				</div>
				<div class="text-center mt-2" v-if="canUpload">
					<a href="#" class="text-danger" @click.prevent="uploadedFilesObj.splice(index, 1)"><i class="far fa-times"></i> töröl</a>
				</div>
			</td>
			<td class="column-on-mobile">
				<div class="filename fw-bold">
					{{file.name}}
				</div>
				<div class="progress mt-1 mb-1" v-if="file.active || file.progress !== '0.00'">
					<div :class="{'progress-bar': true, 'bg-success': 1}" role="progressbar" :style="{width: file.progress + '%'}">{{file.progress}}%</div>
				</div>
				<div class="d-flex gap-2">
					<div class="file-size"><i class="far fa-file"></i> {{ formatFileSize(file.size)}}</div>
					<div v-if="file.width" class="file-resolution"><i class="far fa-image"></i> {{file.width}} x {{file.height}} px</div>
				</div>
			</td>
			<td>
				<TextareaAutosize
						v-model="file.description"
						:classes="{'form-control': 1}"
						:rows="1"
				></TextareaAutosize>
			</td>
		</tr>
		<tr v-for="(file, index) in existingFilesObj" :key="file.id">
			<td>
				<div class="d-flex justify-content-center align-items-center w-100">
					<img class="td-image-thumb img-responsive" v-if="file.thumbnail" :src="file.thumbnail" @click="lightboxImage = file.download_url" />
					<div class="file-icon" v-else>
						<FileIcon :filename="file.extension"></FileIcon>
					</div>
				</div>
				<div class="text-center mt-2" v-if="canDelete">
					<a href="#" class="text-danger" @click.prevent="existingFilesObj.splice(index, 1)"><i class="far fa-times"></i> töröl</a>
				</div>
			</td>
			<td class="column-on-mobile">
				<div class="filename fw-bold">
					<a :href="file.download_url" target="_blank" :title="file.name + '.' + file.extension"><i class="far fa-download"></i> {{file.name}}</a>
				</div>
				<div class="d-flex gap-2">
					<div class="file-size"><i class="far fa-file"></i> {{ formatFileSize(file.size) }}</div>
					<div v-if="file.width" class="file-resolution"><i class="far fa-image"></i> {{file.width}} x {{file.height}} px</div>
				</div>
				<div><i class="far fa-calendar-alt"></i> {{formatDate(file.created_at)}}</div>
				<div><i class="far fa-user"></i> {{file.created_by_user_name}}</div>
			</td>
			<td>
				<div v-if="!canUpdate" v-html="file.description_html"></div>
				<TextareaAutosize
						v-model="file.description"
						:classes="{'form-control': 1}"
						:rows="1"
						v-else
				></TextareaAutosize>
			</td>
		</tr>
		</tbody>
	</table>
	<table class="table table-collapse-mobile" v-if="typeof documentType !== 'object' && getDocumentTypeCount(documentType)">
		<thead>
		<tr>
			<th width="1%">Előnézet</th>
			<th>Adatok</th>
		</tr>
		</thead>
		<tbody>
		<tr v-for="(file, index) in getSystemDocuments(documentType)" :key="file.id">
			<td>
				<div class="d-flex justify-content-center align-items-center w-100">
					<img class="td-image-thumb img-responsive" v-if="file.thumbnail" :src="file.thumbnail" @click="lightboxImage = file.download_url" />
					<div class="file-icon" v-else>
						<FileIcon :filename="file.extension"></FileIcon>
					</div>
				</div>
			</td>
			<td class="column-on-mobile">
				<div class="filename fw-bold">
					<a :href="file.download_url" target="_blank" :title="file.name + '.' + file.extension"><i class="far fa-download"></i> {{file.name}}</a>
				</div>
				<div class="d-flex gap-2">
					<div class="file-size"><i class="far fa-file"></i> {{ formatFileSize(file.size) }}</div>
					<div v-if="file.width" class="file-resolution"><i class="far fa-image"></i> {{file.width}} x {{file.height}} px</div>
				</div>
				<div><i class="far fa-calendar-alt"></i> {{formatDate(file.created_at)}}</div>
				<div><i class="far fa-user"></i> {{file.created_by_user_name}}</div>
			</td>
		</tr>
		</tbody>
	</table>
	<teleport to="body" v-if="lightboxImage">
		<div class="lightbox" @click.prevent="lightboxImage = ''">
			<div class="lightbox-image">
				<img :src="lightboxImage" class="img-responsive" />
			</div>
			<button class="btn btn-secondary btn-label" @click.prevent="lightboxImage = ''">
				<i class="far fa-times label-icon"></i> Bezár
			</button>
		</div>
	</teleport>
</template>

<script>
import FileIcon from "../../components/FileIcon.vue";
import VueUploadComponent from "vue-upload-component";
import TextareaAutosize from "../../components/TextareaAutosize.vue";
import {formatFileSize, formatDate} from "../../functions";

export default {
	emits: ['update:existingFiles', 'update:uploadedFiles', 'valueChanged'],
	components: {VueUploadComponent, FileIcon, TextareaAutosize},
	props: {
		existingFiles: Array,
		canUpload: Boolean,
		canUpdate: Boolean,
		canDelete: Boolean,
		systemDocuments: {
			type: Object,
			default: function(){
				return {}
			}
		},
		fileUploadUrl: String,
		documentType: Object,
		uploadedFiles: Object,
	},
	computed: {
		existingFilesObj: {
			get() {
				return this.existingFiles
			},
			set(value){
				this.$emit('update:existingFiles', value)
			}
		},
		uploadedFilesObj: {
			get() {
				return this.uploadedFiles
			},
			set(value){
				this.$emit('update:uploadedFiles', value)
			}
		},
	},
	data(){
		return {
			lightboxImage: '',
			uploadHeaders: {'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')},
			uploadingFiles: [],
			uploadCounter: 0
		}
	},
	methods: {
		formatDate,
		formatFileSize,
		uploadInputFilter: function(newFile, oldFile, prevent){
			if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
				newFile.blob = ''
				let URL = (window.URL || window.webkitURL)
				if (URL) {
					newFile.blob = URL.createObjectURL(newFile.file)
				}
				newFile.thumb = ''
				if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
					newFile.thumb = newFile.blob
				}
			}

			if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
				newFile.error = 'image parsing'
				let img = new Image();
				img.onload = () => {
					this.$refs.upload.update(newFile, {error: '', height: img.height, width: img.width})
				}
				img.onerror = (e) => {
					this.$refs.upload.update(newFile, { error: 'parsing image size'})
				}
				img.src = newFile.blob
			}
		},
		uploadInputFile: function(newFile, oldFile, prevent){
			if (newFile !== undefined && newFile.success){
				this.$refs.upload.remove(newFile)
				newFile.fileId = parseInt(newFile.response.file.id)
				delete newFile.response
				delete newFile.headers
				try {
					this.uploadedFilesObj.push(newFile)
				} catch (e){
				}

				this.$emit('valueChanged')
			} else {
				if (newFile !== null && (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error)) {
					if (!this.$refs.upload.active) {
						newFile.documentTypeId = this.documentType.id
						this.$refs.upload.active = true
					}
				}
			}
		},
		getDocumentTypeCount: function(type){
			if (type === null){
				return 0
			}
			if (typeof type === 'object') {
				return this.existingFilesObj.length + Object.keys(this.uploadedFilesObj).length
			}

			return this.getSystemDocuments(type).length
		},
		getSystemDocuments: function(type){
			if (this.systemDocuments[type] !== undefined){
				return this.systemDocuments[type]
			}

			return []
		},
		clearUploaded: function(){
			this.uploadingFiles = []

		}
	}
}
</script>

<style scoped>

</style>