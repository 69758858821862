<template>
	<div data-simplebar style="height: 400px;" id="taskList">
		<a :href="'/tasks/redirect/' + task.id" class="text-reset task-item" v-for="task in tasks">
			<div class="d-flex">
				<div class="avatar-xs me-3">
					<span :class="'avatar-title bg-' + task.color + ' rounded-circle font-size-16'">
						<i :class="task.icon"></i>
					</span>
				</div>
				<div class="flex-grow-1">
					<h6 class="mt-0 mb-1">{{ task.title }}</h6>
					<div class="font-size-12 text-muted">
						<p class="mb-1">{{ task.description }}</p>
						<p class="mb-0">
							<i class="far fa-clock"></i> <RelativeTime v-model="task.created_at" />
						</p>
					</div>
				</div>
			</div>
		</a>
		<div class="alert alert-primary" v-if="!tasks.length">Nincs feladatod</div>
	</div>
	<div class="pt-3 border-top d-grid">
		<a class="btn btn-sm btn-link font-size-14 text-center" href="/tasks">
			<i class="far fa-angle-right me-1"></i> <span key="t-view-more">Összes megtekintése</span>
		</a>
	</div>
</template>

<script>
import SimpleBar from "simplebar"
import RelativeTime from "../../components/RelativeTime.vue";

export default {
	components: {RelativeTime},
	data(){
		return {
			tasks: [],
			taskCount: 0,
			simpleBarInstance: null
		}
	},
	mounted() {
		this.simpleBarInstance = new SimpleBar(document.getElementById('taskList'));

		this.update()
		this.setNextUpdate()
	},
	methods: {
		setNextUpdate: function() {
			let that = this
			setTimeout(function(){
				that.update()
				that.setNextUpdate()
			}, 60000)
		},
		update: function(){
			axios.get('/tasks/get').then((response) => {
				this.tasks = response.data.tasks

				this.simpleBarInstance.recalculate();
			}).catch(function (error) {
			})
		},
	}
}
</script>

<style scoped>

</style>