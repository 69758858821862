<template>
	<DataTable
		:data-url="dataUrl"
		:initial-flash="initialFlash"
		:columns="columns"
		sort-by="public_id"
		sort-type="desc"
		name="inquiries"
		:default-filters="defaultFilters"
	>

		<template #filters="{serverOptions}">
			<div class="col-12 col-md-3">
				<v-select
					:options="statusLabelsExtended"
					v-model="serverOptions.status"
					:reduce="item => item.value"
					placeholder="Állapot"
				>
					<template v-slot:option="option">
						<span :class="'badge bg-' + option.color"><i :class="option.icon"></i> {{ option.name }}</span>
					</template>
					<template v-slot:selected-option="option">
						<span :class="'badge bg-' + option.color"><i :class="option.icon"></i> {{ option.name }}</span>
					</template>
				</v-select>
			</div>
			<div class="col-12 col-md-3">
				<v-select
					:options="projectTypesArray"
					v-model="serverOptions.project_type_id"
					:reduce="item => item.id"
					placeholder="Típus"
				>
					<template v-slot:option="option">
						<span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
					</template>
					<template v-slot:selected-option="option">
						<span :class="'project-type-badge project-type-badge-' + option.luminosity" :style="'background:' + option.color + ';'"><i :class="'far fa-' + option.icon"></i><span>{{ option.name }}</span></span>
					</template>
				</v-select>
			</div>
		</template>

		<template #item-operations="{item, deleteButtonPressed}">
			<TableActionCell
				:data="item"
				:edit-url="editUrl"
				:delete-url="deleteUrl"
				:is-editable="isEditable"
				:is-deletable="isDeletable"
				:emit-delete-event="true"
				@deleteButtonPressed="deleteButtonPressed"
			></TableActionCell>
		</template>
		<template #item-status="item">
			<span :class="'badge badge-xl bg-' + statusLabels[item.status].color">
				<i :class="statusLabels[item.status].icon"></i> {{ statusLabels[item.status].name }}
			</span>
		</template>
		<template #item-is_company="item">
			<BooleanBadge
				:data="item"
				name="is_company"
				:key="'is_company_' + item.id"
			></BooleanBadge>
		</template>
		<template #item-project_type_id="item">
			<span v-if="projectTypes[item.project_type_id] !== undefined" :class="'project-type-badge project-type-badge-' + projectTypes[item.project_type_id].luminosity" :style="'background:' + projectTypes[item.project_type_id].color + ';'"><i :class="'far fa-' + projectTypes[item.project_type_id].icon"></i></span>
		</template>
		<template #item-created_at="item">
			{{ formatDate(item.created_at) }}
		</template>
	</DataTable>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import TableActionCell from "../../components/TableActionCell";
import {formatDate} from "../../functions";
import BooleanBadge from "../../components/BooleanBadge.vue";
import vSelect from "vue-select";

export default {
	components: {
		BooleanBadge,
		TableActionCell,
		DataTable,
		vSelect
	},
	props: {
		dataUrl: String,
		editUrl: String,
		deleteUrl: String,
		editAll: Boolean,
		deleteAll: Boolean,
		userId: Number,
		statusLabels: Object,
		projectTypes: Object,
		initialFlash: {
			type: Array,
			default: function(){ return [] }
		},
		milestones: Array,
	},
	data(){
		let columns = [
			{
				text: 'Azonosító',
				value: 'public_id',
				sortable: true
			},
			{
				text: 'Állapot',
				value: 'status',
				sortable: true
			},
			{
				text: 'Típus',
				value: 'project_type_id',
				sortable: true
			},
			{
				text: 'Cég',
				value: 'is_company',
				sortable: true,
			},
			{
				text: 'Név',
				value: 'name',
				sortable: true
			},
			{
				text: 'E-mail cím',
				value: 'email',
				sortable: true
			},
			{
				text: 'Telefonszám',
				value: 'phone',
				sortable: true
			},
			{
				text: 'Ajánló',
				value: 'handlerUserName',
				sortable: true
			},
			{
				text: 'Létrehozva',
				value: 'created_at',
				sortable: true,
			},
			{
				text: '',
				value: 'operations',
				width: 5,
			}
		]

		return {
			columns: columns,
			defaultFilters: {
				status: 'pending'
			}
		}
	},
	computed: {
		statusLabelsExtended: {
			get(){
				let payload = []
				for (let i in this.statusLabels){
					payload.push({
						value: i,
						name: this.statusLabels[i].name,
						icon: this.statusLabels[i].icon,
						color: this.statusLabels[i].color,
					})
				}

				return payload
			}
		},
		projectTypesArray: {
			get(){
				return _.values(this.projectTypes)
			}
		}
	},
	methods: {
		formatDate: formatDate,
		isEditable: function(row){
			if (this.editAll){
				return true
			}
			return row.handler_user_id === this.userId
		},
		isDeletable: function(row){
			if (this.deleteAll){
				return true
			}
			if (row.assigned){
				return false
			}
			return row.handler_user_id === this.userId
		},
	}
}
</script>

<style scoped>
</style>

