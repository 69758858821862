<template>
	<div class="placeholder-glow" v-if="loading" >
		<div class="placeholder text-nowrap">
			{{ formattedValue }}
		</div>
	</div>
	<div class="text-nowrap" v-else>{{ formattedValue }}</div>
</template>

<script>
export default {
	props: {
		currencies: Object,
		currencyId: Number,
		currencyValues: {
			type: Object,
			default: function(){
				return{}
			}
		},
		convertFrom: {
			type: Number,
			default: null
		},
		decimals: {
			type: Number,
			default: null
		},
		value: [Number, String],
		loading: Boolean
	},
	data(){
		return {
			previousValue: 0
		}
	},
	watch: {
		loading(newLoading, oldLoading) {
			if (newLoading && !oldLoading){
				this.previousValue = this.value
			}
		}
	},
	computed: {
		convertedValue: {
			get(){
				let baseValue = this.loading ? this.previousValue : this.value
				if (!this.convertFrom){
					return parseFloat(baseValue)
				}
				let multiplier = 1
				let value = parseFloat(baseValue)
				if (this.currencyValues[this.convertFrom]){
					multiplier = this.currencyValues[this.convertFrom]
				} else if (this.currencies[this.convertFrom]){
					multiplier = this.currencies[this.convertFrom].calculated_value
				}

				value = value * multiplier
				return value / this.displayCurrency.calculated_value
			}
		},
		formattedValue: {
			get() {
				let decimals = this.decimals !== null ? this.decimals : this.displayCurrency.decimals
				if (isNaN(this.convertedValue)){
					return ''
				}
				let number = new Intl.NumberFormat('hu-HU', {
					minimumFractionDigits: decimals,
					maximumFractionDigits: decimals}).format(this.convertedValue)


				if (this.displayCurrency.prefix){
					number = this.displayCurrency.prefix.replace('_', ' ') + number
				}
				if (this.displayCurrency.suffix){
					number = number + this.displayCurrency.suffix.replace('_', ' ')
				}

				return number
			}
		},
		displayCurrency: {
			get(){
				if (this.currencies[this.currencyId] !== undefined) {
					return this.currencies[this.currencyId]
				}
				for (let i in this.currencies){
					if (this.currencies[i].primary){
						return this.currencies[i]
					}
				}
			}
		}
	}
}
</script>

<style scoped>

</style>