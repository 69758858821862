<template>
	<div :class="{'mb-3': !fieldOnly}">
		<label v-if="!fieldOnly" :class="{required: isRequired}">{{ label }}</label>

		<div :class="{'date-invalid': errors[name]}">
			<date-picker
				:disabled="disabled"
				input-class="form-control"
				v-model:value="componentValue"
				:disabled-date="disabledDate"
				lang="hu"
			></date-picker>
			<Field
				v-model="componentValue"
				:name="name"
				:readonly="disabled"
				:disabled="disabled"
				:rules="rulesValue"
				v-slot="{ field }"
				:class="{'d-none': 1, 'is-invalid': errors[name]}"
			>
			</Field>
			<div class="invalid-feedback" v-if="errors[name]">{{ errors[name] }}</div>
			<p class="text-muted mt-2" v-if="info" v-html="info"></p>
		</div>
	</div>
</template>

<script>
	import DatePicker from 'vue-datepicker-next';
	import 'vue-datepicker-next/index.css';
	import { Field, ErrorMessage } from 'vee-validate';
	import * as yup from 'yup';
	import 'vue-datepicker-next/locale/hu';
	export default {
		components: { DatePicker, Field, ErrorMessage },
		props: {
			rules: {
				type: String,
				default: ''
			},
			disabledDate: {
				type: Function,
				default: function (){
					return false
				}
			},
			disabled: {
				type: Boolean,
				default: false
			},
			errors: {
				type: Object,
				default: function(){
					return {}
				}
			},
			label: String,
			name: String,
			modelValue: [String, Date],
			info: {
				type: String,
				default: ''
			},
			fieldOnly: Boolean
		},
		data() {
			return {
			}

		},
		computed: {
			componentValue: {
				get() {
					if (typeof this.modelValue === 'string'){
						return new Date(this.modelValue)
					}
					return this.modelValue
				},
				set(value) {
					this.$emit('update:modelValue', value)
				}
			},
			isRequired: {
				get(){
					return this.rules.indexOf('required') > -1
				}
			},
			rulesValue: {
				get() {
					let tmp = this.rules.split(',')
					let rules = yup.date()
					if (tmp.indexOf('required') > -1){
						rules = rules.required()
					} else {
						rules = rules.nullable()
					}
					rules = rules.label(this.label)

					return rules
				}
			},
		},
		methods: {
			onInput: function(){
			}
		}
	}
</script>

<style scoped>
.mx-datepicker {
	display: block !important;
	width: 100% !important;
}
</style>